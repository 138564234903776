'use strict';

var AURORA_BASE_URL = 'https://api.test.dpc.nike.io/via/v1/dpc/images',
  AURORA_REST_URL = {
    base: AURORA_BASE_URL,
    product: AURORA_BASE_URL + '/product?productId=',
    silhouette: AURORA_BASE_URL + '/silhouette?productId=',
    style: AURORA_BASE_URL + '/product?productCode=',
    styleSilhouette: AURORA_BASE_URL + '/silhouette?productCode=',
    searchByStyle: AURORA_BASE_URL + '/product/search?productCode=',
    searchById: AURORA_BASE_URL + '/product/search?productId=',
    familyByStyle: AURORA_BASE_URL + '/familyfile?productCode=',
    familyById: AURORA_BASE_URL + '/familyfile?productId='
  };

module.exports = AURORA_REST_URL;
