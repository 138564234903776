'use strict';

var angular = require('angular');
var app = require('./modules/gbip.module.js');
var initInjector = angular.injector(['ng']);
var $http = initInjector.get('$http');
var $log = initInjector.get('$log');
var $q = angular.injector(['ng']).get('$q');
var oktaService = require('./services/okta.service.js');

//https://blog.mariusschulz.com/2014/10/22/asynchronously-bootstrapping-angularjs-applications-with-server-side-data
var fetchUserData = function(restUrl) {
  return $http
    .get(restUrl.account, { withCredentials: true })
    .then(function(response) {
      var data = response.data;
      app.constant('USER_DATA', data);
    }, function(error) {
      $log.error('fetchUserData Error:', error);
    });
};

var fetchAppData = function(restUrl) {
  return $http
    .get([restUrl.base, 'data'].join('/'), { withCredentials: true })
    .then(function(response) {
      app.constant('APP_DATA', response.data);
      $log.info('App Data', response.data);
    }, function(error) {
      $log.error('fetchAppData Error:', error);
    });
};

var fetchLibraryViewCodes = function(restUrl) {
  return $http
    .get(`${restUrl.base}/assets/libraryViewCodes`, { withCredentials: true })
    .then(function(response) {
      app.constant('LIBRARY_VIEW_CODES', response.data);
      $log.info('Library View Codes:', response.data);
    }, function(error) {
      $log.error('fetchLibraryViewCodes Error:', error);
    });
};

var bootstrapApplication = function() {
  angular.element(document).ready(function() {
    angular.bootstrap(document, ['gbip']);
  });
};

module.exports = {
  fetchData: function(restUrl) {
    $http.defaults.headers.common['Authorization'] = oktaService.fetchAuthToken();
    return $q.all([fetchUserData(restUrl), fetchAppData(restUrl), fetchLibraryViewCodes(restUrl)]);
  },
  bootstrapApplication: bootstrapApplication
};
