'use strict';

var angular = require('angular');

var assetGalleryComponent = {
  bindings: {
    job: '<', // meta job
    mode: '@', // 'selection' mode is for creating Rework jobs. Otherwise, unused.
    targetStatus: '=?', // if set, will disable asset jobs that are not in specified job status
    showStatus: '=?', // if set, will display the asset job status below the view code
    hidePlaceholders: '<', // if set, will hide placeholder images for expected views that are not present
    onClick: '&',
    onSelection: '&',
    clickType: '<', // 'click' or 'dblclick' (defaults to 'click' if unset)
    viewState: '<'
  },
  require: {
    parentJobLimited: '?^jobLimited',
    parentJobFull: '?^jobFull',
    parentJobFullNew: '?^newFull',
    parentJobProblemSolve: '?^jobProblemSolve',
    parentPhotographyDetail: '?^photographyDetail'
  },
  controller: /*@ngInject*/ function(JobService, APP_DATA, META_STATUS) {
    var _self = this;
    _self.parent;

    var uploadedViews = {},
      expectedViews = {};

    _self.getImageSrc = function(asset) {
      return JobService.getImageByType(asset, 'all');
    };

    _self.handleClick = function(asset, $event, cropType) {
      //We do this instead of using _self.parent because not all possible parents have selectJob and viewDetail methods
      var parent =
        _self.parentJobLimited ||
        _self.parentJobFull ||
        _self.parentJobFullNew ||
        _self.parentJobProblemSolve;
      if (_self.mode === 'selection') {
        asset.selected = !asset.selected;
        _self.handleSelection();
        return;
      }

      // If the asset gallery is inside a job card, use the jobs click handler
      if (parent) {
        if ($event.type === 'click') {
          parent.selectJob();
        }

        if ($event.type === 'dblclick') {
          parent.viewDetail($event, asset.assetJob.id);
        }

        $event.preventDefault();
        $event.stopPropagation();

        return;
      }

      if (_self.clickType !== $event.type || !asset.assetJob) {
        return;
      }

      $event.preventDefault();
      $event.stopPropagation();

      _self.onClick({
        $event: { assetJob: asset.assetJob, cropType: cropType }
      });
    };

    _self.handleSelection = function() {
      var selectedAssets = [];

      angular.forEach(_self.model.assets, function(asset) {
        if (asset.selected) {
          selectedAssets.push(angular.copy(asset));
        }
      });

      _self.onSelection({ $event: { assets: selectedAssets } });
    };

    _self.onImageLoad = function($event, viewCode) {
      _self.model.assets[viewCode].loading = false;
    };

    _self.disableImageAsset = function(
      expectedViews,
      job,
      assetJob,
      referenceAsset
    ) {
      return (
        (expectedViews[assetJob.viewCode] === undefined &&
          job.jobType !== 'External Delivery') ||
        [
          META_STATUS.completed,
          META_STATUS.dropped,
          META_STATUS.closed
        ].indexOf(assetJob.status) !== -1
      );
    };

    _self.getModelData = function(job) {
      var model;

      uploadedViews = {};
      expectedViews = {};

      if (job.expectedViews) {
        job.expectedViews.forEach(function(view) {
          expectedViews[view] = {
            viewCode: view,
            loaded: true
          };
        });
      }

      var expectedCropTypes = job.expectedCropTypes;

      if (job.assetJobs) {
        job.assetJobs.forEach(function(assetJob) {
          assetJob.expectedCropTypes = expectedCropTypes;
          var referenceAsset = JobService.getReferenceAsset(assetJob);
          referenceAsset.expectedCropTypes = expectedCropTypes;

          uploadedViews[referenceAsset.viewCode] = {
            asset: referenceAsset,
            viewCode: referenceAsset.viewCode,
            cropType: referenceAsset.cropType,
            cropTypes: assetJob.cropTypes,
            expectedCropTypes: job.expectedCropTypes,
            status: assetJob.status,
            imgSrc: _self.getImageSrc(referenceAsset),
            assetJob: assetJob,
            disabled: _self.disableImageAsset(
              expectedViews,
              job,
              assetJob,
              referenceAsset
            ),
            loading: true
          };
        });
      }

      model = {};

      model.expectedCropTypes = job.expectedCropTypes;

      if (_self.hidePlaceholders) {
        model.assets = uploadedViews;
      } else {
        model.assets = angular.merge(expectedViews, uploadedViews);
      }

      model.keys = Object.keys(model.assets);
      model.keys.sort();

      angular.forEach(model.assets, function(asset) {
        if (_self.mode === 'selection') {
          asset.selected = !asset.disabled;
        }
      });

      return model;
    };

    _self.sortCropTypes = function() {
      if (!_self.model || !_self.model.assets) {
        return;
      }

      for (const viewCode in _self.model.assets) {
        let viewCropList = [];
        let cropTypesInOrder = null;

        for (const cropTypeIndex in _self.model.expectedCropTypes) {
          const cropType = _self.model.expectedCropTypes[cropTypeIndex];

          if (!!_self.model.assets[viewCode].cropTypes && !!_self.model.assets[viewCode].cropTypes[cropType]) {
            viewCropList.push(cropType);
          }
        }

        viewCropList = JobService.prioritySortCropTypes(viewCropList);

        if (viewCropList.length > 0) {
          cropTypesInOrder = {};

          viewCropList.forEach(cropType => {
            cropTypesInOrder[cropType] = _self.model.assets[viewCode].cropTypes[cropType];
          });
        }

        _self.model.assets[viewCode].cropTypes = cropTypesInOrder;
      }
    };

    _self.$onInit = function() {
      _self.parent =
        _self.parentJobLimited ||
        _self.parentJobFull ||
        _self.parentJobFullNew ||
        _self.parentJobProblemSolve ||
        _self.parentPhotographyDetail;
    };

    _self.$onChanges = function(changes) {
      if (changes.clickType) {
        if (!_self.clickType) {
          _self.clickType = 'click';
        } else {
          _self.clickType = angular.copy(_self.clickType);
        }
      }

      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.job = angular.copy(_self.job);

        JobService.getAvailableCropTypes().then(function() {
          _self.model = _self.getModelData(_self.job);
          _self.sortCropTypes();
        });

        if (_self.mode === 'selection') {
          _self.handleSelection();
        }
      }
    };
  },
  templateUrl: 'templates/partials/detail/assetGallery.tpl.html'
};

module.exports = assetGalleryComponent;
