'use strict';

var angular = require('angular');

var DeliverService = /*@ngInject*/ function(
  $q,
  $http,
  REST_URL,
  NotificationService,
  APP_DATA,
  AssetSearchService,
  JobService
) {
  var _self = this;

  _self.reset = function() {
    _self.data.working = false;
    _self.data.active = false;
    _self.data.valid = false;
    _self.data.error = undefined;
    _self.data.enriched = false;
    _self.data.deliveryPrep = undefined;
    _self.data.bulkDeliveryComplete = false;

    var form = _self.data.formValues;
    form.requestType = 'Manual';
    form.deliveryDestination = undefined;
    form.styleColor = undefined;
    form.shootTypeCode = undefined;
    form.merchType = undefined;

    _self.fetchAssetFilters();
  };

  _self.init = function() {
    _self.data = {
      valid: false,
      working: false,
      data: {
        cta: {
          primary: 'Create Delivery',
          secondary: 'Cancel'
        }
      },
      onSubmit: function() {
        _self.sendDelivery();
      },
      formValues: { styleColor: '' },
      validateForm: function() {
        _self.data.enriched = false;
        var form = _self.data.formValues;
        if (
          form.deliveryDestination &&
          form.requestType &&
          form.styleColor &&
          form.shootTypeCode
        ) {
          _self.data.valid = true;
          _self.setStyleColors();
          if (_self.data.styleColors) {
            if (_self.data.styleColors.length > 20) {
              _self.data.valid = false;
              _self.data.error =
                'Unable to process more than 20 style colors at once';
            }
            if (
              _self.data.styleColors.length > 0 &&
              _self.data.styleColors.length < 21
            ) {
            }
          }
        } else {
          _self.data.valid = false;
        }
        if (_self.data.valid) {
          _self.prepareDeliveries();
        }
      },
      prepareDeliveryDetails: _self.prepareDeliveries,
      resetAdmin: _self.reset,
      creatDeliveries: _self.bulkDeliver
    };
    _self.reset();
  };

  _self.createDelivery = function(deliveryTemplate) {
    var form = _self.data.formValues;
    _self.data.error = undefined;
    form.requestType = 'Manual';
    form.deliveryDestination = undefined;
    form.styleColor = undefined;
    form.shootTypeCode = undefined;
    form.merchType = undefined;

    if (deliveryTemplate.assets && deliveryTemplate.assets.length > 0) {
      //this is a manually triggered delivery that is not based on an existing Job
      form.styleColor = deliveryTemplate.assets[0].styleColor;
      form.shootTypeCode = deliveryTemplate.shootType;
      form.merchType = deliveryTemplate.merchType;
    } else if (deliveryTemplate.shootTypeCode) {
      form.styleColor = deliveryTemplate.styleColor;
      form.shootTypeCode = deliveryTemplate.shootTypeCode;
      form.deliveryDestination = form.deliveryDestinations[0]
      form.merchType = deliveryTemplate.merchType;
    }
    _self.data.validateForm();
    _self.data.active = true;
  };

  _self.sendDelivery = function() {
    _self.data.working = true;
    _self.data.error = undefined;
    var body = {};
    var formData = _self.data.formValues;
    body.requestType = formData.requestType;
    body.deliveryDestination = `${formData.deliveryDestination} (Publish)`; // BE needs full name
    body.styleColor = formData.styleColor;
    body.shootTypeCode = formData.shootTypeCode;
    body.merchType = formData.merchType;
    _self
      .deliver(body)
      .then(function(response) {
        NotificationService.push(
          'DeliveryJob for {{styleColor}} was successfully updated',
          body
        );
        _self.data.active = false;
      })
      .catch(function(error) {
        _self.data.error =
          'Delivery Job was not successfully created: ' + error.data.error;
      })
      .finally(function() {
        _self.data.working = false;
      });
  };

  _self.deliver = function(deliveryJob) {
    return $http.post([REST_URL.base, 'jobs/delivery'].join('/'), deliveryJob);
  };

  _self.bulkDeliver = function() {
    _self.data.working = true;
    var promisArr = [];
    _self.data.jobArray.forEach(job => {
      if (job.count > 0) {
        var form = angular.copy(_self.data.formValues);
        var deliveryJob = {};
        deliveryJob.requestType = form.requestType;
        deliveryJob.deliveryDestination = `${form.deliveryDestination} (Publish)`; // back end is expecting full name
        deliveryJob.merchType = form.merchType;
        deliveryJob.styleColor = job.styleColor;
        deliveryJob.shootTypeCode = form.shootTypeCode;
        var promise = _self.deliver(deliveryJob);
        promisArr.push(promise);
      } else {
        job.status = 'noDelivery';
      }
    });
    if (promisArr.length > 0) {
      $q.all(promisArr)
        .then(function(promises) {
          promises.forEach(promise => {
            var jobObj = _self.data.jobArray.filter(
              job => job.styleColor == promise.data.styleColor
            )[0];
            if (jobObj) {
              jobObj.status = 'Delivered';
            }
          });
        })
        .catch(function(error) {
          _self.data.jobArray.forEach(job => {
            if (error.config.data.styleColor == job.styleColor) {
              job.status = 'Failed';
            } else {
              job.status = 'Delivered';
            }
          });
        })
        .finally(function() {
          _self.data.working = false;
          _self.data.bulkDeliveryComplete = true;
        });
    } else {
      _self.data.working = false;
      _self.data.bulkDeliveryComplete = true;
    }
  };

  _self.prepareDeliveries = function() {
    _self.data.deliveryPrep = true;
    _self.setStyleColors();
    if (_self.data.styleColors.length > 20) {
      _self.data.error = 'Unable to process more than 20 style colors at once';
    } else if (_self.data.styleColors.length > 0) {
      var arrFilters = [
        {
          name: 'shootType',
          title: 'Shoot Type',
          facetValues: [
            {
              value: _self.data.formValues.shootTypeCode,
              selected: true
            }
          ]
        }
      ];
      var arrStyleColors = _self.data.styleColors;

      AssetSearchService.search(arrFilters, arrStyleColors, false, true)
        .then(function(response) {
          _self.createJobArray(response);
        })
        .catch(function(error) {
          _self.data.error =
            'Unable to communicate with server, if problem persists contact support.';
        })
        .finally(function() {});
    }
  };

  _self.createJobArray = function(response) {
    _self.data.enriched = true;
    _self.data.jobArray = [];
    _self.data.styleColors.forEach(styleColor => {
      var job = {};
      job.styleColor = styleColor;
      job.count = 0;
      job.status = 'Pending';
      var styleColorFacet = response.facets.filter(
        facet => facet.name == 'styleColor'
      )[0];
      if (styleColorFacet) {
        var facetValue = styleColorFacet.facetValues.filter(
          facetValue => facetValue.value == styleColor
        )[0];
        if (facetValue) {
          job.count = facetValue.count;
        }
      }
      _self.data.jobArray.push(job);
    });
    _self.data.deliveryPrep = false;
  };

  _self.setStyleColors = function() {
    if (
      _self.data.formValues.styleColor &&
      _self.data.formValues.styleColor.length > 0
    ) {
      _self.data.styleColors = [
        ...new Set(
          AssetSearchService.removeNewLines(
            _self.data.formValues.styleColor
          ).split(/\s/g)
        )
      ];
    }
  };

  _self.fetchAssetFilters = function() {
    _self.data.formValues.deliveryDestinations = [
      'NDDC Scene7',
      'Library'
    ];

    JobService.getAvailableShootTypes()
    .then(function(response) {
      _self.data.formValues.shootTypeCodes = response.data.map(function(data) {
        return {
          code: `${data.code}`
        };
      }).map(shootType => shootType.code);

    });

    _self.data.formValues.merchTypes = APP_DATA.MerchType;
  };

  _self.init();
};

module.exports = DeliverService;
