'use strict';

var angular = require('angular');

/*jshint unused: false*/
var RejectionIndicatorComponent = {
  bindings: {
    job: '<'
  },
  controller: /*@ngInject*/ function() {
    var _self = this;

    _self.$onChanges = function(changes) {
      if (changes.job) {
        if (!_self.job) {
          return;
        }
        if (_self.job['@class'] !== 'MetaJob') {
          return;
        }

        _self.rejectionStatus = _self.job.rejectionStatus;
      }
    };
  },
  templateUrl: 'templates/partials/ui/rejectionIndicator.tpl.html'
};

module.exports = RejectionIndicatorComponent;
