'use strict';

var modalKey = '$$modal';

/*jshint unused: false*/
function register(angular) {
  return [
    function modalService() {
      var modals = {};

      function get(name) {
        return modals[name];
      }

      function add(name, controller) {
        var modal = get(name);
        if (modal) {
          throw new Error('Modal named: "' + name + '" already exists.');
        }
        modals[name] = controller;
        return modals[name];
      }

      function remove(name) {
        modals[name].toggleModal(false);
        delete modals[name];
      }

      return {
        add: add,
        get: get,
        remove: remove
      };
    }
  ];
}

module.exports = register;
