'use strict';

var AssignedUsersService = /*@ngInject*/ function ($http, $q, REST_URL) {
  var _self = this;

  _self.getPostUsers = function () {
    var deferred = $q.defer();

    $http.get(`${REST_URL.base}/users/assigned-users?queue=In Post`)
    .then(function (response) {
      deferred.resolve(response.data);
    })
    .catch(function (error) {
      deferred.reject(error);
    });

    return deferred.promise;
  };
};

module.exports = AssignedUsersService;
