'use strict';

var iScrollService = require('./iscroll.service.js');
var iScrollDirective = require('./iscroll.directive.js');

function register(angular) {
  var app = angular.module('iscroll', ['ng']);

  app.service('iScrollService', iScrollService(angular));
  app.directive('iscroll', iScrollDirective(angular));

  return 'iscroll';
}

module.exports = register;
