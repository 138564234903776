'use strict';

var SEARCH_CONSTANTS = {
  dateProps: [
    'launchDate',
    'modifiedOn',
    'productReceivedDate',
    'startedOn',
    'endedOn'
  ],
  jobDetailHeaders: [
    {
      name: 'Modified On',
      id: 'modifiedOn'
    },
    {
      name: 'Modified By',
      id: 'modifiedBy'
    },
    {
      name: 'Approver',
      id: 'approver'
    },
    {
      name: 'Style-Color',
      id: 'product.styleColor'
    },
    {
      name: 'Vendor',
      id: 'product.vendorCode'
    },
    {
      name: 'Style Name',
      id: 'product.styleName'
    },
    {
      name: 'Color',
      id: 'product.color'
    },
    {
      name: 'Category',
      id: 'product.category'
    },
    {
      name: 'Age',
      id: 'product.age'
    },
    {
      name: 'Division',
      id: 'product.division'
    },
    {
      name: 'Receive Date',
      id: 'merchJob.productReceivedDate'
    },
    {
      name: 'Release Date',
      id: 'launchDate'
    },
    {
      name: 'Season',
      id: 'product.carryoverSeasons'
    },
    {
      name: 'Season',
      id: 'product.seasons'
    },
    {
      name: 'Subcategory',
      id: 'product.subcategory'
    },
    {
      name: 'Current Season',
      id: 'product.currentSeason'
    },
    {
      name: 'Product ID',
      id: 'product.productId'
    },
    {
      name: 'Job Type',
      id: 'jobType'
    },
    {
      name: 'Job ID',
      id: 'id'
    },
    {
      name: 'Assets',
      id: 'product.view'
    },
    {
      name: 'Merch Status',
      id: 'merchJob.status'
    },
    {
      name: 'Business Partner',
      id: 'businessPartner'
    },
    {
      name: 'Job Status',
      id: 'status'
    },
    {
      name: 'Studio',
      id: 'studio.studioName'
    },
    {
      name: 'Assignees',
      id: 'assignees'
    },
    {
      name: 'Rejection Status',
      id: 'rejectionStatus',
    },
    {
      name: 'Confidential',
      id: 'confidential'
    },
    {
      name: 'Priority',
      id: 'priority'
    },
    {
      name: 'Development Team',
      id: 'product.developmentTeam'
    },
    {
      name: 'Gender',
      id: 'product.gender'
    },
    {
      name: 'Silhouette',
      id: 'product.silhouette'
    },
    {
      name: 'Image Type',
      id: 'product.assets.type'
    },
    {
      name: 'Merch Type',
      id: 'merchType'
    },
    {
      name: 'Merch ID',
      id: 'primaryMerchId'
    },
    {
      name: 'Shoot Type',
      id: 'shootType.code'
    },
    {
      name: 'Expected View Codes',
      id: 'expectedViewCodes'
    },
    {
      name: 'Initiating PLM',
      id: 'product.initiatingPlm'
    },
    {
      name: 'Time in State',
      id: 'tasks.startedOn'
    }
  ],
  jobDetailHeadersHash: {},
  facetOrder: [
    'status',
    'subJobStatuses',
    'jobType',
    'shootType.code',
    'businessPartner',
    'studio.studioName',
    'priority',
    'confidential',
    'assignees',
    'modifiedBy',
    'product.assets.type',
    'product.carryoverSeasons',
    'product.vendorCode',
    'product.division',
    'product.category',
    'product.subcategory',
    'product.gender',
    'product.age',
    'product.silhouette',
    'product.team',
    'product.developmentTeam',
    'product.view',
    'launchDate',
    'receiveDate',
    'uploadDate',
    'shipmentDate',
    'user',
    'product.age',
    'product.initiatingPlm'
  ],
  priorityLabel: ['Default', 'Priority', 'Rush'],
  confidentialLabel: { true: 'Yes', false: 'No', T: 'Yes', F: 'No' }
};

// Create Hash
for (var i in SEARCH_CONSTANTS.jobDetailHeaders) {
  var jobDetail = SEARCH_CONSTANTS.jobDetailHeaders[i];
  jobDetail.predicate = jobDetail.id;
  SEARCH_CONSTANTS.jobDetailHeadersHash[jobDetail.predicate] = jobDetail;
}

module.exports = SEARCH_CONSTANTS;
