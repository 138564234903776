'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    'modalService',
    function modalDirective(modalService) {
      return {
        restrict: 'E',
        replace: true,
        transclude: true,
        templateUrl: 'templates/modal.tpl.html',
        scope: {
          name: '@',
          active: '=',
          onHide: '&',
          onShow: '&'
        },
        controller: 'modalController',
        controllerAs: 'modal',
        bindToController: true,
        link: function(scope, elem, attrs, ctrl) {
          modalService.add(ctrl.name, ctrl);

          scope.$on('$destroy', function() {
            modalService.remove(ctrl.name);
          });
        }
      };
    }
  ];
}

module.exports = register;
