'use strict';

var RequestCodeController = /*@ngInject*/ function(
  $rootScope,
  $http,
  REST_URL,
  NotificationService
) {
  var _self = this;

  _self.getCode = function() {
    _self.working = true;

    $http
      .get(REST_URL.requestCode)
      .then(function(response) {
        _self.codeObj = response.data;
      })
      .catch(function(response) {
        $rootScope.$broadcast('error', { error: response });
      })
      .finally(function() {
        _self.working = false;
      });
  };

  _self.copyCode = function() {
    if (_self.codeObj && _self.codeObj.plaintextPassword > '') {
      var el = document.createElement('textarea');
      el.value = _self.codeObj.plaintextPassword;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      NotificationService.push('Code successfully copied to clipboard.');
    }
  };
};

module.exports = RequestCodeController;
