'use strict';

var deliverComponent = {
  controller: /*@ngInject*/ function(DeliverService) {
    var _self = this;

    _self.$onInit = function() {
      _self.service = DeliverService;
      _self.data = DeliverService.data;
    };
  },
  controllerAs: 'delivery',
  templateUrl: 'templates/partials/deliver.tpl.html'
};

module.exports = deliverComponent;
