'use strict';

var angular = require('angular');
var Delegate = require('dom-delegate').Delegate;

var Scanner = /*@ngInject*/ function(
  $document,
  $timeout,
  $rootScope,
  KeymageService
) {
  var _self = this,
    inputChars = [],
    tempChars = [],
    disableShortcuts = true,
    listening = false,
    anytimeKeypress = false;

  _self.data = {};

  // if 7 or more keypresses were detected in 100ms, assume we're working with a
  // scanner. Calls itself until no more changes are detected.
  // NOTE: This will also be triggered in automation tests by the inputText function
  var validateBarcode = function() {
    if (inputChars.length >= 7) {
      // If the value hasn't changed, the scan is complete
      if (inputChars.join('') === tempChars.join('')) {
        listening = false;

        _self.set(inputChars.join(''));

        if (disableShortcuts) {
          KeymageService.enable();
        }
        return;
      }

      // If the values are different, keep checking
      tempChars = angular.copy(inputChars);

      $timeout(function() {
        validateBarcode();
      }, 100);
    } else {
      listening = false;

      if (disableShortcuts) {
        KeymageService.enable();
      }
    }
  };

  // When a keypress is detected, start listening for keys until the timer is reached
  var scanFilter = function(e) {
    var keyToChar,
      keyCode = e.which;

    keyToChar = String.fromCharCode(keyCode);

    if (keyToChar) {
      if (listening) {
        inputChars.push(keyToChar);
        return;
      }

      // Disable keyboard shortcuts (although they should already be disabled)
      disableShortcuts =
        KeymageService.getScope() === KeymageService.getDefaultScope();
      if (disableShortcuts) {
        KeymageService.disable();
      }

      listening = true;
      tempChars = [];
      inputChars = [keyToChar];

      $timeout(function() {
        validateBarcode();
      }, 100);
    }
  };

  _self.set = function(string) {
    // Broadcast scan event for other controllers to respond to
    $rootScope.$broadcast('scan.update', string);
  };

  _self.init = function() {
    var delegate = new Delegate(document.body);

    $rootScope.$on('$stateChangeSuccess', function(
      event,
      toState,
      toParams,
      fromState
    ) {
      $document.off('keypress', scanFilter);
      delegate.off('focus', 'input:not([type="checkbox"]), textarea');
      // If we are on the receive tab in Merch Management, a scan can be done without focus-ing on an input element.
      var anyTimeScan = false;
      if (toState.anyTimeScan) {
        anyTimeScan = true;
      }
      if (
        toState.views &&
        toParams.view &&
        toState.views[toParams.view].anyTimeScan
      ) {
        anyTimeScan = true;
      }

      if (anyTimeScan) {
        anytimeKeypress = true;
        $document.on('keypress', scanFilter);
      } else {
        anytimeKeypress = false;
        // listen for scans when a text input is focused
        delegate.on(
          'focus',
          'input:not([type="checkbox"]), textarea',
          function() {
            $document.on('keypress', scanFilter);
          }
        );
        delegate.on(
          'blur',
          'input:not([type="checkbox"]), textarea',
          function() {
            if (!anytimeKeypress) {
              $document.off('keypress', scanFilter);
            }
          }
        );
      }
    });
  };

  _self.init();
};

module.exports = Scanner;
