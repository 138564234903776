'use strict';

var compactJobListDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      data: '<',
      assetViewType: '=',
      disableSelection: '='
    },
    controllerAs: 'job',
    controller: 'JobController',
    templateUrl: 'templates/partials/job/lists/compact.tpl.html'
  };
};

module.exports = compactJobListDirective;
