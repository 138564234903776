'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function dropdownToggleDirective() {
      return {
        restrict: 'A',
        require: '^^dropdown',
        link: function(scope, element, attrs, dropdownCtrl) {
          if (!dropdownCtrl) {
            return;
          }

          element.addClass('dropdown-toggle');

          dropdownCtrl.toggleElement = element;

          var toggleDropdown = function(event) {
            event.preventDefault();
            event.stopPropagation();

            if (!element.hasClass('disabled') && !attrs.disabled) {
              scope.$apply(function() {
                dropdownCtrl.toggle();
              });
            }
          };

          var keybindFilter = function(event) {
            if (!dropdownCtrl.isOpen() && hotkeys.indexOf(event.which) !== -1) {
              event.preventDefault();
              event.stopPropagation();
              toggleDropdown(event);
            }
          };

          element.attr('id', dropdownCtrl.getGuid());

          var input = element[0].querySelector('input');
          var hotkeys;
          var focus = function() {
            element.addClass('is-active');
          };
          var blur = function() {
            element.removeClass('is-active');
          };

          if (input) {
            hotkeys = [38, 40]; // up, or down
            dropdownCtrl.typeahead = true;
            input.addEventListener('focus', focus);
            input.addEventListener('blur', blur);

            scope.$watch(
              function() {
                return element[0].querySelector('input').value;
              },
              function(value, old) {
                //Don't toggle the dropdown if more than one character is added at once.
                //Users can only type one character at a time, so this will let us detect when we set a value via JS.
                if (!(value.length - old.length > 2)) {
                  dropdownCtrl.toggle(value.trim().length);
                }
              }
            );
          } else {
            hotkeys = [32, 38, 40]; // space, up, or down
            element.on('click', toggleDropdown);
            element.attr('tabindex', '0');
          }

          element.on('keydown', keybindFilter);

          // WAI-ARIA
          element.attr({
            'aria-haspopup': true,
            'aria-expanded': false
          });

          scope.$watch(dropdownCtrl.isOpen, function(isOpen) {
            element.attr('aria-expanded', !!isOpen);
          });

          scope.$on('$destroy', function() {
            if (input) {
              input.removeEventListener('focus', focus);
              input.removeEventListener('blur', blur);
            } else {
              element.unbind('click', toggleDropdown);
              element.unbind('keydown', keybindFilter);
            }
          });
        }
      };
    }
  ];
}

module.exports = register;
