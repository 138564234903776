'use strict';

var angular = require('angular');
var keymage = require('keymage');

/*jshint unused: false*/
var searchResults = {
  replace: true,
  bindings: {
    model: '=', // array of jobs
    displayType: '=', // list or grid
    searchType: '=?', // 'ps' (problem solve) or undefined
    onInitiateJob: '&',
    total: '=',
    viewState: '<',
    button: '='
  },
  controller: /*@ngInject*/ function(
    $scope,
    $element,
    $filter,
    $timeout,
    CSVExporter,
    SEARCH_CONSTANTS,
    KeymageService,
    AuthService,
    SearchService,
    JobTypeService,
    USER_ROLES,
    APP_DATA,
    JobService
  ) {
    var _self = this;

    _self.data = {};
    _self.data.sort = {};
    _self.data.sortFields = [
      'product.styleColor',
      'product.vendorCode',
      'product.styleName',
      'product.color',
      'product.category',
      'product.division',
      'product.currentSeason',
      'product.productId',
      'jobType',
      'id',
      'merchJob.status',
      'shootType.code',
      'businessPartner',
      'rejectionStatus',
      'priority'
    ];

    /* Keybindings -> */

    var focusResult = function(e) {
      var mod = e.keyCode === 74 ? 1 : -1,
        labels = Array.prototype.slice.call(
          $element[0].querySelectorAll('.js-results .gbip-checkbox')
        ),
        index = labels.indexOf(document.activeElement),
        next = (index + 1 * mod) % labels.length;

      if (next < 0) {
        labels[0].focus();
      } else {
        labels[next].focus();
      }
    };

    var setKeyBindings = function() {
      var bindings = [
        {
          shortcut: 'j',
          fn: focusResult
        },
        {
          shortcut: 'k',
          fn: focusResult
        },
        {
          shortcut: 'a',
          fn: function() {
            $timeout(function() {
              _self.toggleAll(!_self.data.allSelected);
            }, 0);
          }
        },
        {
          shortcut: 'o',
          fn: function() {
            var sortElem = $element[0].querySelector(
              '.js-sort [dropdown-toggle]'
            );
            sortElem.focus();
          }
        },
        {
          shortcut: 'v',
          fn: function() {
            $timeout(function() {
              _self.displayType =
                _self.displayType === 'list' ? 'grid' : 'list';
            }, 0);
          }
        }
      ];

      KeymageService.set($scope, bindings);
    };

    /* Selecting/clicking jobs -> */

    var lastSelected = null;
    var lastIndex = null;

    _self.cardClick = function(job, ev) {
      // Note: This is not the only click function for cards. This function runs AFTER the $element.on('click') in the job controller.
      if (lastSelected && lastIndex > -1) {
        if (ev.shiftKey) {
          //select all between the previous and current selection

          // Just do nothing if they click the same job twice while holding shift.
          if (lastIndex === _self.model.indexOf(job)) {
            job.selected = !job.selected;
            return;
          }

          var selectingJobs =
            lastSelected.selected && job.selected ? true : false;
          if (_self.model.indexOf(job) > lastIndex) {
            for (var i = lastIndex; i < _self.model.indexOf(job); i++) {
              if (selectingJobs) {
                _self.model[i].selected = true;
              } else {
                _self.model[i].selected = false;
                lastSelected.selected = false;
                job.selected = true;
              }
            }
          } else if (lastIndex > _self.model.indexOf(job)) {
            for (var i = _self.model.indexOf(job); i < lastIndex; i++) {
              if (selectingJobs) {
                _self.model[_self.model.indexOf(job)].selected = true;
                _self.model[i].selected = true;
              } else {
                _self.model[i].selected = false;
                lastSelected.selected = false;
                job.selected = true;
              }
            }
          }
          // This is to prevent the text in the cards from highlighting. We do still need this functionality though, so users can copy ids/style colors.
          window.getSelection().removeAllRanges();
        } else if (ev.metaKey) {
          //select both, original behavior
        } else {
          //no shift, no ctrl, only select one
          var currentlySelected = job;

          var match = function(a, b) {
            return (
              (a.id ? a.id : '') + (a.product.id ? a.product.id : '') ===
              (b.id ? b.id : '') + (b.product.id ? b.product.id : '')
            );
          };

          for (var i = 0; i < _self.model.length; i++) {
            if (
              !match(_self.model[i], currentlySelected) &&
              _self.model[i].selected
            ) {
              _self.model[i].selected = false;
            }
          }
        }
      }

      lastSelected = job;
      lastIndex = _self.model.indexOf(job);
    };

    _self.toggleAll = function(boolean) {
      if (boolean !== undefined) {
        _self.data.allSelected = boolean;
      }
      _self.model.forEach(function(job) {
        job.selected = _self.data.allSelected;
      });
    };

    /* Sorting -> */

    _self.sortResults = function($event) {
      SearchService.setSorting(
        $event.sortObj.predicate,
        $event.sortObj.reverse
      );
    };

    _self.setSorting = function(predicate, reverse) {
      var sortObj = angular.merge(
        {},
        SEARCH_CONSTANTS.jobDetailHeadersHash[predicate],
        { reverse: reverse }
      );
      // if selected header is the currently selected one then flip the reverse boolean
      if (
        _self.data.sort.current &&
        _self.data.sort.current.predicate ===
          SEARCH_CONSTANTS.jobDetailHeadersHash[predicate].predicate
      ) {
        sortObj.reverse = !_self.data.sort.current.reverse;
      }
      _self.sortResults({ sortObj: sortObj });
    };

    /* Misc -> */
    var handleSelections = function() {
      var selectedCount = 0;

      if (!_self.model || !_self.model.length) {
        _self.data.selections = false;
        _self.data.allSelected = false;
        return;
      }

      _self.selectedJobs = _self.model.filter(function(job) {
        return !!job.selected;
      });

      _self.data.selections = _self.selectedJobs.length > 0;
      _self.data.allSelected = _self.selectedJobs.length === _self.model.length;

      if (AuthService.isAdmin()) {
        _self.button.show = _self.selectedJobs.length > 0;
        _self.button.disable = _self.selectedJobs.length > 1;
        if (_self.selectedJobs.length) {
          _self.button.cta = JobService.canUpdate(_self.selectedJobs[0].status)
            ? 'Update Job'
            : 'Initiate Job';
        }
      }
    };

    /* Init -> */

    _self.$onInit = function() {
      _self.isAdmin = AuthService.isAdmin();

      if (_self.isAdmin) {
        _self.data.sortFields.splice(12, 0, 'confidential');
      }

      _self.setSorting('modifiedOn', true);

      setKeyBindings();

      $scope.$watch(
        function() {
          return _self.model;
        },
        handleSelections,
        true
      );

      $scope.$watch(
        function() {
          return SearchService.data.sort;
        },
        function() {
          if (!SearchService.data.sort) {
            return;
          }

          _self.data.sort = angular.copy(SearchService.data.sort);
          _self.data.sort.current = angular.copy(
            SEARCH_CONSTANTS.jobDetailHeadersHash[_self.data.sort.predicate]
          );
          _self.data.sort.current.reverse = _self.data.sort.reverse;
        },
        true
      );
    };

    _self.$onDestroy = function() {
      keymage.popScope('results');
    };
  },
  templateUrl: 'templates/partials/search/results.tpl.html'
};

module.exports = searchResults;
