'use strict';

var QueueController = /*@ngInject*/ function(
  $scope,
  $state,
  QueueService,
  JobService,
  META_STATUS,
  ASSET_STATUS,
  APP_DATA,
  Session
) {
  var _self = this;

  // Allow view to access service methods
  _self.service = QueueService;

  var init = function() {
    _self.focus = 'tray';
    _self.state = $state.current.name;
    _self.displayData = $state.current.data.display;
    _self.scan = $state.current.data.scan;
    _self.showDownload =
      _self.displayData.main.status === ASSET_STATUS.postProd;

    _self.data = {};

    _self.data.sort = {
      from: {},
      to: {}
    };

    _self.error = {
      active: false
    };

    _self.showTray =
      Session.getSessionValue('showTray', _self.state) !== null
        ? Session.getSessionValue('showTray', _self.state)
        : true;
    _self.showSlateModal = false;
    _self.showPrintModal = false;

    _self.service.setParentScope(_self);

    _self.sortFields = [
      'priority',
      'product.styleColor',
      'product.currentSeason'
    ];
    _self.sortFields = $state.current.data.sortFields || _self.sortFields;
    _self.service.reset();
    _self.service.setSorting('tray', {
      predicate: _self.sortFields[0],
      reverse: true
    });
    _self.service.setSorting('main', {
      predicate: _self.sortFields[0],
      reverse: true
    });
  };

  // abstract states don't automatically refresh on state change
  // check if we came from a queue state, otherwise init gets called twice
  $scope.$on('$stateChangeSuccess', function(
    event,
    toState,
    toParams,
    fromState
  ) {
    if (fromState.name.indexOf('queue') >= 0) {
      init();
    }
  });

  $scope.$on('scan.update', function(e, info) {
    // Exit if the queue doesn't support scanning
    if (!_self.scan) {
      return;
    }
    JobService.getJobDetail(info.split('-')[info.split('-').length - 1])
      .then(function(response) {
        var job = response.data,
          // Return which queue (if any) the scanned job is in
          queue = _self.service.isValidScan(_self.displayData, job);

        // Show the update modal if job is in a queue and not in Problem Solve
        if (queue && job.status !== META_STATUS.problemSolve) {
          _self.service.confirmUpdate(
            [job],
            _self.displayData[queue].updateParams,
            queue
          );
        } else {
          _self.setErrorModal(job);
        }
      })
      .catch(function() {
        $scope.$emit('scan.error');
      });
  });

  // Rock n Roll
  init();
};

module.exports = QueueController;
