'use strict';

var angular = require('angular');

var statusChangeComponent = {
  bindings: {
    model: '<', // array of jobs to be updated
    active: '=',
    onChange: '&',
    showViewsOnChange: '='
  },
  controller: /*@ngInject*/ function (
    $window,
    $scope,
    $state,
    $timeout,
    StatusService,
    SearchService,
    NoteService,
    UPDATE_PARAMS
  ) {
    var _self = this;

    _self.data = StatusService.data;
    _self.requiresSlateNoteReprinting = false;

    _self.setAssignee = function(user) {
      StatusService.setAssignee(user);
      _self.validate();
    };

    _self.doUpdate = function () {

      if (_self.requiresSlateNoteReprinting) {
        $window.open(
          $state.href(
            'slates',
            {
              ids: [_self.model[0].id]
            },
            { absolute: true }
          ),
          '_blank'
        );
      }

      var specialUpdateParams;

      _self.data.working = true;

      // If no user was selected during reassignment, we're actually unassigning
      if (_self.data.params.reassign && !_self.data.params.assignee.userName) {
        var queueName = $state.current.name.substring(6);
        specialUpdateParams = angular.merge({}, _self.data.params, {
          type: UPDATE_PARAMS[queueName].unassign.type,
          notification: UPDATE_PARAMS[queueName].unassign.notification
        });
      }

      StatusService.updateJobs(_self.model, specialUpdateParams)
        .then(function(jobs) {
          _self.onChange({ $event: { jobs: jobs, params: _self.data.params } });
        })
        .finally(function() {
          _self.data.working = false;
        });
    };

    _self.validate = function() {
      if (_self.data.working) {
        $timeout(function() {
          _self.validate();
        }, 500);
        _self.data.isValid = false;
        return;
      }
      _self.data.isValid =
        !_self.data.params.showAssigneeDropdown ||
        (_self.data.params.showAssigneeDropdown &&
          !!_self.data.params.status.value);
    };

    _self.$onInit = function() {
      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          if (_self.active) {
            _self.validate();
          }
        }
      );
      $scope.$watch(
        function () {
          return _self.requiresSlateNoteReprinting;
        },
        function () {
          if (_self.data && _self.data.params && _self.data.params.cta) {
            _self.data.params.cta.primary = (!!_self.requiresSlateNoteReprinting) ? 'Print and Update' : 'Submit';
          }
        }
      );
    };

    _self.checkSlateNoteDate = function (job) {
      let applicableMerchJobStates = ['In Ops', 'Ready for Styling', 'In Styling', 'Prep for Outbound'];
      if (!job.merchJob || job.merchJob.tasks.length == 0 || applicableMerchJobStates.indexOf(job.merchJob.tasks[0].name) === -1 || _self.data.params.type !== "merchJob") {
        return;
      }
      let taskStartedOn = new Date(job.merchJob.tasks[0].startedOn);

      SearchService.findProduct(undefined, 'by-product-id', job.product.productId).then(function (response) {
        if (response.data.jobs && response.data.jobs.length > 0) {
          let dbJob = response.data.jobs.find(j => j.id === job.id);
          if (dbJob) {
            let note = NoteService.getNotes(dbJob, 'SlateNote');
            if (note && note.length > 0) {              
              let noteCreatedOn = new Date(note[0].createdOn);
              // If you send a job into InOps with a slate note, the slate note is created a second after the merchJob - therefore forcing a 2nd print immediately after the first
              taskStartedOn.setSeconds(taskStartedOn.getSeconds() + 3);
              if (noteCreatedOn > taskStartedOn) {
                _self.requiresSlateNoteReprinting = true;
                _self.data.params.cta.primary = "Print and Update";
              }
            }
          }
        }
      });
    }

    _self.$onChanges = function (changes) {
      _self.data.showViewsOnChange = angular.copy(_self.showViewsOnChange);
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);
        _self.requiresSlateNoteReprinting = false;

        if (_self.model.length === 1) {
          _self.checkSlateNoteDate(_self.model[0]);
        }

        if (!angular.isArray(_self.model)) {
          _self.model = [_self.model];
        }
      }
    };
  },
  templateUrl: 'templates/partials/modal.status.tpl.html'
};

module.exports = statusChangeComponent;
