'use strict';

var onLoadDirective = /*@ngInject*/ function($timeout) {
  return {
    restrict: 'A',
    scope: {
      onLoad: '&'
    },
    link: function(scope, elem, attrs) {
      elem.on('load', function() {
        $timeout(function() {
          scope.onLoad({ $event: { src: attrs.src } });
        }, 0);
      });
    }
  };
};

module.exports = onLoadDirective;
