'use strict';

var angular = require('angular');

var MERCH_STATUS = require('../config/merchStatus.constant.js');
var ASSET_STATUS = require('../config/assetStatus.constant.js');
var META_STATUS = require('../config/metaStatus.constant.js');
var USER_ROLES = require('../config/userRoles.constant.js');

var notifications = {
  statusUpdate: {
    single:
      'Job {{jobs[0].id}} has been updated to {{jobs[0].merchJob.status}}.',
    plural:
      '{{ids.length}} jobs have been updated to {{jobs[0].merchJob.status}}.'
  },
  assign: {
    single: 'Job {{ids[0]}} has been assigned to {{user}}.',
    plural: '{{ids.length}} jobs have been assigned to {{user}}.'
  },
  unassign: {
    single: 'Job {{jobs[0].id}} has been unassigned.',
    plural: '{{ids.length}} jobs have been unassigned.'
  }
};

var copyOptions = {
  assign: {
    self: "Clicking 'Submit' will assign this job to you.",
    other: "Clicking 'Submit' will assign this job to the selected user."
  },
  unassign: {
    jobs: 'The following jobs will be unassigned:',
    assets: 'The following assets will be unassigned:'
  }
};

var postProdOptions = {
  assign: {
    self:
      "Clicking 'Submit' will assign the job(s) below to you and add the Assets to your FTP Inbox.",
    other:
      "Clicking 'Submit' will assign the job(s) below to the selected user and add the Assets to their FTP Inbox."
  },
  unassign: {
    jobs: 'The following jobs will be unassigned:',
    assets: 'The following assets will be unassigned:'
  }
};

var getNextMerchStatus = function(currentStatus) {
  return MERCH_STATUS.allStatuses[MERCH_STATUS.allStatuses.indexOf(currentStatus) + 1];
};

var setMerchUpdateParams = function(currentStatus, overwrites) {
  overwrites = overwrites || {};

  return angular.merge(
    {},
    {
      status: {
        value: getNextMerchStatus(currentStatus)
      },
      type: 'merchJob',
      notification: notifications.statusUpdate
    },
    overwrites
  );
};

var UPDATE_PARAMS = {
  jobPending: {
    status: {
      value: MERCH_STATUS.inOps
    },
    type: 'tasks',
    notification: notifications.statusUpdate
  },
  inOps: setMerchUpdateParams(MERCH_STATUS.inOps),
  readyForStyling: setMerchUpdateParams(MERCH_STATUS.readyForStyling),
  inStyling: setMerchUpdateParams(MERCH_STATUS.inStyling),
  prepForOutbound: setMerchUpdateParams(MERCH_STATUS.prepForOutbound, {
    notification: {
      single: 'Merch Workflow is now completed for Job {{jobs[0].id}}',
      plural: 'Merch Workflow is now completed for {{ids.length}} jobs'
    }
  }),
  qa: {
    assign: {
      heading: {
        title: 'Assign'
      },
      type: 'claimMeta',
      preview: 'assets',
      notification: notifications.assign,
      selfAssignmentRoles: [
        USER_ROLES.qaReviewer,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ],
      assignmentRole: ['QA_REVIEWER'],
      copyOptions: copyOptions.assign,
      targetAssetStatus: META_STATUS.qa
    },
    unassign: {
      heading: {
        title: 'Unassign',
        copy: copyOptions.assets
      },
      status: false,
      type: 'unclaimMeta',
      preview: 'assets',
      notification: notifications.unassign,
      targetAssetStatus: META_STATUS.qa
    }
  },
  'problem-solve': {
    assign: {
      heading: {
        title: 'Assign'
      },
      type: 'claimMeta',
      notification: notifications.assign,
      selfAssignmentRoles: [USER_ROLES.studioOps, USER_ROLES.superUser],
      assignmentRole: 'STUDIO_OPS',
      copyOptions: copyOptions.assign
    },
    unassign: {
      heading: {
        title: 'Unassign',
        copy: copyOptions.jobs
      },
      status: false,
      type: 'unclaimMeta',
      notification: notifications.unassign
    }
  },
  'post-prod': {
    assign: {
      heading: {
        title: 'Assign'
      },
      type: 'claimMeta',
      preview: 'assets',
      download: false,
      notification: notifications.assign,
      selfAssignmentRoles: [
        USER_ROLES.imageEditor,
        USER_ROLES.externalImageEditor,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.superUser
      ],
      assignmentRole: [
        'IMAGE_EDITOR',
        'SENIOR_IMAGE_EDITOR',
        'EXTERNAL_IMAGE_EDITOR'
      ],
      copyOptions: postProdOptions.assign,
      primaryCtaOptions: {
        self: 'Submit',
        other: 'Submit'
      },
      targetAssetStatus: META_STATUS.post
    },
    unassign: {
      heading: {
        title: 'Unassign',
        copy: copyOptions.assets
      },
      status: false,
      type: 'unclaimMeta',
      preview: 'assets',
      notification: notifications.unassign,
      targetAssetStatus: META_STATUS.post
    }
  },
  special: {
    reassign: {
      reassign: true,
      heading: {
        title: 'Reassign',
        copy: copyOptions.assign.other
      },
      copyOptions: null
    }
  }
};

module.exports = UPDATE_PARAMS;
