'use strict';

var angular = require('angular');

var PrintSlateController = /*@ngInject*/ function(
  $q,
  $http,
  $timeout,
  REST_URL,
  $stateParams
) {
  var _self = this;
  var ids = $stateParams.ids.split(',');
  _self.makeGetRequests = function(ids) {
    var promises = ids.map(function(id) {
      var deferred = $q.defer();

      $timeout(function() {
        $http
          .get([REST_URL.jobs, id].join('/'))
          .success(function(data) {
            deferred.resolve(data);
          })
          .error(function() {
            deferred.reject();
          });
      }, 1000);

      return deferred.promise;
    });

    return $q.all(promises);
  };

  _self.makeGetRequests(ids).then(function(data) {
    _self.jobs = data;

    // TODO this should fire after images are loaded
    $timeout(function() {
      window.print();
      $timeout(function() {
        window.close();
      }, 100);
    }, 2000);
  });

  _self.hideHeader = function() {
    var header = document.getElementsByClassName('gbip-header'),
      wrappedHeader = angular.element(header);
    wrappedHeader.remove();
  };

  _self.hideHeader();
};

module.exports = PrintSlateController;
