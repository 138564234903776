'use strict';

var IconComponent = {
  bindings: {
    id: '@',
    base: '<'
  },
  controller: /*@ngInject*/ function($element) {
    var _self = this;

    function toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }

    _self.$onChanges = function(changes) {
      if (changes.id) {
        if (_self.base) {
          var useElem = $element[0].querySelector('#baseimg');

          toDataURL('../../../assets/svg/ui/' + _self.id + '.svg', res => {
            useElem.setAttribute('src', res);
          });
        } else {
          var useElem = $element[0].querySelector('use');
          useElem.setAttributeNS(
            'http://www.w3.org/1999/xlink',
            'xlink:href',
            ['#', _self.id].join('')
          );
        }
      }
    };
  },
  templateUrl: 'templates/partials/ui/icon.tpl.html'
};

module.exports = IconComponent;
