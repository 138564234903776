'use strict';

var qrDirective = require('./qr.directive.js');

function register(angular) {
  var app = angular.module('qr', ['ng']);

  app.directive('qrCode', qrDirective(angular));
  app.run([
    '$templateCache',
    function($templateCache) {
      $templateCache.put(
        'templates/qr.tpl.html',
        '<div class="qr-code"></div>'
      );
    }
  ]);

  return 'qr';
}

module.exports = register;
