'use strict';

// Service for Do Not Use requests to delete assets from the BAR
var AssetDeleteService = /*@ngInject*/ function ($http, $q, REST_URL) {
  var _self = this;

  _self.deleteAssets = function (assetIds) {
    var deferred = $q.defer();

    return $http.post(`${REST_URL.base}/assets/do-not-use`, { assetIds: assetIds }).then(
      function (response) {
        deferred.resolve(response.data);
      },
      function () {
        deferred.reject();
      }
    );
  };
};

module.exports = AssetDeleteService;
