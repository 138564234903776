'use strict';

var angular = require('angular');

var RepublishService = /*@ngInject*/ function($q, $http, REST_URL, APP_DATA) {
  var _self = this;

  var publishStatus = {};

  _self.republishAssets = function(jobIds) {
    var jobIdArray = jobIds.split('\n');
    for (var i = 0; i < jobIdArray.length; i++) {
      _self.lookupAssets(jobIdArray[i]);
    }
  };

  _self.lookupAssets = function(job) {
    $http.get([REST_URL.base, 'jobs', job].join('/')).then(function(response) {
      var nestedAssetFilenames = response.data.assetJobs.map(function(
        assetJob
      ) {
        return assetJob.assetFilenames;
      });
      var finalFiles = [].concat
        .apply([], nestedAssetFilenames)
        .filter(function(assetFilename) {
          return assetFilename.toLowerCase().endsWith('.png');
        });
      finalFiles.forEach(function(fileName) {
        publishStatus[fileName] = 'Initiated';
        _self.forcePublish(fileName);
      });
    });
  };

  _self.forcePublish = function(fileName) {
    $http
      .get([REST_URL.base, 'asset', 'publish', fileName].join('/'))
      .then(function(response) {
        publishStatus[fileName] = response.data;
      });
  };

  return _self;
};

module.exports = RepublishService;
