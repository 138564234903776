'use strict';

// seasonSummaryReports
// http://apidoc.nike.ixxus.io/#!/default/get_cpspub_sync_reports
//
// SyncGlobalSummaryReport {
// seasonSummaryReports (Array[SyncSeasonSummaryReport], optional),
// syncActionDetailedReports (Array[SyncActionReportDetailed], optional)
// }
// SyncSeasonSummaryReport {
// seasonCode (string, optional): The SeasonCode ,
// previousProductCount (integer, optional): How many active products were there before this season? ,
// reports (Array[SyncActionReport], optional)
// }
// SyncActionReportDetailed {
// results (Array[SyncActionResult], optional): Information about Sync Action ,
// action (string, optional): Action taken upon Sync ,
// count (integer, optional): How many of these actions were taken?
// }
// SyncActionReport {
// action (string, optional): Action taken upon Sync ,
// count (integer, optional): How many of these actions were taken?
// }
// SyncActionResult {}

var MOCK_DATA_ACTIVITY = {};

MOCK_DATA_ACTIVITY['seasonSummaryReports'] = [
  {
    // date?
    seasonCode: 'FA15', // FA, WI, SP, SU + ##
    previousProductCount: 438,
    reports: [
      {
        action: 'new',
        count: 283
      },
      {
        action: 'dropped',
        count: 64
      }
    ]
  },
  {
    // date?
    seasonCode: 'WI15', // FA, WI, SP, SU + ##
    previousProductCount: 500,
    reports: [
      {
        action: 'new',
        count: 200
      },
      {
        action: 'dropped',
        count: 100
      }
    ]
  },
  {
    // date?
    seasonCode: 'SP15', // FA, WI, SP, SU + ##
    previousProductCount: 367,
    reports: [
      {
        action: 'new',
        count: 353
      },
      {
        action: 'dropped',
        count: 34
      }
    ]
  },
  {
    // date?
    seasonCode: 'SU15', // FA, WI, SP, SU + ##
    previousProductCount: 358,
    reports: [
      {
        action: 'new',
        count: 368
      },
      {
        action: 'dropped',
        count: 123
      }
    ]
  },
  {
    // date?
    seasonCode: 'FA15', // FA, WI, SP, SU + ##
    previousProductCount: 346,
    reports: [
      {
        action: 'new',
        count: 444
      },
      {
        action: 'dropped',
        count: 39
      }
    ]
  },
  {
    // date?
    seasonCode: 'WI16', // FA, WI, SP, SU + ##
    previousProductCount: 222,
    reports: [
      {
        action: 'new',
        count: 777
      },
      {
        action: 'dropped',
        count: 444
      }
    ]
  },
  {
    // date?
    seasonCode: 'SP16', // FA, WI, SP, SU + ##
    previousProductCount: 964,
    reports: [
      {
        action: 'new',
        count: 246
      },
      {
        action: 'dropped',
        count: 368
      }
    ]
  }
];

MOCK_DATA_ACTIVITY['detailedActionReports'] = {
  duplicate: {
    results: [
      {
        id: '510',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:17.683+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6127',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:17.749+0000'
          }
        ],
        product: {
          productId: '0000016',
          division: 'Apparel',
          modelName: 'SB50 NFL Carolina Panthers Game Jersey (Cam Newton)',
          gender: 'Men',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'SB50 NFL Carolina Panthers Game Jersey (Cam Newton)',
          styleColor: '689306-010',
          color: 'Black',
          primaryColor: 'Black',
          fullColorDescription: 'Black/Tidal Blue',
          category: 'Football',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 16
        },
        notes: [],
        merchJob: {
          id: '6105',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:17.739+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6114',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:17.733+0000'
            }
          ],
          metaJobId: '510',
          productReceivedDate: '2016-03-29T19:29:17.731+0000'
        },
        assetJobs: [],
        launchDate: '2016-08-02T04:00:00.000+0000',
        priority: 2,
        confidential: false
      },
      {
        id: '542',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:17.893+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6152',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:17.948+0000'
          }
        ],
        product: {
          productId: '0000017',
          division: 'Apparel',
          modelName: 'SB50 NFL Carolina Panthers Game Jersey (Luke Kuechly)',
          gender: 'Men',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'SB50 NFL Carolina Panthers Game Jersey (Luke Kuechly)',
          styleColor: '689306-011',
          color: 'Black',
          primaryColor: 'Black',
          fullColorDescription: 'Black/Tidal Blue',
          category: 'Football',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 17
        },
        notes: [],
        merchJob: {
          id: '6130',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:17.940+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6139',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:17.937+0000'
            }
          ],
          metaJobId: '542',
          productReceivedDate: '2016-03-29T19:29:17.935+0000'
        },
        assetJobs: [],
        launchDate: '2016-08-02T04:00:00.000+0000',
        priority: 2,
        confidential: false
      },
      {
        id: '830',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:18.631+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6252',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:18.691+0000'
          }
        ],
        product: {
          productId: '0000026',
          division: 'Footwear',
          modelName: 'Nike Blazer',
          gender: 'Women',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'Nike Blazer',
          styleColor: '818730-400',
          color: 'Midnight Navy',
          primaryColor: 'Midnight Navy',
          fullColorDescription: 'Midnight Navy/White',
          category: 'Golf',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 26
        },
        notes: [],
        merchJob: {
          id: '6230',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:18.683+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6239',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:18.680+0000'
            }
          ],
          metaJobId: '830',
          productReceivedDate: '2016-03-29T19:29:18.679+0000'
        },
        assetJobs: [],
        launchDate: '2016-06-23T04:00:00.000+0000',
        priority: 1,
        confidential: false
      }
    ],
    action: 'string',
    count: 3
  },
  reactivate: {
    results: [
      {
        id: '1118',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:18.998+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6302',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:19.056+0000'
          }
        ],
        product: {
          productId: '0000035',
          division: 'Apparel',
          modelName: 'Nike Hyper Elite Crossover Crew',
          gender: 'Unisex',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'Nike Hyper Elite Crossover Crew',
          styleColor: 'SX5232-453',
          color: 'Soar',
          primaryColor: 'Soar',
          fullColorDescription: 'Soar/Black/Black',
          category: 'Basketball',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 35
        },
        notes: [],
        merchJob: {
          id: '6280',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:19.049+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6289',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:19.046+0000'
            }
          ],
          metaJobId: '1118',
          productReceivedDate: '2016-03-29T19:29:19.044+0000'
        },
        assetJobs: [],
        launchDate: '2016-05-05T04:00:00.000+0000',
        priority: 1,
        confidential: false
      },
      {
        id: '1150',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:19.445+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6352',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:19.499+0000'
          }
        ],
        product: {
          productId: '0000036',
          division: 'Apparel',
          modelName: 'Nike Hyper Elite Crossover Crew',
          gender: 'Unisex',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'Nike Hyper Elite Crossover Crew',
          styleColor: 'SX5232-100',
          color: 'White',
          primaryColor: 'White',
          fullColorDescription: 'White/Black/Black',
          category: 'Basketball',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 36
        },
        notes: [],
        merchJob: {
          id: '6330',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:19.491+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6339',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:19.488+0000'
            }
          ],
          metaJobId: '1150',
          productReceivedDate: '2016-03-29T19:29:19.487+0000'
        },
        assetJobs: [],
        launchDate: '2016-05-05T04:00:00.000+0000',
        priority: 1,
        confidential: false
      }
    ],
    action: 'string',
    count: 2
  },
  drop: {
    results: [
      {
        id: '510',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:17.683+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6127',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:17.749+0000'
          }
        ],
        product: {
          productId: '0000016',
          division: 'Apparel',
          modelName: 'SB50 NFL Carolina Panthers Game Jersey (Cam Newton)',
          gender: 'Men',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'SB50 NFL Carolina Panthers Game Jersey (Cam Newton)',
          styleColor: '689306-010',
          color: 'Black',
          primaryColor: 'Black',
          fullColorDescription: 'Black/Tidal Blue',
          category: 'Football',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 16
        },
        notes: [],
        merchJob: {
          id: '6105',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:17.739+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6114',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:17.733+0000'
            }
          ],
          metaJobId: '510',
          productReceivedDate: '2016-03-29T19:29:17.731+0000'
        },
        assetJobs: [],
        launchDate: '2016-08-02T04:00:00.000+0000',
        priority: 2,
        confidential: false
      },
      {
        id: '542',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:17.893+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6152',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:17.948+0000'
          }
        ],
        product: {
          productId: '0000017',
          division: 'Apparel',
          modelName: 'SB50 NFL Carolina Panthers Game Jersey (Luke Kuechly)',
          gender: 'Men',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'SB50 NFL Carolina Panthers Game Jersey (Luke Kuechly)',
          styleColor: '689306-011',
          color: 'Black',
          primaryColor: 'Black',
          fullColorDescription: 'Black/Tidal Blue',
          category: 'Football',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 17
        },
        notes: [],
        merchJob: {
          id: '6130',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:17.940+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6139',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:17.937+0000'
            }
          ],
          metaJobId: '542',
          productReceivedDate: '2016-03-29T19:29:17.935+0000'
        },
        assetJobs: [],
        launchDate: '2016-08-02T04:00:00.000+0000',
        priority: 2,
        confidential: false
      },
      {
        id: '830',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:18.631+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6252',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:18.691+0000'
          }
        ],
        product: {
          productId: '0000026',
          division: 'Footwear',
          modelName: 'Nike Blazer',
          gender: 'Women',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'Nike Blazer',
          styleColor: '818730-400',
          color: 'Midnight Navy',
          primaryColor: 'Midnight Navy',
          fullColorDescription: 'Midnight Navy/White',
          category: 'Golf',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 26
        },
        notes: [],
        merchJob: {
          id: '6230',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:18.683+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6239',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:18.680+0000'
            }
          ],
          metaJobId: '830',
          productReceivedDate: '2016-03-29T19:29:18.679+0000'
        },
        assetJobs: [],
        launchDate: '2016-06-23T04:00:00.000+0000',
        priority: 1,
        confidential: false
      },
      {
        id: '830',
        status: 'In Process',
        modifiedOn: '2016-03-29T19:29:18.631+0000',
        modifiedBy: 'gbipsuperuser@gmail.com',
        tasks: [
          {
            id: '6252',
            name: 'In Process',
            description: 'In Process',
            startedOn: '2016-03-29T19:29:18.691+0000'
          }
        ],
        product: {
          productId: '0000026',
          division: 'Footwear',
          modelName: 'Nike Blazer',
          gender: 'Women',
          carryoverSeasons: [],
          initiatingSeason: 'SP16',
          styleName: 'Nike Blazer',
          styleColor: '818730-400',
          color: 'Midnight Navy',
          primaryColor: 'Midnight Navy',
          fullColorDescription: 'Midnight Navy/White',
          category: 'Golf',
          developmentTeam: 'Not Assigned',
          assets: [],
          id: 26
        },
        notes: [],
        merchJob: {
          id: '6230',
          status: 'In Ops',
          modifiedOn: '2016-03-29T19:29:18.683+0000',
          modifiedBy: 'gbipsuperuser@gmail.com',
          tasks: [
            {
              id: '6239',
              name: 'In Ops',
              description: 'In Ops',
              startedOn: '2016-03-29T19:29:18.680+0000'
            }
          ],
          metaJobId: '830',
          productReceivedDate: '2016-03-29T19:29:18.679+0000'
        },
        assetJobs: [],
        launchDate: '2016-06-23T04:00:00.000+0000',
        priority: 1,
        confidential: false
      }
    ],
    action: 'string',
    count: 4
  }
};

module.exports = MOCK_DATA_ACTIVITY;
