'use strict';

var angular = require('angular');

var taskComponent = {
  bindings: {
    job: '<',
    targetStatus: '<'
  },
  controller: /*@ngInject*/ function($interpolate) {
    var _self = this,
      views = {
        job: '{{length}} {{string}}'
      };

    var setViewString = function() {
      var assetLength = _self.job.assetJobs ? _self.job.assetJobs.length : 0;

      _self.viewString = $interpolate(views.job)({
        length: assetLength,
        string: assetLength !== 1 ? 'views' : 'view'
      });
    };

    _self.$onChanges = function(changes) {
      if (changes.job) {
        if (!_self.job) {
          return;
        }

        _self.job = angular.copy(_self.job);
        setViewString();
      }

      if (changes.targetStatus) {
        if (!_self.job) {
          return;
        }

        setViewString();
      }
    };
  },
  templateUrl: 'templates/partials/task/list.tpl.html'
};

module.exports = taskComponent;
