'use strict';

/*jshint unused: false*/
var PriorityComponent = {
  bindings: {
    type: '<',
    base: '<'
  },
  controller: /*@ngInject*/ function() {
    var _self = this;
    _self.ids = ['', 'p', 'r'];
    _self.label = ['', 'Priority', 'Rush'];

    _self.$onChanges = function(changes) {
      if (changes.type) {
        _self.id = _self.ids[_self.type];
      }
    };
  },
  templateUrl: 'templates/partials/ui/priority.tpl.html'
};

module.exports = PriorityComponent;
