'use strict';

var initInjector = angular.injector(['ng']);
var $log = initInjector.get('$log');

var httpError = require('./httpError.config.js');
var oktaService = require('../services/okta.service.js');

var authInterceptor = /*@ngInject*/ function(
  $q,
  $rootScope,
  AUTH_EVENTS,
  USER_DATA,
  REST_URL
) {
  return {
    request: function(request) {
      if (oktaService.isAccessTokenExpired()) {
        oktaService.logout();
        return;
      }
      request.headers['Authorization'] = oktaService.fetchAuthToken();
      return request;
    },
    response: function(response) {
      if (httpError.requestWasUnAuthorized(response)) {
        $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
        return $q.reject(response);
      } else {
        return response || $q.when(response);
      }
    },
    responseError: function(response) {

      //https://github.com/angular/angular.js/issues/2609
      if (
        response.status === -1 ||
        httpError.requestWasUnAuthorized(response)
      ) {
        //Check if $http is not cancelled by UI
        if (
          response.config.timeout !== undefined &&
          response.config.timeout.$$state.status !== 1
        ) {
          $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
        }
      }
      if (response.config.url && response.config.url.startsWith(REST_URL.base)) {
        if (response.status === 403) {
          //this user is logged in, but doesn't have permissions to do what they are doing
          $rootScope.$broadcast('error', {
            error: response.data.error,
            title: 'User role not authorized',
            copy:
              "This user's roles: " +
              JSON.stringify(USER_DATA.roles) +
              ' are not authorized to complete this action. Contact ICON production support for assistance.'
          });

          response.handled = true;
        } else if (response.status === 401) {
          //this user is not logged in, send them to login
          $rootScope.$broadcast('error', {
            error: response.data.error,
            title: 'User authorization is expired',
            copy:
              'The current session is expired, redirecting to login...'
          });

          response.handled = true;

          oktaService.logout();
        }
      }

      return $q.reject(response);
    }
  };
};

var lineartInterceptor = function(
  $q,
  $rootScope,
  AUTH_EVENTS,
  USER_DATA,
  REST_URL,
  AURORA_REST_URL
) {
  return {
    // optional method
    'request': function (config) {
      if (config.url.startsWith(AURORA_REST_URL.base) ) {
        config.withCredentials = false;
      }

      return config;
    }
  };
};

var HttpConfig = /*@ngInject*/ function($httpProvider) {
  $httpProvider.defaults.withCredentials = true;
  $httpProvider.interceptors.push(authInterceptor);
  $httpProvider.interceptors.push(lineartInterceptor);
};

module.exports = HttpConfig;
