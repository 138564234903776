'use strict';

var angular = require('angular');

var LinkedProductService = /*@ngInject*/ function($http, REST_URL) {
  var _self = this;

  _self.confirmLink = function(link, jobId) {
    var params = {};
    params.fromJobId = jobId;
    params.toStyleColor = link.styleColor;
    params.status = 'PENDING';
    return _self.sendLink(params);
  };

  _self.skipLink = function(link, jobId) {
    var params = {};
    params.fromJobId = jobId;
    params.toStyleColor = link.styleColor;
    params.status = 'DEFERRED';
    return _self.sendLink(params);
  };

  _self.sendLink = function(params) {
    return $http.post(REST_URL.base + '/jobs/links', params);
  };
};

module.exports = LinkedProductService;
