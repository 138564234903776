'use strict';

var angular = require('angular');

var CSVExporter = /*@ngInject*/ function(SEARCH_CONSTANTS, $log) {
  var _self = this;

  //http://jsfiddle.net/hybrid13i/JXrwM/
  function JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';
    //Set Report title in first row or line

    CSV += ReportTitle + '\r\n\n';

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = '';

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = '';

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        let value = arrData[i][index];
        if (/[',"]/g.test(value)) {
          value = `"${value.replace(/"/g, '""')}"`;
        }

        row += value + ',';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV === '') {
      alert('Invalid data');
      return;
    }

    //Generate a file name
    var fileName = 'MyReport_';
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, '_');

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement('a');
    link.href = uri;

    link.download = fileName + '.csv';

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // jshint ignore: end

  function downloadJobs(jobList, title) {
    var downloadJobList = [];

    // No authentication required
    if (jobList === null) {
      return true;
    }

    if (!angular.isArray(jobList)) {
      jobList = [jobList];
    }

    jobList.forEach(function(job) {
      var targetJob = {},
        assets = job.product.assets || [];

      if (job.assetStatusExcelDisplay === undefined && job.assetJobs) {
        job.assetStatusExcelDisplay = '';

        assets.forEach(function(assetItem) {
          job.assetStatusExcelDisplay += assetItem.viewCode + ' ';
        });
      }

      if (job.selected) {
        // headers
        for (var header in _self.jobDetailHeadersHash) {
          if (_self.jobDetailHeadersHash[header].category) {
            if (job[_self.jobDetailHeadersHash[header].category]) {
              targetJob[_self.jobDetailHeadersHash[header].name] =
                job[_self.jobDetailHeadersHash[header].category][
                  _self.jobDetailHeadersHash[header].id
                ];
            } else {
              targetJob[_self.jobDetailHeadersHash[header].name] = '';
            }
          } else {
            targetJob[_self.jobDetailHeadersHash[header].name] =
              job[_self.jobDetailHeadersHash[header].id];
          }

          targetJob[_self.jobDetailHeadersHash[header].name] =
            targetJob[_self.jobDetailHeadersHash[header].name] || '';
        }

        // values
        targetJob['Style-Color'] = job.product.styleColor || '';
        targetJob['Style Name'] = job.product.styleName || '';
        targetJob['Color'] = job.product.color || '';
        targetJob['Category'] = job.product.category || '';
        targetJob['Division'] = job.product.division || '';
        targetJob['Initiating Season'] = job.product.initiatingSeason || '';
        targetJob['Product ID'] = job.product.productId || '';
        targetJob['Job Type'] = job.jobType || '';

        targetJob['Business Partner'] = job.businessPartner || '';

        targetJob['Shoot Type'] =
          job.shootType && job.shootType.code ? job.shootType.code : '';
        targetJob['Merch Status'] = '';
        if (job.merchJob) {
          targetJob['Merch Status'] = job.merchJob.status || '';
        }

        if (job.assetJobs) {
          targetJob['Assets'] = job.assetJobs.length;
        } else {
          targetJob['Assets'] = 0;
        }

        if (job.studio) {
          targetJob['Studio'] = job.studio.studioName || '';
        }

        // can't use '@class' because Product is hacked to look like a MetaJob
        $log.debug('Job Type:', job.jobType);
        if (job.jobType === undefined && job.product) {
          targetJob['Confidential'] = SEARCH_CONSTANTS.confidentialLabel[job.product.confidential] || '';
        } else {
          targetJob['Confidential'] = SEARCH_CONSTANTS.confidentialLabel[targetJob['Confidential']] || '';
        }

        targetJob['Priority'] =
          SEARCH_CONSTANTS.priorityLabel[targetJob['Priority']] || '';

        downloadJobList.push(targetJob);
      }
    });

    if (downloadJobList.length === 0) {
      return;
    }
    JSONToCSVConvertor(downloadJobList, title, true);
  }

  var service = {
    JSONToCSVConvertor: JSONToCSVConvertor,
    downloadJobs: downloadJobs
  };

  _self.jobDetailHeadersHash = SEARCH_CONSTANTS.jobDetailHeadersHash;

  return service;
};

module.exports = CSVExporter;
