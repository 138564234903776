'use strict';

var angular = require('angular');

var ThumbnailComponent = {
  restrict: 'E',
  replace: true,
  transclude: true,
  bindings: {
    asset: '<', // an asset
    cropTypes: '<',
    expectedCropTypes: '<',
    isJobArchived: '<',
    type: '@', // type of the thumbnail to show
    showAnnotation: '=?'
  },
  controller: /*@ngInject*/ function($scope, $rootScope, $element, JobService) {
    var _self = this;
    _self.src;

    _self.$onInit = function() {};

    _self.$onChanges = function(changes) {
      if (changes.asset || changes.type) {
        var newSrc;

        _self.asset = angular.copy(_self.asset);
        _self.src = JobService.getImageByType(_self.asset, _self.type);

        if (
          _self.asset &&
          _self.showAnnotation &&
          !_self.isJobArchived
        ) {
          JobService.fetchAnnotation(_self.asset).then(function(response) {
            _self.annotation = response.data;
          });
        }
      }
    };
  },
  templateUrl: 'templates/partials/job/thumbnail.tpl.html'
};

module.exports = ThumbnailComponent;
