'use strict';

var angular = require('angular');

var printModalDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    scope: {},
    bindToController: {
      active: '=',
      jobs: '=',
      form: '='
    },
    controllerAs: 'printModal',
    controller: /*@ngInject*/ function(
      $scope,
      $window,
      $timeout,
      $state,
      JobService,
      KeymageService
    ) {
      var _self = this;

      _self.closePrintModal = function(print) {
        var jobIds = [];

        _self.active = false;

        if (print) {
          _self.jobs.forEach(function(job) {
            jobIds.push(job.id);
          });

          $window.open(
            $state.href(
              'shipping',
              {
                ids: jobIds.join()
              },
              { absolute: true }
            ),
            '_blank'
          );
        }
      };

      _self.init = function() {
        if (!_self.jobs) {
          return false;
        }
        _self.shipNumber =
          _self.jobs[0].merchJob.sample &&
          _self.jobs[0].merchJob.sample.shipment
            ? _self.jobs[0].merchJob.sample.shipment.internalShippingNumber
            : null;
        _self.shipDate =
          _self.jobs[0].merchJob.sample &&
          _self.jobs[0].merchJob.sample.shipment
            ? _self.jobs[0].merchJob.sample.shipment.shipmentDate
            : null;
      };

      _self.keybindFilter = function(event) {
        if (!_self.active) {
          return;
        }
        $timeout(function() {
          if (event.which === 13) {
            // return
            _self.closePrintModal(true);
          } else if (event.keyCode === 27) {
            // escape
            _self.active = false;
          }
        }, 0);
      };

      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          KeymageService.toggle(!_self.active);

          if (_self.active) {
            angular.element(document.body).on('keyup', _self.keybindFilter);
          } else {
            angular.element(document.body).off('keyup', _self.keybindFilter);
          }
        }
      );

      $scope.$watch(
        function() {
          return _self.jobs;
        },
        function() {
          if (_self.jobs && _self.jobs.length) {
            _self.init();
          }
        }
      );
    },
    templateUrl: 'templates/partials/print/modal.tpl.html',
    link: function(scope, elem, attrs, ctrl) {
      scope.$on('$destroy', function() {
        angular.element(document.body).off('keyup', ctrl.keybindFilter);
      });
    }
  };
};

module.exports = printModalDirective;
