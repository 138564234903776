'use strict';

var assetStatusComponent = {
  require: {
    parent: '^^assetJobDetail'
  },
  controller: /*@ngInject*/ function() {},
  templateUrl: 'templates/partials/detail/assetStatus.tpl.html'
};

module.exports = assetStatusComponent;
