'use strict';

var moment = require('moment-timezone');

var dateDisplayFilter = function($filter) {
  return function(input) {
    var invalidDate = new Date('aiydgaiydfgaif');
    if (input && input.toString() === 'Invalid Date') {
      return '';
    } else {
      return input;
    }
  };
};

module.exports = dateDisplayFilter;
