var angular = require('angular');

var receiveMerchModal = {
  bindings: {
    active: '=',
    product: '<',
    onComplete: '&',
    getJobs: '&',
    value: '=',
    receiveType: '<',
    location: '=',
    sendError: '&'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $http,
    REST_URL,
    TransferService,
    NotificationService,
    $window,
    $state,
    $q,
    $log
  ) {
    var _self = this;
    _self.multipleSC = false;
    _self.multipleResults = {};
    _self.merchSize = {
      label: 'Size',
      value: '',
      disabled: true,
    };

    _self.reset = function() {
      _self.checkInProcess = { catalog: false, refresh: false, create: false };
      _self.multipleSC = false;
      _self.multipleResults = {};
      _self.lineArt = undefined;
      _self.error = '';
      _self.adding = false;
      _self.merchSize.value = '';
      _self.merchSize.disabled = true;
      _self.merchData = {
        productDBId: undefined,
        gtin: undefined,
        studio: _self.location.studio,
        studioId: _self.location.studioId,
        location: _self.location.location,
        merchType: _self.location.merchType,
        businessPartner: _self.location.businessPartner,
        styleColor: undefined,
      };
    };

    _self.checkIn = function(value) {
      _self.reset();

      // padding function
      var padStart = function(sourceString, targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String(padString || ' ');
        if (sourceString.length > targetLength) {
          return String(sourceString);
        } else {
          targetLength = targetLength - sourceString.length;
          if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
          }
          return padString.slice(0, targetLength) + String(sourceString);
        }
      };

      (function() {
        if (_self.receiveType === 'manual') {
          // When manually receiving merch, we do not need to use the gtin CAPS search
          _self.merchData.gtin = '';
          _self.merchSize.disabled = false;
          return $q.resolve({
            data: { products: [{ size: '', styleColor: value }] }
          });
        } else {
          _self.merchData.gtin = value;
          return $http.get(REST_URL.gtin + '?gtin=' + padStart(value, 14, '0') + '&merchType=' + _self.location.merchType);
        }
      })().then(
        function(gtinRes) {
          if (gtinRes.data.products.length == 1) {
            _self.searchStyleColor(gtinRes.data.products[0]);
          } else if (gtinRes.data.products.length > 1) {
            _self.searchMultiple(gtinRes.data.products, value, true);
          } else {
            // error block for a failed gtin/caps search
            _self.active = false;
            _self.sendError({ code: 'recordFound' });
          }
        },
        function(error) {
          // error block for a failed gtin/caps search
          _self.active = false;
          _self.sendError({ code: 'recordFound' });
        }
      );
    };

    _self.searchStyleColor = function(gtinRes) {
      // mark gtin query as complete
      _self.merchSize.value = gtinRes.size;
      _self.merchData.styleColor = gtinRes.styleColor;

      _self.checkInProcess.catalog = true;
      $http
        .get(REST_URL.gtin + '?stylecolor=' + gtinRes.styleColor)
        .finally(function() {
          return $http
            .post(REST_URL.search, {
              term: gtinRes.styleColor,
              page: 0,
              size: 50,
              sort: ''
            })
            .then(
              function(searchRes) {
                searchResults(searchRes, gtinRes);
              },
              function(error) {
                // error block for a failed style color search
                _self.active = false;
                _self.sendError({
                  code: 'sync',
                  data: {
                    styleColor: gtinRes.styleColor
                  }
                });
              }
            );
        });
    };

    function searchResults(searchRes, gtinRes) {
      // mark search as complete
      _self.checkInProcess.refresh = true;

      var products = searchRes.data.results.filter(
        result => result['@class'] == 'Product'
      );

      if (products.length == 0) {
        // block for if no product is found in the style color search
        _self.active = false;
        _self.sendError({
          code: 'sync',
          data: {
            styleColor: gtinRes.styleColor
          }
        });
      } else if (products.length == 1) {
        //user doesn't have to choose, so we can just choose the only one for them
        selectedProduct(products[0]);
      } else {
        //we need the user to choose, so we'll have them pick
        _self.searchMultiple(products);
      }
    }

    function selectedProduct(product) {
      if (product) {
        _self.merchData.product = product;
        _self.checkInProcess.create = true;

        getLineArt(product);
      }
    }

    function getLineArt(product) {
      // get line art
      TransferService.getExternalLineArt(product, 'Line Art').then(response => {
        _self.lineArt = {
          primary: response[0].status === 404 ? undefined : response[0].url,
          secondary: response.length > 1 ? response[1].url : undefined
        };

        _self.modalData = {
          cta: {
            primary: 'Add'
          }
        };
      });
    }

    _self.searchMultiple = function(products, gtin, enrich) {
      _self.modalData = {};
      _self.multipleSC = true;
      _self.active = true;
      _self.multipleResults.products = products;
      if (enrich) {
        var searchStr = '';
        products.forEach(product => {
          searchStr += product.styleColor + ' ';
          product.working = true;
          product.gtin = gtin;
        });
        $http
          .post(REST_URL.search, {
            term: searchStr,
            page: 0,
            size: 50,
            sort: ''
          })
          .then(function(results) {
            _self.enrichMultiple(results.data.results);
          });
      } else {
        products.forEach(product => {
          TransferService.getExternalLineArt(product, 'Line Art').then(function(
            response
          ) {
            $log.debug('Multiple for each', product, response[0]);
            product.lineArtThumb = response[0].thumbnailUrl;
            product.thumbnailClass = response[0].thumbnailClass;
          });
        });
      }
    };

    _self.enrichMultiple = function(results) {
      _self.multipleResults.products.forEach(product => {
        TransferService.getExternalLineArt(product, 'Line Art').then(function(
          response
        ) {
          $log.debug('Enrich multiple', product, response[0])
          product.lineArtThumb = response[0].thumbnailUrl;
          product.thumbnailClass = response[0].thumbnailClass;
        });
        results.forEach(result => {
          if (
            result.styleColor === product.styleColor &&
            result.vendor === product.vendor
          ) {
            Object.assign(product, result); //appends/replaces all of the product data based on the matching result;
            product.working = false;
          }
        });
      });
    };

    _self.selectMultiple = function(product) {
      _self.merchData.product = {};
      _self.merchData.product.id = product.id;
      _self.merchData.styleColor = product.styleColor;
      _self.merchData.gtin = product.gtin;
      _self.merchData.size = product.size;
      _self.addMerch();
    };

    _self.clearError = function() {
      _self.error = '';
    }

    _self.isMerchSizeValid = function() {
      if (_self.merchSize.value === undefined) {
        return false;
      }

      return _self.merchSize.value.trim().length > 0
    }

    _self.addMerch = function() {
      _self.error = '';
      if (!_self.isMerchSizeValid()) {
        _self.error = 'Size is required.'
        return;
      }

      // create the merch object
      _self.adding = true;
      var win = $window.open();

      $http
        .post(REST_URL.merch, {
          productDBId: _self.merchData.product.id,
          gtin: _self.merchData.gtin,
          size: _self.merchSize.value,
          studioId: _self.merchData.studioId,
          location: _self.merchData.location,
          merchType: _self.merchData.merchType,
          businessPartner: _self.merchData.businessPartner,
        })
        .then(
          function(response) {
            win.location = $state.href('merchMngSlate', {
              id: response.data.merchId
            });
            _self.active = false;
            NotificationService.push(
              '{{styleColor}} was successfully added to inventory.',
              {
                styleColor: _self.merchData.styleColor
              }
            );

            _self.getJobs();
            _self.adding = false;

            // the checkin process is now complete
          },
          function(error) {
            // error block for a failed merch creation
            _self.adding = false;
            win.close();
          }
        );
    };

    _self.close = function() {
      _self.reset();
      _self.active = false;
    };

    $scope.$watch(
      function() {
        return _self.active;
      },
      function() {
        if (_self.active) {
          _self.reset();

          _self.checkIn(_self.value);
        }
      }
    );

  },
  templateUrl: 'templates/partials/merchMng/receiveMerchModal.tpl.html'
};

module.exports = receiveMerchModal;
