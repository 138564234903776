'use strict';

var angular = require('angular');
var moment = require('moment-timezone');

var JobFormService = /*@ngInject*/ function(
  $http,
  JobService,
  AuthService,
  SEARCH_CONSTANTS,
  META_STATUS,
  MERCH_STATUS,
  JOB_TYPE,
  APP_DATA,
  REST_URL,
  USER_ROLES
) {
  var _self = this,
    canInitiateJob = AuthService.isAuthorized([
      USER_ROLES.merchOps,
      USER_ROLES.studioOps,
      USER_ROLES.superUser
    ]);

  // Default form data
  _self.formData = {
    studio: {
      required: true,
      label: 'Studio',
      working: true,
      options: (function() {
        if (!canInitiateJob) {
          return [];
        }

        $http
          .get([REST_URL.base, 'studios'].join('/'))
          .then(function(response) {
            _self.formData.studio.options = response.data;
          })
          .catch(function() {
            _self.formData.studio.options = [];
            if (!_self.formData.studio.value) {
              _self.formData.studio.value =
                _self.formData.studio.options[0].studioName;
            }
          })
          .finally(function() {
            _self.formData.studio.working = false;
          });

        return [];
      })(),
      disabled: false,
      visible: true
    },
    jobStatus: {
      required: true,
      label: 'Update Status to',
      options: [META_STATUS.jobPending, MERCH_STATUS.inOps],
      disabled: false,
      visible: true
    },
    assets: {
      required: false,
      label: 'Assets to rework',
      disabled: false,
      visible: false
    },
    reason: {
      required: false,
      label: 'Reason',
      disabled: false,
      visible: false
    },
    merchType: {
      required: true,
      label: SEARCH_CONSTANTS.jobDetailHeadersHash['merchType'].name,
      options: APP_DATA.MerchType,
      disabled: false,
      visible: false
    },
    primaryMerchId: {
      required: false,
      disabled: true,
      label: SEARCH_CONSTANTS.jobDetailHeadersHash['primaryMerchId'].name,
      visible: true,
    },
    businessPartner: {
      required: true,
      label: SEARCH_CONSTANTS.jobDetailHeadersHash['businessPartner'].name,
      options: APP_DATA.BusinessPartner.map(bp => {
        return bp.name;
      }),
      disabled: false,
      visible: true
    },
    baseProduct: {
      required: false,
      label: 'Base Product',
      visible: false
    },
    note: {
      required: false,
      label: 'Notes',
      type: undefined,
      disabled: false,
      visible: false
    },
    slateNote: {
      required: false,
      label: 'Slate Note',
      type: 'slate',
      disabled: false,
      visible: false
    },
    shootDetail: {
      required: true,
      label: 'Shoot Detail',
      disabled: false,
      visible: true
    },
    launchDate: {
      required: false,
      label: SEARCH_CONSTANTS.jobDetailHeadersHash['launchDate'].name,
      disabled: !AuthService.isAdmin()
    },
    confidentialCheck: false,
    priority: {
      required: false,
      label: SEARCH_CONSTANTS.jobDetailHeadersHash['priority'].name,
      options: [
        { label: 'Default', value: '0', icon: 'd', fill: '#8D8D8D' },
        { label: 'Priority', value: '1', icon: 'p', fill: '#FE0000' },
        { label: 'Rush', value: '2', icon: 'r', fill: '#FE0000' }
      ],
      disabled: !AuthService.isAdmin()
    },
    toggle: function() {
      _self.formData.confidentialCheck = !_self.formData.confidentialCheck;
      if (_self.formData.confidentialCheck === true) {
        this.launchDate.value = new Date('2099-01-01T08:00:00.000+0000');
      } else if ((this.launchDate.value = !'2099-01-01T08:00:00.000+0000')) {
        document.getElementById('checked').checked = false;
        document.getElementById('check').checked = false;
      } else {
        delete this.launchDate.value;
        document.getElementById('checked').checked = false;
        document.getElementById('check').checked = false;
      }
    },
    setConfidential: function(newVal) {
      _self.formData.confidentialCheck = newVal;
      this.confidentialCheck = newVal;
      if (document.getElementById('checked')) {
        document.getElementById('checked').checked = newVal;
      }
      if (document.getElementById('check')) {
        document.getElementById('check').checked = newVal;
      }
    }
  };

  // store assignees as we fetch them
  _self.assigneeOptions = {};

  var convertPriorityToInteger = function(string) {
    var int;

    _self.formData.priority.options.some(function(option) {
      if (string === option.label) {
        int = parseInt(option.value, 10);
        return true;
      }
    });

    return int;
  };

  _self.convertPriorityToString = function(int) {
    if (
      int === undefined ||
      _self.formData.priority.options[int] === undefined
    ) {
      return;
    }
    return _self.formData.priority.options[int].label;
  };

  var setApiData = function(apiData, formData) {
    apiData.studioName = formData.studio.value;
    apiData.businessPartner = formData.businessPartner.value;
    apiData.priority = Number.isInteger(
      Number.parseInt(formData.priority.value)
    )
      ? Number.parseInt(formData.priority.value)
      : convertPriorityToInteger(formData.priority.value) !== undefined
        ? convertPriorityToInteger(formData.priority.value)
        : 0;

    //based on ICONS-1720 this will give us a bunch of tech debt to resolve going forward but solves the immediate requirements
    if (formData.launchDate && formData.launchDate.value) {
      apiData.releaseDate = formData.launchDate.value;
    } else if (apiData.releaseDate) {
      delete apiData.releaseDate;
    }

    if (apiData.releaseDate == null) {
      delete apiData.releaseDate;
    }

    if (formData.reason && formData.reason.value) {
      apiData.reason = formData.reason.value;
    }

    if (formData.note && formData.note.value) {
      apiData.note = formData.note.value;
    }

    if (formData.merchType && formData.merchType.value) {
      apiData.merchType = formData.merchType.value;
    }

    // if the job type is 'duplicate', shoot details are handled by the backend
    if (formData.jobType && formData.jobType.value !== 'Duplicate') {
      if (formData.shootDetail && formData.shootDetail.value) {
        apiData.shootTypeCode = formData.shootDetail.value.shootType.data.code;
        apiData.expectedViews = formData.shootDetail.value.expectedViews;
      }
    } else if (formData.jobType && formData.jobType.value === 'Duplicate') {
      apiData.expectedViews = ['a', 'b'];
      apiData.shootTypeCode = 'Duplicate';
    }

    if (formData.assign && formData.assign.value) {
      apiData.assignee = formData.assign.value.userName;
    }

    if (formData.baseProduct && formData.baseProduct.value) {
      apiData.createdFrom = formData.baseProduct.value.id;
    }
  };

  // Set formData to values present on existing job
  _self.setFormData = function(model, formData, isBase, error) {
    model = model || {};
    error = error || {};

    formData.studio.value = model.studio
      ? model.studio.studioName
      : _self.formData.studio.options.length
        ? _self.formData.studio.options[0].studioName
        : undefined;
    formData.studio.options = _self.formData.studio.options;

    if (formData.shootDetail) {
      formData.shootDetail.value = {
        shootType: model.shootType,
        expectedViews: model.expectedViews
      };
    }

    formData.primaryMerchId.value = model.primaryMerchId;

    // sub jobs do not inherit release date & priority from base job
    if (isBase) {
      return;
    }

    formData.merchType.value = model.merchType;
    formData.businessPartner.value =
      model.businessPartner || APP_DATA.BusinessPartner[0].name;
    // merch type editing is enabled when a job has no child jobs, and it is in either 'job pending' or 'in progress'
    formData.merchType.disabled =
      (model.relatedJobs && model.relatedJobs.length) ||
      (model.status !== META_STATUS.jobPending &&
        model.status !== META_STATUS.inProgress);
    formData.primaryMerchId.disabled = !JOB_TYPE.allowsEditPrimaryMerchId.includes(model.jobType);

    formData.priority.value =
      _self.convertPriorityToString(model.priority) ||
      _self.formData.priority.options[0].label;

    if (model.releaseDate) {
      formData.launchDate.value = new Date(model.releaseDate);
      formData.launchDate.required = true;
      formData.confidentialCheck = true;
    } else {
      delete formData.launchDate.value;
      formData.confidentialCheck = false;
      formData.launchDate.required = false;
    }

    formData.launchDate.disabled = model.jobType === 'Duplicate';
    formData.priority.disabled = model.jobType === 'Duplicate';
    _self.validate(formData, error);
  };
  //
  // validate release date & priority
  _self.validate = function(formData, error) {
    var _tenDaysFromNow = moment(new Date()).add(10, 'days'),
      launchDate = formData.launchDate.value;

    if (launchDate && moment(launchDate).isValid()) {
      _self.formData.setConfidential(true);
    } else {
      //if there is no launchDate
      _self.formData.setConfidential(false);
    }

    if (error) {
      error.title = ''; //no validation errors, clear error message
    }
    return true;
  };

  // Convert duplicate job to a different job type
  _self.resolveJob = function(model, formData) {
    var apiData = {
      routing: [
        {
          jobId: model.merchJob.id,
          goto: formData.jobStatus.value
        }
      ],
      resolutionReason: 'Shoot Newly Arrived Merch',
      newJobType: formData.jobType.value,
      newBusinessPartner: formData.businessPartner.value,
      newShootTypeCode: formData.shootDetail.value.shootType.code,
      newPriority: convertPriorityToInteger(formData.priority.value),
      newLaunchDate: formData.launchDate.value || '',
      newExpectedViews: formData.shootDetail.value.expectedViews
    };

    return JobService.resolveProblemSolve(model.id, model.tasks[0].id, apiData);
  };

  // Make updates to an existing job
  _self.updateJob = function(model, formData) {
    var apiData = angular.copy(model);

    apiData.businessPartner = formData.businessPartner.value;
    apiData.priority = convertPriorityToInteger(formData.priority.value);
    apiData.initialLaunchDate = model.product.initialReleaseDate;
    if (!!formData.primaryMerchId.value) {
      apiData.primaryMerchId = formData.primaryMerchId.value;
    }

    setApiData(apiData, formData);

    // prep data for PUTing
    delete apiData.tasks;
    delete apiData.product;
    delete apiData.merchJob;
    delete apiData.assetJobs;
    delete apiData.shootType;

    if (formData.jobType && formData.jobType.value) {
      //Need to call job convert endpoint if there is a change in job type.
      if (apiData.jobType !== formData.jobType.value) {
        apiData.jobType = formData.jobType.value;
        apiData.targetStatus = formData.jobStatus.value;
        return JobService.convert(apiData);
      }
    }
    return JobService.update(apiData);
  };

  // Create a new job for a product
  _self.createJob = function(model, formData) {
    var apiData = {};

    // Required data
    apiData.productDBId = model.product.id;
    apiData.jobType = formData.jobType.value;
    apiData.targetStatus = formData.jobStatus.value;
    apiData.initialLaunchDate = model.product.initialReleaseDate;
    if (!!formData.primaryMerchId.value) {
      apiData.primaryMerchId = formData.primaryMerchId.value;
    }

    setApiData(apiData, formData);

    return JobService.create(apiData);
  };
};

module.exports = JobFormService;
