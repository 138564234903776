'use strict';

var angular = require('angular');

var MetaQueueController = /*@ngInject*/ function(
  $scope,
  $rootScope,
  $timeout,
  $state,
  $q,
  META_STATUS,
  QueueService,
  Session,
  iScrollService,
  ProblemSolveIndicatorService,
  AuthService,
  JobService,
  SearchService,
  USER_ROLES,
  UPDATE_PARAMS,
  RouterTracker,
  RESULTS_CONSTANT,
  EventLoggerService,
  APP_DATA
) {
  var _self = this,
    stateWatch,
    updateParams;

  _self.showResolveProblemModal = false;
  _self.canResolve = false;

  var setUpdateParams = function(params) {
    updateParams = params || _self.stateData.params.updateParams;
  };

  var setSearchParams = function(params) {
    _self.searchParams = params ? params : _self.stateData.params.searchParams;
  };

  // Allow view to access service methods
  _self.service = QueueService;

  _self.data = {
    allJobs: [], // full job list
    jobs: [], // job list after filtering
    viewType: 'list',
    disableAssets: true
  };

  _self.config = {
    page: 0,
    size: 50
  };

  _self.allResults = false;

  _self.toggleAll = function(jobs, boolean) {
    QueueService.toggleAll(jobs, boolean);

    jobs.forEach(function(job) {
      job.assetJobs.forEach(function(assetJob) {
        assetJob.selected = boolean;
      });
    });

    return jobs;
  };

  _self.permissions = {
    assign: (function() {
      return AuthService.isAuthorized([
        USER_ROLES.imageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    })(),
    unassign: (function() {
      return AuthService.isAuthorized([
        USER_ROLES.imageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    })(),
    complete: (function() {
      return AuthService.isAuthorized([
        USER_ROLES.imageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    })()
  };

  _self.toggleAssign = function(assign) {
    if (assign) {
      _self.data.assignee = Session.get();
      Session.setSessionValue('toggleAssign', true, _self.state);
    } else {
      _self.data.assignee = false;
      Session.setSessionValue('toggleAssign', false, _self.state);
    }
    _self.service.resetAndGetData(_self);

    if (AuthService.isAdmin()) {
      _self.stateData.params.stop = false;
    }
  };

  _self.confirmUpdate = function(jobs) {
    jobs = jobs || _self.data.selectedJobs;

    if (_self.stateData.params.initiateJobFlag) {
      _self.service.initiateJob(jobs);
    } else {
      _self.service.confirmUpdate(jobs, updateParams, _self.stateData.location);
    }
  };

  _self.goToSearch = function() {
    var ids = _self.data.selectedJobs.map(function(job) {
      return job.id;
    });
    $state.go('search', {
      t: 'problem',
      c: ids.join('|')
    });
  };

  _self.doSearch = function(term, force) {
    Session.setSessionValue(
      _self.stateData.location + 'searchTerm',
      term,
      _self.state
    );
    // If we're not doing the special second search, reset the search filters.
    if (!force) {
      setSearchParams();
    }

    _self.service.doSearch(_self, term, force).then(function(jobs) {
      if (term && jobs.length === 1 && _self.stateData.params.updateParams) {
        //Suppressing the assignment modal when searching from main or immediately returning from the Details page
        if (_self.stateData.location === 'tray' && !_self.fromDetail) {
          if (!_self.fromDetail) {
            _self.confirmUpdate(jobs);
          } else {
            _self.fromDetail = false;
          }
        }
      }
      if (_self.merchScanned) {
        _self.merchScanned = undefined;
        if (jobs.length > 0) {
          let track = function(eventStr) {
            EventLoggerService.track(
              eventStr,
              {
                page: $state.current.name,
                location: _self.stateData ? ' ' + _self.stateData.location : ''
              },
              true
            );
          };
          track('PREPRO_MERCH_HAS_JOBS');
          var jobsPending = jobs.filter(
            job => job.status && job.status === META_STATUS.jobPending
          );
          var jobsInProgress = jobs.filter(
            job =>
              job.status && META_STATUS.progressStatuses.includes(job.status)
          );
          if (jobsPending.length > 0) {
            track('PREPRO_MERCH_HAS_JOBS_PENDING');
          }
          if (jobsInProgress.length > 0) {
            track('PREPRO_MERCH_HAS_JOBS_INPROGRESS');
          }
        }
      }
    });
  };

  _self.onScrollEnd = function() {
    if (
      !_self.working &&
      _self.scrollerObj.y <= _self.scrollerObj.maxScrollY &&
      !_self.allResults
    ) {
      _self.getJobs();
    }

    $rootScope.$broadcast('scroll-stop');
  };

  _self.getJobs = function() {
    var deferred = $q.defer();

    _self.working = true;
    _self.loadingError = false;

    if (_self.data.allJobs === undefined || _self.data.allJobs.length === 0) {
      _self.config.page = 0;
    }

    var searchParams = angular.copy(_self.searchParams);
    if (
      _self.stateData.params.detailState === 'qa' &&
      _self.stateData.location === 'main'
    ) {
      searchParams.filters += ',processedForSeconds=60';
    }

    if (_self.data.assignee !== undefined) {
      if (_self.data.assignee) {
        searchParams.filters += ',assignees=' + _self.data.assignee.userName;
      } else {
        searchParams.filters += ',+assignees';
      }
    }

    if (
      Session.getSessionValue(
        _self.stateData.location + 'Filters',
        _self.state
      ) !== null
    ) {
      var searchFilters = Session.getSessionValue(
        _self.stateData.location + 'Filters',
        _self.state
      );
      var searchFilterKeys = Object.keys(searchFilters);
      var options = [];
      searchFilterKeys.forEach(function(filter) {
        options.push(filter + '=' + searchFilters[filter]);
      });
    }

    QueueService.getJobs(
      _self.data.term,
      searchParams,
      _self.config.page,
      _self.config.size,
      _self.stateData.location
    )
      .then(function(response) {
        _self.data.allJobsUnfiltered = JobService.filterDuplicateJobs(
          _self.data.allJobsUnfiltered.concat(response.data.results)
        );
        var queryResults = response;

        //use facet values from filtered query
        _self.data.filters = SearchService.transformFacets(
          queryResults.data.facets
        );
        _self.data.filters.push({
          id: 'assignees',
          name: 'Assignee',
          options: undefined
        });

        //Only use the filters we pass through the router
        if (_self.filterOptions) {
          var temp = [];
          for (var i = 0; i < _self.data.filters.length; i++) {
            var current = _self.data.filters[i];
            if (_self.filterOptions.indexOf(current.id) >= 0) {
              temp.push(current);
            }
            //Season options are pre-populated, so we need a separate if loop.
            if (
              current.name === 'Seasons' &&
              _self.filterOptions.indexOf('seasons') >= 0
            ) {
              temp.push(current);
            }
          }
          _self.data.filters = temp;
          _self.data.buttonFilters = _self.stateData.params.buttonFilters || [];
        }

        var sessionFilters = Session.getSessionValue(
          _self.stateData.location + 'Filters',
          _self.state
        );
        if (sessionFilters && sessionFilters.assignees && _self.data.assignee) {
          delete sessionFilters.assignees;
        }
        searchParams['filters'] += ',' + Session.stringify(sessionFilters);

        var deferredSessionFilters = $q.defer();
        var promiseSessionFilters = deferredSessionFilters.promise;

        //If we have filters saved in Session storage, apply them and call QueueService to get the new job list, otherwise just resolve the promise and use the results from the first job search
        if (sessionFilters !== null && Object.keys(sessionFilters).length > 0) {
          QueueService.getJobs(
            _self.data.term,
            searchParams,
            _self.config.page,
            _self.config.size,
            _self.stateData.location
          ).then(function(responseSessionFilters) {
            _self.data.allJobs = JobService.filterDuplicateJobs(
              _self.data.allJobs.concat(responseSessionFilters.data.results)
            );
            queryResults = responseSessionFilters;
            deferredSessionFilters.resolve();
          });
        } else {
          _self.data.allJobs = _self.data.allJobsUnfiltered;
          deferredSessionFilters.resolve();
        }

        promiseSessionFilters.then(function() {
          _self.config.page++;
          EventLoggerService.track(
            'QUEUE_PAGINATION',
            {
              page: $state.current.name,
              location: _self.stateData ? ' ' + _self.stateData.location : '',
              pageNumber: _self.config.page
            },
            _self.config.page > 1
          );

          _self.data.total = queryResults.data.total;

          if (_self.config.size > queryResults.data.results.length) {
            _self.allResults = true;
          }

          _self.data.jobs = JobService.getFilteredJobs(
            _self.data.allJobs,
            _self.data.assignee,
            _self.stateData.params.assignType
          );
          _self.data.total = queryResults.data.total;

          if (
            _self.stateData.params.status === META_STATUS.problemSolve &&
            !_self.stateData.params.assignToggle
          ) {
            ProblemSolveIndicatorService.setCount(_self.data.jobs);
          }
          _self.working = false;

          deferred.resolve(_self.data.jobs);
        });
      })
      .catch(function(err) {
        _self.working = false;
        _self.loadingError = true;
      });

    return deferred.promise;
  };

  _self.setSelectionCta = function() {
    if (_self.data.selectedJobs.length) {
      if (_self.stateData.params.initiateJobFlag) {
        _self.data.selectionCta = JobService.canUpdate(
          _self.data.selectedJobs[0].status
        )
          ? 'Update Job'
          : 'Initiate Job';
      } else if (AuthService.isAdmin() && _self.stateData.params.assignToggle) {
        _self.data.selectionCta = 'Reassign';
      } else {
        _self.data.selectionCta = _self.stateData.params.cta;
      }
    } else {
      _self.data.selectionCta = _self.stateData.params.cta || 'Update Status';
    }
  };

  /* onClick funtions for cards */

  var lastSelected = null;
  var lastIndex = null;

  _self.cardClick = function(job, ev) {
    // Note: This is not the only click function for cards. This function runs AFTER the $element.on('click') in the job controller.
    if (lastSelected && lastIndex > -1) {
      if (ev.shiftKey) {
        // Just do nothing if they click the same job twice while holding shift.
        if (lastIndex === _self.data.jobs.indexOf(job)) {
          job.selected = !job.selected;
          return;
        }

        //select all between the previous and current selection
        var currentIndex = _self.data.jobs
          .map(function(a) {
            return a.id;
          })
          .indexOf(job.id);
        var selectingJobs =
          lastSelected.selected && job.selected ? true : false;
        if (currentIndex > lastIndex) {
          for (var i = lastIndex; i < currentIndex; i++) {
            if (selectingJobs) {
              _self.data.jobs[i].selected = true;
            } else {
              _self.data.jobs[i].selected = false;
              lastSelected.selected = false;
              job.selected = true;
            }
          }
        } else if (lastIndex > currentIndex) {
          for (var i = currentIndex; i < lastIndex; i++) {
            if (selectingJobs) {
              _self.data.jobs[i].selected = true;
            } else {
              _self.data.jobs[i].selected = false;
              lastSelected.selected = false;
              job.selected = true;
            }
          }
        }
        // This is to prevent the text in the cards from highlighting. We do still need this functionality though, so users can copy ids/style colors.
        window.getSelection().removeAllRanges();
      } else if (ev.metaKey) {
        //select both, original behavior
      } else {
        //no shift, no ctrl, only select one
        var currentlySelected = job;

        var match = function(a, b) {
          return (
            (a.id ? a.id : '') + (a.product.id ? a.product.id : '') ===
            (b.id ? b.id : '') + (b.product.id ? b.product.id : '')
          );
        };

        for (var i = 0; i < _self.data.jobs.length; i++) {
          if (
            !match(_self.data.jobs[i], currentlySelected) &&
            _self.data.jobs[i].selected
          ) {
            _self.data.jobs[i].selected = false;
          }
        }
      }
    }

    lastSelected = job;
    lastIndex = _self.data.jobs
      .map(function(a) {
        return a.id;
      })
      .indexOf(job.id);

    if (_self.stateData.setProblemJob) {
      var selectedJobs = _self.data.jobs.filter(function(job) {
        return !!job.selected;
      });

      if (selectedJobs.length === 1) {
        _self.isProblem = selectedJobs[0].status === META_STATUS.problemSolve;
        _self.hasAssignee =
          selectedJobs[0].tasks.length && selectedJobs[0].tasks[0].assignee;
        _self.canResolve =
          _self.isProblem && AuthService.isAdmin() && _self.hasAssignee;

        _self.stateData.setProblemJob(selectedJobs[0]);
      } else {
        _self.stateData.setProblemJob(undefined);
      }
    }
  };

  //Resolve problem solve modal
  _self.resolveProblem = function() {
    _self.stateData.showResolveProblemModal(_self.data.selectedJobs[0]);
  };

  /* Queue filters */
  _self.filtersActive = false;
  _self.toggleFilters = function() {
    _self.filtersActive = !_self.filtersActive;
  };

  _self.init = function() {
    _self.state = $state.current.name;
    _self.data.allJobsUnfiltered = [];
    _self.data.showSecondaryCropTypes = false;

    stateWatch = $scope.$watch('stateData', function() {
      var specialUpdateParams;
      var queueName = $state.current.name.substring(6);

      _self.stateData = angular.copy($scope.stateData);
      _self.filterOptions = _self.stateData.params.filters;

      _self.constant = AuthService.getAuthorizedResults(
        Session.get('roles'),
        $state.current.data.authorizedRoles,
        RESULTS_CONSTANT[
          _self.stateData.viewState + '.' + _self.stateData.location
        ],
        _self.stateData.location
      );

      _self.authorizedForAssets = AuthService.showSubSection(
        Session.get('roles'),
        $state.current.data.authorizedRoles,
        _self.stateData.location,
        'assets'
      );

      _self.showResolveProblemModal = _self.stateData.showResolveProblemModal;

      var stateLocation = _self.stateData.location;

      _self.data.viewType =
        Session.getSessionValue(stateLocation + 'View', _self.state) &&
        _self.authorizedForAssets !== null
          ? Session.getSessionValue(stateLocation + 'View', _self.state)
          : 'list';

      var sessionFilters = Session.getSessionValue(
        stateLocation + 'Filters',
        _self.state
      );
      if (sessionFilters !== null && Object.keys(sessionFilters).length > 0) {
        _self.filtersActive = true;
      }

      var routeHistory = RouterTracker.getRouteHistory();
      var lastRoute = routeHistory[routeHistory.length - 1];
      if (
        lastRoute.route.name === 'qa' ||
        lastRoute.route.name === 'detail' ||
        lastRoute.route.name === 'outreach'
      ) {
        var term = Session.getSessionValue(
          stateLocation + 'searchTerm',
          _self.state
        );
        _self.data.term = term === null ? '' : term;
        _self.data.inputTerm = _self.data.term;
        _self.fromDetail = lastRoute.route.name === 'detail';
        _self.doSearch(_self.data.inputTerm);
      } else {
        Session.setSessionValue(stateLocation + 'searchTerm', '', _self.state);
      }

      _self.service.registerScope(_self, _self.stateData.location);
      setSearchParams();

      if (AuthService.isAdmin() && _self.stateData.params.assignToggle) {
        specialUpdateParams = angular.merge(
          {},
          UPDATE_PARAMS[queueName].assign,
          UPDATE_PARAMS.special.reassign
        );
      }

      setUpdateParams(specialUpdateParams);

      if (_self.stateData.params.assignToggle) {
        if (!_self.permissions.assign) {
          _self.stateData.params.assignToggle = false;
        }

        var sessionToggleAssign = Session.getSessionValue(
          'toggleAssign',
          _self.state
        );
        var toggleAssignDefault =
          sessionToggleAssign === null ? true : sessionToggleAssign;
        _self.toggleAssign(toggleAssignDefault);
      } else {
        _self.service.resetAndGetData(_self);
      }

      $scope.$on('queue.update.' + _self.stateData.location, function(
        e,
        params
      ) {
        _self.service.updateJobs(_self, params);
      });

      $scope.$on('scan.gtinSearch', function(e, params) {
        _self.doSearch(params.term, true);
      });

      $scope.$on('scan.merchId', function(e, params) {
        _self.merchScanned = true;
        params.predicate = '_allFiltered';
        params.sort = [{ field: 'lastUpdated', order: 'desc' }];
        params.size = 50;
        QueueService.getMerchManagementJobs(params).then(function(data) {
          var prodRecord = data.data.hits.hits[0]['_source'].productRecord;
          _self.doSearch(prodRecord, undefined);
        });
      });

      _self.setSelectionCta();

      stateWatch(); // end watch
    });

    $scope.$watch(
      function() {
        return _self.data.jobs;
      },
      function() {
        _self.service.handleSelections(_self);
        _self.setSelectionCta();
      },
      true
    );

    $scope.$watch(
      function() {
        return _self.data.viewType;
      },
      function() {
        $timeout(function() {
          iScrollService.refresh(_self);
        }, 0);
      }
    );

    $timeout(function() {
      _self.scrollerObj = iScrollService.find(_self);

      if (_self.scrollerObj) {
        _self.scrollerObj = _self.scrollerObj[0].scrollerObj;
        _self.scrollerObj.on('scrollEnd', _self.onScrollEnd);
      }
    }, 0);
  };

  _self.init();
};

module.exports = MetaQueueController;
