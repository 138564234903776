'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    '$document',
    '$rootScope',
    function dropdownService($document, $rootScope) {
      var openScope = null;

      var closeDropdown = function(evt) {
        // This method may still be called during the same mouse event that
        // unbound this event handler. So check openScope before proceeding.
        if (!openScope) {
          return;
        }

        if (evt) {
          var toggleElement = openScope.getToggleElement(),
            dropdownElement = openScope.getDropdownElement();

          if (evt.which === 3) {
            return;
          }

          if (toggleElement && toggleElement[0].contains(evt.target)) {
            return;
          }

          if (dropdownElement && dropdownElement[0].contains(evt.target)) {
            if (
              evt.target.nodeName === 'INPUT' ||
              evt.target.nodeName === 'SELECT'
            ) {
              return;
            }
          }
        }

        openScope.focusToggleElement();
        if (openScope) {
          openScope.isOpen = false;
        }

        if (!$rootScope.$$phase) {
          if (openScope) {
            openScope.$apply();
          }
        }
      };

      var keybindFilter = function(evt) {
        if ([27].indexOf(evt.which) !== -1 && openScope.isOpen) {
          //escape
          evt.preventDefault();
          evt.stopPropagation();
          closeDropdown();
        } else if ([9].indexOf(evt.which) !== -1 && openScope.isOpen) {
          //tab
          closeDropdown();
        } else if ([13, 32].indexOf(evt.which) !== -1) {
          //return, space
          openScope.selectActiveOption();
        } else if ([38, 40].indexOf(evt.which) !== -1 && openScope.isOpen) {
          // up, down
          evt.preventDefault();
          evt.stopPropagation();
          openScope.focusDropdownEntry(evt.which);
        }
      };

      this.open = function(dropdownScope) {
        if (!openScope) {
          $document.on('click', closeDropdown);
          $document.on('keydown', keybindFilter);
        }

        if (openScope && openScope !== dropdownScope) {
          openScope.isOpen = false;
        }

        openScope = dropdownScope;
      };

      this.close = function(dropdownScope) {
        if (openScope === dropdownScope) {
          openScope = null;
          $document.off('click', closeDropdown);
          $document.off('keydown', keybindFilter);
        }
      };
    }
  ];
}

module.exports = register;
