'use strict';

var SearchFilterController = /*@ngInject*/ function(
  $rootScope,
  $scope,
  $timeout,
  SearchService
) {
  var _self = this;

  _self.data = {};

  // Clear all filters
  _self.clear = function() {
    _self.data.filters.forEach(function(filter) {
      filter.options.forEach(function(option) {
        option.selected = false;
      });
    });
  };

  var init = function() {
    _self.working = true;
    SearchService.getFacets()
      .then(function() {
        _self.data.filters = SearchService.data.filters;
      })
      .catch(function(response) {
        $rootScope.$broadcast('error', { error: response });
      })
      .finally(function() {
        _self.working = false;
      });
  };

  init();
};

module.exports = SearchFilterController;
