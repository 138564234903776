'use strict';

/*jshint unused: false*/
var fileUploadComponent = {
  restrict: 'E',
  bindings: {
    accept: '<',
    product: '<',
    files: '<',
    uploadSuccess: '&'
  },
  controller: /*@ngInject*/ function(
    TransferService,
    USER_ROLES,
    AuthService,
    NotificationService,
    $rootScope
  ) {
    var _self = this;
    _self.canUpload = false;
    _self.fileDuplicateError = {
      active: false,
      data: {
        error:
          'A file with this name already exists, please modify and try again.',
        cta: { secondary: 'Close' }
      }
    };
    _self.fileUploadError = {
      active: false,
      data: {
        error: 'An error has occurred during file upload please try again.',
        cta: { secondary: 'Close' }
      }
    };

    _self.$onInit = function() {
      _self.canUpload = AuthService.isAuthorized([
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser,
        USER_ROLES.qaReviewer,
        USER_ROLES.productReviewer
      ]);
    };

    _self.closeModal = function() {
      _self.fileUploadError.active = false;
      _self.fileDuplicateError.active = false;
    };

    _self.uploadAttachment = function($event) {
      _self.working = true;
      var filename = $event.file.value.substr(
        $event.file.value.lastIndexOf('\\') + 1
      );
      if (_self.isDuplicateFileUpload(filename)) {
        _self.fileDuplicateError.active = true;
        $rootScope.$apply();
        return;
      }

      TransferService.uploadLineArt(_self.product, $event)
        .then(_self.handleUpload)
        .catch(_self.handleError);
    };

    _self.isDuplicateFileUpload = function(filename) {
      var isDuplicate = false;
      if (_self.files) {
        for (var i = 0; i < _self.files.length; i++) {
          if (_self.files[i].name === filename) {
            isDuplicate = true;
            break;
          }
        }
      }
      return isDuplicate;
    };

    _self.handleUpload = function(response) {
      NotificationService.push('File uploaded.', { response: response });
      _self.uploadSuccess();
    };

    _self.handleError = function(response) {
      var responseError = response.data.error;
      var responseStatus = response.status;
      if (responseStatus === 500) {
        if (responseError.indexOf('SizeLimitExceededException') !== -1) {
          $rootScope.$broadcast('error', {
            error: 'This file exceeds the limit of 200MB.'
          });
        } else {
          $rootScope.$broadcast('error', { error: response.data.error });
        }
      } else {
        $rootScope.$broadcast('error', { error: response.data.error });
      }
    };
  },
  templateUrl: 'templates/partials/ui/fileUpload.tpl.html'
};

module.exports = fileUploadComponent;
