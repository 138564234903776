'use strict';

/*jshint unused: false*/
var progressBarComponent = {
  scope: {},
  bindings: {
    completion: '='
  },
  controller: /*@ngInject*/ function() {
    var _self = this;
  },
  templateUrl: 'templates/partials/ui/progressBar.tpl.html'
};

module.exports = progressBarComponent;
