'use strict';

const rejectionPanelComponent = {
  bindings: {
    active: '=',
    job: '=',
    userSelectedRejectionReasons: '=',
    onCloseRejectionPanel: '&',
    onSaveAndCloseRejectionPanel: '&',
    onRejectionThumbClick: '&',
  },
  controller: /*@ngInject*/ function($scope, QAService, JobService) {
    const _self = this;

    const REJECTION_COLORS = ['blue', 'red', 'yellow', 'black'];

    const DEFUALT_REJECTION = {
      colorSwatch: undefined,
      title: undefined,
      issue: undefined,
      detail: undefined,
      comment: '',
      rejectedAssets: []
    };

    _self.isCommandKeyDown = false;
    _self.rejectionReasons = [];
    _self.rejectionIssueOptions = [];
    _self.rejectionDetailOptions = [];
    _self.assetTable = [];
    _self.rejectionsList = [];
    _self.curRejection = undefined;
    _self.curRejectionIsValid = null;
    _self.rejectionsMaxedOut = false;
    _self.showError = false;

    QAService.getRejectionReasons().then(function(response) {
      _self.rejectionReasons = response.data.map(
        ({ text: issue, details }) => ({
          issue,
          details: details.map(({ text }) => text)
        })
      );
      _self.rejectionIssueOptions = _self.rejectionReasons.map(
        ({ issue }) => issue
      );
    });

    _self.onKeyDown = function(event) {
      if(_self.rejectionsMaxedOut) {
        return;
      }
      if ([91, 93, 224].includes(event.which)) {
        _self.isCommandKeyDown = true;
      } else if (_self.isCommandKeyDown && event.which === 65) {
        _self.selectAll();
        _self.validateRejection();
        event.preventDefault();
      }
    };

    _self.onKeyUp = function(event) {
      if ([91, 93, 224].includes(event.which)) {
        _self.isCommandKeyDown = false;
      }
    };

    _self.loadImages = function() {
      if (_self.job && _self.job.assetJobs && _self.job.assetJobs.length) {
        const expectedViews = _self.job.expectedViews;
        const assetJobs = _self.job.assetJobs;
        const views = assetJobs.filter(
          ({ viewCode, rejectionStatus }) =>
            expectedViews.includes(viewCode) && rejectionStatus !== 'REJECTED'
        );

        _self.assetTable = views
          .map(({ viewCode, tasks, cropTypes }) => ({
            viewCode,
            taskId: tasks[0].id,
            assets: Object.entries(cropTypes)
              .map(([cropType, asset]) => ({
                thumbnailUrl: JobService.getImageByType(asset, 'all'),
                heroUrl: JobService.getImageByType(asset, 'large'),
                colorSwatches: [],
                selected: false,
                cropType,
                viewCode,
              }))
              .sort((a, b) => JobService.cropTypeSort(a.cropType, b.cropType))
          }))
          .sort((a, b) => a.viewCode < b.viewCode);
      }
    };

    _self.setCurRejectionColor = function() {
      const rejectionColors = _self.rejectionsList.map(
        ({ colorSwatch }) => colorSwatch
      );
      const nextColor = REJECTION_COLORS.find(color => !rejectionColors.includes(color));
      _self.curRejection.colorSwatch = nextColor;
    };

    _self.setRejectionDetailOptions = function() {
      const issueObj = _self.rejectionReasons.find(
        ({ issue }) => issue === _self.curRejection.issue
      );
      _self.rejectionDetailOptions = issueObj.details;
    };

    _self.selectAll = function(value) {
      const areAllAssetsSelected = !_self.assetTable
        .flatMap(({ assets }) => assets.map(({ selected }) => selected))
        .some(selected => !selected);

      const selection = value !== undefined ? value : !areAllAssetsSelected;

      _self.assetTable.forEach(function(view) {
        view.assets.forEach(function(asset) {
          asset.selected = selection;
          _self.adjustColorSwatch(asset);
        });
      });
    };

    _self.saveRejection = function() {
      if (!_self.curRejectionIsValid) {
        return;
      }

      const rejectedAssets = [];
      _self.assetTable.forEach(function(view) {
        view.assets.forEach(function(asset) {
          if (asset.selected) {
            const rejectedAsset = {
              taskId: view.taskId,
              cropType: asset.cropType,
              rejectionReason: _self.curRejection.issue,
              rejectionReasonDetail: _self.curRejection.detail,
              comment: _self.curRejection.comment
            };
            rejectedAssets.push(rejectedAsset);
          }
        });
      });
      _self.curRejection.rejectedAssets = rejectedAssets;

      _self.rejectionsList.push(_self.curRejection);
    };

    _self.resetCurRejection = function() {
      _self.curRejection = angular.copy(DEFUALT_REJECTION);
      _self.rejectionsMaxedOut = _self.rejectionsList.length >= REJECTION_COLORS.length;
      _self.curRejectionIsValid = null;
      _self.rejectionDetailOptions = [];
      _self.selectAll(false);
      _self.setCurRejectionColor();
    };

    _self.selectImagesOnlyWithColorSwatch = function(color) {
      _self.assetTable.forEach(function(view) {
        view.assets.forEach(function(asset) {
          if (asset.colorSwatches && asset.colorSwatches.includes(color)) {
            asset.selected = true;
          } else {
            asset.selected = false;
          }
        });
      });
    };

    _self.adjustColorSwatch = function(asset) {
      const color = _self.curRejection.colorSwatch;
      if (asset.selected && !asset.colorSwatches.includes(color)) {
        asset.colorSwatches.push(color);
      } else if (!asset.selected && asset.colorSwatches.includes(color)) {
        asset.colorSwatches = asset.colorSwatches.filter(
          colorSwatch => colorSwatch !== color
        );
      }
    };

    _self.repaintColorSwatches = function() {
      let taskColorSwatchMap = {};
      _self.rejectionsList.forEach(function(rejection) {
        taskColorSwatchMap[
          rejection.colorSwatch
        ] = rejection.rejectedAssets.map(
          rejection => rejection.taskId + '|' + rejection.cropType
        );
      });

      _self.assetTable.forEach(function(view) {
        view.assets.forEach(function(asset) {
          asset.colorSwatches = [];
          for (let color in taskColorSwatchMap) {
            if (
              taskColorSwatchMap[color].includes(
                view.taskId + '|' + asset.cropType
              )
            ) {
              asset.colorSwatches.push(color);
            }
          }
          if (asset.selected) {
            asset.colorSwatches.push(_self.curRejection.colorSwatch);
          }
        });
      });
    };

    _self.getSelectionsLength = () => {
      return _self.assetTable
        .flatMap(({ assets }) => assets.map(({ selected }) => selected))
        .filter(selected => selected).length;
    };

    _self.validateRejection = function(shouldShowErrorWhenInvalid) {
      let validCount = 0;
      let invalidCount = 0;
      !!_self.curRejection.issue ? ++validCount : ++invalidCount;
      !!_self.curRejection.detail ? ++validCount : ++invalidCount;
      !!_self.getSelectionsLength() ? ++validCount : ++invalidCount;

      if(!!validCount && !!invalidCount) {
        _self.curRejectionIsValid = !invalidCount;
      } else {
        _self.curRejectionIsValid = !!validCount ? true : null;
      }

      if (_self.curRejectionIsValid || shouldShowErrorWhenInvalid === false) {
        _self.showError = false;
      } else {
        if (_self.showError || shouldShowErrorWhenInvalid) {
          _self.showError = true;
        }
      }
    };

    _self.editRejectionReason = function(rejection) {
      _self.saveRejection();
      _self.resetCurRejection();
      _self.curRejection = rejection;
      _self.rejectionsList = _self.rejectionsList.filter(
        ({ colorSwatch }) => colorSwatch != rejection.colorSwatch
      );
      _self.rejectionsMaxedOut = false;
      _self.setRejectionDetailOptions();
      _self.selectImagesOnlyWithColorSwatch(rejection.colorSwatch);
      _self.validateRejection();
    };

    _self.removeRejectionReason = function(rejection) {
      _self.rejectionsList = _self.rejectionsList.filter(
        ({ colorSwatch }) => colorSwatch !== rejection.colorSwatch
      );
      _self.rejectionsMaxedOut = false;
      _self.setCurRejectionColor();
      _self.repaintColorSwatches();
      _self.validateRejection();
    };

    _self.onSelectRejectionIssue = function() {
      _self.curRejection.detail = undefined;
      _self.setRejectionDetailOptions();
      _self.validateRejection();
    };

    _self.onSelectRejectionDetail = function() {
      _self.curRejection.title =
        _self.curRejection.issue + ': ' + _self.curRejection.detail;
      _self.validateRejection();
    };

    _self.onImageSelect = function(asset) {
      if(_self.rejectionsMaxedOut) {
        return;
      }
      const isSelected = asset.selected;
      const selectionsLength = _self.getSelectionsLength();
      const hasSelections = selectionsLength > 0;
      const hasMultipleSelections = selectionsLength > 1;
      if (!_self.isCommandKeyDown && hasSelections) {
        _self.selectAll(false);
      }
      if (!_self.isCommandKeyDown && hasMultipleSelections) {
        asset.selected = true;
      } else {
        asset.selected = !isSelected;
      }
      if (asset.selected) {
        _self.onRejectionThumbClick()(asset);
      }
      _self.adjustColorSwatch(asset);
      _self.validateRejection();
    };

    _self.onAddAnotherReason = function() {
      _self.saveRejection();
      _self.resetCurRejection();
    };
    _self.onRejectionPanelDone = function() {
      _self.validateRejection(true);
      if(_self.curRejectionIsValid === false) {
        return;
      }
      _self.saveRejection();
      _self.resetCurRejection();
      _self.onSaveAndCloseRejectionPanel()(_self.rejectionsList);
    };

    _self.onRejectionPanelCancel = function() {
      _self.resetCurRejection();
      _self.onRejectionPanelDone();
    };

    $scope.$watch(
      function() {
        return _self.active;
      },
      function() {
        if (_self.active) {
          _self.rejectionsList = angular.copy(
            _self.userSelectedRejectionReasons
          );
          _self.resetCurRejection();
          _self.repaintColorSwatches();
        }
      }
    );

    $scope.$watch(
      function() {
        return _self.job;
      },
      function() {
        _self.loadImages();
        _self.repaintColorSwatches();
      }
    );

    angular.element(window).bind('keyup', _self.onKeyUp);
    $scope.$on('$destroy', function() {
      angular.element(window).unbind('keyup', _self.onKeyUp);
    });
  },
  templateUrl: 'templates/partials/qa/rejectionPanel.tpl.html'
};

module.exports = rejectionPanelComponent;
