'use strict';

var IScroll = require('iscroll');
var iscrollKey = '$$iscroll';

/*jshint unused: false*/
function register(angular) {
  return [
    function iscrollService() {
      function getOrCreateCtx(scope) {
        var ctx = scope[iscrollKey];
        if (!ctx) {
          ctx = scope[iscrollKey] = {
            scrollers: []
          };
        }
        return ctx;
      }

      function find(scope, name) {
        var scrollers = getOrCreateCtx(scope).scrollers;
        if (!name) {
          return scrollers;
        } else {
          for (var i = 0; i < scrollers.length; i++) {
            if (scrollers[i].name === name) {
              return scrollers[i].scrollerObj;
            }
          }
        }
      }

      function add(scope, name, scrollerObj) {
        var scroller = find(scope, name);
        if (scroller) {
          throw new Error(
            'Scroller named: "' +
              name +
              '" already exists in same angular scope.'
          );
        }
        var ctx = getOrCreateCtx(scope);
        scroller = {
          name: name,
          scrollerObj: scrollerObj
        };
        ctx.scrollers.push(scroller);
        return scroller;
      }

      function remove(scope, name) {
        var scrollers = getOrCreateCtx(scope).scrollers;
        for (var i = 0; i < scrollers.length; i++) {
          if (scrollers[i].name === name) {
            scrollers[i].scrollerObj.destroy();
            scrollers.splice(i, 1);
          }
        }
      }

      function refresh(scope, name) {
        var scrollers = getOrCreateCtx(scope).scrollers;
        for (var i = 0; i < scrollers.length; i++) {
          if (!name || scrollers[i].name === name) {
            scrollers[i].scrollerObj.refresh();
          }
        }
      }

      return {
        add: add,
        find: find,
        remove: remove,
        refresh: refresh
      };
    }
  ];
}

module.exports = register;
