'use strict';

/*jshint unused: false*/
var otherAssetsComponent = {
  restrict: 'E',
  replace: true,
  transclude: true,
  bindings: {
    job: '<',
    product: '<',
    hideparam: '='
  },
  controller: /*@ngInject*/ function(JobService, $q, $scope) {
    var _self = this;
    _self.otherImages = [];

    //Data to initialize IMG Hero
    _self.imgHero = {
      active: false,
      src: ''
    };

    _self.getSrc = function(image) {
      return JobService.getImageByType(image, 'all');
    };

    _self.showHero = function(image) {
      _self.imgHero.active = true;
      _self.imgHero.src = JobService.getImageByType(image, 'large');
      _self.imgHero.name = image.fileName;
      _self.imgHero.type = 'Other';
    };

    _self.$onChanges = function(changesObj) {
      if (_self.product) {
        _self.product.assets.forEach(asset => {
          if (!_self.job.expectedViews.includes(asset.viewCode) && !asset.viewCode.toLowerCase().startsWith('fco')) {
            var unexpected = true;
            _self.otherImages.forEach(image => {
              if (image.id === asset.id) {
                unexpected = false;
              }
            });
            if (unexpected) {
              _self.otherImages.push(asset);
            }
          }
        });
      }
    };
  },
  templateUrl: 'templates/partials/ui/otherAssetsGroup.tpl.html'
};

module.exports = otherAssetsComponent;
