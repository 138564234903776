'use strict';

var HISTORY_TRANSLATOR = {
  Default: {
    description: 'No template for {{action}}',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CreateMetaJob: {
    description: 'Job Created{{payload.source ? " via " + payload.source : ""}}',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UpdateMetaJob: {
    description: 'Job Modified{{payload.source ? " via " + payload.source : ""}}',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CreateMerchJob: {
    description: 'Merch Job Started',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  ClaimMetaJob: {
    description: 'Job Assigned',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UnclaimMetaJob: {
    description: 'Job Unassigned',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CompleteMetaJobTask: {
    description: 'Completed Meta Task',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CloseMetaJob: {
    description: 'Job Closed',
    templateUrl: 'templates/partials/history/closeJob.tpl.html'
  },
  DropMetaJob: {
    description: 'Job Dropped',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  ConvertMetaJob: {
    description:
      'Job converted from {{payload.oldJobType}} to {{payload.newJobType}}',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  MetaJobPublishFailed: {
    description: 'Meta Job Publishing Failed',
    templateUrl: 'templates/partials/history/publishFailed.tpl.html'
  },
  CompleteMerchJobTask: {
    description: 'Status Updated',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CompleteAssetJobTask: {
    description: 'Status Updated',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  AddNoteToMetaJob: {
    description: '{{payload.noteType}} added',
    templateUrl: 'templates/partials/history/note.tpl.html'
  },
  UpdateNoteOnMetaJob: {
    description: 'Slate Note edited',
    templateUrl: 'templates/partials/history/note.tpl.html'
  },
  StartProblemSolve: {
    description: 'Problem Solve initiated',
    templateUrl: 'templates/partials/history/problemSolveStarted.tpl.html'
  },
  ClaimProblemSolve: {
    description: 'Problem Solve claimed',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UnclaimProblemSolve: {
    description: 'Problem Solve unclaimed',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CompleteProblemSolve: {
    description: 'Completed Problem Solve',
    templateUrl: 'templates/partials/history/problemSolveCompleted.tpl.html'
  },
  ClaimAssetJob: {
    description: 'Assigned',
    separator: 'to',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UnclaimAssetJob: {
    description: 'Unclaimed',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  DropAssetJob: {
    description: 'Asset Dropped',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CloseAssetJob: {
    description: 'Asset closed',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UploadUneditedViews: {
    description: 'Unedited Assets Uploaded',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UploadDeliverableViews: {
    description: 'Deliverable Assets Uploaded',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  UploadUneditedAsset: {
    description: 'Unedited asset uploaded',
    templateUrl: 'templates/partials/history/asset.tpl.html'
  },
  UploadDeliverableAsset: {
    description: 'Deliverable asset uploaded',
    templateUrl: 'templates/partials/history/asset.tpl.html'
  },
  UploadWorkerAsset: {
    description: 'Worker asset uploaded',
    templateUrl: 'templates/partials/history/asset.tpl.html'
  },
  MapUploadUneditedAsset: {
    description: '(D6) Unedited asset uploaded',
    templateUrl: 'templates/partials/history/d6asset.tpl.html'
  },
  MapUploadDeliverableAsset: {
    description: '(D6) Deliverable asset uploaded',
    templateUrl: 'templates/partials/history/d6asset.tpl.html'
  },
  MapUploadWorkerAsset: {
    description: '(D6) Worker asset uploaded',
    templateUrl: 'templates/partials/history/d6WorkerAssetUploaded.tpl.html'
  },
  DownloadAsset: {
    description: 'Asset downloaded',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  CopyAsset: {
    description: 'Asset copied',
    templateUrl: 'templates/partials/history/copyAsset.tpl.html'
  },
  ApproveAsset: {
    description: 'Approved',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  RejectAsset: {
    description: 'Rejected',
    templateUrl: 'templates/partials/history/rejectedAsset.tpl.html'
  },
  RejectAsset2: {
    description: 'Rejected',
    templateUrl: 'templates/partials/history/rejectedAsset2.tpl.html'
  },
  RejectAsset3: {
    description: 'Rejected',
    templateUrl: 'templates/partials/history/rejectedAsset3.tpl.html'
  },
  AssetAnnotated: {
    description: 'New Annotation',
    templateUrl: 'templates/partials/history/newAnnotation.tpl.html'
  },
  SendAssetToProductReview: {
    description: 'Sent to Product Review',
    templateUrl: 'templates/partials/history/productReview.tpl.html'
  },
  ReturnAssetToImageQA: {
    description: 'Returned to Image QA',
    templateUrl: 'templates/partials/history/productReview.tpl.html'
  },
  PublishAsset: {
    description: 'Asset Published to Directory',
    sections: [
      {
        label: 'Filename',
        value: '{{payload.publishedImage.publishedFileName}}'
      },
      {
        label: 'Directory',
        value: '{{payload.publishedImage.directory}}'
      },
      {
        label: 'Status Code',
        value: '{{payload.publishedImage.statusCode}}'
      }
    ],
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  ApproveAllViews: {
    description: 'All Assets Approved',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  PublishAllViews: {
    description: 'Assets Published',
    templateUrl: 'templates/partials/history/statusChange.tpl.html'
  },
  RenameAsset: {
    description:
      'Asset Renamed from {{ payload.originalFileName }} to {{ payload.fileName }}',
    templateUrl: 'templates/partials/history/assetRename.tpl.html'
  },
  UpdateProductProperties: {
    description: 'Product Attributes Modified',
    templateUrl: 'templates/partials/history/updateProductProperties.tpl.html'
  },
  UpdateMetaJobProperties: {
    description: 'Job Attributes Modified',
    templateUrl: 'templates/partials/history/updateMetaJobProperties.tpl.html'
  },
  SendToProductReview: {
    description: 'Sent to Product Team',
    templateUrl: 'templates/partials/history/sendToProductReview.tpl.html'
  },
  ReturnToImageQA: {
    description: 'Returned to Image QA',
    templateUrl: 'templates/partials/history/returnToImageQA.tpl.html'
  },
  MetaJobFilesDownload: {
    description: "Desktop download requested",
    templateUrl: 'templates/partials/history/metaJobFilesDownload.tpl.html'
  }
};

module.exports = HISTORY_TRANSLATOR;
