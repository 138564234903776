'use strict';

var angular = require('angular');
var moment = require('moment-timezone');

var studioComponent = {
  bindings: {
    model: '<',
    onEdit: '&'
  },
  controller: /*@ngInject*/ function($http, $interpolate, REST_URL) {
    var _self = this;

    _self.templateStrings = {
      expiration: 'Code will expire in {{time}} days',
      expired: '<b class="h-red">This code is expired</b>'
    };

    _self.handleEditClick = function() {
      _self.onEdit({ $event: { studio: _self.model } });
    };

    _self.getCode = function() {
      _self.working = true;

      $http
        .post(
          [REST_URL.base, 'studios', _self.model.id, 'generatePassword'].join(
            '/'
          )
        )
        .then(_self.getCodeStudiosResponse)
        .catch(_self.getCodeStudiosCatch)
        .finally(_self.getCodeStudiosFinally);
    };

    _self.getCodeStudiosResponse = function(response) {
      _self.updateModel(response.data);
    };

    _self.getCodeStudiosCatch = function() {};

    _self.getCodeStudiosFinally = function() {
      _self.working = false;
    };

    _self.getPasswordExpirationString = function(now, then) {
      if (_self.model.ftpPasswordExpiration) {
        var daysUntilExpiration = then.diff(now, 'days');

        return daysUntilExpiration > 0
          ? $interpolate(_self.templateStrings.expiration)({
              time: daysUntilExpiration
            })
          : $interpolate(_self.templateStrings.expired)();
      }

      return _self.templateStrings.expired;
    };

    _self.updateModel = function(newModel) {
      _self.model = angular.copy(newModel);
      _self.expirationString = _self.getPasswordExpirationString(
        moment(),
        moment(_self.model.ftpPasswordExpiration)
      );
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.updateModel(_self.model);
      }
    };
  },
  templateUrl: 'templates/partials/admin/studio.tpl.html'
};

module.exports = studioComponent;
