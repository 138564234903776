'use strict';

var LOGIN_URL = {
  qa    : 'https://whqqfed.nike.com:9031/idp/startSSO.ping?PartnerSpId=com.ixxus.nike.QA.api',
  local : 'http://localhost:3000/',
  dev   : 'https://gbipuidev01.sandbox.dbt.svs.nike.com/',
  uat   : 'https://gbipuiuat.sandbox.dbt.svs.nike.com/',
  prod  : 'https://icon.svs.nike.com/'
};

module.exports = LOGIN_URL;
