'use strict';

var ASSET_STATUS = {
  postProd: 'In Post Production',
  qa: 'In QA',
  approved: 'Approved',
  dropped: 'Dropped',
  closed: 'Closed',
  post: 'In Post',
  review: 'In Product Review'
};

module.exports = ASSET_STATUS;
