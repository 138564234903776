'use strict';

//http://stackoverflow.com/questions/12046928/how-to-use-regex-with-ng-repeat-in-angularjs
var taheadFilter = function() {
  return function(input, filtering) {
    if (input === 'Unassign') {
      return '<i>Unassign</i>';
    }

    //If we need to display blank text. This is necessary for <typeahead>s, as they still need to send an object for the dropdown.
    if (input === ' ') {
      return '';
    }

    if (input && filtering && input.replace) {
      var temp = new RegExp(filtering, 'ig');
      return input.replace(temp, '<strong>$&</strong>');
    } else {
      return input;
    }
  };
};

module.exports = taheadFilter;
