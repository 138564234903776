'use strict';

const USER_ASSIGNED = {
  label: 'All Assigned',
  value: 'assigned',
  order: '!',
};

var csvDownloadController = {
  bindings: {
    mode: '@',
  },
  controller: /*@ngInject*/ function(
    NotificationService,
    AssignedUsersService,
    CSVDownloadService,
    USER_DATA,
    USER_ROLES,
    AuthService
  ) {
    var _self = this;

    _self.data = {
      assigneeParams: {
        label: 'Assignee',
        model: USER_ASSIGNED.label,
        object: USER_ASSIGNED,
        isOpen: false,
        required: true,
        options: [USER_ASSIGNED],
      },
      loadingUsers: false,
      downloading: false,
      showAssigneeOptions: false,
      permissions: {
        canDownloadAllJobs: (function() {
          return AuthService.isAuthorized([
            USER_ROLES.seniorImageEditor,
            USER_ROLES.studioOps,
            USER_ROLES.superUser
          ]);
        })(),
        canDownloadOwnJobs: (function() {
          return AuthService.isAuthorized([
            USER_ROLES.imageEditor,
            USER_ROLES.externalImageEditor,
            USER_ROLES.superUser
          ]);
        })(),
        canDownload: false,
      },
    };

    if (_self.mode === 'unassigned') {
      _self.data.permissions.canDownload = _self.data.permissions.canDownloadAllJobs;
    } else if (_self.mode === 'assigned') {
      _self.data.permissions.canDownload = (
        _self.data.permissions.canDownloadAllJobs ||
        _self.data.permissions.canDownloadOwnJobs
      );
    }

    _self.startCSVDownload = function(assignee) {
      _self.data.downloading = true;
      CSVDownloadService.downloadJobsInPostByUser(assignee)
        .then(function(response) {
          _self.data.downloading = false;
        })
        .catch(function(error) {
          NotificationService.push('Unable to download post assignment jobs.');
          _self.data.downloading = false;
        });
    };

    _self.getAssignedPostUsers = function() {
      _self.data.loadingUsers = true;
      AssignedUsersService.getPostUsers()
        .then(function(response) {
          _self.data.assigneeParams.options = [USER_ASSIGNED].concat(response.users.map(function(user) {
            return {
              label: `${user.firstName} ${user.lastName}`,
              value: user.username,
              order: user.lastName,
            }
          }));
          _self.data.loadingUsers = false;
        })
        .catch(function(error) {
          NotificationService.push('Unable to get post assignment user data.');
          _self.data.loadingUsers = false;
          _self.data.showAssigneeOptions = false;
        });
    };

    _self.download = function() {
      if (_self.mode === 'unassigned') {
        if (_self.data.permissions.canDownloadAllJobs) {
          _self.startCSVDownload('unassigned');
        }
      } else if (_self.mode === 'assigned') {
        if (_self.data.permissions.canDownloadAllJobs) {
          _self.data.showAssigneeOptions = true;
          _self.getAssignedPostUsers();
        } else if (_self.data.permissions.canDownloadOwnJobs) {
          _self.startCSVDownload(USER_DATA.userName);
        }
      }
    };

    _self.setAssignee = function(option) {
      _self.data.assigneeParams.object = option;
    };

    _self.cancel = function () {
      _self.data.showAssigneeOptions = false;
    }

    _self.submit = function () {
      _self.data.showAssigneeOptions = false;
      _self.startCSVDownload(_self.data.assigneeParams.object.value);
    }
  },
  templateUrl: 'templates/partials/queue/csvDownload.tpl.html',
};

module.exports = csvDownloadController;
