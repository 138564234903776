'use strict';

var NotificationService = require('./notification.service.js');
var notificationComponent = require('./notification.component.js');
var notificationProvider = require('./notification.provider.js');

function register(angular) {
  var app = angular.module('notification', ['ng']);

  app.service('NotificationService', NotificationService(angular));
  app.component('notifications', notificationComponent(angular));
  app.provider('$$notifications', notificationProvider(angular));

  app.run([
    '$templateCache',
    function($templateCache) {
      $templateCache.put(
        'templates/notification.tpl.html',
        '<div class="notification-collection"></div>'
      );
    }
  ]);

  app.run([
    '$rootScope',
    '$$notifications',
    'NotificationService',
    function($rootScope, $$notifications, NotificationService) {
      var options = $$notifications.options();

      if (options.globalAccess) {
        window[options.baseClass] = {
          push: function(message, patterns) {
            $rootScope.$apply(function() {
              NotificationService.push(message, patterns);
            });
          },
          get: NotificationService.get
        };
      }
    }
  ]);

  return 'notification';
}

module.exports = register;
