'use strict';

/**
 * App Controller
 * @module AppController
 * @description Root controller for application page
 */
var AppController = /*@ngInject*/ function(
  $rootScope,
  $scope,
  $timeout,
  $state,
  AUTH_EVENTS,
  AuthService,
  KeymageService,
  REDIRECT
) {
  var _self = this;

  _self.bounce = false;

  _self.scanError = {
    active: false,
    data: {
      type: 'error',
      heading: {
        title: 'Product Not Found',
        copy: "This product couldn't be located in the system."
      },
      cta: {
        primary: 'Ok'
      }
    },
    onSubmit: function() {
      _self.scanError.active = false;
    }
  };

  _self.generalError = {
    active: false,
    data: {
      type: 'error',
      heading: {},
      cta: {
        primary: 'Ok'
      }
    },
    onSubmit: function() {
      _self.generalError.active = false;
    }
  };

  var showGeneralError = function(params) {
    var heading = {
      title: params.title || 'Error',
      copy:
        params.copy ||
        "The system encountered an issue. If you're unable to proceed after retrying, please contact Production Support for assistance."
    };
    var styling = params.styling || {};

    _self.generalError.data.heading = heading;
    _self.generalError.data.styling = styling;
    _self.generalError.data.error = params ? params.error : undefined;
    _self.generalError.active = true;
  };

  _self.shortcutData = {
    active: false,
    data: {
      heading: {
        title: 'Keyboard Shortcuts'
      },
      cta: {
        primary: 'Ok'
      }
    },
    onSubmit: function() {
      _self.shortcutData.active = false;
    }
  };

  _self.logout = {
    active: false,
    data: {
      type: 'logout',
      heading: {
        title: 'Log Out',
        copy: 'Are you sure you want to log out?'
      },
      cta: {
        secondary: 'Cancel',
        primary: 'Yes'
      }
    },
    onShow: function() {
      _self.logout.active = true;
    },
    onSubmit: function () {
      AuthService.logOut()
        .then(function (response) {
          $log.info("User logged out");
          $rootScope.$broadcast(AUTH_EVENTS.notAuthenticated);
        }, function (error) {
          $log.error('authService logout Error:', error);
        });
    }
  };

  var setKeyBindings = function() {
    var bindings = [];

    bindings.push({
      shortcut: 'shift-/',
      fn: function() {
        $timeout(function() {
          _self.shortcutData.active = true;
        }, 0);
      }
    });

    KeymageService.set($scope, bindings);
  };

  var init = function() {
    setKeyBindings();

    $rootScope.$on(AUTH_EVENTS.notAuthorized, function() {
      $state.go('search');
    });

    $rootScope.$on(AUTH_EVENTS.notAuthenticated, function() {
      REDIRECT.login();
    });

    $rootScope.$on('scan.error', function() {
      _self.scanError.active = true;
    });

    $rootScope.$on('scan.update', function() {
      _self.scanError.active = false;
    });

    $rootScope.$on('error', function(e, params) {
      showGeneralError(params);
    });
  };

  init();
};

module.exports = AppController;
