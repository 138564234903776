'use strict';

/*jshint unused: false*/
var imgHeroComponent = {
  bindings: {
    model: '<',
    hideparam: '='
  },
  controller: /*@ngInject*/ function(TransferService, $element) {
    var _self = this;
    _self.type = $element.attr('type');

    _self.saveLineArt = function() {
      if (_self.model.type && _self.model.type === 'FCO') {
        TransferService.downloadAssets(_self.model.name);
      } else {
        TransferService.saveLineArt({
          url: _self.model.src,
          name: _self.model.name,
          viewCode: _self.model.viewCode
        });
      }
    };

    _self.closeModal = function() {
      _self.model.active = false;
      _self.model.src = '';
      if (_self.hideparam) {
        _self.hideparam = false;
      }
    };
  },
  templateUrl: 'templates/partials/ui/imgHeroComponent.tpl.html'
};

module.exports = imgHeroComponent;
