'use strict';

var angular = require('angular');

var jobDetailComponent = {
  bindings: {
    model: '<',
    onChange: '&'
  },
  controller: /*@ngInject*/ function() {
    var _self = this;

    _self.toggleProperty = function(property, boolean) {
      property = angular.copy(property);

      if (boolean !== undefined && property === boolean) {
        return;
      }

      property = boolean !== undefined ? boolean : !property;

      return property;
    };

    _self.update = function($event) {
      _self.onChange({ $event: $event });
    };

    _self.updateNotes = function($event) {
      _self.onChange({ $event: $event });
    };

    _self.viewAssetDetails = function($event) {
      _self.onChange({
        $event: { assetJobId: $event.assetJob.id, cropType: $event.cropType }
      });
    };

    _self.$onInit = function() {};

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }

        _self.model = angular.copy(_self.model);
      }
    };
  },
  templateUrl: 'templates/partials/detail/jobDetail.tpl.html'
};

module.exports = jobDetailComponent;
