'use strict';

/*jshint unused: false*/
var JobTypeComponent = {
  bindings: {
    type: '<',
    base: '<'
  },
  controller: /*@ngInject*/ function(JobTypeService, JOB_TYPE) {
    var _self = this;
    _self.$onChanges = function(changes) {
      if (changes.type) {
        _self.id = JOB_TYPE.icons[_self.type];
      }
    };
  },
  templateUrl: 'templates/partials/ui/jobType.tpl.html'
};

module.exports = JobTypeComponent;
