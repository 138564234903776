'use strict';

var angular = require('angular');
var moment = require('moment');

var productDetailComponent = {
  bindings: {
    model: '<',
    full: '<'
  },
  controller: /*@ngInject*/ function(
    $element,
    $state,
    AnimationService
  ) {
    var _self = this;

    _self.toggleFullData = function(boolean) {
      var elem = $element[0].querySelector('.js-full-data');
      if (boolean !== undefined && _self.showFullData === boolean) {
        return;
      }

      _self.showFullData =
        boolean !== undefined ? boolean : !_self.showFullData;

      _self.animating = true;

      AnimationService.expand(elem, _self.showFullData).then(function() {
        _self.animating = false;
      });

      return _self.showFullData;
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (changes.model.isFirstChange()) {
          return;
        }

        _self.model = angular.copy(_self.model);

        if(_self.model.confidentialTo && _self.model.confidentialTo.indexOf("[") === 0) {
          _self.model.confidentialTo = JSON.parse(_self.model.confidentialTo).join(", ");
        }
      }
    };

    _self.linkProduct = function(link) {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: link.productId,
        jobId: null,
        assetJobId: null,
        tab: null
      });
      window.open(url, '_blank');
    };

    _self.hasProductId = function(link) {
      return link.productId;
    };

    _self.hasNoProductId = function(link) {
      return !link.productId;
    };
  },
  templateUrl: 'templates/partials/detail/productDetail.tpl.html'
};

module.exports = productDetailComponent;
