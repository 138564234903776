'use strict';

var angular = require('angular');

var TabsComponent = {
  bindings: {
    onSelect: '&'
  },
  transclude: true,
  controller: function() {
    var _self = this,
      panes = (_self.panes = []);

    _self.select = function(pane) {
      angular.forEach(panes, function(pane) {
        pane.selected = false;
      });
      pane.selected = true;
      _self.onSelect({ $event: { pane: pane } });
    };

    _self.addPane = function(pane) {
      if (panes.length === 0) {
        _self.select(pane);
      }

      panes.push(pane);
    };
  },
  templateUrl: 'templates/partials/tabs/tabs.tpl.html'
};

module.exports = TabsComponent;
