'use strict';

/*jshint unused: false*/
var searchDirective = /*@ngInject*/ function($timeout, $document) {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      model: '=?',
      placeholder: '@',
      onEnter: '&',
      readonly: '=',
      autoFocus: '='
    },
    controllerAs: 'search',
    controller: /*@ngInject*/ function($element) {
      var _self = this;

      _self.focus = function() {
        $element.addClass('is-active');
      };

      _self.blur = function() {
        $element.removeClass('is-active');
      };

      _self.clearSearch = function() {
        _self.model = '';
        $timeout(function() {
          _self.onEnter({ value: _self.model });
        }, 0);
      };

      _self.$onInit = function() {
        if (_self.readonly === undefined) {
          _self.readonly = false;
        }
      };
    },
    templateUrl: 'templates/partials/ui/search.tpl.html',
    link: function(scope, elem, attrs, ctrl) {
      var input = elem[0].querySelector('input');

      var keybindFilter = function(e) {
        if (e.which === 13) {
          // return
          e.preventDefault();
          e.stopPropagation();
          ctrl.onEnter({ value: ctrl.model, event: e });
        }
      };

      input.addEventListener('focus', ctrl.focus);
      input.addEventListener('blur', ctrl.blur);
      if (ctrl.autoFocus) {
        $timeout(function() {
          input.focus();
        });
      }

      elem.on('keydown', keybindFilter);

      scope.$on('$destroy', function() {
        elem.unbind('keydown', keybindFilter);
        input.removeEventListener('focus', ctrl.focus);
        input.removeEventListener('blur', ctrl.blur);
      });
    }
  };
};

module.exports = searchDirective;
