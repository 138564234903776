'use strict';

/*jshint unused: false*/
function register(angular) {
  return function tooltipProvider() {
    var api = {};

    var defaultOptions = {
      class: 'tooltip',
      position: 'top',
      trigger: 'mouseenter',
      delay: 250
    };

    var globalOptions = {};

    api.triggerMap = {
      mouseenter: 'mouseleave',
      click: 'click',
      outsideClick: 'outsideClick',
      focus: 'blur',
      none: ''
    };

    api.options = function(options) {
      return angular.extend({}, defaultOptions, globalOptions, options);
    };

    this.setOptions = function(value) {
      angular.extend(globalOptions, value);
    };

    this.$get = function() {
      return api;
    };
  };
}

module.exports = register;
