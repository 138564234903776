'use strict';

function register(angular) {
  return ['$http', function ($http) {
    return {

        link: function ($scope, elem, attrs) {
          function revokeObjectURL() {
            if ($scope.objectURL) {
              URL.revokeObjectURL($scope.objectURL);
            }
          }

          function fetchAndLoadImage(url) {
            $http.get(url, { responseType: 'arraybuffer' })
              .then(function (response) {
                /**
                 * enableUrlCheck prevents a race condition when httpSrc is modified during the get call causing an unpredictable image to be shown.
                 */
                if (!attrs.enableUrlCheck || attrs.httpSrc === url) {
                  var blob = new Blob(
                    [ response.data ],
                    { type: response.headers('Content-Type') }
                  );
                  $scope.objectURL = URL.createObjectURL(blob);
                }
              })
              .catch(function (error) {
                const unwrappedElem = elem[0];
                if (typeof unwrappedElem.onerror === 'function') {
                  unwrappedElem.onerror(error);
                }
              });
          }

          $scope.$watch('objectURL', function (objectURL) {
            elem.attr('src', objectURL);
          });

          $scope.$on('$destroy', function () {
            revokeObjectURL();
          });

          attrs.$observe('httpSrc', function (url) {
            revokeObjectURL();

            if (url === undefined || url.length === 0) {
              return;
            }

            // we've already fetched image, so don't need to do that again
            if (url.indexOf('data:') === 0) {
              $scope.objectURL = url;
              return;
            }

            // some things start behind a spinner (e.g. QA zoom), so just go ahead and load
            if (attrs['class'] && attrs['class'].indexOf('eager-load') >= 0) {
              fetchAndLoadImage(url);
              return;
            }

            var observer = new IntersectionObserver(function (changes) {
              changes.forEach(function (change) {
                if (change.intersectionRatio > 0.1) {
                  fetchAndLoadImage(url);
                }
              });
            });

            observer.observe(angular.element(elem)[0]);

          });
        }
      };
  }];
}

module.exports = register;
