'use strict';

var httpSrc = require('./httpSrc.directive.js');

function register(angular) {
  var app = angular.module('angular.img', []);

  app.directive('httpSrc', httpSrc(angular));

  return 'angular.img';
}

module.exports = register;
