'use strict';

var META_STATUS = require('../config/metaStatus.constant.js');
var MERCH_STATUS = require('../config/merchStatus.constant.js');
var ASSET_STATUS = require('../config/assetStatus.constant.js');
var UPDATE_PARAMS = require('../config/updateParams.constant.js');

var setSubJobFilter = function(status) {
  var subJobFilter = [
    ['subJobStatuses', status].join('='),
    ['!status', META_STATUS.problemSolve].join('=')
  ];

  return subJobFilter.join(',');
};

var getNextMerchStatus = function(currentStatus) {
  return MERCH_STATUS.allStatuses[MERCH_STATUS.allStatuses.indexOf(currentStatus) + 1];
};

var setMerchInstruction = function(currentStatus) {
  var instruction =
      "<strong>Scan</strong> or <strong>select</strong> a product to move to '",
    nextStatus = currentStatus
      ? getNextMerchStatus(currentStatus)
      : MERCH_STATUS.inOps;

  return instruction + nextStatus + "'";
};

var QUEUE_DATA = {
  jobPending: {
    title: 'Search Inbound',
    hideCount: true,
    status: META_STATUS.jobPending,
    instruction: setMerchInstruction(),
    searchParams: {
      type: '',
      queue: META_STATUS.jobPending
    },
    cta: 'Initiate Job',
    updateParams: UPDATE_PARAMS.jobPending,
    initiateJobFlag: true
  },
  inOps: {
    title: MERCH_STATUS.inOps,
    status: MERCH_STATUS.inOps,
    searchParams: {
      filters: setSubJobFilter(MERCH_STATUS.inOps)
    },
    updateParams: UPDATE_PARAMS.inOps
  },
  readyForStyling: {
    title: MERCH_STATUS.readyForStyling,
    status: MERCH_STATUS.readyForStyling,
    instruction: setMerchInstruction(MERCH_STATUS.readyForStyling),
    searchParams: {
      filters: setSubJobFilter(MERCH_STATUS.readyForStyling)
    },
    updateParams: UPDATE_PARAMS.readyForStyling
  },
  inStyling: {
    title: MERCH_STATUS.inStyling,
    status: MERCH_STATUS.inStyling,
    searchParams: {
      filters: setSubJobFilter(MERCH_STATUS.inStyling)
    },
    updateParams: UPDATE_PARAMS.inStyling
  },
  prepForOutbound: {
    title: MERCH_STATUS.prepForOutbound,
    status: MERCH_STATUS.prepForOutbound,
    instruction: setMerchInstruction(MERCH_STATUS.prepForOutbound),
    searchParams: {
      filters: setSubJobFilter(MERCH_STATUS.prepForOutbound)
    },
    updateParams: UPDATE_PARAMS.prepForOutbound
  },
  completed: {
    title: 'Complete',
    status: MERCH_STATUS.completed,
    searchParams: {
      type: '',
      filters: setSubJobFilter(MERCH_STATUS.completed),
      sort: 'merchJob.modifiedOn|desc'
    },
    stop: true
  },
  postProdUnclaimed: {
    title: 'Unassigned',
    status: META_STATUS.post,
    instruction: '<strong>Select</strong> a product to assign it',
    searchParams: {
      filters: [['status', META_STATUS.post].join('='), '-assignees'].join(',')
    },
    filters: [
      'product.division',
      'product.category',
      'seasons',
      'shootType.code',
      'product.age',
      'businessPartner',
      'jobType',
      'product.vendorCode',
      'studio.studioName'
    ],
    buttonFilters: ['rejectionStatus'],
    cta: 'Assign Jobs',
    showAssets: true,
    assignType: 'meta',
    updateParams: UPDATE_PARAMS['post-prod'].assign
  },
  postProdClaimed: {
    title: 'Assigned',
    status: META_STATUS.post,
    searchParams: {
      filters: ['status', META_STATUS.post].join('=')
    },
    filters: [
      'product.division',
      'product.category',
      'seasons',
      'shootType.code',
      'product.age',
      'businessPartner',
      'jobType',
      'assignees',
      'product.vendorCode',
      'studio.studioName'
    ],
    buttonFilters: ['rejectionStatus'],
    updateParams: UPDATE_PARAMS['post-prod'].unassign,
    cta: 'Unassign',
    showAssets: true,
    showOthersAssets: true,
    assignToggle: true,
    assignType: 'meta'
  },
  problemSolveUnclaimed: {
    title: 'Unassigned',
    status: META_STATUS.problemSolve,
    instruction: '<strong>Select</strong> a product to assign it',
    searchParams: {
      filters: [
        ['status', META_STATUS.problemSolve].join('='),
        '-assignees'
      ].join(',')
    },
    filters: [
      'seasons',
      'product.division',
      'product.category',
      'product.age',
      'product.vendorCode',
      'studio.studioName'
    ],
    cta: 'Assign',
    assignType: 'meta',
    updateParams: UPDATE_PARAMS['problem-solve'].assign
  },
  problemSolveClaimed: {
    title: 'Assigned',
    status: META_STATUS.problemSolve,
    searchParams: {
      filters: ['status', META_STATUS.problemSolve].join('=')
    },
    filters: [
      'seasons',
      'product.division',
      'product.category',
      'product.age',
      'assignees',
      'product.vendorCode',
      'studio.studioName'
    ],
    cta: 'Unassign',
    assignToggle: true,
    assignType: 'meta',
    updateParams: UPDATE_PARAMS['problem-solve'].unassign
  },
  qaUnclaimed: {
    title: 'Unassigned',
    status: META_STATUS.qa,
    instruction: '<strong>Select</strong> a product to assign it',
    searchParams: {
      filters: [['status', META_STATUS.qa].join('='), '-assignees'].join(',')
    },
    filters: [
      'product.division',
      'product.category',
      'seasons',
      'shootType.code',
      'product.age',
      'businessPartner',
      'jobType',
      'product.vendorCode',
      'studio.studioName'
    ],
    buttonFilters: ['rejectionStatus'],
    cta: 'Assign Jobs',
    showAssets: true,
    showFilters: true,
    assignType: 'meta',
    detailState: 'qa',
    updateParams: UPDATE_PARAMS.qa.assign
  },
  qaClaimed: {
    title: 'Assigned',
    status: META_STATUS.qa,
    searchParams: {
      filters: ['status', META_STATUS.qa].join('=')
    },
    filters: [
      'product.division',
      'product.category',
      'seasons',
      'shootType.code',
      'product.age',
      'businessPartner',
      'jobType',
      'assignees',
      'product.vendorCode',
      'studio.studioName'
    ],
    buttonFilters: ['rejectionStatus'],
    cta: 'Unassign',
    showAssets: true,
    assignToggle: true,
    assignType: 'meta',
    detailState: 'qa',
    updateParams: UPDATE_PARAMS.qa.unassign
  },
  outreach: {
    title: 'Product Team Outreach',
    // status: META_STATUS.post,
    // instruction: '<strong>Select</strong> a product to assign it',
    searchParams: {
      filters: 'status=In Product Review,processedForSeconds=60'
    },
    filters: [
      'product.division',
      'product.category',
      'product.subcategory',
      'product.age',
      'product.gender',
      'product.vendorCode'
    ],
    cta: 'Review',
    showAssets: true,
    updateParams: UPDATE_PARAMS['post-prod'].assign
  },
  merchMngManage: {
    title: 'Merch Management',
    searchParams: null,
    filters: null,
    cta: 'Review',
    showAssets: true
  }
};

module.exports = QUEUE_DATA;
