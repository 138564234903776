'use strict';

var angular = require('angular');
var moment = require('moment');
var oktaService = require('./okta.service.js');

var AssetSearchService = /*@ngInject*/ function($http, $q, REST_URL) {
  var _self = this;
  _self.filters = [];

  //requires an array of filters and uses them to construct a search query, returns the search results  including the updated facets
  _self.search = function(
    arrFilters,
    arrStyleColors,
    includeThumbs,
    includeFacets
  ) {
    var path = '/content/studio_assets/v1?';
    if (includeThumbs) {
      path += 'includeThumbs=true&count=200';
    }
    if (includeFacets) {
      path += '&returnFacets=true';
    }
    return assetSearch(
      REST_URL.base.replace('/api', '') + path,
      arrFilters,
      arrStyleColors
    );
  };

  _self.updateAssetMetadata = function (payload) {
    let url = REST_URL.assetMetadata.replace('/api/', '/');
    return $http.put(url, payload);
  };

  //requires an array of filters and uses them to construct a search query, sends the request to generate a download folder, and returns success or error
  _self.download = function(arrFilters, arrStyleColors) {
    return assetSearch(
      [
        REST_URL.base.replace('/api', ''),
        'content/studio_assets/v1',
        'download?&folderName=' + moment().format('MMMM_Do_YYYY_h-mm-ss_a')
      ].join('/'),
      arrFilters,
      arrStyleColors
    );
  };

  _self.removeNewLines = function(copiedText) {
    var i, c, ignore, cr, nl;
    var rows, row, str;
    ignore = false;
    str = '';
    rows = [];
    row = [];
    for (i = 0; i < copiedText.length; i++) {
      c = copiedText[i];
      if (ignore === true && c !== '"') {
        str += c;
      } else {
        switch (c) {
          case '"':
            ignore = !ignore;
            str += c;
            cr = false;
            break;
          case '\r':
            cr = true;
            break;
          case '\n':
            str += ' ';

            if (cr === true) {
              row.push(str);
              rows.push(row);
              row = [];
              str = '';
              c = '';
              cr = false;
            }
            break;
          case '\t':
            row.push(str);
            str = '';
            cr = false;
            break;
          default:
            str += c;
            cr = false;
            break;
        }
      }
    }
    row.push(str);
    rows.push(row);

    return rows.join();
  };

  //private function to remove duplicate code since the only difference in search and download is the URL
  function assetSearch(url, arrFilters, arrStyleColors) {
    var deferred = $q.defer();

    if (arrStyleColors.length > 0) {
      var strQuery = getUrlParams(arrFilters, arrStyleColors);
      $http.get(`${url}${strQuery}`).then(
        function(response) {
          deferred.resolve(response.data);
        },
        function() {
          deferred.reject();
        }
      );
    } else {
      //no filters were passed, reject the promise
      deferred.reject();
    }

    return deferred.promise;
  }

  //private shared function for generating the URL params.  if this needs to be re-used in the future another service is in order
  function getUrlParams(arrFilters, arrStyleColors) {
    if (arrFilters.length > 0) {
      for (var i = 0; i < arrFilters.length; i++) {
        if (arrFilters[i].name === 'styleColor') {
          arrFilters.splice(i--, 1);
        }
      }
    }

    if (arrStyleColors.length > 0) {
      var filter = {};
      filter.name = 'styleColor';
      filter.facetValues = arrStyleColors.map(function(str) {
        return { value: str };
      });
      arrFilters.push(filter);
    }
    var queryParams = '';
    arrFilters.forEach(filter => {
      queryParams +=
        '&filter=' +
        filter.name +
        '(' +
        filter.facetValues.map(v => encodeURIComponent(v.value)).join(',') +
        ')';
    });
    return queryParams;
  }
};

module.exports = AssetSearchService;
