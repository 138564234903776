'use strict';

var modalController = require('./modal.controller.js');
var modalService = require('./modal.service.js');
var modalDirective = require('./modal.directive.js');

function register(angular) {
  var app = angular.module('modal', ['ng']);

  app.controller('modalController', modalController(angular));
  app.service('modalService', modalService(angular));
  app.directive('modal', modalDirective(angular));
  app.run([
    '$templateCache',
    function($templateCache) {
      $templateCache.put(
        'templates/modal.tpl.html',
        '<div class="modal" aria-hidden="{{!modal.active}}" role="dialog">\n' +
          '  <div class="modal__main" role="document">\n' +
          '    <div class="modal__content" ng-transclude></div>\n' +
          '  </div>\n' +
          '</div>'
      );
    }
  ]);

  return 'modal';
}

module.exports = register;
