'use strict';

var NotificationConfig = /*@ngInject*/ function($$notificationsProvider) {
  $$notificationsProvider.setOptions({
    classIn: 'flipInX',
    classOut: 'flipOutX'
  });
};

module.exports = NotificationConfig;
