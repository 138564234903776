'use strict';

var angular = require('angular');

var baseProductSelectorComponent = {
  bindings: {
    term: '<',
    job: '<',
    onChange: '&',
    readonly: '<',
    jobType: '<'
  },
  controller: function(SearchService, JobTypeService, META_STATUS, APP_DATA) {
    var _self = this;

    _self.filter = '!status=Pending Drop|Dropped';
    _self.baseJobs = [];
    _self.visibleCount = 3;
    _self.baseProductItemWidth = -126;
    _self.jobTypes = JobTypeService.getTypes();
    _self.previousSearchTerm = undefined;

    _self.reset = function() {
      _self.previousSearchTerm = _self.term;
      _self.baseJobs = [];
    };

    _self.doSearch = function(term, filter) {
      // If term is the same as previous search, do not search again
      if (term === _self.previousSearchTerm) {
        return;
      }

      _self.reset();

      // if search was cleared, exit after resetting
      if (!term) {
        _self.setBaseProduct();
        return;
      }

      _self.working = true;
      _self.currentBaseProductIndex = 0;

      SearchService.search({ term: term, filter: filter }, 'jobs')
        .then(function(response) {
          if (response.data.results) {
            _self.baseJobs = response.data.results.filter(function(job) {
              // Only return existing job if it is not in job pending (otherwise you could choose yourself as your base)
              // Never return Duplicate jobs
              // Never return Redelivery jobs if parent job is a Redelivery
              // Always return Standard jobs
              // Return Sub-Jobs jobs if they are not in "Job Pending"
              return (
                (_self.job.id !== job.id ||
                  (_self.job.id === job.id &&
                    _self.job.status !== META_STATUS.jobPending)) &&
                job.jobType !== 'Duplicate' &&
                (_self.job.jobType !== 'Redelivery' ||
                  job.jobType !== 'Redelivery') &&
                (job.jobType === 'Standard' ||
                  job.status !== META_STATUS.jobPending)
              );
            });
          }
        })
        .finally(function() {
          _self.working = false;
          _self.nextEnabled =
            _self.currentBaseProductIndex <
            _self.baseJobs.length - _self.visibleCount;
          // If we're editing a job and it has a base product, attempt to select it in the results
          _self.setBaseProduct(_self.existingBase);
        });
    };

    _self.currentBaseProductIndex = 0;

    _self.previousProduct = function() {
      if (_self.currentBaseProductIndex > 0) {
        _self.currentBaseProductIndex--;
      }
      _self.nextEnabled = true;
    };

    _self.nextProduct = function() {
      if (_self.nextEnabled) {
        _self.currentBaseProductIndex++;
      }
      _self.nextEnabled =
        _self.currentBaseProductIndex <
        _self.baseJobs.length - _self.visibleCount;
    };

    _self.setBaseProduct = function(baseProduct) {
      var selectedProduct;

      _self.baseJobs.forEach(function(job) {
        job.selected = false;
        if (
          baseProduct &&
          job.id === baseProduct.id &&
          baseProduct.selected === true
        ) {
          job.selected = true;
          selectedProduct = job;
        }
      });

      // send updates to child components
      _self.baseJobs = angular.copy(_self.baseJobs);

      _self.onChange({ job: selectedProduct });
    };

    _self.$onChanges = function(changes) {
      if (changes.term) {
        _self.term = angular.copy(_self.term);
        _self.doSearch(_self.term, _self.filter);
      }

      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.job = angular.copy(_self.job);
        _self.existingBase = {
          id: _self.job.createdFrom,
          selected: !!_self.job.createdFrom
        };
      }

      if (changes.jobType) {
        if (changes.jobType.previousValue && changes.jobType.currentValue) {
          if (changes.jobType.previousValue !== changes.jobType.currentValue) {
            _self.doSearch(_self.term, _self.filter);
          }
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/baseProductSelector.tpl.html'
};

module.exports = baseProductSelectorComponent;
