'use strict';

var angular = require('angular');
var oktaService = require('./okta.service.js');

var AuthService = /*@ngInject*/ function(
  $log,
  $cookies,
  $window,
  Session,
  USER_ROLES,
  USER_ROLES_LABEL,
  USER_DATA,
  APP_DATA
) {
  var authService = {};

  authService.isAuthorized = function(authorizedRoles) {
    var validAuthorization = false;

    oktaService.isAuthenticated(); // will force logout if not authenticated

    if (!angular.isArray(authorizedRoles)) {
      if (authorizedRoles === null) {
        return true;
      }
      authorizedRoles = [authorizedRoles];
    }
    // No authentication required
    if (authorizedRoles.indexOf(USER_ROLES.all) !== -1) {
      return true;
    }

    USER_DATA.roles.some(function(userRole) {
      if (
        authorizedRoles.indexOf(USER_ROLES_LABEL[userRole]) >= 0 &&
        USER_ROLES_LABEL[userRole] !== undefined
      ) {
        validAuthorization = true;
        return validAuthorization;
      }
    });

    return validAuthorization;
  };

  authService.isAuthorizedForSection = function(
    currentUserRoles,
    authorizedRoles,
    sectionName
  ) {
    return currentUserRoles.some(function(role) {
      if (!authorizedRoles[USER_ROLES_LABEL[role]]) {
        return false; //not supported role, go to next
      }
      return !!authorizedRoles[USER_ROLES_LABEL[role]].sections[sectionName];
    });
  };

  authService.getAuthorizedResults = function(
    currentUserRoles,
    authorizedRoles,
    sectionResults,
    sectionName
  ) {
    if (!sectionResults) {
      return; //no columns to filter
    }
    var authorizedResults = [];
    currentUserRoles.forEach(function(role) {
      if (!authorizedRoles[USER_ROLES_LABEL[role]]) {
        return; //not supported role, go to next
      }
      var sectionRules =
        authorizedRoles[USER_ROLES_LABEL[role]].sections[sectionName];
      var authorizedSection = angular.copy(sectionResults);

      if (sectionRules.excludeColumns) {
        sectionRules.excludeColumns.forEach(function(excludeColumn) {
          authorizedSection.some(function(column, index, authorizedSection) {
            if (column.label === excludeColumn) {
              authorizedSection.splice(index, 1);
              return true;
            }
          });
        });
      }
      //only add unique values
      authorizedSection.forEach(function(value) {
        var alreadyIncluded = authorizedResults.some(function(
          authorizedResults
        ) {
          if (authorizedResults.label === value.label) {
            return true;
          }
        });
        if (!alreadyIncluded) {
          authorizedResults.push(value);
        }
      });
    });
    return authorizedResults;
  };

  authService.showSubSection = function(
    currentUserRoles,
    authorizedRoles,
    sectionName,
    subSection
  ) {
    return currentUserRoles.some(function(role) {
      if (!authorizedRoles[USER_ROLES_LABEL[role]]) {
        return false; //not supported role, go to next
      }
      var showSubSection =
        authorizedRoles[USER_ROLES_LABEL[role]].sections[sectionName][
          subSection
        ];
      if (showSubSection !== undefined) {
        return authorizedRoles[USER_ROLES_LABEL[role]].sections[sectionName][
          subSection
        ];
      } else {
        return true;
      }
    });
  };

  authService.getUsersLandingPage = function() {
    //returns the last found route
    var route;
    Session.get('roles').forEach(function(role) {
      switch (USER_ROLES_LABEL[role]) {
        case USER_ROLES.externalImageEditor:
          route = 'queue.post-prod';
          break;
      }
    });
    return route;
  };

  authService.canSearchAllJobs = function(filters) {
    //if we aren't searching for jobs that are assigneed, return true
    if (!filters || filters.indexOf('assignees=') === -1) {
      return true;
    }
    return Session.get('roles').some(function(userRole) {
      if (
        USER_ROLES_LABEL[userRole] !== USER_ROLES.externalImageEditor &&
        USER_ROLES_LABEL[userRole] !== USER_ROLES.all
      ) {
        return true;
      }
    });
  };

  authService.hideOthersAssignments = function() {
    return Session.get('roles').some(function(userRole) {
      if (
        USER_ROLES_LABEL[userRole] === USER_ROLES.externalImageEditor
      ) {
        return true;
      }
    });
  };

  authService.isAdmin = function() {
    return authService.isAuthorized([
      USER_ROLES.studioOps,
      USER_ROLES.superUser
    ]);
  };

  authService.logOut = function() {
    return oktaService.logout();
  };

  return authService;
};

module.exports = AuthService;
