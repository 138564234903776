'use strict';
var USER_ROLES = require('./userRoles.constant.js');

var USER_ROLES_LABEL = {};
//internal
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.AllUsers'] = USER_ROLES.all;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.DigiTech'] = USER_ROLES.digiTech;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.MerchOps'] = USER_ROLES.merchOps;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.Photographer'] =
  USER_ROLES.photographer;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.SeniorImageEditor'] =
  USER_ROLES.seniorImageEditor;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.ImageEditor'] =
  USER_ROLES.imageEditor;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.StudioStylist'] =
  USER_ROLES.studioStylist;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.Superuser'] =
  USER_ROLES.superUser;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.ArtBuyer'] = USER_ROLES.artBuyer;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.StudioOps'] =
  USER_ROLES.studioOps;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.ProductReviewer'] =
  USER_ROLES.productReviewer;
USER_ROLES_LABEL['Application.CDT.DevOps.GBIP.ExternalImageEditor'] =
  USER_ROLES.externalImageEditor;

// external
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.AllUsers'] = USER_ROLES.all;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.DigiTech'] = USER_ROLES.digiTech;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.MerchOps'] = USER_ROLES.merchOps;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.Photographer'] =
  USER_ROLES.photographer;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.SeniorImageEditor'] =
  USER_ROLES.imageEditor;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.ImageEditor'] =
  USER_ROLES.imageEditor;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.StudioStylist'] =
  USER_ROLES.studioStylist;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.Superuser'] =
  USER_ROLES.superUser;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.ArtBuyer'] = USER_ROLES.artBuyer;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.StudioOps'] =
  USER_ROLES.studioOps;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.ProductReviewer'] =
  USER_ROLES.productReviewer;
USER_ROLES_LABEL['Application.EXTUSRMGRUI.GBIP.ExternalImageEditor'] =
  USER_ROLES.externalImageEditor;

// API
USER_ROLES_LABEL['DIGITECH'] = USER_ROLES.digiTech;
USER_ROLES_LABEL['MERCH_OPS'] = USER_ROLES.merchOps;
USER_ROLES_LABEL['PHOTOGRAPHER'] = USER_ROLES.photographer;
USER_ROLES_LABEL['SENIOR_IMAGE_EDITOR'] = USER_ROLES.seniorImageEditor;
USER_ROLES_LABEL['IMAGE_EDITOR'] = USER_ROLES.imageEditor;
USER_ROLES_LABEL['STUDIO_STYLIST'] = USER_ROLES.studioStylist;
USER_ROLES_LABEL['SUPERUSER'] = USER_ROLES.superUser;
USER_ROLES_LABEL['ART_BUYER'] = USER_ROLES.artBuyer;
USER_ROLES_LABEL['STUDIO_OPS'] = USER_ROLES.studioOps;
USER_ROLES_LABEL['QA_REVIEWER'] = USER_ROLES.qaReviewer;
USER_ROLES_LABEL['PRODUCT_REVIEWER'] = USER_ROLES.productReviewer;
USER_ROLES_LABEL['EXTERNAL_IMAGE_EDITOR'] = USER_ROLES.externalImageEditor;
USER_ROLES_LABEL['USER'] = USER_ROLES.all;

module.exports = USER_ROLES_LABEL;
