'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function accordionGroupDirective() {
      return {
        require: '^accordion',
        scope: {
          isOpen: '=?',
          groupName: '@'
        },
        controller: [
          '$scope',
          '$element',
          function($scope, $element) {
            var _self = this,
              openClass = 'is-open';

            this.toggle = function($event) {
              $scope.isOpen = !$scope.isOpen;
            };

            $scope.$watch('isOpen', function(value) {
              $element.toggleClass(openClass, !!value);
              _self.headerElement.attr('aria-expanded', !!value);
              _self.bodyElement.toggleClass(openClass, !!value);
            });
          }
        ],
        link: function(scope, element, attrs, accordionCtrl) {
          accordionCtrl.addGroup(scope);

          scope.$watch('isOpen', function(value) {
            if (value) {
              accordionCtrl.closeOthers(scope);
            }
          });
        }
      };
    }
  ];
}

module.exports = register;
