'use strict';

var angular = require('angular');

var imageViewerHeroComponent = {
  bindings: {
    metaJob: '<',
    assetJob: '<',
    url: '<',
    zoom: '=',
    onMagnify: '&?',
    annotation: '<?',
    updateParentView: '&?',
    selectedCropType: '<'
  },
  transclude: true,
  controller: /*@ngInject*/ function(
    $element,
    $animate,
    $scope,
    JobService,
    CanvasService
  ) {
    var _self = this,
      _magnify,
      _target,
      _zoomedContainer,
      hideHero = false,
      animateOptions = {
        classIn: 'fadeIn'
      };
    _self.magnifyBoxDrawn = false;
    _self.canvasService = CanvasService;

    $scope.$watch('$ctrl.canvasService.canvas.isDrawingMode',
      function(after) {
        $scope.$eval(function() {
          _self.hideHero = after;
        });
      }
    )

    var getMouseCoords = function(event) {
      if (!_self.drawMagnifyBox()) {
        return;
      }
      _self.zoomPosX = event.offsetX - _magnify.clientWidth / 2;
      _self.zoomPosX = Math.max(0, _self.zoomPosX);
      _self.zoomPosX = Math.min(
        _target.clientWidth - _magnify.clientWidth,
        _self.zoomPosX
      );

      _self.zoomPosY = event.offsetY - _magnify.clientHeight / 2;
      _self.zoomPosY = Math.max(0, _self.zoomPosY);
      _self.zoomPosY = Math.min(
        _target.clientHeight - _magnify.clientHeight,
        _self.zoomPosY
      );

      _self.scaleWidth = _target.clientWidth;
      _self.scaleHeight = _target.clientHeight;

      angular.element(_magnify).css({
        left: _self.zoomPosX + 'px',
        top: _self.zoomPosY + 'px',
        display: 'block'
      });
    };

    _self.zoomFlag = false;

    if (_self.zoomFlag === true) {
      _self.zoomClass = 'zoom-out';
    } else {
      _self.zoomClass = 'zoom-in';
    }

    _self.magnify = function() {
      if(!_self.magnifyBoxDrawn) {
        _self.zoomClass = 'zoom-out';
        $animate.addClass(_zoomedContainer, animateOptions.classIn);
        _self.magnifyBoxDrawn = true;
        if (_self.zoom) {
          _self.onMagnify({ flag: false });
        }
      }
    };

    _self.unmagnify = function() {
      if (_self.magnifyBoxDrawn) {
        _self.zoomClass = 'zoom-in';
        _self.magnifyBoxDrawn = false;
        if (_self.zoom) {
          _self.onMagnify({ flag: true });
        }
        $animate
          .removeClass(_zoomedContainer, animateOptions.classIn)
          .then(function() {
            _self.zoomFlag = false;
          });
      }
    };

    _self.mouseOver = function() {
      _self.drawMagnifyBox();
    };

    _self.drawMagnifyBox = function() {
      if(!_self.magnifyBoxDrawn && !!_self.cropTypeWidth) {
        _magnify = $element[0].querySelector('.gbip-image-viewer__magnify');
        _target = $element[0].querySelector('.gbip-image-viewer__hero');
        _zoomedContainer = $element[0].querySelector('.gbip-image-viewer__zoom');
        const els = document.getElementsByClassName("zoomed-in-qa-image");
        const fullWidth = (!!els && els[0].width > 0) ? els[0].width : this.cropTypeWidth;
        const zoomRatio = (_target.clientWidth / fullWidth);
        const _dimensionX = _target.clientWidth * zoomRatio;
        const _dimensionY = _target.clientHeight * zoomRatio;

        // Draws the selection box on the large thumbnail
        angular
          .element(_magnify)
          .css({ width: +_dimensionX + 'px', height: +_dimensionY + 'px' });
        _self.showMagnify = true;
        return true;
      }
      return _self.magnifyBoxDrawn;
    }

    _self.mouseLeave = function() {
      _self.showMagnify = false;
      _self.unmagnify();
    };

    _self.mouseMove = function(e) {
      if(_self.drawMagnifyBox()) {
        getMouseCoords(e);
      }
    };

    _self.mouseClick = function() {
      if (_self.zoomFlag === false) {
        _self.zoomFlag = true;
        _self.magnify();
      } else {
        _self.unmagnify();
      }
    };

    _self.$onChanges = function(changes) {
      if(this.metaJob && this.selectedCropType) {
        JobService.getCropTypeDimensions(this.metaJob.shootType.code, this.metaJob.merchType, this.selectedCropType).then(function (cropType) {
          _self.cropTypeWidth = cropType.width;
          _self.cropTypeHeight = cropType.height;
        });
      }
    };
  },
  templateUrl: 'templates/partials/qa/imageViewerHero.tpl.html'
};

module.exports = imageViewerHeroComponent;
