'use strict';

var angular = require('angular');

var priorityToggleComponent = {
  bindings: {
    priority: '=',
    options: '<',
    disabled: '<'
  },
  controller: /*@ngInject*/ function(JobService) {
    var _self = this;
    _self.currentIndex = 0;

    _self.handleToggle = function() {
      if (!_self.disabled) {
        if (_self.currentIndex === _self.options.length - 1) {
          _self.currentIndex = 0;
        } else {
          _self.currentIndex++;
        }
      }

      _self.priority = _self.options[_self.currentIndex].value;
    };

    _self.$onChanges = function(changes) {
      if (changes.options) {
        _self.options = angular.copy(_self.options);

        switch (_self.priority) {
          case 'Default':
            _self.currentIndex = 0;
            break;
          case 'Priority':
            _self.currentIndex = 1;
            break;
          case 'Rush':
            _self.currentIndex = 2;
            break;
          default:
            _self.currentIndex = 0;
        }
      }

      if (changes.disabled) {
        if (_self.disabled) {
          _self.currentIndex = 0;
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/priorityToggle.tpl.html'
};

module.exports = priorityToggleComponent;
