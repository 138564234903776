'use strict';

var angular = require('angular');

var baseProductResultsComponent = {
  bindings: {
    term: '<',
    job: '<',
    onChange: '&',
    readonly: '<',
    jobType: '<',
    disabled: '<'
  },
  controller: function(
    SearchService,
    JobTypeService,
    APP_DATA,
    BaseProductSearchService,
    $q,
    $scope,
    $timeout
  ) {
    var _self = this;
    _self.otherJobs = [];
    _self.linkedJobs = [];
    _self.otherSearch = [];
    _self.baseProduct = null;

    _self.reset = function() {
      _self.job = undefined;
      _self.baseProduct = undefined;
      _self.term = '';
      _self.otherJobs = [];
      _self.linkedJobs = [];
      _self.otherSearch = [];
    };

    _self.setBaseProduct = function(job) {
      if (_self.disabled) {
        return;
      }

      for (var x = 0; x < _self.otherJobs.length; x++) {
        if (_self.otherJobs[x].id === job.id) {
          _self.otherJobs[x].selected = !_self.otherJobs[x].selected;
          if (_self.otherJobs[x].selected) {
            _self.baseProduct = job;
          } else {
            _self.baseProduct = undefined;
          }
        } else {
          _self.otherJobs[x].selected = false;
        }
      }
      for (var x = 0; x < _self.linkedJobs.length; x++) {
        if (_self.linkedJobs[x].id === job.id) {
          _self.linkedJobs[x].selected = !_self.linkedJobs[x].selected;
          if (_self.linkedJobs[x].selected) {
            _self.baseProduct = job;
          } else {
            _self.baseProduct = undefined;
          }
        } else {
          _self.linkedJobs[x].selected = false;
        }
      }
      for (var x = 0; x < _self.otherSearch.length; x++) {
        if (_self.otherSearch[x].id === job.id) {
          _self.otherSearch[x].selected = !_self.otherSearch[x].selected;
          if (_self.otherSearch[x].selected) {
            _self.baseProduct = job;
          } else {
            _self.baseProduct = undefined;
          }
        } else {
          _self.otherSearch[x].selected = false;
        }
      }

      _self.onChange({ job: _self.baseProduct });
    };

    _self.$onInit = function() {};

    _self.deselectBaseProduct = function() {
      for (var x = 0; x < _self.otherJobs.length; x++) {
        _self.otherJobs[x].selected = false;
      }
      for (var x = 0; x < _self.linkedJobs.length; x++) {
        _self.linkedJobs[x].selected = false;
      }
      for (var x = 0; x < _self.otherSearch.length; x++) {
        _self.otherSearch[x].selected = false;
      }
      _self.baseProduct = undefined;
      _self.onChange({ job: _self.baseProduct });
    };

    _self.$onChanges = function(change) {
      if (change.job) {
        _self.reset();
      }

      if (change.job && change.job.currentValue !== undefined) {
        _self.job = change.job.currentValue;

        BaseProductSearchService.search(_self.job.product.styleNumber).then(
          function(results) {
            _self.otherJobs = results.data.results;
          }
        );

        if (
          _self.job.product.linkedProducts &&
          _self.job.product.linkedProducts.length > 0
        ) {
          _self.linkedJobs = [];
          _self.job.product.linkedProducts.forEach(function(linkedJob) {
            BaseProductSearchService.search(linkedJob.styleColor).then(function(
              results
            ) {
              _self.linkedJobs.push(...results.data.results);
            });
          });
        }
      }

      if (change.term) {
        $timeout(function() {
          if (change.term.currentValue === _self.term) {
            var term = change.term.currentValue;
            if (
              [
                _self.job.product.styleColor,
                _self.job.product.styleNumber
              ].indexOf(term) === -1
            ) {
              BaseProductSearchService.search(term).then(function(results) {
                _self.otherSearch = results.data.results.filter(function(job) {
                  var retVal = false;
                  if (job.id !== _self.job.id) {
                    retVal = true;
                  }

                  return retVal;
                });
              });
            }
          }
        }, 750);
      }

      if (change.jobType) {
        _self.jobType = angular.copy(change.jobType.currentValue);
        if (
          [
            'Variation',
            'Copy',
            'Copy with Edits',
            'Redelivery',
            'Take Up',
            'Take Down'
          ].indexOf(_self.jobType) !== -1
        ) {
          _self.disabled = false;
        } else {
          _self.deselectBaseProduct();
          _self.disabled = true;
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/baseProductResults.tpl.html'
};

module.exports = baseProductResultsComponent;
