'use strict';

var transferComponent = {
  bindings: {
    data: '<'
  },
  controller: /*@ngInject*/ function(TransferService) {
    var _self = this;

    _self.$onInit = function() {
      _self.service = TransferService;
    };
  },
  templateUrl: 'templates/partials/transfer.tpl.html'
};

module.exports = transferComponent;
