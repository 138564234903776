'use strict';

var ActivityController = /*@ngInject*/ function(
  $http,
  $scope,
  $state,
  ReportsService,
  JobService,
  REST_URL,
  ACTIVITY
) {
  var _self = this;

  var getReports = function() {
    _self.service.getReports().then(function() {
      for (var action in ACTIVITY.actions) {
        for (
          var i = 0;
          i <
          _self.service.data.syncActionDetailedReports[action].results.length;
          i++
        ) {
          _self.service.data.syncActionDetailedReports[action].results[
            i
          ].isShown = true;
        }
      }
    });
  };

  var init = function() {
    getReports();
  };

  _self.service = ReportsService;
  _self.jobService = JobService;

  _self.showModal = false;
  _self.showProblemModal = false;

  _self.duplicateJobConfig = {
    primaryBtnText: ACTIVITY.buttonText.duplicateJob.primary,
    secondaryBtnText: ACTIVITY.buttonText.duplicateJob.secondary,
    jobType: 'duplicate'
  };

  _self.reactivationJobConfig = {
    primaryBtnText: ACTIVITY.buttonText.reactivationJob.primary,
    secondaryBtnText: ACTIVITY.buttonText.reactivationJob.secondary,
    jobType: 'reactivate'
  };

  _self.doAction = function(job, jobType, jobAction) {
    var jobDisplayData = job.currentJob;
    var isUserJob = true;

    if (jobAction === 'overWriteManual') {
      jobDisplayData = job.systemCreatedProduct;
      isUserJob = false;
    }

    var newData = {
      copy: ACTIVITY.updateTypes[jobType][jobAction].copy,
      isUserJob: isUserJob,
      job: job.currentJob,
      jobAction: jobAction,
      jobDisplay: jobDisplayData,
      jobType: jobType,
      post: job,
      title: ACTIVITY.updateTypes[jobType][jobAction].title
    };

    JobService.activity.setUpdateData(newData);

    if (jobAction === 'problemSolve') {
      _self.showProblemModal = true;
    } else {
      _self.showModal = true;
    }
  };

  _self.hideJob = function(type, idOfJobToHide) {
    var jobsArray = _self.service.data.syncActionDetailedReports[type].results;

    for (var i = 0; i < jobsArray.length; i++) {
      if (jobsArray[i].currentJob.id === idOfJobToHide) {
        // hide job in list
        // remove from jobs array --> decrements number
        jobsArray.splice(i, 1);
        break;
      }
    }
  };

  /**
   * Resolve a duplicate by manually editing it, passing the system-created
   * product object for use in job detail state.
   * @param  {Object} job Duplicate job object
   */
  _self.editDuplicateManually = function(job) {
    $state.go('job', {
      id: job.currentJob.id,
      from: 'activity',
      systemCreatedProduct: job.systemCreatedProduct
    });
  };

  $scope.$on('$stateChangeSuccess', function(event, toState) {
    if (toState.name.indexOf('activity') >= 0 && !_self.service.working) {
      init();
    }
  });
};

module.exports = ActivityController;
