'use strict';

/*jshint unused: false*/
var gbipModalComponent = {
  transclude: true,
  bindings: {
    working: '=',
    name: '@',
    isActive: '=',
    isValid: '=?',
    data: '=',
    onSubmit: '&',
    onCancel: '&',
    model: '<'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $timeout,
    $element,
    $attrs,
    KeymageService
  ) {
    var _self = this,
      buttonElem,
      keybindFilter = function(event) {
        if (event.which === 13) {
          // return
          $timeout(function() {
            if (_self.isValid) {
              _self.onSubmit();
            }
          }, 0);
        }
      };

    _self.submitModal = function() {
      _self.onSubmit();
    };

    _self.cancelModal = function() {
      _self.isActive = false;
      _self.onCancel();
    };

    _self.$onInit = function() {
      buttonElem = $element[0].querySelector('.js-primary-button');
      _self.static = $attrs.static ? $attrs.static : false;

      if (_self.isValid === undefined) {
        _self.isValid = true;
      }
    };

    _self.$onDestroy = function() {
      $element.off('keypress', keybindFilter);
    };

    $scope.$watch(
      function() {
        return _self.isActive;
      },
      function() {
        KeymageService.toggle(!_self.isActive);

        if (_self.isActive) {
          $element.on('keypress', keybindFilter);
        } else {
          $element.off('keypress', keybindFilter);
        }
      }
    );
  },
  templateUrl: 'templates/partials/ui/modal.tpl.html'
};

module.exports = gbipModalComponent;
