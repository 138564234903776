'use strict';

var CHARACTER_LIMITS = {
  general: 500,
  problem: 500,
  slate: 500,
  assetSearch: 5000
};

module.exports = CHARACTER_LIMITS;
