'use strict';

var angular = require('angular');

var BaseProductSearchService = /*@ngInject*/ function(SearchService) {
  var _self = this;
  _self.currentBaseProductIndex = undefined;
  _self.previousSearchTerm = '';
  _self.working = false;

  _self.search = function(term) {
    var filter = '!status=Pending Drop|Dropped';
    // If term is the same as previous search, do not search again
    // if (term === _self.previousSearchTerm) { return; }
    // _self.previousSearchTerm = term;

    //_self.working = true;
    //_self.currentBaseProductIndex = 0;

    return SearchService.search(
      { term: term, filter: filter, size: 200 },
      'jobs'
    );
  };
};

module.exports = BaseProductSearchService;
