'use strict';

var angular = require('angular');

var assetDownloadFiltersComponent = {
  bindings: {
    filters: '=',
    filterChange: '&'
  },
  controller: /*@ngInject*/ function($scope) {
    var _self = this;
    _self.facets = [];

    _self.parseFacets = function(facets) {
      facets.forEach(facet => {
        var f = _self.facets.find(f => f.name === facet.name);
        if (f) {
          f.facetValues.forEach(facetValue => {
            var filterValue = facet.facetValues.find(
              v => v.value === facetValue.value
            );
            facetValue.count = filterValue.count;
            if (facetValue.selected) {
              filterValue.selected = facetValue.selected;
            }
          });
        } else {
          if (facet.title) {
            _self.facets.push(facet);
          }
        }
      });
    };

    _self.filterChecked = function(option) {
      option.selected = option.selected === undefined ? true : !option.selected;
      _self.filters.forEach(facet => {
        facet.facetValues.forEach(facetValue => {
          if (facetValue.value === option.value) {
            facetValue.selected = option.selected;
          }
        });
      });
      _self.filterChange();
    };

    $scope.$watch(
      function() {
        return _self.filters;
      },
      function() {
        _self.parseFacets(_self.filters);
      }
    );
  },
  templateUrl: 'templates/partials/assetDownload/filters.tpl.html'
};

module.exports = assetDownloadFiltersComponent;
