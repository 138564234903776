'use strict';

var angular = require('angular');

var BaseProductThumbnailComponent = {
  restrict: 'E',
  replace: true,
  transclude: true,
  bindings: {
    asset: '<', // an asset
    cropTypes: '<',
    expectedCropTypes: '<',
    type: '@', // type of the thumbnail to show
    showAnnotation: '=?',
    jobType: '<'
  },
  controller: /*@ngInject*/ function(JobService) {
    var _self = this;

    _self.onImageLoad = function() {
      _self.loading = false;
    };

    _self.$onChanges = function(changes) {
      if (changes.asset && changes.type) {
        var newSrc;

        _self.asset = angular.copy(changes.asset.currentValue);
        newSrc = JobService.getImageByType(_self.asset, _self.type);

        // Exit if src hasn't changed, or if there is no src
        if (_self.src === newSrc) {
          return;
        }

        _self.src = newSrc;
        //_self.jobType = _self.asset.viewCode ? _self.asset.viewCode : '';
        _self.loading = !!_self.src;

        if (
          _self.asset &&
          _self.showAnnotation
        ) {
          JobService.fetchAnnotation(_self.asset).then(function(response) {
            _self.annotation = response.data;
          });
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/baseProductThumbnail.tpl.html'
};

module.exports = BaseProductThumbnailComponent;
