'use strict';

var angular = require('angular');

var recordLinksComponent = {
  bindings: {},
  controller: /*@ngInject*/ function(
    REST_URL,
    NotificationService,
    TransferService
  ) {
    var _self = this;

    _self.data = {
      uploadResponse: function(response) {
        if (response.data) {
          _self.showProductLinkFeedback = true;
          if (response.data.success) {
            _self.productLinkFeedback = {
              success: 'Uploaded file was processed successfully.',
              error: ''
            };
            NotificationService.push('Product links successfuly updated.');
          } else {
            _self.productLinkFeedback = {
              success: '',
              error:
                'There was an error. No links were imported, please try again or contact support if this continues.'
            };
          }
        }
        _self.data.response = response.data;
      },
      uploadError: function(response) {
        _self.showProductLinkFeedback = true;
        var msg, rows;
        if (response.data.data) {
          msg =
            'There were ' +
            response.data.data.length +
            ' errors. No links were imported, please correct all errors and re-upload the file.';
          rows = response.data.data;
        } else {
          msg = 'There was an error. ' + response.data.error;
        }
        _self.productLinkFeedback = {
          success: '',
          error: msg,
          rows: rows
        };
      },
      upload: function($event) {
        _self.showProductLinkFeedback = false;
        TransferService.uploadFile($event.file, REST_URL.productLink.upload)
          .then(this.uploadResponse)
          .catch(this.uploadError);
      },
      downloadTemplate: function() {
        TransferService.productLinkTemplate();
      },
      download: function() {
        _self.productLinkFeedback = { error: '' };
        TransferService.downloadProductLinks().catch(this.downloadError);
      },
      downloadError: function(response) {
        _self.productLinkDownloadFeedback.error =
          'An error has occured processing your request, please try again. If the problem continues please contact ICON production support.';
      }
    };

    _self.assetRequestFeedback = { success: '', error: '' };
  },
  templateUrl: 'templates/partials/admin/recordLinks.tpl.html'
};

module.exports = recordLinksComponent;
