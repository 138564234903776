'use strict';

var fileReaderService = require('./fileReader.service.js');
var fileInputDirective = require('./fileInput.directive.js');

function register(angular) {
  var app = angular.module('file-reader', ['ng']);

  app.factory('fileReader', fileReaderService(angular));
  app.directive('fileInput', fileInputDirective(angular));

  app.run([
    '$templateCache',
    function($templateCache) {
      $templateCache.put(
        'templates/fileInput.tpl.html',
        '<label style="display: block; position: relative; overflow: hidden;">' +
          '<input style="position: absolute; right: 100%; bottom: 100%;" type="file" class="gbip-upload__input" accept="{{ accept }}" />' +
          '<div ng-transclude></div>' +
          '</label>'
      );
    }
  ]);

  return 'file-reader';
}

module.exports = register;
