'use strict';

var angular = require('angular');

/*jshint unused: false*/
var CropTypesComponent = {
  bindings: {
    job: '<'
  },
  controller: /*@ngInject*/ function(APP_DATA) {
    var _self = this;

    _self.$onChanges = function(changes) {
      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.job = angular.copy(_self.job);
      }
    };
  },
  templateUrl: 'templates/partials/ui/croptypes.tpl.html'
};

module.exports = CropTypesComponent;
