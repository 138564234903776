'use strict';

//http://odetocode.com/blogs/scott/archive/2013/07/03/building-a-filereader-service-for-angularjs-the-service.aspx

/*jshint unused: false*/
function register(angular) {
  return [
    '$q',
    function fileReader($q) {
      var onLoad = function(reader, deferred, scope) {
        return function() {
          scope.$apply(function() {
            deferred.resolve(reader.result);
          });
        };
      };

      var onError = function(reader, deferred, scope) {
        return function() {
          scope.$apply(function() {
            deferred.reject(reader.result);
          });
        };
      };

      var onProgress = function(reader, deferred, scope) {
        return function(event) {
          scope.$apply(function() {
            deferred.notify({
              total: event.total,
              loaded: event.loaded
            });
          });
        };
      };

      var getReader = function(deferred, scope) {
        var reader = new FileReader();
        reader.onload = onLoad(reader, deferred, scope);
        reader.onerror = onError(reader, deferred, scope);
        reader.onprogress = onProgress(reader, deferred, scope);
        return reader;
      };

      var readAsDataURL = function(file, scope) {
        var deferred = $q.defer();
        var reader = getReader(deferred, scope);

        reader.readAsDataURL(file);

        return deferred.promise;
      };

      var readAsText = function(file, scope) {
        var deferred = $q.defer();
        var reader = getReader(deferred, scope);

        reader.readAsText(file);

        return deferred.promise;
      };

      return {
        readAsDataUrl: readAsDataURL
      };
    }
  ];
}

module.exports = register;
