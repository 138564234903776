'use strict';

var ROLE_NAV = {
  'Page Order': [
    'merch-management.views',
    'queue.inbound',
    'queue.merch-prep',
    'queue.post-prod',
    'queue.qa',
    'outreachQueue.outreach',
    'queue.merch-putaway',
    'queue.problem-solve',
    'request-code'
  ],
  'Super User': [
    {
      label: 'Inbound',
      state: 'queue.inbound'
    },
    {
      label: 'Prep',
      state: 'queue.merch-prep'
    },
    {
      label: 'Product Review',
      state: 'outreachQueue.outreach'
    },
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'QA',
      state: 'queue.qa'
    },
    {
      label: 'Merch Putaway',
      state: 'queue.merch-putaway'
    },
    {
      label: 'Problem Solve',
      state: 'queue.problem-solve'
    },
    {
      label: 'Request Code',
      state: 'request-code'
    }
  ],
  'Merch Ops': [
    {
      label: 'Inbound',
      state: 'queue.inbound'
    },
    {
      label: 'Merch Putaway',
      state: 'queue.merch-putaway'
    }
  ],
  'Studio Ops': [
    {
      label: 'Inbound',
      state: 'queue.inbound'
    },
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'QA',
      state: 'queue.qa'
    },
    {
      label: 'Merch Putaway',
      state: 'queue.merch-putaway'
    },
    {
      label: 'Problem Solve',
      state: 'queue.problem-solve'
    },
  ],
  'Studio Stylist': [
    {
      label: 'Prep',
      state: 'queue.merch-prep'
    },
    {
      label: 'Merch Putaway',
      state: 'queue.merch-putaway'
    }
  ],
  'Digi Tech': [
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'Request Code',
      state: 'request-code'
    }
  ],
  'Image Editor': [
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'Request Code',
      state: 'request-code'
    }
  ],
  'Senior Image Editor': [
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'Request Code',
      state: 'request-code'
    }
  ],
  'External Image Editor': [
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'Request Code',
      state: 'request-code'
    }
  ],
  Photographer: [
    {
      label: 'Post',
      state: 'queue.post-prod'
    },
    {
      label: 'Request Code',
      state: 'request-code'
    }
  ],
  'QA Reviewer': [
    {
      label: 'QA',
      state: 'queue.qa'
    }
  ],
  'Product Reviewer': [
    {
      label: 'Product Review',
      state: 'outreachQueue.outreach'
    }
  ]
};

module.exports = ROLE_NAV;
