'use strict';

var PaneComponent = {
  transclude: true,
  require: {
    tabsCtrl: '^tabs'
  },
  bindings: {
    tabTitle: '@',
    selected: '<'
  },
  controller: function() {
    var _self = this;

    _self.$onInit = function() {
      _self.tabsCtrl.addPane(_self);
    };

    _self.$onChanges = function(changes) {
      if (changes.selected) {
        if (_self.selected) {
          _self.tabsCtrl.select(_self);
        }
      }
    };
  },
  templateUrl: 'templates/partials/tabs/pane.tpl.html'
};

module.exports = PaneComponent;
