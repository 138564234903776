'use strict';

var angular = require('angular');

var assetRequestsComponent = {
  bindings: {},
  controller: /*@ngInject*/ function(
    REST_URL,
    NotificationService,
    TransferService,
    $http
  ) {
    var _self = this;

    _self.fetchAssetFilters = function() {
      _self.assetFilterPromise =
        _self.assetFilterPromise ||
        $http.get([REST_URL.base, 'assets/requests/filters'].join('/'));

      _self.assetFilterPromise.then(_self.assetFilterPromiseResolution);
    };

    _self.assetFilterPromiseResolution = function(response) {
      _self.data.requesters = response.data.requesters;
      _self.data.seasons = angular.copy(response.data.seasons);
      _self.data.seasons.unshift('');
    };

    _self.data = {
      uploadResponse: function(response) {
        if (response.data) {
          _self.showAssetRequestFeedback = true;
          if (response.data.success) {
            _self.assetRequestFeedback = {
              success: 'Uploaded file was processed successfully.',
              error: ''
            };
            NotificationService.push('Asset upload successfully completed.');
          } else {
            _self.assetRequestFeedback = {
              success: '',
              error:
                'There was an error. No requests were imported, please try again or contact support if this continues.'
            };
          }
        }
        _self.data.response = response.data;
      },
      uploadError: function(response) {
        _self.showAssetRequestFeedback = true;
        var msg, rows;
        if (response.data.data) {
          msg =
            'There were ' +
            response.data.data.length +
            ' errors. No requests were imported, please correct all errors and re-upload the file.';
          rows = response.data.data;
        } else {
          msg = 'There was an error. ' + response.data.error;
        }
        _self.assetRequestFeedback = {
          success: '',
          error: msg,
          rows: rows
        };
      },
      upload: function($event) {
        _self.showAssetRequestFeedback = false;
        TransferService.uploadFile($event.file, REST_URL.assetRequest.upload)
          .then(this.uploadResponse)
          .catch(this.uploadError);
      },
      downloadTemplate: function() {
        TransferService.assetRequestTemplate();
      },
      download: function() {
        _self.assetDownloadFeedback = { error: '' };
        TransferService.downloadAssetRequests(
          _self.data.requester,
          _self.data.season
        ).catch(this.downloadError);
      },
      downloadError: function(response) {
        _self.assetDownloadFeedback.error =
          'An error has occured processing your request, please try again. If the problem continues please contact ICON production support.';
      }
    };

    _self.$onInit = function() {
      _self.fetchAssetFilters();
    };
  },
  templateUrl: 'templates/partials/admin/assetRequests.tpl.html'
};

module.exports = assetRequestsComponent;
