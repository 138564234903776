'use strict';

var fabric = require('fabric').fabric;
var angular = require('angular');

var CanvasService = /*@ngInject*/ function($http, $timeout, $window, QAService) {
  var _self = this,
    _notesMode,
    _canvasElement,
    _demoCanvasInitialWidth,
    _demoCanvasInitialHeight,
    _currentNote,
    _canvasInitialWidth,
    _canvasInitialHeight,
    _notesSVGUrl = 'assets/note-rich.png',
    _defaultWidth = 560,
    _defaultHeight = 560,
    _fabricToJSONHotFix;

  /*******************************************************************/
  // Active Canvas Methods
  /*******************************************************************/
  _self.loadJSON = function(jsonFile) {
    var canvasJSON = jsonFile || _self.saveAnnotation;
    var c = canvasJSON.toJSON,
      _noteCounter = 1;
    var origianlCanvasWidth = false;
    for (var i = 0; i < c.objects.length; i++) {
      if (c.objects[i].type === 'group') {
        if (
          c.objects[i].objects === undefined ||
          c.objects[i].objects.length === 0
        ) {
          c.objects[i].objects = angular.copy(_fabricToJSONHotFix);
          c.objects[i].objects[
            c.objects[i].objects.length - 1
          ].text = _noteCounter.toString();
        }
        _noteCounter++;
      }
      if (c.objects[i].type === 'path') {
        origianlCanvasWidth = c.objects[i].originalWidth;
      }
    }
    _self.canvas.loadFromJSON(canvasJSON.toJSON, function() {
      _self.canvas.renderAll();
      _self.loadNotesFromJSON(canvasJSON);
      _self.loadDrawingScaleWidth(_self.canvas, canvasJSON);
      if (origianlCanvasWidth) {
        _self.zoomIt(
          _canvasInitialWidth / origianlCanvasWidth,
          _canvasInitialWidth,
          _canvasInitialHeight
        );
      }
    });
  };

  _self.showActiveCanvas = function() {
    _self.resetZoom();
    var activeCanvas = document
      .getElementsByClassName('canvas-container')
      .item(0);
    var staticCanvas = document.getElementById('demoCanvas');
    if (activeCanvas && staticCanvas) {
      activeCanvas.style.display = 'block';
      staticCanvas.style.display = 'none';
    }
  };
  _self.getAnnotationData = function() {
    if (!_self.canvas) {
      return null;
    }
    _self.resetZoom();
    _self.canvas.setBackgroundImage(0);
    _self.data.img = _self.canvas.toDataURL();
    // JSON without default values
    var c = _self.canvas.toJSON(),
      _notesText = [],
      _noteCounter = 1,
      i;

    if (c.objects.length > 0) {
      for (i = 0; i < c.objects.length; i++) {
        if (c.objects[i].type === 'group') {
          if (c.objects[i].objects && c.objects[i].objects.length) {
            _fabricToJSONHotFix = angular.copy(c.objects[i].objects);
          } else {
            c.objects[i].objects = angular.copy(_fabricToJSONHotFix);
            c.objects[i].objects[
              c.objects[i].objects.length - 1
            ].text = _noteCounter.toString();
          }
          _noteCounter++;
        }
      }
    } else {
      _self.data.img = '';
    }

    for (i = 0; i < _self.data.notes.length; i++) {
      _notesText.push(_self.data.notes[i].text);
    }

    var data = {
      img: _self.data.img,
      toJSON: angular.copy(c),
      notes: _notesText
    };

    _self.saveDrawingScaleWidth(data.toJSON.objects);

    return data;
  };

  _self.zoomIt = function(factor, newWidth, newHeight) {
    _self.canvas.setHeight(newHeight);
    _self.canvas.setWidth(newWidth);
    if (_self.canvas.backgroundImage) {
      _self.canvas.backgroundImage.scaleToWidth(newWidth);
      _self.canvas.backgroundImage.scaleToHeight(newHeight);
    }

    var objects = _self.canvas.getObjects();
    for (var i in objects) {
      if (objects[i].scaleX === 1) {
        objects[i].set(
          'originalWidth',
          objects[i].originalWidth || _canvasInitialWidth
        );
      }

      if (objects[i].type !== 'group') {
        objects[i].set({
          left: objects[i].left * factor,
          top: objects[i].top * factor,
          scaleX: newWidth / objects[i].originalWidth,
          scaleY: newWidth / objects[i].originalWidth
        });
      } else {
        objects[i].set({
          left: objects[i].left * factor,
          top: objects[i].top * factor
        });
      }
      objects[i].setCoords();
    }
    _self.canvas.renderAll();
    _self.canvas.calcOffset();

    _canvasInitialWidth = newWidth;
    _canvasInitialHeight = newHeight;
  };

  /*
    Annotation drawings can have different scale ratio depends on the browser size when the drawing was done,
    Need to save/load it into json.
  */
  _self.saveDrawingScaleWidth = function(json) {
    var canvasElement;
    for (var i = 0; i < json.length; i++) {
      canvasElement = _self.canvas.getObjects()[i];
      if (json[i].type !== 'group') {
        json[i].originalWidth = canvasElement.originalWidth
          ? canvasElement.originalWidth
          : _self.canvas.width;
      }
    }
  };

  _self.loadDrawingScaleWidth = function(myCanvas, json) {
    var canvasElement,
      _objects = json.toJSON.objects;
    for (var i = 0; i < _objects.length; i++) {
      canvasElement = myCanvas.getObjects()[i];
      if (canvasElement && _objects[i].type !== 'group') {
        if (!_objects[i].originalWidth) {
          _objects[i].originalWidth = _self.getOriginalCanvasWidth(json);
        }
        canvasElement.originalWidth = _objects[i].originalWidth;
      }
    }
  };

  _self.submit = function(jobId) {
    var _tempWidth = _canvasInitialWidth;
    var _tempHeight = _canvasInitialHeight;
    _self.zoomIt(_defaultWidth / _canvasInitialWidth, _defaultWidth, _defaultHeight);

    _self.saveAnnotation = _self.getAnnotationData();

    QAService.setAnnotation(jobId, _self.saveAnnotation);

    setTimeout(function() {
      _self.zoomIt(_tempWidth / _defaultWidth, _tempWidth, _tempHeight);
    }, 500);
  };

  _self.getCanvas = function() {
    return _self.canvas;
  };

  /*
  Store existing notes into an array and save the reference to associate fabricjs element.
  */
  _self.loadNotesFromJSON = function(json) {
    _self.data.notes = [];
    var _objects = json.toJSON.objects,
      _notesCount = 0;
    for (var i = 0; i < _objects.length; i++) {
      var canvasElement = _self.canvas.getObjects()[i];
      if (_objects[i].type === 'group') {
        _self.data.notes.push({
          view: canvasElement,
          text: json.notes[_notesCount]
        });
        _notesCount++;
      }
    }
    _currentNote = _notesCount + 1;
  };

  _self.resetZoom = function() {
    if (_self.canvas) {
      _self.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
      _self.canvas.renderAll();
    }
  }

  /** Drawing Tools Methods */
  _self.setDrawingMode = function(mode) {

    if (_self.canvas) {
      _self.canvas.isDrawingMode = mode;

      if (_self.canvas.isDrawingMode === true) {
        _self.canvas.on('mouse:wheel', function(event) {
          var delta = event.e.deltaY;
          var zoom = _self.canvas.getZoom();
          zoom *= Math.pow(0.999, delta);
          if (zoom > 20) {
            zoom = 20;
          }
          if (zoom < 1) {
            zoom = 1;
          }
          _self.canvas.zoomToPoint({ x: event.e.offsetX, y: event.e.offsetY }, zoom);
          event.e.preventDefault();
          event.e.stopPropagation();
          var vpt = this.viewportTransform;
  
          if (vpt[4] >= 0) {
            vpt[4] = 0;
          } else if (vpt[4] < _self.canvas.getWidth() - _canvasInitialWidth * zoom) {
            vpt[4] = _self.canvas.getWidth() - _canvasInitialWidth * zoom;
          }
          if (vpt[5] >= 0) {
            vpt[5] = 0;
          } else if (vpt[5] < _self.canvas.getHeight() - _canvasInitialHeight * zoom) {
            vpt[5] = _self.canvas.getHeight() - _canvasInitialHeight * zoom;
          }
        });
      }
    }
  };

  _self.setNotesMode = function(mode) {
    _notesMode = mode;
    if (_self.canvas) {
      if (_notesMode === true) {
        _self.resetZoom();
        _self.canvas.on('mouse:up', function(e) {
          _self.addNotesFlag(e);
        });
        _self.canvas.__eventListeners['mouse:wheel'] = [];
      } else if (_self.canvas.__eventListeners) {
        _self.canvas.__eventListeners['mouse:up'] = [];
      }
    }
  };

  _self.removeNotes = function(index) {
    var target = _self.data.notes[index].view,
      _text;

    if (target) {
      _self.canvas.remove(target);
      _self.data.notes.splice(index, 1);
      for (var i = 0; i < _self.data.notes.length; i++) {
        _text =
          _self.data.notes[i].view._objects[
            _self.data.notes[i].view._objects.length - 1
          ];
        _text.text = (i + 1).toString();
        if (i === 8) {
          _text.set({ left: (1 - _text.width) / 2 + 1 });
        } else {
          _text.set({ left: (1 - _text.width) / 2 - 1 });
        }
      }
      _self.canvas.renderAll();
      _currentNote--;
    }
  };

  _self.undoDrawing = function() {
    var totalEdits = _self.canvas._objects.length - 1,
      item;

    for (var i = totalEdits; i >= 0; i--) {
      item = _self.canvas._objects[i];
      if (item.type !== 'group') {
        _self.canvas.remove(item);
        break;
      }
    }
  };
  _self.drawNote = function(x, y) {
    new fabric.Image.fromURL(_notesSVGUrl, function(object) {
      var svgObj = object,
        text = new fabric.Text(_currentNote.toString(), {
          fontSize: 12,
          fontWeight: 'bold',
          fill: '#FFFFFF',
          fontFamily: 'Helvetica, sans-serif',
          hasControls: false
        });

      text.set({
        left: Math.floor(svgObj.width / 2 - text.width / 2),
        top: Math.floor(svgObj.height / 2 - text.height / 2)
      });

      var loadedObjects = new fabric.Group([svgObj, text], {
        left: x,
        top: y,
        hasBorders: false,
        hasControls: false,
        selectable: true,
        hasRotatingPoint: false
      });
      _self.canvas.add(loadedObjects);
      _self.canvas.renderAll();

      $timeout(function() {
        _self.data.notes.push({
          view: loadedObjects,
          text: ''
        });
        _currentNote++;
      }, 0);
    });
  };

  _self.getDrawingMode = function() {
    if (_self.canvas) {
      return _self.canvas.isDrawingMode;
    }
    return false;
  };

  _self.addNotesFlag = function(event) {
    var pointer = event.e,
      posX = pointer.offsetX,
      posY = pointer.offsetY;
    _self.drawNote(posX, posY);
  };

  _self.setStrokeColor = function(color) {
    if (_self.canvas) {
      _self.canvas.freeDrawingBrush.color = color;
    }
  };

  /*******************************************************************/
  // Demo Canvas Methods
  /*******************************************************************/
  _self.loadJSONDemo = function(jsonFile) {
    var canvasData = jsonFile || _self.saveAnnotation;
    var canvasJSON = canvasData.toJSON;
    var noteDrawings = [];
    var origianlCanvasWidth = false;
    for (var i = 0; i < canvasJSON.objects.length; i++) {
      if (canvasJSON.objects[i].type === 'group') {
        noteDrawings.push({
          index: i,
          canvasMarkUp: canvasJSON.objects[i].objects
        });
      }
      if (canvasJSON.objects[i].type === 'path') {
        origianlCanvasWidth = canvasJSON.objects[i].originalWidth;
      }
    }

    origianlCanvasWidth =
      origianlCanvasWidth || _self.getOriginalCanvasWidth(jsonFile);

    _self.demoCanvas.loadFromJSON(canvasJSON, function() {
      _self.demoCanvas.renderAll();
      _self.loadDrawingScaleWidth(_self.demoCanvas, canvasData);
      _self.zoomItDemo(
        _demoCanvasInitialWidth / origianlCanvasWidth,
        _demoCanvasInitialWidth,
        _demoCanvasInitialHeight
      );
      _self.showStaticCanvas();
      for (var i = 0; i < noteDrawings.length; i++) {
        canvasJSON.objects[noteDrawings[i].index].objects =
          noteDrawings[i].canvasMarkUp;
      }
    });
  };

  _self.getOriginalCanvasWidth = function(jsonFile) {
    //Get PNG width
    var uint8 = Uint8Array.from(atob(jsonFile.img.substr(22)), function(c) {
      return c.charCodeAt(0);
    });
    var dataView = new DataView(uint8.buffer, 0, 28);

    return dataView.getInt32(16);
  };

  _self.showStaticCanvas = function() {
    var activeCanvas = document
      .getElementsByClassName('canvas-container')
      .item(0);
    var staticCanvas = document.getElementById('demoCanvas');
    if (activeCanvas && staticCanvas) {
      activeCanvas.style.display = 'none';
      staticCanvas.style.display = 'block';
    }
  };

  _self.zoomItDemo = function(factor, newWidth, newHeight) {
    _self.demoCanvas.setHeight(newHeight);
    _self.demoCanvas.setWidth(newWidth);

    var objects = _self.demoCanvas.getObjects();
    for (var i in objects) {
      if (objects[i].scaleX === 1) {
        objects[i].set(
          'originalWidth',
          objects[i].originalWidth || _canvasInitialWidth
        );
      }

      if (objects[i].type !== 'group') {
        objects[i].set({
          left: objects[i].left * factor,
          top: objects[i].top * factor,
          scaleX: newWidth / objects[i].originalWidth,
          scaleY: newWidth / objects[i].originalWidth
        });
      } else {
        objects[i].set({
          left: objects[i].left * factor,
          top: objects[i].top * factor
        });
      }
      objects[i].setCoords();
    }
    _self.demoCanvas.renderAll();
    _self.demoCanvas.calcOffset();

    _demoCanvasInitialWidth = newWidth;
    _demoCanvasInitialHeight = newHeight;
  };

  /*******************************************************************/
  // General Methods
  /*******************************************************************/
  _self.reset = function() {
    _currentNote = 1;
    _notesMode = false;
    _self.data = {
      img: {},
      notes: []
    };
    if (_self.canvas) {
      _self.canvas.clear();
      _self.demoCanvas.clear();
      _self.setDrawingMode(true);
      _self.showActiveCanvas();
    }
  };

  _self.destroy = function() {
    _self.canvas = undefined;
  };

  _self.init = function(canvasBgImage, jsonFile, heroImage) {
    _self.reset();

    _canvasElement = canvasBgImage;
    _canvasInitialWidth = _canvasElement.getBoundingClientRect().width;
    _canvasInitialHeight = _canvasElement.getBoundingClientRect().height;
    _demoCanvasInitialWidth = _canvasElement.getBoundingClientRect().width;
    _demoCanvasInitialHeight = _canvasElement.getBoundingClientRect().height;

    if (_self.canvas === undefined) {
      _self.canvas = new fabric.Canvas('canvas', {
        isDrawingMode: true,
        width: _canvasInitialWidth,
        height: _canvasInitialHeight
      });
      _self.demoCanvas = new fabric.StaticCanvas('demoCanvas', {
        isDrawingMode: false,
        selection: false,
        width: _canvasInitialWidth,
        height: _demoCanvasInitialHeight
      });

      angular.element(canvasBgImage).bind('load', function() {
        _canvasInitialWidth = _canvasElement.getBoundingClientRect().width;
        _canvasInitialHeight = _canvasElement.getBoundingClientRect().height;
        _demoCanvasInitialWidth = _canvasElement.getBoundingClientRect().width;
        _demoCanvasInitialHeight = _canvasElement.getBoundingClientRect().height;
        if (_self.canvas) {
          _self.zoomIt(1, _canvasInitialWidth, _canvasInitialHeight);
          _self.zoomItDemo(1, _demoCanvasInitialWidth, _demoCanvasInitialHeight);
        }
      });
      angular.element($window).bind('resize', function() {
        var _newWidth = _canvasElement.getBoundingClientRect().width;
        var _newHeight = _canvasElement.getBoundingClientRect().height;
        if (_self.canvas) {
          _self.resetZoom();
          _self.zoomIt(_newWidth / _canvasInitialWidth, _newWidth, _newHeight);
          _self.zoomItDemo(_newWidth / _demoCanvasInitialWidth, _newWidth, _newHeight);
        }
      });
    }

    $http.get(heroImage, { responseType: 'arraybuffer' }).then(function (response) {
      var blob = new Blob(
        [ response.data ],
        { type: response.headers('Content-Type') }
      );
      var blobURL = URL.createObjectURL(blob);

      fabric.Image.fromURL(blobURL, (img) => {
        img.set({
          scaleX: _self.canvas.width / img.width,
          scaleY: _self.canvas.height / img.height,
        });
        _self.canvas.setBackgroundImage(img);
        _self.canvas.renderAll();
      });
    });

    if (jsonFile) {
      _self.loadJSON(jsonFile);
    }

    fabric.Object.prototype.hasBorders = fabric.Object.prototype.hasControls = fabric.Object.prototype.hasRotatingPoint = false;
    _self.canvas.freeDrawingBrush.width = 3;
  };
};

module.exports = CanvasService;
