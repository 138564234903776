'use strict';

var META_STATUS = {
  // OPEN JOBS / IN PROGRESS
  jobPending: 'Job Pending',
  inProgress: 'In Progress',
  post: 'In Post',
  qa: 'In Image QA',
  problemSolve: 'Problem Solve',
  review: 'In Product Review',
  pendingRelease: 'Pending Release',
  failedToPublish: 'Failed To Publish',
  released: 'Released',
  // COMPLETED JOBS
  completed: 'Completed',
  pendingDrop: 'Pending Drop',
  dropped: 'Dropped',
  closed: 'Closed'
};

META_STATUS.progressStatuses = [
  META_STATUS.inProgress,
  META_STATUS.problemSolve,
  META_STATUS.qa,
  META_STATUS.post,
  META_STATUS.review,
  META_STATUS.pendingRelease
];

META_STATUS.openJobs = [
  META_STATUS.jobPending,
  META_STATUS.inProgress,
  META_STATUS.post,
  META_STATUS.qa,
  META_STATUS.problemSolve,
  META_STATUS.review,
  META_STATUS.pendingRelease,
  META_STATUS.failedToPublish,
  META_STATUS.released
];

META_STATUS.closedJobs = [
  META_STATUS.completed,
  META_STATUS.pendingDrop,
  META_STATUS.dropped,
  META_STATUS.closed
];

module.exports = META_STATUS;
