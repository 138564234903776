'use strict';

var accordionController = require('./accordion.controller.js');
var accordionDirective = require('./accordion.directive.js');
var accordionGroupDirective = require('./accordion.group.directive.js');
var accordionHeaderDirective = require('./accordion.header.directive.js');
var accordionBodyDirective = require('./accordion.body.directive.js');

function register(angular) {
  var app = angular.module('accordion', ['ng']);

  app.controller('accordionController', accordionController(angular));
  app.directive('accordion', accordionDirective(angular));
  app.directive('accordionGroup', accordionGroupDirective(angular));
  app.directive('accordionHeader', accordionHeaderDirective(angular));
  app.directive('accordionBody', accordionBodyDirective(angular));

  return 'accordion';
}

module.exports = register;
