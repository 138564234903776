'use strict';

var fullJobListDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {},
    bindToController: {
      data: '<',
      view: '=',
      showDownload: '<',
      disableSelection: '=',
      viewState: '=?'
    },
    controllerAs: 'job',
    controller: 'JobController',
    templateUrl: 'templates/partials/job/lists/full.tpl.html'
  };
};

module.exports = fullJobListDirective;
