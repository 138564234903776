'use strict';

var angular = require('angular');
var initInjector = angular.injector(['ng']);
var $window = initInjector.get('$window');
var LOGIN_URL = require('./loginUrl.constant.js');

var Redirect = /*@ngInject*/ (function() {
  return {
    login: function() {
      var loginHref;

      $window.localStorage.setItem('ls.pre-redirect', window.location.href);
      if (location.href.indexOf(':3000') > -1) {
        //to test local against dev servers use http://local.nike.ixxus.io:8888
        //loginHref = LOGIN_URL.local;
        return true;
      } else if (location.host.indexOf('gbipuidev01') > -1) {
        //DEV (in AWS)
        loginHref = LOGIN_URL.dev;
      } else if (location.host.indexOf('gbipuiqa') > -1) {
        //QA
        loginHref = LOGIN_URL.qa;
      } else if (location.host.indexOf('gbipuiuat') > -1) {
        //UAT
        loginHref = LOGIN_URL.uat;
      } else {
        //PROD
        loginHref = LOGIN_URL.prod;
      }
      $window.location = loginHref;
    }
  };
})();

module.exports = Redirect;
