'use strict';

/*jshint unused: false*/
var noteComponent = {
  bindings: {
    model: '=',
    type: '@'
  },
  controller: /*@ngInject*/ function($scope, USER_ROLES_LABEL) {
    var _self = this;

    _self.$onInit = function() {
      _self.userRoles = USER_ROLES_LABEL;
    };
  },
  templateUrl: 'templates/partials/note.tpl.html'
};

module.exports = noteComponent;
