'use strict';

var TooltipConfig = /*@ngInject*/ function($$tooltipProvider) {
  $$tooltipProvider.setOptions({
    class: 'gbip-tooltip',
    delay: 500
  });
};

module.exports = TooltipConfig;
