'use strict';

var angular = require('angular');

var bestAssetsComponent = {
  bindings: {
    styleColor: '<',
    toggleRemoveAssets: '&'
  },
  controller: /*@ngInject*/ function(
    AssetSearchService,
    DeliverService,
    AuthService,
    QAService,
    NotificationService,
    USER_ROLES,
    $scope
  ) {
    var _self = this;
    _self.data = {
      identifier: '',
      assets: [],
      groupKeys: [],
      loading: true,
      filters: [{ name: 'imageType', facetValues: [{ value: 'deliverable' }] }],
      permissions: {
        canEditLibraryViewCodes: AuthService.isAuthorized([
          USER_ROLES.superUser,
          USER_ROLES.studioOps,
          USER_ROLES.qaReviewer,
        ]),
        canRemoveAssets: AuthService.isAuthorized([
          USER_ROLES.superUser,
          USER_ROLES.studioOps,
        ]),
      },
      showShootTypeActionMenu: {},
      inLibraryViewCodeEditMode: {},
      tempLibraryViewCodes: {},
      libraryViewCodeErrorMessages: {},
      shootTypeSaveErrors: {},
      errorMessage: {
        ERR_DUPLICATE: 'Duplicate Library View Code.',
        ERR_NO_CODE: 'Library View Code is unknown'
      }
    };
    _self.data.permissions.canViewAssetActionMenu = (
      _self.data.permissions.canEditLibraryViewCodes ||
      _self.data.permissions.canRemoveAssets
    )

    _self.lvcOptions = QAService.getValidLibraryViewCodes();

    _self.getShootTypeMerchTypeKey = function (shootType, merchType) {
      let noSpacesMerchType = merchType.replace(" ", "_");
      return `${shootType}_${noSpacesMerchType}`;
    }

    _self.toggleShootTypeActionMenu = function (shootType, merchType) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      for (let st in _self.data.showShootTypeActionMenu) {
        _self.data.showShootTypeActionMenu[st] = (st === key) ? !_self.data.showShootTypeActionMenu[st] : false;
      }
    };

    _self.removeAssets = function (shootType, merchType) {
      _self.toggleRemoveAssets({ options: {
        shootType,
        merchType,
      } })
    };

    _self.toggleLibraryViewCodeEditMode = function (shootType, merchType, val) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      _self.data.showShootTypeActionMenu[key] = false;
      _self.data.inLibraryViewCodeEditMode[key] = val;
      if (!!val) {
        _self.copyCurrentToTemp(shootType, merchType);
        _self.validateLibraryViewCodes(shootType, merchType);
      } else {
        _self.data.tempLibraryViewCodes[key] = {};
        _self.data.libraryViewCodeErrorMessages[key] = {};
        delete _self.data.shootTypeSaveErrors[key];
      }
    };

    _self.hasLibraryViewCodeErrors = function (shootType, merchType) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      return !angular.equals(_self.data.libraryViewCodeErrorMessages[key],{});
    };

    _self.copyCurrentToTemp = function (shootType, merchType) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      let shootTypeObj = _self.data.groupKeys.find(i => i.shootType === shootType && i.merchType === merchType)
      _self.data.tempLibraryViewCodes[key] = {};
      for (let index in shootTypeObj.viewCodes) {
        let viewCode = shootTypeObj.viewCodes[index].code;
        _self.data.tempLibraryViewCodes[key][viewCode] = shootTypeObj.viewCodes[index].libraryViewCode;
      }
    };

    _self.copyTempToCurrent = function (shootType, merchType) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      let shootTypeObj = _self.data.groupKeys.find(i => i.shootType === shootType && i.merchType === merchType);
      for (let index in shootTypeObj.viewCodes) {
        let viewCode = shootTypeObj.viewCodes[index].code;
        shootTypeObj.viewCodes[index].libraryViewCode = _self.data.tempLibraryViewCodes[key][viewCode];
      }
    }

    _self.validateLibraryViewCodes = function (shootType, merchType) {
      let shootTypeObj = _self.data.groupKeys.find(i => i.shootType === shootType && i.merchType === merchType);
      for (let index in shootTypeObj.viewCodes) {
        let viewCode = shootTypeObj.viewCodes[index].code;
        if(_self.shouldShowLVC(_self.data.assets, viewCode)) {
          _self.validateLibraryViewCode(shootType, merchType, viewCode);
        }
      }
    }

    _self.validateLibraryViewCode = function(shootType, merchType, viewCode) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      let libraryViewCode = _self.data.tempLibraryViewCodes[key][viewCode];

      if (!libraryViewCode || !QAService.getValidLibraryViewCodes().includes(libraryViewCode)) {
        _self.data.libraryViewCodeErrorMessages[key][viewCode] = _self.data.errorMessage.ERR_NO_CODE;
      } else if (_self.isDuplicateLibraryViewCode(key, viewCode, libraryViewCode)) {
        _self.data.libraryViewCodeErrorMessages[key][viewCode] = _self.data.errorMessage.ERR_DUPLICATE;
      } else if (_self.data.libraryViewCodeErrorMessages[key][viewCode]) {
        delete _self.data.libraryViewCodeErrorMessages[key][viewCode];
      }
    };

    _self.shouldShowLVC = function(assets, viewCode) {
      if(!!assets && !!assets.length) {
        if(!!viewCode) {
          assets = assets.filter(a => a.viewCode === viewCode);
        }
        return assets.some(a => a.cropType === "1x1");
      } else {
        return false;
      }
    }

    _self.isDuplicateLibraryViewCode = function (key, viewCode, libraryViewCode) {
      for (let code in _self.data.tempLibraryViewCodes[key]) {
        if (code !== viewCode) {
          let lvc = _self.data.tempLibraryViewCodes[key][code];
          if (lvc === libraryViewCode) {
            return true;
          }
        }
      }
      return false;
    };

    _self.getAssetsbyShootTypeAndMerchType = function (shootType, merchType) {
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      let shootTypeObj = _self.data.groupKeys.find(i => i.shootType === shootType && i.merchType === merchType);
      return shootTypeObj.assets;
    }

    _self.shouldShowLibraryViewCodesForViewCode = function (shootType, merchType, viewCode) {
      let assets = _self.getAssetsbyShootTypeAndMerchType(shootType, merchType);
      return assets.filter(a => { return a.viewCode === viewCode && a.cropType === '1x1' && a.vendor && a.vendor.toUpperCase() !== 'CONVERSE' }).length > 0;
    }

    _self.saveLibrayViewCodes = function(shootType, merchType) {
      let payload = [];
      let key = _self.getShootTypeMerchTypeKey(shootType, merchType);
      let assets = _self.getAssetsbyShootTypeAndMerchType(shootType, merchType);
      for (let i in assets) {
        let asset = assets[i];
        payload.push({
          id: asset.id,
          properties: { libraryViewCode: _self.data.tempLibraryViewCodes[key][asset.viewCode] }
        });
      }

      let handleError = function(response) {
        _self.data.shootTypeSaveErrors[key] = "An error occurred while saving.";
      };

      AssetSearchService.updateAssetMetadata(payload).then(response => {
        if (response.status === 200) {
          _self.copyTempToCurrent(shootType, merchType);
          _self.toggleLibraryViewCodeEditMode(shootType, merchType, false);
          NotificationService.push(
            'Library View Codes saved successfully.'
          );
        } else {
          handleError(response);
        }
      }, handleError);
    };

    _self.updateAssets = function() {
      _self.assets = [];
      _self.data.loading = true;
      AssetSearchService.search(
        _self.data.filters,
        [_self.data.identifier],
        true,
        false
      ).then(
        function(results) {
          if (results.objects && results.objects.length > 0) {
            _self.data.assets = results.objects.filter(function(asset) {
              return !asset['doNotUse'];
            });
          }
          _self.buildShootTypes();
          _self.data.loading = false;
        },
        function(error) {
          //it didn't work
        }
      );
    };

    _self.buildShootTypes = function() {
      _self.data.groupKeys = [];

      for (let index in _self.data.assets) {
        let element = _self.data.assets[index];
        var key = {
          shootType: element.shootType,
          merchType: element.merchType
        };
        let shootMerchKey = _self.getShootTypeMerchTypeKey(key.shootType, key.merchType);
        var filterArr = _self.data.groupKeys.filter(function(groupKey) {
          return (
            groupKey.shootType === key.shootType &&
            groupKey.merchType === key.merchType
          );
        });
        if (filterArr.length == 0) {
          key.assets = _self.data.assets.filter(function(asset) {
            return (
              asset.shootType === key.shootType &&
              asset.merchType === key.merchType
            );
          });
          key.viewCodes = [];

          key.assets.sort(function (a, b) {
            if (a.viewCode > b.viewCode) {
              return 1;
            } else if (a.viewCode < b.viewCode) {
              return -1;
            } else {
              return (a.cropType === "1x1") ? -1 : 1;
            }
          });

          for (let index in key.assets) {
            let element = key.assets[index];
            if (key.viewCodes.filter(function(viewCode) {
                return viewCode.code === element.viewCode;
              }).length == 0
            ) {
              key.viewCodes.push({
                code: element.viewCode,
                libraryViewCode: element.libraryViewCode,
                assets: key.assets
                  .filter(function(asset) {
                    return asset.viewCode === element.viewCode;
                  })
                  .sort(function(a, b) {
                    let comparison = 0;
                    if (a.cropType > b.cropType) {
                      comparison = 1;
                    } else if (a.cropType < b.cropType) {
                      comparison = -1;
                    }
                    return comparison;
                  })
              });
            }
          }

          _self.data.inLibraryViewCodeEditMode[shootMerchKey] = false;
          _self.data.showShootTypeActionMenu[shootMerchKey] = false;
          _self.data.libraryViewCodeErrorMessages[shootMerchKey] = {};
          _self.data.groupKeys.push(key);
        }
      }

      _self.data.groupKeys.sort(function(a, b) {
        let comparison = 0;
        let keyA = a.shootType + a.merchType;
        let keyB = b.shootType + b.merchType;

        if (keyA > keyB) {
          comparison = 1;
        } else if (keyA < keyB) {
          comparison = -1;
        }
        return comparison;
      });
    };

    _self.createDelivery = function (groupKey) {
      let shootMerchKey = _self.getShootTypeMerchTypeKey(groupKey.shootType, groupKey.merchType);
      _self.data.showShootTypeActionMenu[shootMerchKey] = false;
      DeliverService.createDelivery(groupKey);
    };

    _self.extractShootTypeFromKey = function(key) {
      if(!key) {
        return null;
      }
      return key.split("_")[0];
    };

    _self.extractMerchTypeFromKey = function(key) {
      if(!key) {
        return null;
      }
      let split = key.split("_");
      split.splice(0, 1);
      return split.join(" ");
    };

    $scope.$watch('$ctrl.data.tempLibraryViewCodes', function(newVal, preVal) {
      for(let index in _self.data.inLibraryViewCodeEditMode) {
        if(_self.data.inLibraryViewCodeEditMode[index]) {
          const shootType = _self.extractShootTypeFromKey(index);
          const merchType = _self.extractMerchTypeFromKey(index);
          _self.validateLibraryViewCodes(shootType, merchType);
        }
      }
    }, true);

    _self.$onChanges = function(changes) {
      if (
        changes.styleColor &&
        _self.styleColor &&
        _self.styleColor !== _self.data.identifier
      ) {
        _self.data.identifier = angular.copy(_self.styleColor);
        _self.updateAssets();
      }
    };
  },
  templateUrl: 'templates/partials/detail/bestAssets.tpl.html'
};

module.exports = bestAssetsComponent;
