'use strict';

var SearchGridController = /*@ngInject*/ function(
  $rootScope,
  $scope,
  $q,
  $element,
  $state,
  $stateParams,
  $timeout,
  SearchService,
  KeymageService,
  AuthService,
  TransferService,
  NotificationService,
  Session,
  CSVExporter,
  RouterTracker,
  USER_ROLES,
  EventLoggerService,
  QueueService,
  ScanTypeService
) {
  var _self = this;
  _self.downloadRole = false;
  _self.lastMerchIdScanned = null;
  _self.lastMerchIdScannedProductIdentifier = null;

  _self.primaryButton = {
    show: undefined,
    disable: undefined,
    cta: undefined
  };

  _self.termsModal = {
    active: false,
    data: {
      type: 'small',
      heading: {
        title: 'Search Terms'
      },
      cta: {
        primary: 'Ok'
      }
    },
    onSubmit: function() {
      _self.termsModal.active = false;
    }
  };

  _self.resetDownloadsModal = function() {
    _self.downloadsModal = {
      active: false,
      imageTypes: new Set(),
      cropTypes: new Set(),
      showCropTypes: false,
      productCount: 0,
      disableFilesDownload: false,
      warningMessage: '',
      assets: [],
      selectedAssets: [],
      downloadApproach: 'dataCsv',
      downloadValid: false,
      params: {
        cta: {
          primary: 'Download',
          secondary: 'Cancel'
        },
      },
      error: {
        message: undefined,
      },
      downloadAuth: {
        data: AuthService.isAuthorized([
          USER_ROLES.studioOps,
          USER_ROLES.superUser,
        ]),
        assets: AuthService.isAuthorized([
          USER_ROLES.studioOps,
          USER_ROLES.superUser,
        ]),
      },
    };
  };

  /* Keybindings */
  var toggleSearchInput = function() {
    var input = $element[0].querySelector('.js-search input');
    input.focus();
  };

  var setKeyBindings = function() {
    var bindings = [
      {
        shortcut: '/',
        fn: toggleSearchInput,
        options: { preventDefault: true }
      },
      {
        shortcut: 'd',
        fn: _self.downloadButtonPressed
      }
    ];

    KeymageService.set($scope, bindings);
  };

  /* Setting and resetting page values */
  var setPageTitle = function() {
    var count = function() {
      return _self.data.term.split(/\s/g).length;
    };
    if (!_self.data.term) {
      //nothing searched for
      _self.data.title = 'Search';
    } else if (count() == 1) {
      //One term, show in quotes
      _self.data.title = '"' + _self.data.term + '"';
    } else if (count() == 2) {
      //Two terms in quotes
      _self.data.title = '"' + _self.data.term.split(/\s/g).join('" "') + '"';
    } else if (count() >= 3) {
      //3+, just show how many we searched for
      _self.data.title = count() + ' items';
    }
  };

  // This tells the 'instructions' modal which text to display.
  var setInstructionState = function(state) {
    if (state) {
      _self.instructionState = state;
      return;
    }

    if (!_self.data.results.length) {
      if (_self.data.term || !!SearchService.getFacetString()) {
        _self.instructionState = 'no-results';
      } else {
        _self.instructionState = 'start';
      }
      return;
    }

    _self.instructionState = '';
  };

  _self.syncSingle = function() {
    var term = _self.data.term;
    setInstructionState('blank');
    _self.working = true;
    _self.loadingError = false;
    SearchService.syncSingle(term)
      .then(function(response) {
        //request sent to sync, wait 7 seconds then try it again
        $timeout(function() {
          _self.doSearch(_self.data.term, true);
        }, 7000);
      })
      .catch(function(response) {
        //sync call failed, search again
        _self.doSearch(_self.data.term);
      });
  };

  // Reset and clear all resuLts
  var resetResults = function(term, facets, sort) {
    _self.data.term = term;
    _self.data.inputTerm = term;
    _self.data.facets = facets || '';
    _self.config.sort = sort || '';
    _self.data.total = 0;
    _self.config.page = 0;
    _self.config.size = 50;
    _self.data.results = [];
    _self.data.allResults = false;
  };

  _self.isInfiniteScrollingDisabled = function() {
    return (
      _self.data.results.length === 0 ||
      _self.working === true ||
      _self.data.allResults === true
    );
  };

  /* Search functions */
  //silently update url
  var setUrl = function() {
    $state.go(
      'search',
      {
        q: _self.data.term,
        f: _self.data.facets
      },
      {
        location: 'replace',
        notify: false
      }
    );
  };

  _self.handleSearchResults = function(response) {
    _self.config.page++;
    EventLoggerService.track(
      'QUEUE_PAGINATION',
      {
        page: $state.current.name,
        location: _self.stateData ? ' ' + _self.stateData.location : '',
        pageNumber: _self.config.page
      },
      _self.config.page > 1
    );

    _self.data.results = _self.data.results.concat(response.data.results);
    _self.data.total = response.data.total;
    _self.data.searchResultQualifier =
      response.data.searchResultQualifier === undefined
        ? false
        : response.data.searchResultQualifier;

    if (_self.config.size > response.data.results.length) {
      _self.data.allResults = true;
    }

    setInstructionState();
    setPageTitle();
    setUrl();

    $timeout(function() {
      // prevent infinite scrolling from triggering again
      _self.working = false;
    }, 0);
  };

  _self.redirectJobDetail = function() {
    if (
      _self.data.total === 1 &&
      !AuthService.isAuthorized(USER_ROLES.productReviewer)
    ) {
      $timeout(function() {
        var paramsPayload = { jobId: _self.data.results[0].id };
        paramsPayload.productIdentifierType = 'by-product-id';
        paramsPayload.productIdentifier =
          _self.data.results[0].product.productId;
        $state.go('detail', paramsPayload);
      }, 10); // Need a small timeout to allow Url to change
    }
  };

  _self.doSearch = function(term, force) {
    if (!term && !_self.data.facets) {
      resetResults();
      setInstructionState();
      setPageTitle();
      setUrl();
    } else {
      Session.setSessionValue('searchTerm', term, _self.viewState);
      _self
        .search(
          term,
          _self.data.facets,
          SearchService.getSortingString(),
          force
        )
        .then(_self.redirectJobDetail);
    }
  };

  _self.search = function(term, facets, sort, force) {
    var deferred = $q.defer();
    if (term !== _self.lastMerchIdScanned && term !== _self.lastMerchIdScannedProductIdentifier) {
      _self.lastMerchIdScanned = null;
      _self.lastMerchIdScannedProductIdentifier = null;
    }

    if (
      term !== _self.data.term ||
      facets !== _self.data.facets ||
      sort !== _self.config.sort ||
      force
    ) {
      resetResults(term, facets, sort);
    }

    if (!_self.data.allResults) {
      setInstructionState('blank');
      _self.working = true;
      _self.loadingError = false;

      if (_self.cancelSearch) {
        _self.cancelSearch.resolve('cancel search');
      }

      _self.cancelSearch = $q.defer();

      SearchService.search(
        {
          term: _self.data.term,
          filters: _self.data.facets,
          page: _self.config.page,
          size: _self.config.size,
          sort: _self.config.sort
        },
        null,
        _self.cancelSearch.promise
      )
        .then(function(response) {
          _self.handleSearchResults(response);
          deferred.resolve();
        })
        .catch(function(response) {
          //Check if $http is not cancelled by UI
          if (
            response.config.timeout != undefined &&
            response.config.timeout.$$state.status !== 1
          ) {
            $rootScope.$broadcast('error', { error: response.data.error });
          }
          _self.working = false;
          _self.loadingError = true;
        });
    }

    return deferred.promise;
  };

  /* Result/Job functions */
  _self.openJob = function() {
    var job;
    for (var i = 0; i < _self.data.results.length; i++) {
      var current = _self.data.results[i];
      if (current.selected) {
        job = current;
        break;
      }
    }
    if (!!_self.lastMerchIdScanned) {
      job.primaryMerchId = _self.lastMerchIdScanned;
    }
    _self.updateInitiateJob({ job: job, active: true });
  };

  _self.buildDownloadRequests = function() {
    const assets = _self.downloadsModal.selectedAssets;
    const requests = new Map();
    const pattern = /^([A-Z0-9]+(?:-[A-Z0-9]+)?)_(\d+)_([DUW])_([A-Z0-9]+)(?:_([A-Z0-9]+))?.(PNG|TIFF?)$/i;
    const types = {
      'U': 'Unedited',
      'W': 'Worker',
      'D': 'Deliverable',
    };

    assets.forEach(asset => {
      if (!asset.fileName) {
        console.log('Missing filename: ', asset);
        return;
      }

      const matches = asset.fileName.match(pattern);
      const properties = matches.map((x) => x) || [];
      const [
        , // full match
        , // style color
        metaJobId,
        imageType,
        , // view code
        , // crop type case may not match DB
        , // extenstion
      ] = properties;

      if(!requests.has(metaJobId)) {
        requests.set(metaJobId, { metaJobId, imageTypes: new Set(), cropTypes: new Set()});
      }

      const payload = requests.get(metaJobId);
      payload.imageTypes.add(types[imageType]);
      if (asset.cropType) {
        payload.cropTypes.add(asset.cropType);
      }
    });

    return [...requests.values()].map(request => ({
      metaJobId: request.metaJobId,
      includeImageTypes: [...request.imageTypes],
      includeCropTypes: [...request.cropTypes],
    }));
  };

  _self.downloadSubmit = function() {
    switch (_self.downloadsModal.downloadApproach) {
      case 'dataCsv':
        CSVExporter.downloadJobs(_self.data.results, 'GBIP Job Search Result');
        _self.resetDownloadsModal();
        break;
      case 'files':
        const requests = _self.buildDownloadRequests();
        TransferService.sendDownloadRequest(requests).then(function(response) {
          NotificationService.push('Assets have been sent for download.');
          _self.resetDownloadsModal();
        })
          .catch(function(response) {
            console.error(`Desktop download request error -` +
              `\n\tStatus: ${response.data.status}` +
              `\n\tError: ${response.data.error}` +
              `\n\tMessage: ${response.data.message}` +
              `\n\tRequest: ${response.config.method} to ${response.config.url}` +
              `\n\tPayload: ${JSON.stringify(response.config.data)}`);
            _self.downloadsModal.error.message = 'An error occurred while processing the download.';
          });
        break;
      default:
        NotificationService.push(`Unknown Download Approach: ${_self.downloadsModal.downloadApproach}`);
    }
  };

  _self.downloadButtonPressed = function() {
    if(_self.downloadsModal.downloadAuth.data && _self.downloadsModal.downloadAuth.assets) {
      _self.showDownloadModal();
    }

    if(_self.downloadsModal.downloadAuth.data && !_self.downloadsModal.downloadAuth.assets) {
      CSVExporter.downloadJobs(_self.data.results, 'GBIP Job Search Result');
    }
  };

  _self.showDownloadModal = function() {
    _self.downloadsModal.active = true;
    _self.updateModalDownloadValid();

    const selected = _self.data.results.filter(s => s.selected);
    _self.downloadsModal.selectedJobs = selected.filter(s => s.id).map(s => ({id: s.id, product: s.product}));
    _self.downloadsModal.productCount = selected.filter(s => !s.id).length;
    _self.downloadsModal.assets = _self.downloadsModal.selectedJobs.flatMap(job => job.product.assets);

    if(_self.downloadsModal.selectedJobs.length > 50) {
      _self.downloadsModal.disableFilesDownload = true;
      _self.downloadsModal.warningMessage =
        'File downloads are currently limited to 50 Jobs at a time.';
      return;
    }

    if(_self.downloadsModal.productCount > 0) {
      _self.downloadsModal.disableFilesDownload = true;
      _self.downloadsModal.warningMessage =
        'You have selected one or more Product Records, only Jobs can be selected for image asset download at this time.';
      return;
    }

    if(!_self.downloadsModal.assets.length > 0) {
      _self.downloadsModal.disableFilesDownload = true;
      _self.downloadsModal.warningMessage =
        'The selected job[s] contain no image assets for download.';
      return;
    }

    const tempImageTypes = new Set();
    const tempCropTypes = new Set();
    _self.downloadsModal.assets.forEach(asset => {

      // Fix for older jobs. Deliverables appear to have once been called "Publish"
      if (asset.type === 'Publish') {
        tempImageTypes.add('Deliverable');
      } else {
        tempImageTypes.add(asset.type);
      }

      if (asset.cropType) {
        tempCropTypes.add(asset.cropType);
      }
    });

    // Hack for AngularJS to display Set items
    _self.downloadsModal.imageTypes = [...tempImageTypes].map(type => ({ value: type, selected: false }));
    _self.downloadsModal.cropTypes = [...tempCropTypes].map(type => ({ value: type, selected: true }));

    _self.downloadsModal.imageTypes.sort(
      (typeA,typeB) => {
        const a = typeA.value.toUpperCase();
        const b = typeB.value.toUpperCase();

        switch (a) {
          case 'UNEDITED':
            return -1;
          case 'WORKER':
            if(b === 'UNEDITED') {
              return 1;
            }
            return -1;
          case 'DELIVERABLE':
            return 1;
          default:
            return 0;
        }
      }
    );
    _self.downloadsModal.cropTypes.sort(
      (typeA,typeB) => {
        const a = typeA.value.toUpperCase();
        const b = typeB.value.toUpperCase();

        if(a.length > b.length) {
          return 1;
        } else if (a.length < b.length) {
          return -1;
        }

        if (a >= b) {
          return 1;
        }
        return -1;
      }
    );
  };

  _self.updateModalDownloadValid = function() {
    if(_self.downloadsModal.downloadApproach === 'dataCsv') {
      _self.downloadsModal.downloadValid = true;
      return;
    }

    if(_self.downloadsModal.downloadApproach === 'files' && _self.downloadsModal.assets.length > 0) {
      if(_self.downloadsModal.selectedAssets.length > 0) {
        _self.downloadsModal.downloadValid = true;
        return;
      }
    }

    _self.downloadsModal.downloadValid = false;
  };

  _self.updateSelectedAssets = function() {
    let tempAssets = _self.downloadsModal.assets;

    // check which image and crop types are selected
    let selectedImageTypes = _self.downloadsModal.imageTypes
    .filter(type => type.selected)
    .map(type => type.value);

    let selectedCropTypes = _self.downloadsModal.cropTypes
    .filter(type => type.selected)
    .map(type => type.value);

    _self.downloadsModal.selectedAssets = tempAssets.filter(asset => {
      if((asset.type === 'Deliverable' || asset.type === 'Publish') && selectedImageTypes.includes('Deliverable')) {
        return selectedCropTypes.includes(asset.cropType);
      } else {
        return selectedImageTypes.includes(asset.type);
      }
    });

    _self.downloadsModal.selectedImageTypes = selectedImageTypes;
    _self.downloadsModal.selectedCropTypes = selectedCropTypes;
  };

  _self.downloadModalImageTypeChange = function(opt) {
    // Function called before model is changed
    // Used to get correct state to show crops
    opt.selected = !opt.selected;

    if (opt.value === 'Deliverable') {
      if (opt.selected) {
        _self.downloadsModal.showCropTypes = true;
      } else {
        _self.downloadsModal.showCropTypes = false;
      }
    }

    _self.updateSelectedAssets();
    _self.updateModalDownloadValid();
  };

  _self.downloadModalCropTypeChange = function(opt) {
    opt.selected = !opt.selected;
    _self.updateSelectedAssets();
    _self.updateModalDownloadValid();
  };

  _self.updateInitiateJob = function($event) {
    if ($event.job) {
      _self.initiateJob = $event.job;
    }
    _self.showInitiateJobModal = $event.active;
  };

  _self.handleJobCreation = function($event) {
    _self.showInitiateJobModal = $event.active;

    if (!$event.job) {
      return;
    }

    $state.go('detail', {
      productIdentifierType: 'by-product-id',
      productIdentifier: $event.job.product.productId,
      jobId: $event.job.id
    });
  };

  _self.onJobCreation = function($event) {
    _self.data.results = _self.data.results.concat($event.job);
    _self.data.total++;
  };

  _self.selectAll = function(boolean) {
    if (boolean !== undefined) {
      _self.data.results.allSelected = boolean;
    }
    _self.data.results.forEach(function(job) {
      job.selected = _self.data.results.allSelected;
    });
  };

  /* Initiation */
  _self.initWatchFilters = function() {
    $scope.$watch(
      function() {
        return SearchService.data.filters;
      },
      function() {
        if (!SearchService.data.filters) {
          return;
        }

        if (!_self.data.term && !SearchService.getFacetString()) {
          resetResults(null, null, SearchService.getSortingString());
          setInstructionState();
          setPageTitle();
        } else if (SearchService.getFacetString() !== _self.data.facets) {
          _self.search(
            _self.data.term,
            SearchService.getFacetString(),
            SearchService.getSortingString()
          );
        }
      },
      true
    );

    $scope.$watch(
      function() {
        return SearchService.data.sort;
      },
      function() {
        if (!SearchService.data.sort) {
          return;
        }

        if (
          SearchService.getSortingString() !== _self.config.sort &&
          _self.data.results.length
        ) {
          _self.search(
            _self.data.term,
            SearchService.getFacetString(),
            SearchService.getSortingString()
          );
        }
      },
      true
    );
  };

  _self.init = function() {
    _self.stateParams = $stateParams;
    _self.isAdmin = AuthService.isAdmin();
    if (
      AuthService.isAuthorized([
        USER_ROLES.superUser,
        USER_ROLES.seniorImageEditor
      ])
    ) {
      _self.downloadRole = true;
    }
    _self.data = {};
    _self.config = {};
    _self.viewState = 'search';
    _self.data.displayType =
      Session.getSessionValue('mainView', _self.viewState) !== null
        ? Session.getSessionValue('mainView', _self.viewState)
        : 'list';
    _self.data.compare = $stateParams.c ? $stateParams.c.split('|') : [];
    SearchService.setSorting('modifiedOn', true);
    resetResults(null, null, SearchService.getSortingString());
    setKeyBindings();

    if ($stateParams.t === 'problem') {
      _self.data.searchType = 'ps';
    }

    var routeHistory = RouterTracker.getRouteHistory();
    var lastRoute = routeHistory[routeHistory.length - 1];
    if (lastRoute.route.name === 'detail') {
      var term = Session.getSessionValue('searchTerm', _self.state);
      _self.data.term = term === null ? '' : term;
      _self.data.inputTerm = _self.data.term;
    } else {
      Session.setSessionValue('searchTerm', '', _self.state);
    }

    $scope.$on('scan.update', function(e, info) {
      var scannedCode;
      var productIdentifierType;
      var productIdentifier;
      var jobId;

      if (_self.showInitiateJobModal) {
        return;
      }

      if (ScanTypeService.isJobSlate(info)) {
        scannedCode = info.split('_');
        productIdentifier = scannedCode[0];
        jobId = scannedCode[1];
        if (productIdentifier.indexOf('-') == -1) {
          //this means this is a product id
          productIdentifierType = 'by-product-id';
        } else {
          productIdentifierType = 'by-style-color';
        }
      } else if (ScanTypeService.isMerchId(info)) {
        _self.lastMerchIdScanned = info;
        var params = {};
        params.term = info;
        params.predicate = '_allFiltered';
        params.sort = [{ field: 'lastUpdated', order: 'desc' }];
        params.size = 50;
        QueueService.getMerchManagementJobs(params).then(function(data) {
          var prodRecord = data.data.hits.hits[0]['_source'].productRecord;
          _self.lastMerchIdScannedProductIdentifier = prodRecord;
          _self.data.term = prodRecord;
          _self.data.facets = undefined;
          _self.search(_self.data.term, _self.data.facts);
        });
        return;
      }

      _self.data.term = productIdentifier;
      _self.data.facets = undefined;

      setUrl();

      $timeout(function() {
        $state.go('detail', {
          productIdentifierType: productIdentifierType,
          productIdentifier: productIdentifier,
          jobId: jobId
        });
      }, 0);
    });
    setPageTitle();
    setInstructionState('blank');
    _self.resetDownloadsModal();

    if (!$stateParams.f && !$stateParams.q) {
      SearchService.setFilters();
      setInstructionState();
      _self.initWatchFilters();
      return;
    }

    if ($stateParams.f) {
      SearchService.getFacets().then(function() {
        var selections = {},
          filterGroups = $stateParams.f.split(',');

        filterGroups.forEach(function(filterGroup) {
          var filter = filterGroup.split('=');
          selections[filter[0]] = filter[1].split('|');
        });

        SearchService.data.filters.forEach(function(filter) {
          if (selections[filter.id]) {
            filter.open = true;
            filter.options.forEach(function(option) {
              if (selections[filter.id].indexOf(option.value) >= 0) {
                option.selected = true;
              }
            });
          }
        });
      });
    }

    if ($stateParams.q || $stateParams.f) {
      _self
        .search($stateParams.q, $stateParams.f, _self.config.sort)
        .then(_self.initWatchFilters);
    }
  };

  _self.init();
};

module.exports = SearchGridController;
