'use strict';
var angular = require('angular');
var appendTransform = require('../vendor/appendTransform');
var searchCompactComponent = {
  bindings: {
    onChange: '&',
    onActive: '&',
    filters: '<',
    buttonFilters: '<',
    options: '=',
    viewState: '<',
    viewPane: '@',
    usersJobs: '='
  },
  /*@ngInject*/
  controller: function(
    $element,
    SEARCH_CONSTANTS,
    SearchService,
    AnimationService,
    Session,
    QueueService,
    $http,
    REST_URL,
    UsersService,
    JobFormService,
    UPDATE_PARAMS
  ) {
    var _self = this;
    _self.forceUpdate = _self.active = _self.working = false;
    _self.active = true;
    _self.headerCTA = 'Filter Results';
    _self.clear = '';
    _self.clearAssignee = {
      lastName: 'aaaaaaa', //The list sorts alphabetically by last name. This ensures it reaches the top.
      label: ' ',
      name: ' ',
      email: ''
    };
    _self.formData = {
      division: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['product.division'].name
      },
      'shootType.code': {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['shootType.code'].name
      },
      'product.age': {
        label: 'Age'
      },
      'product.gender': {
        label: 'Gender'
      },
      category: {
        disabled: false,
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['product.category'].name
      },
      'product.subcategory': {
        disabled: true,
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['product.subcategory'].name
      },
      seasons: {
        label: 'Seasons' // options: ['SP16', 'SU16', 'FA16', 'HO16', 'SP17', 'SU17', 'FA17']
      },
      businessPartner: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['businessPartner'].name
      },
      rejectionStatus: {},
      jobType: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['jobType'].name
      },
      assignees: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['assignees'].name
      },
      'product.vendorCode': {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['product.vendorCode'].name
      },
      'studio.studioName': {
        label: "Studio"
      }
    };
    _self.formData['product.category'] = _self.formData['category'];
    _self.formData['product.division'] = _self.formData['division'];
    _self.toggleButton = filterKey => {
      if (_self.formData[filterKey].value) {
        _self.formData[filterKey].value = undefined;
      } else {
        _self.formData[filterKey].value = true;
      }
      _self.formUpdate();
    };
    _self.updateFilterValue = function(options, selectedOptions, filter) {
      if (!filter.value) {
        return;
      }
      options.push([filter.name, filter.value].join('='));
      selectedOptions[filter.name] = filter.value;
    };
    _self.formUpdate = function() {
      var options = [],
        selectedOptions = {};
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.division',
        value: _self.formData.division.value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'rejectionStatus',
        value: _self.formData.rejectionStatus.value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.category',
        value: _self.formData.category.value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.subcategory',
        value: _self.formData['product.subcategory'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.currentSeason',
        value: _self.formData.seasons.value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'shootType.code',
        value: _self.formData['shootType.code'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.age',
        value: _self.formData['product.age'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.gender',
        value: _self.formData['product.gender'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'businessPartner',
        value: _self.formData['businessPartner'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'jobType',
        value: _self.formData['jobType'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'assignees',
        value: _self.formData['assignees'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'product.vendorCode',
        value: _self.formData['product.vendorCode'].value
      });
      _self.updateFilterValue(options, selectedOptions, {
        name: 'studio.studioName',
        value: _self.formData['studio.studioName'].value
      });
      Session.setSessionValue(_self.viewPane, selectedOptions, _self.viewState);
      _self.working = true;
      _self.onChange({
        $event: {
          filterObj: options
        }
      });
    };
    _self.resetCategory = function() {
      _self.formData.division.value = _self.formData.category.value = '';
      _self.formData.category.disabled = true;
      _self.formData['product.subcategory'].disabled = true;
    };
    _self.reset = function() {
      delete _self.formData.seasons.value;
      delete _self.formData['product.age'].value;
      delete _self.formData['product.gender'].value;
      delete _self.formData['shootType.code'].value;
      delete _self.formData['businessPartner'].value;
      delete _self.formData['jobType'].value;
      delete _self.formData['product.subcategory'].value;
      delete _self.formData['rejectionStatus'].value;
      delete _self.formData['assignees'].value;
      delete _self.formData['assignees'].display;
      delete _self.formData['product.vendorCode'].value;
      delete _self.formData['studio.studioName'].value;
      _self.resetCategory();
      _self.formUpdate();
    };
    _self.onDivisionChange = function(option) {
      if (option === _self.clear) {
        _self.resetCategory();
      } else {
        _self.formData.category.value = _self.clear;
        _self.formData.category.disabled = false;
      } //Reset season to get brand new filters
      // _self.formData.seasons.value = [];
      _self.forceUpdate = true;
      _self.formUpdate();
    };
    _self.onCategoryChange = function(option) {
      if (option === _self.clear) {
        _self.formData['product.subcategory'].disabled = true;
        _self.formData['product.subcategory'].value = '';
      } else {
        _self.formData['product.subcategory'].value = _self.clear;
        _self.formData['product.subcategory'].disabled = false;
      } //Reset season to get brand new filters
      // _self.formData.seasons.value = [];
      _self.forceUpdate = true;
      _self.formUpdate();
    };
    _self.onSeasonChange = function() {
      _self.formUpdate();
    };
    _self.onShootTypeChange = function() {
      _self.formUpdate();
    };
    _self.onAgeChange = function() {
      _self.formUpdate();
    };
    _self.onBusinessPartnerChange = function() {
      _self.formUpdate();
    };
    _self.onJobTypeChange = function() {
      _self.formUpdate();
    };
    _self.onAssigneeTypeChange = function(option) {
      _self.formData.assignees.value = option.userName;
      _self.formData.assignees.display = option.name;
      _self.formUpdate();
    };
    _self.dropdownChange = {
      seasons: _self.formUpdate,
      'product.category': _self.onCategoryChange,
      'product.subcategory': _self.formUpdate,
      'product.vendorCode': _self.formUpdate,
      'product.division': _self.onDivisionChange,
      'shootType.code': _self.formUpdate,
      'product.gender': _self.formUpdate,
      'product.age': _self.formUpdate,
      businessPartner: _self.formUpdate,
      jobType: _self.formUpdate,
      assignees: _self.onAssigneeTypeChange,
      'studio.studioName': _self.formUpdate
    };
    _self.toggleFilter = function() {
      var elem = $element[0].querySelector('.js-queue-filter-content');
      _self.active = !_self.active;
      if (_self.active) {
        _self.headerCTA = 'Hide Filter';
      } else {
        _self.headerCTA = 'Filter Results';
      }
      AnimationService.expand(elem, _self.active).then(function() {
        _self.onActive();
      });
    };
    _self.setOptionsForFilter = function(filter, filterName, forceUpdate) {
      if (
        (!forceUpdate && !_self.formData[filterName].options) ||
        forceUpdate
      ) {
        if (forceUpdate) {
          _self.formData[filterName].disabled = false;
        }
        _self.formData[filterName].options = filter.options; // If we are setting the assignees filter and it does not have clearAssignee as the first option
        if (filterName === 'assignees') {
          _self.formData[filterName].options.unshift(_self.clearAssignee);
        } else {
          _self.formData[filterName].options.unshift(_self.clear);
        }
      }
    };
    _self.getRolesForQueue = function() {
      var currentQueue =
        _self.viewState.indexOf('queue.') !== -1
          ? _self.viewState.substring(
              _self.viewState.indexOf('.') + 1,
              _self.viewState.length
            )
          : _self.viewState;
      return UPDATE_PARAMS[currentQueue].assign.assignmentRole;
    };
    _self.setFilters = function(filters) {
      _self.selectedFilters = Session.getSessionValue(
        _self.viewPane,
        _self.viewState
      );
      filters.forEach(function(filter) {
        if (filter.id === 'product.category') {
          _self.setOptionsForFilter(
            filter,
            filter.id,
            _self.forceUpdate === true ||
              (_self.selectedFilters !== null &&
                _self.selectedFilters['product.division'] !== undefined)
          );
        } else if (filter.id === 'product.subcategory') {
          _self.setOptionsForFilter(
            filter,
            filter.id,
            _self.forceUpdate === true ||
              (_self.selectedFilters !== null &&
                _self.selectedFilters['product.category'] !== undefined)
          );
        } else if (filter.id === 'product.carryoverSeasons') {
          _self.setOptionsForFilter(filter, 'seasons', false);
        } else if (filter.id === 'assignees') {
          if (!_self.formData['assignees'].options) {
            UsersService.getUsersByRoles(_self.getRolesForQueue()).then(
              function(response) {
                filter.options = angular.copy(response.data);
                for (var i = 0; i < filter.options.length; i++) {
                  filter.options[i].label = filter.options[i].name;
                  filter.options[i].value = filter.options[i].userName;
                }
                _self.setOptionsForFilter(filter, filter.id, false);
              }
            );
          }
        } else {
          _self.setOptionsForFilter(filter, filter.id, false);
        }
      });
      _self.setSessionFilters();
    };
    _self.setSessionFilters = function() {
      _self.selectedFilters = Session.getSessionValue(
        _self.viewPane,
        _self.viewState
      );
      if (_self.selectedFilters !== null) {
        if (Object.keys(_self.selectedFilters).length > 0) {
          var selectedFilterKeys = Object.keys(_self.selectedFilters);
          var options = [];
          selectedFilterKeys.forEach(function(filterKey) {
            var formDataKey =
              filterKey === 'product.currentSeason' ? 'seasons' : filterKey;
            options.push(filterKey + '=' + _self.selectedFilters[filterKey]);
            _self.formData[formDataKey].disabled = false;
            if (filterKey === 'assignees') {
              UsersService.getUsersByRoles(_self.getRolesForQueue()).then(
                function(res) {
                  if (_self.formData[formDataKey].options) {
                    _self.formData[formDataKey].options.forEach(function(
                      option
                    ) {
                      if (
                        typeof option === 'object' &&
                        option.value === _self.selectedFilters[filterKey]
                      ) {
                        _self.formData[formDataKey].value = option.userName;
                        _self.formData[formDataKey].display = option.name;
                      }
                    });
                  }
                }
              );
            }
            if (_self.formData[formDataKey].options) {
              _self.formData[formDataKey].options.forEach(function(option) {
                if (
                  typeof option === 'object' &&
                  option.value === _self.selectedFilters[filterKey]
                ) {
                  _self.formData[formDataKey].value = option.value;
                }
              });
            }
            // if there are no options, just assume this is a button, aka boolean
            else {
              _self.formData[formDataKey].value =
                _self.selectedFilters[formDataKey];
            }
          });
        }
      }
    };
    _self.$onInit = function() {
      //if we already have the filters on init, set session values (open, select, close, open)
      if (_self.filters) {
        _self.setSessionFilters();
      }
    };
    _self.$onChanges = function(changes) {
      if (changes.filters) {
        _self.working = false;
        if (!_self.filters || _self.filters.length === 0) {
          return;
        }
        _self.filters = angular.copy(_self.filters);
        _self.setFilters(_self.filters);
        _self.forceUpdate = false;
      }
    };
  },
  templateUrl: 'templates/partials/search/filter.compact.tpl.html'
};
module.exports = searchCompactComponent;
