'use strict';

/*jshint unused: false*/
var seasonBoxDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      season: '='
    },
    controllerAs: 'seasonBox',
    controller: /*@ngInject*/ function() {
      var _self = this;
    },
    templateUrl: 'templates/partials/activity/seasonBox.tpl.html',
    link: function(scope, elem, attrs, controller, x) {}
  };
};

module.exports = seasonBoxDirective;
