'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    '$log',
    'KeymageService',
    function keymageDisableDirective($log, KeymageService) {
      return {
        restrict: 'A',
        link: function(scope, elem, attrs, ctrl) {
          elem.on('focus', KeymageService.disable);
          elem.on('blur', KeymageService.enable);

          scope.$on('$destroy', function() {
            elem.off('focus', KeymageService.disable);
            elem.off('blur', KeymageService.enable);
          });
        }
      };
    }
  ];
}

module.exports = register;
