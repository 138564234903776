'use strict';

/*jshint unused: false*/
var checkboxDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      model: '=',
      label: '@',
      disabled: '=',
      onChange: '&'
    },
    controllerAs: '$ctrl',
    controller: /*@ngInject*/ function($attrs) {
      var _self = this;

      _self.type = $attrs.type === undefined ? 'row' : $attrs.type;
    },
    templateUrl: 'templates/partials/ui/checkbox.tpl.html',
    link: function(scope, elem, attrs, ctrl) {
      var keybindFilter = function(event) {
        if (event.which === 32) {
          // space
          event.preventDefault();
          event.stopPropagation();
          elem[0].click();
        }
      };

      elem.on('click', function() {
        elem[0].focus();
      });

      elem.on('keydown', keybindFilter);

      scope.$on('$destroy', function() {
        elem.unbind('click');
        elem.unbind('keydown', keybindFilter);
      });
    }
  };
};

module.exports = checkboxDirective;
