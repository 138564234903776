'use strict';

var angular = require('angular');

var ShippingInfo = /*@ngInject*/ function(
  $q,
  $http,
  $timeout,
  REST_URL,
  $stateParams,
  APP_DATA,
  Session
) {
  var _self = this;

  _self.getJobs = function(ids) {
    var promises = ids.map(function(id) {
      var deferred = $q.defer();

      $http
        .get([REST_URL.jobs, id].join('/'))
        .success(function(data) {
          deferred.resolve(data);
        })
        .error(function() {
          deferred.reject();
        });

      return deferred.promise;
    });
    return $q.all(promises);
  };

  _self.init = function() {
    var ids = $stateParams.ids.split(',');
    _self.getJobs(ids).then(function(data) {
      _self.jobs = data;
      _self.properties = {};
      _self.userName = Session.get('name');
      _self.currentDate = new Date();

      _self.properties.isStored =
        _self.jobs[0].merchJob.status === APP_DATA.MerchJobStatus[6]; //Stored onsite

      if (_self.properties.isStored) {
        _self.properties.storageType =
          _self.jobs[0].merchJob.sample.storage.storageType;
        _self.properties.storageLocation =
          _self.jobs[0].merchJob.sample.storage.storageLocation;
      } else {
        _self.properties.shipmentType =
          _self.jobs[0].merchJob.sample.shipment.shipmentType;
        _self.properties.sender = _self.jobs[0].merchJob.sample.shipment.from;
        _self.properties.fromBuildingCode =
          _self.jobs[0].merchJob.sample.shipment.buildingCodeFrom;
        _self.properties.recipient =
          _self.jobs[0].merchJob.sample.shipment.recipient;
        _self.properties.department =
          _self.jobs[0].merchJob.sample.shipment.departmentTo;
        _self.properties.toBuildingCode =
          _self.jobs[0].merchJob.sample.shipment.buildingCodeTo;
        _self.properties.phoneExt =
          _self.jobs[0].merchJob.sample.shipment.internalPhoneExtTo;
        _self.properties.shipDate =
          _self.jobs[0].merchJob.sample.shipment.shipmentDate;
        _self.properties.shipNumber =
          _self.jobs[0].merchJob.sample.shipment.internalShippingNumber;
      }
      $timeout(function() {
        window.print();
        $timeout(function() {
          window.close();
        }, 500);
      }, 1000);
    });
  };

  _self.hideHeader = function() {
    var header = document.getElementsByClassName('gbip-header'),
      wrappedHeader = angular.element(header);
    wrappedHeader.remove();
  };

  _self.hideHeader();

  _self.init();
};

module.exports = ShippingInfo;
