'use strict';

var angular = require('angular');

var productDeliveryComponent = {
  controllerAs: 'deliveries',
  bindings: {
    styleColor: '<',
    count: '=',
    productId: '<'
  },
  controller: /*@ngInject*/ function(
    DeliveryJobService,
    DeliverService,
    $state,
    $timeout,
    $window
  ) {
    var _self = this;
    _self.loading = false;
    _self.data = {
      identifier: '',
      deliveryJobs: [],
      failedMode: false,
      currentPage: 0,
    };

    _self.updateDeliveries = function(getFailed, page) {
      _self.loading = true;
      if (getFailed || _self.data.failedMode) {
        _self.data.failedMode = true;
        DeliveryJobService.getFailedDeliveries(page)
          .then(processResponse)
          .catch(function(error) {
            //do something about the failed call!
          });
      } else {
        _self.data.failedMode = false;
        DeliveryJobService.getDeliveryJobsByStyleColor(
          _self.data.identifier,
          page
        )
          .then(processResponse)
          .catch(function(error) {
            _self.count = 1;
            _self.data.error =
              'There was an error retrieving data. Please refresh the page to try again. If this problem persists, please contact support.';
          });
      }
    };

    var processResponse = function(response) {
      _self.data.deliveryJobs = response.deliveryJobs;
      _self.data.error = undefined;
      _self.count = _self.data.deliveryJobs.length;
      _self.data.pages = response.totalPages;
      _self.data.currentPage = response.currentPage;
      _self.data.wideInitiatedByColumn =
        _self.data.deliveryJobs.filter(
          dj => dj.requestType.startsWith('Job') && dj.requestType !== 'Job'
        ).length >= 1;
      _self.loading = false;
    };

    _self.previousPage = function() {
      _self.updateDeliveries(undefined, _self.data.currentPage - 1);
    };

    _self.nextPage = function() {
      _self.updateDeliveries(undefined, _self.data.currentPage + 1);
    };

    _self.expand = function(deliveryJob) {
      if (!_self.data.failedMode) {
        if (deliveryJob.expanded) {
          deliveryJob.expanded = false;
        } else {
          deliveryJob.expanded = true;
          _self.closeToolTip(deliveryJob);
        }
        _self.enrichDeliveries(deliveryJob);
      }
    };

    _self.enrichDeliveries = function(deliveryJob) {
      if (
        !deliveryJob.enriching &&
        !deliveryJob.enriched &&
        deliveryJob.deliveries.length > 0
      ) {
        var arrIds = [];
        deliveryJob.goodAssetCount = 0;
        deliveryJob.enriching = true;
        deliveryJob.deliveries.forEach(delivery => {
          if (!!delivery.version) {
            arrIds.push(delivery.assetNodeId + ':' + delivery.version);
          } else {
            arrIds.push(delivery.assetNodeId);
          }
        });

        DeliveryJobService.getDeliveryImageDetails(
          deliveryJob.styleColor,
          arrIds
        )
          .then(function(response) {
            deliveryJob.viewCodes = [];
            deliveryJob.deliveries.forEach((delivery, index) => {
              var imageDetail = response.objects.filter(
                object => object.id === delivery.assetNodeId && object.viewCode
              );
              if (imageDetail[0]) {
                deliveryJob.goodAssetCount += 1;
                var id = angular.copy(delivery.id);
                delivery = Object.assign(delivery, imageDetail[0]);
                delivery.id = id;
                if (
                  deliveryJob.viewCodes.filter(
                    viewCode => viewCode == delivery.viewCode
                  ).length == 0
                ) {
                  deliveryJob.viewCodes.push(delivery.viewCode);
                }
              }
              if (imageDetail[1]) {
                delivery.cropType = imageDetail[0].cropType + ' - 2 Files';
                if (delivery.fileName.includes('PREM')) {
                  delivery.viewCode = '99ZZXXYY';
                } else {
                  var fileNames = '';
                  var files = deliveryJob.deliveries.filter(
                    object => object.assetNodeId == delivery.assetNodeId
                  );
                  files.forEach(file => {
                    fileNames += file.fileName + ', ';
                  });
                  delivery.fileName = fileNames.substr(0, fileNames.length - 2);
                }
              }
            });

            deliveryJob.deletedAssetCount = arrIds.length - deliveryJob.goodAssetCount;

            deliveryJob.viewCodes.sort();
            deliveryJob.deliveries.sort(function(a, b) {
              if(!a.hasOwnProperty('cropType') && b.hasOwnProperty('cropType')) {
                return -1;
              }
              if (a.hasOwnProperty('cropType') && !b.hasOwnProperty('cropType')) {
                return 1;
              }
              if (!a.hasOwnProperty('cropType') && !b.hasOwnProperty('cropType')) {
                return 0;
              }
              var nameA = a.cropType.toLowerCase(),
                nameB = b.cropType.toLowerCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });

            deliveryJob.enriching = false;
            deliveryJob.enriched = true;
          })
          .catch(function(error) {
            deliveryJob.enriching = false;
            deliveryJob.enrichError = true;
          });
      }
    };

    _self.showJob = function(delivery) {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: _self.productId,
        jobId: delivery.productionJobId,
        tab: null
      });
      $window.open(url, '_blank');
    };

    _self.showSourceJob = function(deliveryJob) {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: deliveryJob.productId,
        jobId: deliveryJob.sourceMetaJobId,
        tab: null
      });
      $window.open(url, '_blank');
    };

    _self.showProduct = function(deliveryJob) {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: deliveryJob.productId,
        jobId: null,
        tab: 'deliveries'
      });
      $window.open(url, '_blank');
    };

    _self.showDetails = function(delivery, deliveryJob) {
      _self.closeToolTip(deliveryJob);
      $timeout(function() {
        delivery.expanded = true;
      }, 1);
    };

    _self.closeToolTip = function(deliveryJob) {
      deliveryJob.deliveries.forEach(element => {
        element.expanded = false;
      });
    };

    _self.complete = function(delivery) {
      DeliveryJobService.completeDelivery(delivery.id)
        .then(function() {
          delivery.status = 'Published';
        })
        .catch(function() {
          delivery.status = 'Complete Attempt Failed';
        });
    };

    _self.retry = function(delivery) {
      DeliveryJobService.retryDeliveries([delivery.id])
        .then(function() {
          delivery.status = 'Retry Requested';
        })
        .catch(function() {
          delivery.status = 'Retry Attempt Failed';
        });
    };

    _self.retryAll = function() {
      var retries = _self.data.deliveryJobs.map(delivery => delivery.id);
      DeliveryJobService.retryDeliveries(retries)
        .then(function() {
          _self.data.deliveryJobs.forEach(delivery => {
            delivery.status = 'Retry Requested';
          });
        })
        .catch(function() {
          _self.data.deliveryJobs.forEach(delivery => {
            delivery.status = 'Retry Attempt Failed';
          });
        });
    };

    _self.redeliver = function(delivery) {
      DeliverService.createDelivery(delivery);
    };

    _self.$onChanges = function(changes) {
      if (
        changes.styleColor &&
        _self.styleColor &&
        _self.styleColor !== _self.data.identifier
      ) {
        _self.data.identifier = angular.copy(_self.styleColor);
        _self.updateDeliveries();
      } else {
        _self.updateDeliveries(true);
      }
    };
  },
  templateUrl: 'templates/partials/detail/productDelivery.tpl.html'
};

module.exports = productDeliveryComponent;
