'use strict';

var MERCH_STATUS = {
  inOps: 'In Ops',
  readyForStyling: 'Ready for Styling',
  inStyling: 'In Styling',
  inPhotography: 'In Photography',
  prepForOutbound: 'Prep for Outbound',
  completed: 'Completed'
}

MERCH_STATUS.allStatuses = [
  MERCH_STATUS.inOps,
  MERCH_STATUS.readyForStyling,
  MERCH_STATUS.inStyling,
  MERCH_STATUS.inPhotography,
  MERCH_STATUS.prepForOutbound,
  MERCH_STATUS.completed,
];

module.exports = MERCH_STATUS;
