'use strict';

var JOB_TYPE = {
  standard: 'Standard',
  quickStandard: 'Quick Standard',
  variation: 'Variation',
  copy: 'Copy',
  copyWithEdits: 'Copy With Edits',
  takeUp: 'Take Up',
  takeDown: 'Take Down',
  reshoot: 'Reshoot',
  rework: 'Rework',
  duplicate: 'Duplicate',
  externalDelivery: 'External Delivery',
  redelivery: 'Redelivery'
}

JOB_TYPE.all = [
  JOB_TYPE.standard,
  JOB_TYPE.quickStandard,
  JOB_TYPE.variation,
  JOB_TYPE.copy,
  JOB_TYPE.copyWithEdits,
  JOB_TYPE.takeUp,
  JOB_TYPE.takeDown,
  JOB_TYPE.reshoot,
  JOB_TYPE.rework,
  JOB_TYPE.duplicate,
  JOB_TYPE.externalDelivery,
  JOB_TYPE.redelivery,
];

JOB_TYPE.assetOnly = [
  JOB_TYPE.quickStandard,
  JOB_TYPE.variation,
  JOB_TYPE.copy,
  JOB_TYPE.copyWithEdits,
  JOB_TYPE.takeUp,
  JOB_TYPE.takeDown,
  JOB_TYPE.rework,
  JOB_TYPE.externalDelivery,
  JOB_TYPE.redelivery,
];

JOB_TYPE.requiresCompletedJob = [
  JOB_TYPE.reshoot,
  JOB_TYPE.rework
];

JOB_TYPE.icons = {
  [JOB_TYPE.standard]: 'standard-job',
  [JOB_TYPE.quickStandard]: 'quick-standard-job',
  [JOB_TYPE.variation]: 'variation-job',
  [JOB_TYPE.copy]: 'copy-job',
  [JOB_TYPE.copyWithEdits]: 'copy-with-edits-job',
  [JOB_TYPE.takeUp]: 'take-up-job',
  [JOB_TYPE.takeDown]: 'take-down-job',
  [JOB_TYPE.reshoot]: 'reshoot-job',
  [JOB_TYPE.rework]: 'rework-job',
  [JOB_TYPE.duplicate]: 'duplicate-job',
  [JOB_TYPE.externalDelivery]: 'external-delivery-job',
  [JOB_TYPE.redelivery]: 'redelivery-job',
};

JOB_TYPE.allowsEditPrimaryMerchId = [
  JOB_TYPE.standard,
  JOB_TYPE.quickStandard,
  JOB_TYPE.reshoot,
];

module.exports = JOB_TYPE;
