'use strict';

var angular = require('angular');

/*jshint unused: false*/
var TypeaheadComponent = {
  bindings: {
    model: '=', // data
    options: '<', // list of options to display
    optionKey: '@',
    label: '<',
    onSelection: '&',
    isOpen: '=?',
    disabled: '<',
    orderBy: '<',
    required: '<',
    activateKeyPress: '<'
  },
  controllerAs: '$ctrl',
  controller: /*@ngInject*/ function($scope, regexFilter) {
    var _self = this;

    var getRegex = function() {
      return ['.*?', _self.model, '.*?'].join('');
    };

    _self.selectOption = function(option) {
      _self.model = option[_self.optionKey] || option;
      if(_self.options && _self.options.includes(option)) {
        _self.lastModel = _self.model;
      }
      _self.onSelection({ option: option });
    };

    _self.onKeyDown = function($event) {
      if(!_self.activateKeyPress) {
        return;
      }
      if (event.which === 13) {
        _self.selectOption(event.target.value);
        _self.blur();
      }
    };

    _self.setDisplayOptions = function(options) {
      if (_self.optionKey) {
        _self.displayOptions = options.filter(function(option) {
          var patt = new RegExp(getRegex(), 'i');

          if (patt.test(option[_self.optionKey])) {
            return option;
          }
        });
      } else {
        _self.displayOptions = regexFilter(options, getRegex(), 'i');
      }

      _self.isOpen = _self.hasFocus;
    };

    _self.open = function() {
      document.getElementsByClassName('typeahead-input')[0].focus();
      _self.displayOptions = _self.options;
      _self.isOpen = true;
      _self.hasFocus = true;
      if (_self.model) {
        _self.lastModel = _self.model;
      }
      _self.model = '';
    };

    _self.blur = function() {
      //This timeout allows .blur() to run before .selectOption()
      window.setTimeout(function() {
        //This ensures that the input isn't submitted with text that isn't an option
        if (_self.lastModel && (!_self.options || !_self.options.includes(_self.model))) {
          _self.model = _self.lastModel;
        }
        _self.hasFocus = false;
        _self.isOpen = false;
      }, 0);
    };

    _self.focus = function() {
      _self.hasFocus = true;
      if (_self.model) {
        _self.lastModel = _self.model;
      }
    };

    _self.$onChanges = function(changes) {
      if (changes.label) {
        if (_self.required !== undefined) {
          _self.labelDisplay = _self.label + '*';
        } else {
          _self.labelDisplay = _self.label;
        }
      }

      if (changes.options) {
        if (!_self.options) {
          return;
        }
        _self.options = angular.copy(_self.options);
        _self.setDisplayOptions(_self.options);
      }
    };
  },
  templateUrl: 'templates/partials/ui/typeahead.tpl.html',
  link: function(scope, elem, attrs, ctrl) {}
};

module.exports = TypeaheadComponent;
