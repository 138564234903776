'use strict';

var ReportsService = /*@ngInject*/ function(
  $http,
  $q,
  $filter,
  REST_URL,
  ACTIVITY
) {
  var _self = this;

  var updateSeasonSummaryReports = function() {
    var seasonSummary = {};
    var seasonParts;
    var seasonReport = {};

    var seasonSortOrder = {
      sp: 0,
      su: 1,
      fa: 2,
      ho: 3
    };

    var seasonSortMethod = function(a, b) {
      if (a.year === b.year) {
        // sort by season
        return seasonSortOrder[a.season] - seasonSortOrder[b.season];
      }
      // sort by year
      return a.year - b.year;
    };

    for (var i = 0; i < _self.data.seasonSummaryReports.length; i++) {
      seasonSummary = _self.data.seasonSummaryReports[i];

      seasonSummary.startDate = $filter('date')(
        seasonSummary.startDate,
        'shortDate'
      );

      seasonParts = seasonSummary.seasonCode.match(/^(\D+)(\d+)/);
      seasonSummary.season = seasonParts[1].toLowerCase();
      seasonSummary.year = parseInt(seasonParts[2], 10);

      seasonSummary.currentProductCount = seasonSummary.previousProductCount;

      // current, new, and dropped counts
      for (
        var j = 0;
        j < _self.data.seasonSummaryReports[i].reports.length;
        j++
      ) {
        seasonReport = seasonSummary.reports[j];
        seasonSummary[seasonReport.action.toLowerCase() + 'Count'] =
          seasonReport.count;

        if (seasonReport.action === ACTIVITY.seasonReportActions.add) {
          seasonSummary.currentProductCount += seasonReport.count;
        } else if (seasonReport.action === ACTIVITY.seasonReportActions.drop) {
          seasonSummary.currentProductCount -= seasonReport.count;
        }
      }

      // count difference
      seasonSummary.countDifference =
        seasonSummary.addCount - seasonSummary.dropCount;
      if (seasonSummary.countDifference > 0) {
        seasonSummary.countDifference = '+' + seasonSummary.countDifference;
      }
      if (seasonSummary.currentProductCount < 0) {
        seasonSummary.currentProductCount = 0;
      }

      seasonParts = seasonSummary.seasonCode.match(/^(\D+)(\d+)/);
      seasonSummary.season = seasonParts[1].toLowerCase();
      seasonSummary.year = parseInt(seasonParts[2], 10);
    }

    // sort by year and season
    _self.data.seasonSummaryReports.sort(seasonSortMethod);
  };

  var updateSyncActionDetailedReports = function() {
    for (var i = 0; i < _self.data.syncActionDetailedReports.length; i++) {
      switch (_self.data.syncActionDetailedReports[i].action) {
        case ACTIVITY.actions.duplicate:
          _self.data.syncActionDetailedReports.duplicate =
            _self.data.syncActionDetailedReports[i];
          break;
        case ACTIVITY.actions.reactivate:
          _self.data.syncActionDetailedReports.reactivate =
            _self.data.syncActionDetailedReports[i];
          break;
        case ACTIVITY.actions.drop:
          _self.data.syncActionDetailedReports.drop =
            _self.data.syncActionDetailedReports[i];
          break;
        default:
          break;
      }
    }
  };

  _self.working = false;

  _self.data = {
    seasonSummaryReports: {},
    syncActionDetailedReports: {
      duplicate: {},
      reactivate: {},
      drop: {}
    },
    lastSuccessfulSync: null,
    lastSyncHadError: false
  };

  _self.getReports = function() {
    var deferred = $q.defer();

    _self.working = true;

    $http
      .get(REST_URL.reports)
      .then(function(response) {
        _self.data = response.data;

        _self.data.lastSuccessfulSync = $filter('date')(
          new Date(_self.data.lastSuccessfulSync),
          'MM/dd/yyyy HH:mm a Z'
        );

        updateSeasonSummaryReports();
        updateSyncActionDetailedReports();

        _self.working = false;
        deferred.resolve();
      })
      .catch(function() {
        deferred.reject();
      });

    return deferred.promise;
  };

  return _self;
};

module.exports = ReportsService;
