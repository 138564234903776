'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function accordionDirective() {
      return {
        controller: 'accordionController',
        link: function() {}
      };
    }
  ];
}

module.exports = register;
