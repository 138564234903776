'use strict';

var PRIORITY_CROP_TYPE_SIZES = {
  ONE_BY_ONE: {
    width: 3144,
    height: 3144
  },
  FOUR_BY_FIVE: {
    width: 2880,
    height: 3600
  }
};

module.exports = PRIORITY_CROP_TYPE_SIZES;
