'use strict';

var IScroll = require('iscroll');

/*jshint unused: false*/
function register(angular) {
  return [
    'iScrollService',
    '$timeout',
    function angularIScroll(iScrollService, $timeout) {
      function link(scope, elem, attrs) {
        var iscrollScope = scope.iscrollScope || scope.$parent;
        var container = elem[0];
        var name = scope.$eval(attrs.iscroll);
        var iscrollObj;

        container.classList.add('iscroll', 'iscroll--no-scroll');
        container.firstChild.classList.add('iscroll__wrapper');

        if (scope.iscrollParams.scrollX) {
          container.classList.add('iscroll--x');
        }
        if (scope.iscrollParams.scrollY) {
          container.classList.add('iscroll--y');
        }
        if (scope.iscrollParams.scrollY) {
          container.classList.add('iscroll--scroll-bars');
        }

        iscrollObj = new IScroll(container, scope.iscrollParams);
        iScrollService.add(iscrollScope, name, iscrollObj);

        $timeout(function() {
          iscrollObj.refresh();
        }, 0);

        iscrollObj.on('refresh', function() {
          var boolean = false;
          if (scope.iscrollParams.scrollX && iscrollObj.maxScrollX === 0) {
            boolean = true;
          } else if (
            scope.iscrollParams.scrollY &&
            iscrollObj.maxScrollY === 0
          ) {
            boolean = true;
          }

          container.classList.toggle('iscroll--no-scroll', boolean);
        });

        scope.$on('$destroy', function() {
          iScrollService.remove(iscrollScope, name);
        });
      }

      return {
        restrict: 'A',
        scope: {
          iscrollScope: '=',
          iscrollParams: '='
        },
        link: link
      };
    }
  ];
}

module.exports = register;
