'use strict';

/*jshint unused: false*/
var viewOptionsComponent = {
  restrict: 'E',
  replace: true,
  bindings: {
    view: '=',
    viewState: '=',
    viewPane: '@'
  },
  controller: /*@ngInject*/ function(Session) {
    var _self = this;

    _self.setView = function(newView) {
      _self.view = newView;
      Session.setSessionValue(_self.viewPane, _self.view, _self.viewState);
    };
  },
  templateUrl: 'templates/partials/ui/viewOptions.tpl.html'
};

module.exports = viewOptionsComponent;
