'use strict';

var angular = require('angular');
var union = require('../vendor/union');

var photographyDetailComponent = {
  bindings: {
    model: '<'
  },
  require: {
    parent: '^^jobDetail'
  },
  controller: /*@ngInject*/ function(
    AuthService,
    TransferService,
    NotificationService,
    USER_ROLES,
    APP_DATA,
    JobService
  ) {
    var _self = this;
    _self.error = {};
    _self.downloadApproach;
    _self.showSecondaryCropTypes = true;
    _self.downloadData = {
      imageTypes: [],
      cropTypes: [],
      assetCount: 0,
      params: {
        cta: {
          primary: 'Download',
          secondary: 'Cancel'
        }
      },
      error: {},
      isValid: true,
      working: false,
      onFilter: function() {
        _self.downloadData.buildFilters = true;
        _self.downloadData.updateForm();
      },
      buildFilters: false,
      onSubmit: function() {
        _self.downloadData.working = true;
        if(_self.downloadApproach === "desktop") {
          let getSelected = (varName) => { return _self.downloadData[varName].filter(c => c.selected).map(c => c.value); };
          let selectedCropTypes = getSelected("cropTypes");
          let selectedImageTypes = getSelected("imageTypes");
          const payload = [{
            metaJobId: _self.model.job.id,
            includeImageTypes: selectedImageTypes || [],
            includeCropTypes: selectedCropTypes || [],
          }];

          TransferService.sendDownloadRequest(payload)
          .then(function(response) {
            _self.downloadData.buildFilters = false;
            NotificationService.push('Assets have been sent for download.');
            _self.downloadData.resetForm();
          })
          .catch(function(response) {
            _self.error = {
              message: 'An error occurred while processing the download.'
            };
          })
          .finally(function() {
            _self.downloadData.working = false;
          });
        } else {
          var files = _self.model.job.product.assets.filter(
            _self.downloadData.filterFileNames
          );
          var filenames = files.map(function(file) {
            return file.fileName;
          });
          _self.downloadData.buildFilters = false;

          TransferService.downloadAssets(filenames).finally(function() {
            _self.downloadData.working = false;
            _self.downloadData.resetForm();
          });
        }
      },
      resetForm: function() {
        _self.downloadData.showCropTypes = false;
        _self.downloadApproach = "browser";
        _self.error = {};
      },
      updateForm: function() {
        _self.downloadData.assetCount = 0;
        var imageTypes = {
          D: 'Deliverable',
          W: 'Worker',
          U: 'Unedited'
        };

        _self.downloadData.imageTypes = [];
        _self.downloadData.cropTypes = [];

        console.log(_self.model);
        _self.model.job.product.assets.forEach(function(asset) {
          if (asset.fileName) {
            var imageTypeCode = asset.fileName.split('_')[2];
            var imageType = imageTypes[imageTypeCode];

            if (imageType) {
              var filterLength = _self.downloadData.imageTypes.filter(function(
                element
              ) {
                return element.value === imageType;
              }).length;
              // Don't show Unedited for archived meta jobs
              const shouldHideImageType = !!_self.model.job.jobArchive && "U" === imageTypeCode;
              if (filterLength === 0 && !shouldHideImageType) {
                _self.downloadData.imageTypes.push({
                  value: imageType,
                  selected: false,
                  code: imageTypeCode
                });
              }
            }
            if (!!asset.cropType) {
              var filterLength = _self.downloadData.cropTypes.filter(function(element) { return element.value === asset.cropType; }).length;
              if (filterLength === 0) {
                _self.downloadData.cropTypes.push({
                  value: asset.cropType,
                  selected: true
                });
              }
            }
          }
        });
      },
      filterFileNames: function(asset) {
        var fileName = asset.fileName;
        var cropTypes = _self.downloadData.cropTypes.reduce((acc, cropType) => {
          if (cropType.selected) {
            acc.push(cropType.value);
          }
          return acc;
        }, []);
        var imageTypes = _self.downloadData.imageTypes.reduce(
          (acc, imageType) => {
            if (imageType.selected) {
              acc.push(imageType.code);
            }
            return acc;
          },
          []
        );

        var retValue = false;
        var imageTypeCode = fileName.split('_')[2];
        if (imageTypeCode === 'D' && imageTypes.includes('D')) {
          if (cropTypes.includes(asset.cropType)) {
            retValue = true;
          }
        } else {
          if (imageTypes.includes(imageTypeCode)) {
            retValue = true;
          }
        }

        return retValue;
      },
      updateCount: function(option) {
        option.selected = !option.selected;
        if (option.value === 'Deliverable') {
          _self.downloadData.showCropTypes = option.selected;
        }
        _self.downloadData.assetCount = _self.model.job.product.assets.filter(
          _self.downloadData.filterFileNames
        ).length;
      }
    };

    _self.setGalleryViewModel = function(job, showExpectedViews) {
      var viewModel = angular.copy(job);

      viewModel.assetJobs = viewModel.assetJobs.filter(function(assetJob) {
        if (_self.model.job.jobType === 'External Delivery') {
          //treat all external delivery assests as expected.
          return showExpectedViews;
        }
        return (
          job.expectedViews.indexOf(assetJob.viewCode) >= 0 ===
          showExpectedViews
        );
      });

      return viewModel;
    };

    _self.$onInit = function() {
      _self.downloadData.authorized = AuthService.isAuthorized([
        USER_ROLES.studioOps,
        USER_ROLES.digiTech,
        USER_ROLES.imageEditor,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.superUser,
        USER_ROLES.qaReviewer,
        USER_ROLES.photographer
      ]);
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model || !_self.model.job) {
          return;
        }

        _self.model = angular.copy(_self.model);
        _self.model.expectedViewModel = _self.setGalleryViewModel(
          _self.model.job,
          true
        );
        _self.model.extraViewModel = _self.setGalleryViewModel(
          _self.model.job,
          false
        );
      }
    };
  },
  templateUrl: 'templates/partials/detail/photographyDetail.tpl.html'
};

module.exports = photographyDetailComponent;
