'use strict';

var angular = require('angular');
var moment = require('moment-timezone');

var DropdownComponent = {
  bindings: {
    model: '=?', // data
    options: '<', // list of options to display when type is default ('dropdown')
    optionKey: '@?', // key to use if options is an object
    label: '<',
    onSelection: '&', // callback when an option is selected
    type: '@', // 'datepicker' or default
    viewType: '@', // styling modifier
    isOpen: '=',
    disabled: '<'
  },
  controllerAs: 'dropdown',
  controller: /*@ngInject*/ function($attrs, $filter, $timeout) {
    var _self = this;

    _self.selectOption = function(option) {
      if (option !== undefined) {
        _self.model = option[_self.optionKey] || option;
      }

      $timeout(function() {
        _self.onSelection({
          option: angular.copy(option) || angular.copy(_self.model)
        });
      }, 0);
    };

    _self.updateTimezoneOffset = function() {
      _self.timezoneOffset = moment
        .tz(_self.model, _self.timezone)
        .format('Z')
        .replace(':', '');
    };

    _self.setModifierClasses = function() {
      var prefix = 'gbip-dropdown--';

      _self.modifierClasses = [];

      if (_self.viewType) {
        _self.modifierClasses.push(prefix + _self.viewType);
      }

      if (_self.disabled) {
        _self.modifierClasses.push(prefix + 'disabled');
      }
    };

    _self.init = function() {
      if (_self.type === 'datepicker') {
        _self.timezones = moment.tz.names();

        for (var i = 0; i < _self.timezones.length; i++) {
          if (_self.timezones[i] === moment.tz.guess()) {
            _self.timezone = _self.timezones[i];
            _self.updateTimezoneOffset();
            break;
          }
        }
      }

      _self.viewType = _self.viewType || 'compact';
    };

    _self.$onChanges = function(changes) {
      if (changes.label) {
        if ($attrs.required !== undefined) {
          _self.labelDisplay = _self.label + '*';
        } else {
          _self.labelDisplay = _self.label;
        }
      }

      if (changes.options) {
        _self.options = angular.copy(_self.options);
      }

      if (changes.viewType || changes.disabled) {
        _self.setModifierClasses();
      }
    };

    _self.init();
  },
  templateUrl: 'templates/partials/ui/dropdown.tpl.html'
};

module.exports = DropdownComponent;
