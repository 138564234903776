'use strict';

var moment = require('moment-timezone');
var angular = require('angular');

var JobController = /*@ngInject*/ function(
  $scope,
  $attrs,
  $window,
  $state,
  $element,
  AuthService,
  META_STATUS,
  Session,
  DownloadStatusService,
  JobService,
  APP_DATA
) {
  var _self = this;

  _self.setDownloadIconVisibility = function() {
    if (_self.showDownload) {
      if (!_self.data.assetJobs || _self.data.assetJobs.length === 0) {
        _self.showDownloadIcon = false;
        return;
      }

      if (_self.showDownload === 'always') {
        _self.showDownloadIcon = true;
      } else {
        var isAssigned = Session.compareUser(_self.assignee),
          hasDownloaded = DownloadStatusService.readCookie(
            _self.data.product.styleColor
          );

        _self.showDownloadIcon = isAssigned && !hasDownloaded;
      }
    }
  };

  _self.convertReadableDate = date => {
    if (date) {
      return moment(date).format('MM/DD/YY h:mma');
    } else {
      return '';
    }
  };

  _self.showReviewStyling = function(job) {
    if (job && job.notes) {
      return (
        job.status === META_STATUS.qa &&
        job.notes.filter(function(a) {
          return a.noteType === 'Product Review';
        }).length > 0
      );
    } else {
      return false;
    }
  };

  _self.setAssignee = function() {
    _self.assignee = JobService.getAssignedUser(_self.data, 'meta');

    if (!_self.assignee) {
      _self.assignee =
        ['queue.post-prod', 'queue.qa'].indexOf($state.current.name) !== -1 &&
        $attrs.view === 'tray.data.viewType'
          ? _self.assignee
          : JobService.getAssignedUser(_self.data, 'assetJobs');
    }
  };

  _self.selectJob = function(boolean) {
    var job = _self.data;

    if (_self.disableSelection) {
      return;
    }
    if (!angular.isObject(job)) {
      return;
    }

    job.selected = boolean !== undefined ? boolean : !job.selected;
    if (job.assetJobs) {
      job.assetJobs.forEach(function(assetItem) {
        assetItem.selected = job.selected;
      });
    }
  };

  _self.viewDetail = function(e, assetJobId) {
    if (_self.dblClick) {
      _self.dblClick();
    } else {
      var detailState = $attrs.detailState || 'detail',
        routeParams = (function() {
          if (detailState === 'detail') {
            return {
              productIdentifierType: 'by-product-id',
              productIdentifier: _self.data.product.productId,
              jobId: _self.data.id
            };
          } else if (detailState === 'qa') {
            return { id: _self.data.id };
          }
        })();

      routeParams.assetJobId = assetJobId;

      if (e.metaKey) {
        $window.open(
          $state.href(detailState, routeParams, { absolute: true }),
          '_blank'
        );
      } else {
        $state.go(detailState, routeParams);
      }
    }
  };

  _self.onDownload = function() {
    _self.setDownloadIconVisibility();
  };

  _self.toggleCropTypes = function() {
    _self.showSecondaryCropTypes = !_self.showSecondaryCropTypes;
  };

  _self.setJobData = function() {
    _self.greyedOut =
      _self.data.status === META_STATUS.completed || !!_self.greyCondition;

    _self.setAssignee();

    if (_self.disableSelection) {
      _self.selectJob(false);
    }
  };

  _self.$onInit = function() {
    _self.isAdmin = AuthService.isAdmin();

    _self.disableEvents =
      $attrs.disableEvents !== undefined && $attrs.disableEvents !== 'false';

    if (_self.data) {
      _self.isConfidential =
        _self.data.confidential ||
        ($state.current.name === 'queue.inbound' &&
          !_self.data.status &&
          _self.data.product.confidential);
    }

    if (!_self.disableEvents) {
      $element.on('dblclick', _self.viewDetail);
    }

    _self.showSecondaryCropTypes = false;

    $element.on('click', function() {
      $scope.$apply(function() {
        _self.selectJob();
      });
    });
  };

  _self.$onChanges = function(changes) {
    if (changes.data) {
      if (!_self.data) {
        return;
      }
      _self.setJobData();
    }

    if (changes.showDownload) {
      _self.setDownloadIconVisibility();
    }
  };

  _self.$onDestroy = function() {
    $element.off('click dblclick');
  };
};

module.exports = JobController;
