'use strict';

var ProblemSolveIndicator = /*@ngInject*/ function(
  SearchService,
  META_STATUS,
  APP_DATA
) {
  var data = {
    lastUpdated: new Date(),
    count: 0
  };

  var setCount = function(jobs) {
    var count = 0;

    if (jobs) {
      jobs.forEach(function(job) {
        job.tasks.forEach(function(metaTask) {
          if (
            metaTask.name === META_STATUS.problemSolve &&
            !metaTask.assignee
          ) {
            count++;
          }
        });
      });

      data.lastUpdated = new Date();
      data.count = count;
    }
  };

  var update = function() {
    SearchService.search(
      {
        filters: ['status', META_STATUS.problemSolve].join('=')
      },
      'jobs'
    ).then(function(response) {
      setCount(response.data.results);
    });
  };

  return {
    data: data,
    update: update,
    setCount: setCount
  };
};

module.exports = ProblemSolveIndicator;
