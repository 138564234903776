'use strict';

var angular = require('angular');

var baseProductSearchComponent = {
  bindings: {
    term: '=',
    job: '<',
    onChange: '&',
    readonly: '<',
    jobType: '<'
  },
  controller: function(JobTypeService, META_STATUS, APP_DATA) {
    var _self = this;

    _self.filter = '!status=Pending Drop|Dropped';
    _self.visibleCount = 3;
    _self.baseProductItemWidth = -126;
    _self.jobTypes = JobTypeService.getTypes();
    _self.previousSearchTerm = undefined;
    _self.currentBaseProductIndex = 0;

    _self.reset = function() {
      _self.previousSearchTerm = _self.term;
    };

    _self.doSearch = function(term, filter) {
      // If term is the same as previous search, do not search again
      if (term === _self.previousSearchTerm) {
        return;
      }
      _self.term = term;
      _self.reset();
    };

    _self.$onInit = function() {};

    _self.$onChanges = function(changes) {
      if (changes.term) {
        _self.term = angular.copy(_self.term);

        if (changes.term.isFirstChange()) {
          var response = _self.doSearch(_self.term, _self.filter);
          response
            .then(function(response) {
              if (response.data.results) {
                _self.otherWork = response.data.results.filter(function(job) {
                  // Only return existing job if it is not in job pending (otherwise you could choose yourself as your base)
                  // Never return Duplicate jobs
                  // Never return Redelivery jobs if parent job is a Redelivery
                  // Always return Standard jobs
                  // Return Sub-Jobs jobs if they are not in "Job Pending"
                  return (
                    (_self.job.id !== job.id ||
                      (_self.job.id === job.id &&
                        _self.job.status !== META_STATUS.jobPending)) &&
                    job.jobType !== 'Duplicate' &&
                    (_self.job.jobType !== 'Redelivery' ||
                      job.jobType !== 'Redelivery') &&
                    (job.jobType === 'Standard' ||
                      job.status !== META_STATUS.jobPending)
                  );
                });
              }
            })
            .finally(function() {
              _self.working = false;
            });
        } else {
          _self
            .doSearch(_self.term, _self.filter)
            .then(function(response) {
              if (response.data.results) {
                _self.otherSearch = response.data.results.filter(function(job) {
                  // Only return existing job if it is not in job pending (otherwise you could choose yourself as your base)
                  // Never return Duplicate jobs
                  // Never return Redelivery jobs if parent job is a Redelivery
                  // Always return Standard jobs
                  // Return Sub-Jobs jobs if they are not in "Job Pending"
                  return (
                    (_self.job.id !== job.id ||
                      (_self.job.id === job.id &&
                        _self.job.status !== META_STATUS.jobPending)) &&
                    job.jobType !== 'Duplicate' &&
                    (_self.job.jobType !== 'Redelivery' ||
                      job.jobType !== 'Redelivery') &&
                    (job.jobType === 'Standard' ||
                      job.status !== META_STATUS.jobPending)
                  );
                });
              }
            })
            .finally(function() {
              _self.working = false;
              _self.nextEnabled =
                _self.currentBaseProductIndex <
                _self.otherSearch.length - _self.visibleCount;
              // If we're editing a job and it has a base product, attempt to select it in the results
              _self.setBaseProduct(_self.existingBase);
            });
        }
      }

      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.job = angular.copy(_self.job);
        _self.existingBase = {
          id: _self.job.createdFrom,
          selected: !!_self.job.createdFrom
        };
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/baseProductSearch.tpl.html'
};

module.exports = baseProductSearchComponent;
