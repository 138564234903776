'use strict';

/*jshint unused: false*/
function register(angular) {
  return function notificationProvider() {
    var api = {};

    var defaultOptions = {
      globalAccess: true,
      baseClass: 'notification',
      classIn: 'fadeIn',
      classOut: 'fadeOut',
      pauseOnHover: true,
      delay: 3000
    };

    var globalOptions = {};

    api.options = function(options) {
      return angular.extend({}, defaultOptions, globalOptions, options);
    };

    api.container = function() {
      return '<div class="' + api.options().baseClass + '-collection"></div>';
    };

    api.template = function() {
      return (
        '<div class="' +
        api.options().baseClass +
        '"><p class="' +
        api.options().baseClass +
        '__message" ng-bind-html="message"></p><button class="' +
        api.options().baseClass +
        '__close"><gbip-icon id="x"></gbip-icon></button></div>'
      );
    };

    this.setOptions = function(value) {
      angular.extend(globalOptions, value);
    };

    this.$get = function() {
      return api;
    };
  };
}

module.exports = register;
