'use strict';

var oktaService = require('../services/okta.service.js');

var RouteChange = /*@ngInject*/ function(
  $rootScope,
  AUTH_EVENTS,
  AuthService,
  $timeout,
  $state,
  localStorageService
) {
  $rootScope.$on('$stateChangeStart', function(event, next) {
      var authorizedRoles = null;

      if (next.data) {
        authorizedRoles = Object.keys(next.data.authorizedRoles);
      }

      if (!AuthService.isAuthorized(authorizedRoles)) {
        event.preventDefault();
        if (next.name === 'search') {
          var landingPage = AuthService.getUsersLandingPage();
          //redirect default to user specific default
          if (landingPage) {
            $state.go(landingPage);
            return;
          }
        }
        $rootScope.$broadcast(AUTH_EVENTS.notAuthorized);
        return;
      } else {
        // not being redirected, let's see if they have a pre-redirect
        var preRedirect = localStorageService.get('pre-redirect');
        if (preRedirect > '') {
          event.preventDefault();
          localStorageService.remove('pre-redirect');
          window.location.assign(preRedirect);
        }
      }

      if (next.pageTitle) {
        $timeout(function() {
          document.title = next.pageTitle;
        }, 0);
      }
    });
};

module.exports = RouteChange;
