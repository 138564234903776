'use strict';

var angular = require('angular');
var initInjector = angular.injector(['ng']);
var $http = initInjector.get('$http');
var $log = initInjector.get('$log');
var $window = initInjector.get('$window');

var $q = angular.injector(['ng']).get('$q');

var REDIRECT_OKTA_URI = require('../config/redirectOktaURI.constant');
var OKTA_ISSUER = require('../config/oktaIssuer.constant');
var STORAGE_KEY = require('../config/storageKeyEnv.constant');
var EVENT_LOGGER_SERVICE = require('./eventLogger.service.js');
var get = require('lodash').get;

var OktaAuth = require('@okta/okta-auth-js').OktaAuth;
var authClient = null;
var EventLoggerService = EVENT_LOGGER_SERVICE && EVENT_LOGGER_SERVICE[2] && EVENT_LOGGER_SERVICE[2]({}, $window);

// local storage service looks for prefix 'ls.'
// session storage service uses 'ss.' prefix

var config = {
  // Required for login flow using getWithRedirect()
  clientId: 'nike.marketingeng.icon-web',
  // Required config
  issuer: OKTA_ISSUER.getOktaIssuer(),
  responseType: ['id_token', 'token'],
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  autoRenew: true,

  postLogoutRedirectUri: REDIRECT_OKTA_URI.getOktaRedirectURL(),
  redirectUri: REDIRECT_OKTA_URI.getOktaRedirectURL(),

  // Originally we configured TokenManager to use sessionStorage instead of localStorage. But this was not clearing the
  // tokens in other tabs, on user logout. SessionStorage will only be available to the current browser tab but
  // localstorage will be available to all browser tabs. So updating tokenManager to use localstorage.
  // https://github.com/okta/okta-auth-js#storagetype
  tokenManager: {
    storage: 'localStorage',
    storageKey: 'okta-icon-storage' + STORAGE_KEY.getStorageKeyEnv(),
    autoRenew: true
  },
  session: {
    autoRenew: true,
    autoRemove: false
  }
};

authClient = new OktaAuth(config);
authClient.start();


var clearState = function() {
  $window.sessionStorage.removeItem('ss.expires-at');
  if (EventLoggerService) {
    var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
    var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

    EventLoggerService.track('OKTA:CLEAR STATE', {
      userName: userName,
      email: email
    }, true);
  }

  if ($window.sessionStorage.getItem('ss.auth-timeout-id') !== undefined) {
    $window.clearTimeout($window.sessionStorage.getItem('ss.auth-timeout-id'));
    $window.sessionStorage.removeItem('ss.auth-timeout-id');
  }
};

var logout = function (signoutOptions) {
  clearState();

  $log.info('logging out - revoking and clearing all tokens');

  return authClient.revokeAccessToken()
    .then(function (value) {
      if (EventLoggerService) {
        var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
        var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

        EventLoggerService.track('OKTA:LOGOUT', {
          userName: userName,
          email: email
        }, true);
      }
      authClient.signOut(signoutOptions)
        .then(function (value) {
          authClient.tokenManager.clear();
          authClient.stop();
        })
        .catch((err) => {
          var error = 'Okta sign-out Error: ';
          if (err.message) {
            error += err.message;
          }
          if (err.stack) {
            error += ' | stack: ' + err.stack;
          }
          $log.error(error);
          if (EventLoggerService) {
            var userName = get(authClient, 'authStateManager._authState.idToken.claims.name', undefined);
            var email = get(authClient, 'authStateManager._authState.idToken.claims.email', undefined);

            EventLoggerService.track('OKTA:LOGOUT ERROR', {
              userName: userName,
              email: email
            }, true);
          }
        });
    })
    .catch((err) => {
      $log.error('logout revokeAccessToken error: ' + err);
    });
};

var authenticate = function () {
  if (authClient.isLoginRedirect()) {
    var deferred = $q.defer();

    // Parse token from redirect url
    authClient.token.parseFromUrl()
      .then(function (value) {
        var tokens = value.tokens;

        // Store parsed token in Token Manager
        authClient.tokenManager.setTokens(tokens);
        setDefaultAuthorizationHeader();

        if (EventLoggerService) {
          var email = get(value, 'tokens.accessToken.claims.sub', undefined);
          EventLoggerService.track('OKTA:AUTHENTICATE', {
            email: email,
          }, true);
        }

        if (deferred) {
          deferred.resolve();
        }
      }, function (error) {
        $log.error('isLoginRedirect Error in auth: ' + error);
        logout();
      });

    return deferred.promise;
  }

  return authClient.isAuthenticated()
    .then(function (value) {
      if (!value) {
        // add to pre-redirect local storage
        $window.localStorage.setItem('ls.pre-redirect', window.location.href);
        authClient.signInWithRedirect();
      } else {
        setDefaultAuthorizationHeader();
      }
    }, function (error) {
      $log.error('isAuthenticated Error in auth: ' + error);
      logout();
    });
};

var isAuthenticated = function () {
  //ignore tests
  if (location.href.indexOf(':9876') > -1 || location.href.indexOf(':3010') > -1) {
    return true;
  }

  return authClient.isAuthenticated()
  .then(function(value) {
      if (!value) {
        $log.info('okta says accessToken is expired, logging out');
        logout();
      }

      return value;
    });
}

var fetchAccessToken = function () {
  if (location.href.indexOf(':9876') > -1 || location.href.indexOf(':3010') > -1) {
    return {accessToken: ' '};
  }
  return authClient.tokenManager.getSync('accessToken');
};

var fetchAuthToken = function () {
  var token = fetchAccessToken();

  if (token !== undefined) {
    return 'Bearer ' + token.accessToken;
  }

  return null;
};

var setDefaultAuthorizationHeader = function() {
  $http.defaults.headers.common['Authorization'] = fetchAuthToken();
};

function isAccessTokenExpired() {
  var accessToken = fetchAccessToken();
  var now = new Date();

  return (accessToken === undefined) ? true : (now > (accessToken.expiresAt * 1000));
}


module.exports = {
  authenticate: authenticate,
  fetchAuthToken: fetchAuthToken,
  logout: logout,
  isAuthenticated: isAuthenticated,
  isAccessTokenExpired: isAccessTokenExpired,
};
