'use strict';

var QRCode = require('./qr.js');

/*jshint unused: false*/
function register(angular) {
  return [
    function qrDirective() {
      return {
        restrict: 'E',
        replace: true,
        templateUrl: 'templates/qr.tpl.html',
        scope: {
          text: '=',
          width: '@',
          height: '@'
        },
        bindToController: true,
        controllerAs: 'qr',
        controller: [
          function() {
            var _self = this;
          }
        ],
        link: function(scope, elem, attrs, ctrl) {
          var qrcode = new QRCode(elem[0], {
            text: ctrl.text,
            width: ctrl.width,
            height: ctrl.height,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.L
          });

          scope.$watch(
            function() {
              return ctrl.text;
            },
            function() {
              if (ctrl.text) {
                qrcode.makeCode(ctrl.text);
              }
            }
          );
        }
      };
    }
  ];
}

module.exports = register;
