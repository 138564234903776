'use strict';

var approveComponent = {
  bindings: {
    status: '<'
  },
  controller: /*@ngInject*/ function() {},
  templateUrl: 'templates/partials/ui/approve.tpl.html'
};

module.exports = approveComponent;
