'use strict';

var SlateController = /*@ngInject*/ function(NoteService) {
  var _self = this;

  _self.init = function() {
    _self.note = NoteService.getNotes(_self.job, 'SlateNote');
    if (_self.job && _self.job.product) {
      if (_self.job.product.styleColor) {
        _self.qrData = [_self.job.product.styleColor, _self.job.id].join('_');
      } else {
        _self.qrData = [_self.job.product.productId, _self.job.id].join('_');
      }
      if (_self.job.product.subcategory) {
        _self.combinedCategory = [_self.job.product.category, _self.job.product.subcategory].join(' / ');
      } else {
        _self.combinedCategory = _self.job.product.category;
      }
      //Sort views
      _self.job.expectedViews.sort();
    }
  };
};

module.exports = SlateController;
