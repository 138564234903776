'use strict';

var angular = require('angular');

var productJobsComponent = {
  bindings: {
    styleColor: '<',
    jobs: '<',
  },
  controller: /*@ngInject*/ function($state, $window) {
    var _self = this;

    _self.viewJobDetail = function(job) {
      var url = $state.href(
        'detail',
        {
          productIdentifierType: 'by-product-id',
          productIdentifier: job.product.productId,
          jobId: job.id,
          tab: null
        }
      );
      $window.open(url, '_blank');
    };
  },
  templateUrl: 'templates/partials/detail/productJobs.tpl.html'
};

module.exports = productJobsComponent;
