'use strict';

/*jshint unused: false*/
var imageThumbDirective = function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      model: '=',
      showLibraryViewCode: '<',
      isSelected: '<',
      view: '@?',
      onClick: '&',
      libraryViewCodeErrorMessages: '=',
      validateLibraryViewCodes: '&',
      job: '<',
      assetJob: '<',
      annotations: '=',
      loadingLvcs: '<'
    },
    controllerAs: 'image',
    controller: 'ImageThumbController',
    templateUrl: 'templates/partials/qa/imageThumb.tpl.html',
    link: function(scope, elem, attrs, ctrl) {
      scope.$watch(
        'image.libraryViewCodeErrorMessages',
        function(newVal, oldVal) {
          if (
            scope.image.libraryViewCodeErrorMessages &&
            scope.image.libraryViewCodeErrorMessages.length > 0
          ) {
            scope.image.errorMessage =
              scope.image.libraryViewCodeErrorMessages[0];
          } else {
            scope.image.errorMessage = null;
          }
        },
        true
      );
    }
  };
};

module.exports = imageThumbDirective;
