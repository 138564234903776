'use strict';

/*jshint unused: false*/
var outreachImageThumbDirective = function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      model: '=',
      showLibraryViewCode: '<',
      view: '@?',
      onClick: '&',
      showCropType: '<',
      job: '<'
    },
    controllerAs: 'image',
    controller: /*@ngInject*/ function(JobService, QAService, $scope) {
      var _self = this;

      _self.showCommentIcon = false;
      _self.errorMessage = null;
      _self.qaData = QAService.get($scope.image.model.id);

      $scope.$watch('image.model', function(currentVal, prevVal) {
        if (
          !$scope.image.model ||
          !$scope.image.model.relatedAssets.Deliverable
        ) {
          return;
        }
        _self.qaData = QAService.get($scope.image.model.id);
        $scope.image.url = JobService.getImageByType(
          $scope.image.model.relatedAssets.Deliverable,
          'all'
        );
      });
    },
    templateUrl: 'templates/partials/qa/outreachImageThumb.tpl.html',
    link: function(scope, elem, attrs, ctrl) {}
  };
};

module.exports = outreachImageThumbDirective;
