'use strict';

var REDIRECT = require('../config/redirect.constant.js');

var HttpErrorInterceptor = /*@ngInject*/ function($q, EventLoggerService) {
  var httpErrorInterceptor = {
    responseError: function(response) {
      var deferred = $q.defer();
      switch (response.status) {
        case 401:
          REDIRECT.login();
          return $q.reject(response);
          break;
      }
      EventLoggerService.track(
        'HTTP Response Error ' + response.status,
        {},
        true
      );
      return $q.reject(response);
    }
  };
  return httpErrorInterceptor;
};

module.exports = HttpErrorInterceptor;
