'use strict';

var imageZoomComponent = {
  bindings: {
    assetJob: '<',
    topPosition: '<',
    leftPosition: '<',
    scaleOffsetX: '<',
    scaleOffsetY: '<',
    selectedCropType: '<',
    cropTypeWidth: '<',
    cropTypeHeight: '<'
  },
  controller: /*@ngInject*/ function(ASSET_HIGH_RES_WIDTH) {
    var _self = this;
    _self.zoomImg = document.getElementById("zoomed-image") || {};
    _self.loadingImg = document.getElementById("loading-image") || {};

    _self.onImageLoad = function() {
      _self.zoomImg.hidden = false;
      _self.loadingImg.hidden = true;
    }

    _self.setLoading = function() {
      _self.zoomImg.hidden = true;
      _self.loadingImg.hidden = false;
    }

    _self.setLoading();

    _self.$onChanges = function() {
      if (_self.topPosition >= 0 && _self.leftPosition >= 0) {
        var cropTypesLinks = _self.assetJob.cropTypes[
          _self.selectedCropType
        ].links.filter(function(link) {
          if (link.rel === 'annotation') {
            return false;
          }
          return true;
        });

        var deliverableLinks = _self.assetJob.relatedAssets.Deliverable.links.filter(
          function(link) {
            if (link.rel === 'annotation') {
              return false;
            }
            return true;
          }
        );

        var newUrl = _self.selectedCropType ? cropTypesLinks[0].href : deliverableLinks[0].href;
        if (newUrl !== _self.url) {
          _self.setLoading();
        }
        _self.url = newUrl;

        _self.zoomTop =
          -_self.topPosition * (_self.cropTypeHeight / _self.scaleOffsetY);
        _self.zoomLeft =
          -_self.leftPosition * (_self.cropTypeWidth / _self.scaleOffsetX);
        _self.transformStyle =
          'left: ' +
          _self.zoomLeft +
          'px; top: ' +
          _self.zoomTop +
          'px; width:' +
          _self.cropTypeWidth +
          'px; height: ' +
          _self.cropTypeHeight +
           'px;';
      }
    };
  },
  templateUrl: 'templates/partials/qa/imageViewerZoom.tpl.html'
};

module.exports = imageZoomComponent;
