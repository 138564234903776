'use strict';

var angular = require('angular');

var detailHeaderComponent = {
  bindings: {
    job: '<',
    product: '<',
    onChange: '&',
    show: '<',
    onClose: '&?'
  },
  controller: /*@ngInject*/ function(
    $state,
    CSVExporter,
    RouterTracker,
    AuthService,
    USER_ROLES,
    META_STATUS,
    JobService,
    APP_DATA
  ) {
    var _self = this;

    _self.model = {};
    _self.show = _self.show === undefined ? true : _self.show;
    _self.showCloseJobModal = false;

    _self.problemSolve = {
      visible: false
    };

    _self.newJob = {
      visible: false
    };

    _self.closeJob = {
      visible: false
    };

    _self.canInitiateNewJob = function() {
      return (
        $state.current.name === 'detail' &&
        AuthService.isAdmin() &&
        !JobService.canUpdate(_self.model.job.status)
      );
    };

    _self.canCloseJob = function() {
      var retVal = false;
      if (_self.model.job && _self.model.job.status) {
        if (
          _self.model.job.status.toUpperCase() === 'READY TO PUBLISH' ||
          _self.model.job.status.toUpperCase() === 'JOB PENDING'
        ) {
          retVal = true;
        }
        
        // Only allow super users and studio ops roles to close jobs in 'Pending Release' status
        if (
          _self.model.job.status.toUpperCase() === 'PENDING RELEASE' && 
          AuthService.isAuthorized([USER_ROLES.superUser, USER_ROLES.studioOps])
        ) {
          retVal = true;
        }
      }

      return retVal;
    };

    _self.canMoveToProblemSolve = function(job) {
      var authorized = AuthService.isAuthorized([
        USER_ROLES.merchOps,
        USER_ROLES.studioOps,
        USER_ROLES.studioStylist,
        USER_ROLES.digiTech,
        USER_ROLES.imageEditor,
        USER_ROLES.superUser,
        USER_ROLES.photographer,
        USER_ROLES.qaReviewer
      ]);

      if (!job) {
        return false;
      }

      if ($state.current.name === 'outreach') {
        return false;
      }

      // Show if job is 'In Progress' or 'In Image QA' or 'In Post'
      if (
        job.status === META_STATUS.inProgress ||
        job.status === META_STATUS.qa ||
        job.status === META_STATUS.post
      ) {
        return true;
      }

      // Don't show if job is 'Job Pending' already in Problem Solve
      if (
        job.status === META_STATUS.jobPending ||
        job.status === META_STATUS.problemSolve
      ) {
        return false;
      }

      return authorized && job.active;
    };

    _self.initiateNewJob = function() {
      _self.onChange({ $event: { type: 'newjob' } });
    };

    _self.initiateProblemSolve = function() {
      _self.onChange({ $event: { type: 'problemsolve' } });
    };

    _self.confirmCloseJob = function() {
      _self.showCloseJobModal = true;
    };

    _self.downloadDetails = function() {
      var selectedJob = angular.copy(_self.model.job);
      selectedJob.selected = true;
      CSVExporter.downloadJobs(selectedJob, selectedJob.id);
    };

    _self.close = function() {
      if (_self.onClose !== undefined) {
        _self.onClose();
      } else {
        RouterTracker.gotoPreviousState();
      }
    };

    _self.$onChanges = function(changes) {
      if (changes.job) {
        if (!_self.job) {
          return;
        }
        _self.model.job = angular.copy(_self.job);
        _self.problemSolve.visible = _self.canMoveToProblemSolve(
          _self.model.job
        );
        _self.newJob.visible = _self.canInitiateNewJob();
        _self.closeJob.visible = _self.canCloseJob();

        if (!_self.product) {
          _self.model.product = _self.model.job.product;
        }
      }
      if (changes.product) {
        if (!_self.product) {
          return;
        }
        _self.model.product = angular.copy(_self.product);
      }
      if (changes.show) {
        _self.show = angular.copy(_self.show);
        _self.show = changes.show.currentValue;
      }
    };
  },
  templateUrl: 'templates/partials/detail/header.tpl.html'
};

module.exports = detailHeaderComponent;
