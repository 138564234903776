'use strict';

var NoteService /*@ngInject*/ = function($http, $q, REST_URL, JobService) {
  var service = {};

  service.noteTypes = {
    general: { type: 'GeneralNote', class: 'AddGeneralNote' },
    problem: { type: 'ProblemSolveNote', class: 'AddProblemSolveNote' },
    slate: { type: 'SlateNote', class: 'AddSlateNote' },
    productReview: { type: 'ProductReviewNote', class: 'AddProductReviewNote' }
  };

  service.addNote = function(type, note, job, issueType) {
    var url = REST_URL.jobs + '/' + job + '/notes';
    var deferred = $q.defer();
    var data = {
      '@class': service.noteTypes[type].class,
      noteContent: note
    };

    if (issueType) {
      data['issueType'] = issueType;
    }

    $http
      .post(url, data)
      .then(function(response) {
        JobService.setExtraJobData(response.data);
        deferred.resolve(response);
      })
      .catch(function(response) {
        deferred.reject(response);
      });
    return deferred.promise;
  };

  service.getNotes = function(job, type) {
    var notes = [];

    if (!job.notes) {
      return notes;
    }

    job.notes.forEach(function(note) {
      if (note['@class'] === type) {
        notes.push(note);
      }
    });

    return notes;
  };

  service.convertLineBreaks = function(text, toHtml) {
    if (toHtml) {
      return text.replace(/(\r\n|\n|\r)/gm, '<br>');
    } else {
      return text.replace(/<br>/gm, '\n');
    }
  };

  return service;
};

module.exports = NoteService;
