'use strict';

var angular = require('angular');

var manageStudiosComponent = {
  bindings: {},
  controller: /*@ngInject*/ function(REST_URL, $http) {
    var _self = this;

    _self.addStudio = function(type) {
      _self.studio.model = {
        studioType: type
      };
      _self.studio.active = true;
    };

    _self.editStudio = function($event) {
      _self.studio.model = $event.studio;
      _self.studio.active = true;
    };

    _self.fetchStudioData = function() {
      _self.studioPromise =
        _self.studioPromise || $http.get([REST_URL.base, 'studios'].join('/'));

      _self.studioPromise.then(_self.studioPromiseResolution);
    };

    _self.studioPromiseResolution = function(response) {
      _self.data.studios.Internal = [];
      _self.data.studios.External = [];

      response.data.forEach(_self.addStudioToList);
    };

    _self.addStudioToList = function(studio) {
      _self.data.studios[studio.studioType].push(studio);
    };

    _self.handleStudioSubmission = function($event) {
      var studioFromEvent = angular.copy($event.studio),
        isEdit = false;

      _self.data.studios[studioFromEvent.studioType] = _self.data.studios[
        studioFromEvent.studioType
      ].map(function(studio) {
        if (studio.id === studioFromEvent.id) {
          isEdit = true;
          return studioFromEvent;
        } else {
          return studio;
        }
      });

      if (!isEdit) {
        _self.data.studios[$event.studio.studioType].push(studioFromEvent);
      }

      // clear promise so new results are fetched if user goes to different admi ntab then back to manage studios
      delete _self.studioPromise;

      _self.studio.active = false;
    };

    _self.$onInit = function() {
      _self.data = {
        studios: {
          Internal: [],
          External: []
        }
      };
      _self.fetchStudioData();
      _self.studio = {
        active: false
      };
    };
  },
  templateUrl: 'templates/partials/admin/manageStudios.tpl.html'
};

module.exports = manageStudiosComponent;
