'use strict';

/*jshint unused: false*/
var userVsSystemJobDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      userJob: '=',
      systemProduct: '=',
      config: '=',
      priClickHandler: '&',
      secClickHandler: '&'
    },
    controllerAs: 'userVsSystemJob',
    controller: /*@ngInject*/ function($scope) {
      var _self = this;

      _self.handlePriClick = function() {
        _self.priClickHandler();
      };

      _self.handleSecClick = function() {
        _self.secClickHandler();
      };
    },
    templateUrl: 'templates/partials/activity/userVsSystemJob.tpl.html',
    link: function(scope, elem, attrs, controller, x) {}
  };
};

module.exports = userVsSystemJobDirective;
