'use strict';
var angular = require('angular');

var resolveProblemModal = {
  bindings: {
    active: '=',
    model: '<',
    onResolve: '&'
  },
  controller: /*@ngInject*/ function(
    $scope,
    JobService,
    ProblemSolveIndicatorService,
    NotificationService,
    META_STATUS,
    ASSET_STATUS,
    APP_DATA
  ) {
    var _self = this;

    _self.data = {
      working: false,
      params: {
        cta: {
          primary: 'Submit',
          secondary: 'Cancel'
        }
      },
      error: {},
      isValid: false
    };

    _self.setExtraAssetData = function(job) {
      job.inactiveAssets = job.assetJobs.filter(function(assetJob) {
        if (!assetJob.active) {
          return assetJob;
        }
      });

      job.publishedAssets = job.assetJobs.filter(function(assetJob) {
        if (
          assetJob.status === ASSET_STATUS.approved ||
          assetJob.status === 'Published'
        ) {
          //Replace-This-Junk
          return assetJob;
        }
      });

      job.closedAssets = job.assetJobs.filter(function(assetJob) {
        if (assetJob.status === ASSET_STATUS.closed) {
          return assetJob;
        }
      });
    };

    _self.getReasonOptions = function(job) {
      var options = ['New Merch Ordered', 'Product Data Updated'];

      // Duplicate jobs have different resolution options
      if (job.jobType === 'Duplicate') {
        return ['Ship Merch', 'Shoot Newly Arrived Merch'];
      }

      if (job.assetJobs.length && !job.publishedAssets.length) {
        options.push('Updated Post Instructions');
      }

      if (job.assetJobs.length && job.merchJob && job.merchJob.active) {
        options.push('Re-Image');
      }

      options.push('Other');

      return options;
    };

    _self.resetFormData = function() {
      _self.formData = {
        type: {
          value: _self.model.jobType,
          label: 'Job Type',
          options: [],
          disabled: true
        },
        reason: {
          value: '',
          label: 'Reason for Resolution',
          options: _self.getReasonOptions(_self.model)
        },
        metaStatus: {
          value: '',
          label: 'Job Status',
          options: [META_STATUS.inProgress, META_STATUS.closed],
          disabled: false
        },
        merchStatus: {
          value: '',
          label: 'Merch Status',
          options: APP_DATA.MerchJobStatus.slice(0, 5),
          disabled: false,
          visible: !!_self.model.merchJob
        },
        assetChangeType: {
          value: '',
          label: 'Set Asset Status for',
          options:
            _self.model.assetJobs.length > 1
              ? ['All Active Assets', 'Specific Assets']
              : ['All Active Assets'],
          disabled: false,
          visible: !!_self.model.assetJobs.length
        },
        allAssetStatus: {
          value: '',
          label: 'Asset Status',
          options: [
            '<i>Maintain Current Status</i>',
            ASSET_STATUS.postProd,
            ASSET_STATUS.closed
          ],
          disabled: false,
          visible: !!_self.model.assetJobs.length
        },
        note: {
          value: '',
          visible: false
        }
      };

      // Duplicate jobs have a default resolution reason
      if (_self.model.jobType === 'Duplicate') {
        _self.formData.reason.value = _self.formData.reason.options[0];
        _self.setFormData(_self.formData.reason.options[0]);
      }
    };

    _self.setMerchStatus = function(merchJob, targetStatus, disabled) {
      if (!merchJob) {
        _self.formData.merchStatus.value = null;
        _self.formData.merchStatus.disabled = true;
        _self.formData.merchStatus.visible = false;
        return;
      } else if (!merchJob.active) {
        _self.formData.merchStatus.value = merchJob.status;
        _self.formData.merchStatus.disabled = true;
        return;
      }

      // If target status is not set, use current status
      _self.formData.merchStatus.value = targetStatus || merchJob.status;
      _self.formData.merchStatus.disabled = !!disabled;
    };

    _self.setAllAssetStatus = function(targetStatus, disabled) {
      targetStatus = targetStatus || _self.formData.allAssetStatus.options[0];

      _self.formData.allAssetStatus.value = targetStatus;
      _self.formData.allAssetStatus.disabled = !!disabled;
    };

    _self.setAssetFormData = function() {
      _self.formData.assetChangeType.visible =
        !_self.formData.allAssetStatus.disabled && _self.model.assetJobs.length;

      if (
        !_self.formData.assetChangeType.visible ||
        !_self.formData.assetChangeType.value
      ) {
        _self.formData.assetChangeType.value =
          _self.formData.assetChangeType.options[0];
        _self.handleAssetTypeSelection();
      }

      if (_self.formData.reason.value === 'Other') {
        _self.formData.allAssetStatus.options = [ASSET_STATUS.postProd];
        _self.formData.allAssetStatus.value =
          _self.formData.allAssetStatus.options[0];

        if (_self.formData.metaStatus.value === 'Closed') {
          _self.formData.allAssetStatus.options = [ASSET_STATUS.closed];
          _self.formData.allAssetStatus.value =
            _self.formData.allAssetStatus.options[0];
        }
        _self.assetFormData = _self.model.assetJobs.map(function(assetJob) {
          var data = {
            value: assetJob.status,
            label: ['View', assetJob.viewCode].join(' '),
            options: [ASSET_STATUS.postProd, ASSET_STATUS.closed],
            disabled: !assetJob.active
          };

          if (assetJob.relatedAssets.Deliverable) {
            data.options.push(ASSET_STATUS.qa);
            data.deliverable = true;
          }

          // If there is a specific asset job status for this reason, and the job is not complete, change the target asset job status
          if (
            !data.disabled &&
            data.options.indexOf(_self.formData.allAssetStatus.value) !== -1
          ) {
            data.value = _self.formData.allAssetStatus.value;
          }

          return data;
        });
      } else {
        _self.formData.allAssetStatus.options = [
          '<i>Maintain Current Status</i>',
          ASSET_STATUS.postProd,
          ASSET_STATUS.closed
        ];
        _self.assetFormData = _self.model.assetJobs.map(function(assetJob) {
          var data = {
            value: assetJob.status,
            label: ['View', assetJob.viewCode].join(' '),
            options: _self.formData.allAssetStatus.options.slice(1),
            disabled: !assetJob.active
          };

          // Add current asset job status to options if it's not present
          if (data.options.indexOf(assetJob.status) === -1) {
            data.options.unshift(assetJob.status);
          }

          // If there is a specific asset job status for this reason, and the job is not complete, change the target asset job status
          if (
            !data.disabled &&
            data.options.indexOf(_self.formData.allAssetStatus.value) !== -1
          ) {
            data.value = _self.formData.allAssetStatus.value;
          }

          return data;
        });
      }
    };

    // Move the merch to "Ready for Ship or Store", unless it's farther along
    _self.getDroppedMerchStatus = function(merchJob) {
      return merchJob && APP_DATA.MerchJobStatus.indexOf(merchJob.status) > 4
        ? merchJob.status
        : APP_DATA.MerchJobStatus[4];
    };

    // If the all assets are published or dropped, show that status
    // If there are a mix of active and inactive assets, show dropped
    _self.getDroppedAssetStatus = function(job) {
      if (job.publishedAssets.length === job.assetJobs.length) {
        return ASSET_STATUS.dropped;
      }

      if (
        job.closedAssets.length === job.assetJobs.length ||
        _self.model.assetJobs.length - _self.model.inactiveAssets.length !== 0
      ) {
        return ASSET_STATUS.closed;
      }
    };

    _self.setFormData = function() {
      var merchStatus = {},
        assetStatus = {};

      _self.formData.note.visible = false;
      _self.formData.metaStatus.visible = true;
      _self.formData.merchStatus.visible = true;
      _self.formData.allAssetStatus.visible = true;
      _self.data.params.cta.primary = 'Submit';

      switch (_self.formData.reason.value) {
        case 'New Merch Ordered':
          _self.formData.metaStatus.value = META_STATUS.closed;
          _self.formData.metaStatus.disabled = true;
          merchStatus.value = _self.getDroppedMerchStatus(_self.model.merchJob);
          merchStatus.disabled = true;
          assetStatus.value = _self.getDroppedAssetStatus(_self.model);
          assetStatus.disabled = true;
          break;
        case 'Product Data Updated':
          _self.formData.metaStatus.value = META_STATUS.inProgress;
          _self.formData.metaStatus.disabled = false;
          merchStatus.value = APP_DATA.MerchJobStatus[3];
          merchStatus.disabled = false;
          assetStatus.disabled = true;
          break;
        case 'Updated Post Instructions':
          _self.formData.metaStatus.value = META_STATUS.inProgress;
          _self.formData.metaStatus.disabled = true;
          merchStatus.value = _self.model.merchJob
            ? _self.model.merchJob.status
            : null;
          merchStatus.disabled = false;
          assetStatus.value = META_STATUS.post;
          assetStatus.disabled = true;
          break;
        case 'Re-Image':
          _self.formData.metaStatus.value = META_STATUS.inProgress;
          _self.formData.metaStatus.disabled = true;
          merchStatus.value = APP_DATA.MerchJobStatus[3];
          merchStatus.disabled = false;
          assetStatus.value = _self.getDroppedAssetStatus(_self.model);
          assetStatus.disabled = true;
          break;
        case 'Other':
          _self.formData.metaStatus.value = META_STATUS.inProgress;
          _self.formData.metaStatus.disabled = false;
          // Specific asset selection open by default
          _self.formData.note.visible = true;
          // Disable asset selection if there are no active assets.
          assetStatus.disabled = _self.model.assetJobs.length === 0;
          break;
        case 'Ship Merch':
          _self.formData.metaStatus.value = META_STATUS.inProgress;
          _self.formData.metaStatus.disabled = true;
          merchStatus.value = APP_DATA.MerchJobStatus[5];
          merchStatus.disabled = true;
          assetStatus.disabled = true;
          break;
        case 'Shoot Newly Arrived Merch':
          _self.formData.metaStatus.value = META_STATUS.inProgress;
          merchStatus.disabled = true;
          assetStatus.disabled = true;
          _self.formData.metaStatus.visible = false;
          _self.formData.merchStatus.visible = false;
          _self.formData.allAssetStatus.visible = false;
          _self.data.params.cta.primary = 'Next';
      }

      _self.setMerchStatus(
        _self.model.merchJob,
        merchStatus.value,
        merchStatus.disabled
      );
      _self.setAllAssetStatus(assetStatus.value, assetStatus.disabled);
      _self.setAssetFormData();

      _self.validate();
    };

    _self.setAssetOptions = function(postCount) {
      if (!_self.assetFormData) {
        return;
      }
      if (!postCount || postCount > 1) {
        for (var i = 0; i < _self.assetFormData.length; i++) {
          var current = _self.assetFormData[i];
          current.options = [ASSET_STATUS.postProd, ASSET_STATUS.closed];
          if (current.deliverable) {
            current.options.push(ASSET_STATUS.qa);
          }
        }
      } else if (postCount === 1) {
        for (var i = 0; i < _self.assetFormData.length; i++) {
          var current = _self.assetFormData[i];
          if (current.value === 'In Post Production') {
            current.options = ['In Post Production'];
          } else {
            current.options = [ASSET_STATUS.postProd, ASSET_STATUS.closed];
            if (current.deliverable) {
              current.options.push(ASSET_STATUS.qa);
            }
          }
        }
      }
    };

    _self.handleJobTypeSelection = function() {
      _self.setFormData();
    };

    _self.handleAssetTypeSelection = function() {
      _self.formData.allAssetStatus.visible =
        _self.model.assetJobs.length &&
        _self.formData.assetChangeType.value ===
          _self.formData.assetChangeType.options[0];
    };

    _self.handleMetaStatusSelection = function() {
      if (_self.formData.metaStatus.value === META_STATUS.closed) {
        _self.setMerchStatus(
          _self.model.merchJob,
          _self.getDroppedMerchStatus(_self.model.merchJob),
          true
        );
        _self.setAllAssetStatus(ASSET_STATUS.closed, true);
      } else {
        _self.setMerchStatus(_self.model.merchJob);
        _self.setAllAssetStatus();
      }

      _self.setAssetFormData();

      _self.validate();
    };

    _self.handleAllAssetStatus = function(option) {
      var isJobClosed = option === 'Closed' ? true : false;
      _self.handleAutoMetaJobClose(isJobClosed);
      _self.validate();
    };

    //When individual asset values change
    _self.handleAssetStatus = function() {
      var isJobClosed = true;
      var postCount = 0;
      _self.assetFormData.map(function(asset) {
        if (asset.value !== 'Closed') {
          isJobClosed = false;
        }
        if (asset.value === 'In Post Production') {
          postCount++;
        }
      });

      _self.handleAutoMetaJobClose(isJobClosed);
      if (_self.formData.reason.value === 'Other') {
        _self.setAssetOptions(postCount);
      }
      _self.validate();
    };

    _self.handleAutoMetaJobClose = function(closeJob) {
      if (closeJob) {
        _self.formData.metaStatus.value = 'Closed';
        _self.formData.metaStatus.disabled = true;
      } else {
        _self.formData.metaStatus.disabled = false;
      }
    };

    _self.validate = function() {
      _self.data.error = {};
      _self.data.isValid = !!_self.formData.reason.value;

      if (_self.formData.note.visible) {
        _self.validateNote(_self.data.noteEvent);
      }

      return _self.data.isValid;
    };

    _self.validateNote = function($event) {
      if (!$event) {
        return false;
      }
      _self.data.isValid = $event.valid;

      if (_self.data.isValid) {
        _self.formData.note.value = $event.text;
      }

      _self.data.noteEvent = $event;
      return _self.data.isValid;
    };

    _self.doUpdate = function() {
      var routing = {
          merch: [],
          assets: []
        },
        params = {};

      // If we're converting a duplicate job, send callback to parent to open initiate job modal
      if (_self.formData.reason.value === 'Shoot Newly Arrived Merch') {
        _self.onResolve({ $event: { type: 'newjob' } });
        return;
      }

      _self.data.error = {};
      _self.data.working = true;

      params.resolutionReason = _self.formData.reason.value;

      if (_self.formData.metaStatus.value === META_STATUS.closed) {
        params.finish = 'close';
      }

      if (_self.model.merchJob && _self.model.merchJob.active) {
        routing.merch.push({
          jobId: _self.model.merchJob.id,
          goto: _self.formData.merchStatus.value
        });
      }

      if (_self.model.assetJobs && _self.model.assetJobs.length) {
        routing.assets = [];

        _self.model.assetJobs.forEach(function(assetJob, index) {
          var route = { jobId: assetJob.id },
            value = _self.formData.allAssetStatus.visible
              ? _self.formData.allAssetStatus.value
              : _self.assetFormData[index].value;

          // Do not attempt to route asset jobs that are in a terminal state
          if (!assetJob.active) {
            return;
          }

          if (value === ASSET_STATUS.closed) {
            route.close = true;
          } else if (
            [ASSET_STATUS.postProd, ASSET_STATUS.qa, META_STATUS.post].indexOf(
              value
            ) !== -1
          ) {
            route.unclaim = true;
            if (
              assetJob.status === ASSET_STATUS.qa &&
              value === ASSET_STATUS.postProd
            ) {
              route.reject = true;
              route.rejectReason = _self.formData.reason.value;
            } else {
              route.goto = value;
            }
          } else if (
            value === _self.formData.allAssetStatus.options[0] ||
            value === assetJob.status
          ) {
            route.unclaim = false;
          }

          routing.assets.push(route);
        });
      }

      JobService.resolveProblemSolve(
        _self.model.id,
        _self.model.tasks[0].id,
        angular.merge(params, { routing: routing.merch.concat(routing.assets) })
      )
        .then(function(response) {
          var payload = { job: response.data };

          if (_self.formData.reason.value === 'Other') {
            payload.note = _self.formData.note.value;
          }

          NotificationService.push(
            'Job {{id}} is now in {{status}}.',
            payload.job
          );
          ProblemSolveIndicatorService.update();
          _self.onResolve({ $event: payload });
        })
        .catch(function(response) {
          _self.data.error.title = response.error || response.data.error;
        })
        .finally(function() {
          _self.data.working = false;
        });
    };

    _self.$onInit = function() {
      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          if (_self.active) {
            _self.resetFormData();
            _self.formData.reason.value = 'Other';
            _self.setFormData();
            _self.validate();
          }
        }
      );
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);
        _self.setExtraAssetData(_self.model);
        _self.resetFormData();
      }
    };
  },
  templateUrl: 'templates/partials/problem/resolve.tpl.html'
};

module.exports = resolveProblemModal;
