'use strict';

/*jshint unused: false*/
var searchHeaderComponent = {
  require: {
    results: '^searchResults'
  },
  templateUrl: /*@ngInject*/ function($attrs) {
    return $attrs.source;
  }
};

module.exports = searchHeaderComponent;
