'use strict';

var qaRejectionComponent = {
  bindings: {
    active: '=',
    onRejection: '&'
  },
  controller: /*@ngInject*/ function($scope, QAService) {
    var _self = this;

    if(!_self.rejectionReasons) {
      _self.rejectionReasons = [];
      QAService.getRejectionReasons().then(function(response) {
        _self.rejectionReasons = response.data;
      });
    }

    _self.getProblemSolveData = function() {
      var noteElements = [_self.ps.reason];
      if (_self.ps.detail !== '') {
        noteElements.push(_self.ps.detail);
      }
      if (_self.ps.note !== '') {
        noteElements.push(_self.ps.note);
      }

      return {
        type: 'Pre-production',
        note: noteElements.join(': ')
      };
    };

    $scope.$watch(
      function() {
        return _self.active;
      },
      function() {
        if (_self.active) {
          _self.reset();
        }
      }
    );

    _self.ps = {
      valid: false,
      required: false,
      label: 'Select Reason',
      options: [],
      note: '',
      reason: '',
      detail: '',
    };

    _self.psDetail = {
      label: 'Select Detail',
      options: []
    };

    $scope.$watch(
      function() {
        return _self.rejectionReasons;
      },
      function() {
        _self.ps.options = _self.rejectionReasons.map(r => { return r.text; });
      }
    );

    _self.modal = {
      working: false,
      data: {
        heading: {
          title: 'Reject Asset',
          copy: 'Select a reason for asset rejection'
        },
        cta: {
          primary: 'Continue',
          secondary: 'Cancel'
        }
      }
    };

    _self.checkValidity = function() {
      if (_self.ps.reason === '') {
        _self.ps.valid = false;
        return;
      }
      if (_self.ps.detail === '') {
        _self.ps.valid = false;
        return;
      }
      _self.ps.valid = true;
      return;
    };

    _self.onPsSelectionChange = function(option) {
      _self.ps.reason = option;
      _self.ps.detail = null;
      if(!!_self.rejectionReasons && _self.rejectionReasons.length > 0) {
        _self.psDetail.options = _self.rejectionReasons.find(r => r.text === _self.ps.reason).details.map(d => d.text).sort();
      }
      _self.checkValidity();
    };

    _self.onPsDetailSelectionChange = function(option) {
      _self.ps.detail = option;
      _self.checkValidity();
    };

    _self.onTextChange = function($event) {
      if ($event.valid) {
        _self.ps.note = $event.text;
      }
      _self.checkValidity();
    };

    _self.needsAnnotations = function() {
      // clean up in ICONS-4012 [FE] Asset-Level QA Rejections III
      // currently rejections will require annotations until ICONS-4012 when annotations will be optional to signal a rejection
      return true;
    };

    _self.reset = function() {
      _self.modal.working = false;
      _self.ps.note = '';
      _self.ps.reason = '';
      _self.ps.detail = '';
      _self.ps.required = false;
      _self.ps.valid = false;
      _self.active = true;
    };

    _self.rejectAsset = function() {
      _self.modal.working = true;
      _self.onRejection({
        $event: {
          annotate: _self.needsAnnotations(),
          psData: _self.getProblemSolveData(),
          contactProductTeam: false,
          rejectionReason: _self.ps.reason,
          rejectionReasonDetail: _self.ps.detail
        }
      });
    };
  },
  templateUrl: 'templates/partials/qa/rejection.tpl.html'
};

module.exports = qaRejectionComponent;
