'use strict';

var angular = require('angular');
var appendTransform = require('../vendor/appendTransform');
var moment = require('moment-timezone');

var JobService = /*@ngInject*/ function(
  $http,
  $q,
  $filter,
  Session,
  REST_URL,
  SEARCH_CONSTANTS,
  META_STATUS,
  ASSET_STATUS,
  APP_DATA,
  ACTIVITY,
  EventLoggerService,
  PRIORITY_CROP_TYPE_SIZES,
  $state
) {
  var _self = this;
  _self.shootTypeMerchTypeMap = null;
  _self.cropTypeDimensions = {};
  _self.availableCropTypes = null;

  _self.getProductIdentifier = function(job) {
    if (!job.product) {
      return undefined;
    }
    return job.product.productId || job.product.styleColor || job.product.id;
  };

  _self.getProductIdentifierType = function(job) {
    if (job.product && job.product.productId) {
      return 'by-product-id';
    } else if (job.product && job.product.styleColor) {
      return 'by-style-color';
    } else {
      return 'by-id';
    }
  };

  _self.getAvailableShootTypes = function() {
    return $http.get(`${REST_URL.base}/shoottypes`);
  };

  _self.getAvailableViewCodes = function() {
    return $http.get(`${REST_URL.base}/viewcodes`);
  };

  _self.getAvailableCropTypes = function() {
    if(!!_self.availableCropTypes) {
      return $q.when(_self.availableCropTypes);
    }
    const url = `${REST_URL.base}/croptypes`;
    var deferred = $q.defer();
    _self.availableCropTypes = {};
    $http.get(url).then(function(response) {
      response.data.forEach(cropType => {
        _self.availableCropTypes[cropType.id] = cropType;
      });

      return deferred.resolve(_self.availableCropTypes);
    });

    return deferred.promise;
  };

  _self.getDeliveryDestinations = function(merchType, shootType, onSuccess, onError) {
    var endpointURL = `${REST_URL.base}/merch-type/${merchType}/shoot-type/${shootType}/destinations`;
    $http.get(endpointURL)
      .then(function(response) {
        if (response.status === 200) {
          if(response.data.length && response.data !== null) {
            var destArr = [];
            response.data.map(function (delivDest) {
              destArr.push(delivDest.name.replace(' (Publish)', ''));
            });
            onSuccess(destArr.join(', '));
          } else {
            onSuccess('None'); // No destinations for this ST/MT combo, but still good request.
          }
        } else {
          onError(`Error: Delivery destination request received unexpected status of ${response.status}`);
        }
      })
      .catch(function (error) {
        onError(`Error: ${JSON.stringify(error)}`); // Bad request, likely an unknown ST or MT in request.
      });
  };

  _self.getCropTypeDimensions = function(shootTypeId, merchType, cropType) {
    if(!!_self.cropTypeDimensions[cropType]) {
      return $q.when(_self.cropTypeDimensions[cropType]);
    }
    const url = `${REST_URL.base}/merch-type/${merchType}/shoot-type/${shootTypeId}`;
    var deferred = $q.defer();
    $http.get(url).then(function(response) {
      response.data.views.default.concat(response.data.views.other).flatMap(d => d.crops).forEach(function (cropTypeData) {
        _self.cropTypeDimensions[cropTypeData.id] = {
          width: cropTypeData.widthPixels,
          height: cropTypeData.heightPixels,
        };
      });
      return deferred.resolve(_self.cropTypeDimensions[cropType]);
    });

    return deferred.promise;
  };

  _self.prioritySortCropTypes = function(cropTypes) {
    if (!!_self.availableCropTypes && Array.isArray(cropTypes)) {
      return cropTypes.sort((a,b) => _self.cropTypeSort(a,b));
    }
    //don't have available crops yet, just use alpha sort
    return cropTypes.sort();
  };

  _self.cropTypeSort = function(a, b) {
    var aCropType = _self.availableCropTypes[a];
    var bCropType = _self.availableCropTypes[b];

    if (aCropType && bCropType) {
      if (_self.isPriorityCrop(aCropType) && !_self.isPriorityCrop(bCropType)) {
        return -1;
      }

      if (!_self.isPriorityCrop(aCropType) && _self.isPriorityCrop(bCropType)) {
        return 1;
      }

      // if both are priority crops, then handle them special if one of them is 1x1, so 1x1 is always first
      if (_self.isPriorityCrop(aCropType) && _self.isPriorityCrop(bCropType)) {
        if (aCropType.heightPixels === PRIORITY_CROP_TYPE_SIZES.ONE_BY_ONE.height &&
          aCropType.widthPixels === PRIORITY_CROP_TYPE_SIZES.ONE_BY_ONE.width) {
          return -1;
        } else if (bCropType.heightPixels === PRIORITY_CROP_TYPE_SIZES.ONE_BY_ONE.height &&
          bCropType.widthPixels === PRIORITY_CROP_TYPE_SIZES.ONE_BY_ONE.width) {
          return 1;
        }
      }

      return a.localeCompare(b);
    }
  };

  _self.isPriorityCrop = function(cropType) {
    if ((cropType.heightPixels === PRIORITY_CROP_TYPE_SIZES.ONE_BY_ONE.height &&
      cropType.widthPixels === PRIORITY_CROP_TYPE_SIZES.ONE_BY_ONE.width) ||
      (cropType.heightPixels === PRIORITY_CROP_TYPE_SIZES.FOUR_BY_FIVE.height &&
        cropType.widthPixels === PRIORITY_CROP_TYPE_SIZES.FOUR_BY_FIVE.width)) {
      return true;
    }

    return false;
  };

  // grab view codes from the shoot type
  _self.getViewCodesFromShootTypeMerchType = function (shootTypeId, merchType) {
    var deferred = $q.defer();
    // /api/viewcodes/{shootTypeId}
    var url = `${REST_URL.base}/shoottypes/${shootTypeId}?onlyViews=true`;
    if (merchType) {
      url = `${REST_URL.base}/merch-type/${merchType}/shoot-type/${shootTypeId}?onlyViews=true`;
    }

    $http.get(url).then(
      function (response) {
        this.working = false;
        // this should return an array of view codes...
        var viewCodes = { available: [], defaults:[] };

        if (!response.data.views || !response.data.views.available) {
          deferred.reject( { title: "Bad server response while retrieving view codes for shoot type", data: response });
          return;
        }

        response.data.views.default.forEach((viewCode) => {
          viewCodes.defaults.push(viewCode.id);
        });
        response.data.views.available.forEach((viewCode) => {
          viewCodes.available.push(viewCode.id);
        });
        viewCodes.available.sort();
        deferred.resolve(viewCodes);
      }.bind(this),
      function (response) {
        this.working = false;
        deferred.reject({ title: 'Failed to retrieve view codes from shoot type.', data: response});
      }.bind(this)
    );

    return deferred.promise;
  };

  // when claiming asset jobs, an entry for each asset job is returned
  _self.filterDuplicateJobs = function(jobs) {
    var jobIds = [];

    return jobs.filter(function(job) {
      if (jobIds.indexOf(job.id) >= 0) {
        return false;
      } else {
        if (job.id) {
          jobIds.push(job.id);
        }
        return true;
      }
    });
  };

  _self.currentJob = {};

  _self.getImageByType = function(asset, type) {
    var url = '';
    //Get the url, insert PNG into it (images will always be PNGs), and append the type (size). URLs/filenames should always be in this format:
    // https://gbipapiuat.sandbox.dbt.svs.nike.com/api/products/654201/assets/QA3361-744-17569657_D_A.PNG/1.0
    if (asset && asset.links) {
      url = asset.links.filter(function(a) {
        return a.rel === 'self';
      });
      url = url[0].href;
    }

    if (url.length > 0 && type === 'large') {
      return url + '?thumbnail=964';
    } else if (url.length > 0 && type === 'all') {
      return url + '?thumbnail=164';
    } else {
      console.log('Unexpected thumbnail type and/or url', type, asset);
    }

    return url;
  };

  _self.getImagePath = function(jobId, filename) {
    var url = [REST_URL.base, 'api/products', jobId, 'assets', filename].join(
      '/'
    );
    return url;
  };

  _self.getReferenceAsset = function(assetJob) {
    if (!assetJob) {
      return;
    }
    return (
      assetJob.relatedAssets.Publish ||
      assetJob.relatedAssets.Deliverable ||
      assetJob.relatedAssets.Worker ||
      assetJob.relatedAssets.Unedited
    );
  };

  _self.getDownloadAsset = function(assetJob) {
    return (
      assetJob.relatedAssets.Worker ||
      assetJob.relatedAssets.Deliverable ||
      assetJob.relatedAssets.Unedited
    );
  };

  _self.fetchAnnotation = function(asset) {
    var annotation;

    asset.links && asset.links.some(function(link) {
      if (link.rel === 'annotation') {
        annotation = link.href;
        return true;
      }
    });
    if (annotation) {
      annotation = annotation.replace('iconapi.svs.nike.com', 'iconapi.dbt.svs.nike.com');
      return $http.get(annotation, { cache: true }).then(function(response) {
        response.data = angular.isArray(response.data)
          ? response.data
          : [response.data]; //backwards compatiablity
        return $q.when(response);
      });
    } else {
      return $q.when({ data: [] });
    }
  };

  _self.transformJobsConfig = {
    transformResponse: appendTransform(
      $http.defaults.transformResponse,
      function(response) {
        var transformedResponse;

        if (angular.isArray(response)) {
          transformedResponse = response.map(_self.setExtraJobData);
        } else {
          transformedResponse = _self.setExtraJobData(response);
        }

        return transformedResponse;
      }
    )
  };

  _self.setExtraAssigneeData = function(tasks) {
    if (angular.isArray(tasks)) {
      tasks.forEach(function(task) {
        if (angular.isObject(task.assignee)) {
          task.assignee.name = [
            task.assignee.firstName,
            task.assignee.lastName
          ].join(' ');
          task.assignee.userName = task.assignee.username;
        }
      });
    }
  };

  // TODO don't call this method directly, transform the http response using the above config
  _self.setExtraJobData = function(job) {
    job.active =
      job.status === META_STATUS.jobPending || //Job Pending
      job.status === META_STATUS.inProgress || //In Progress
      job.status === META_STATUS.problemSolve || //Problem Solve
      job.status === META_STATUS.qa || //In Image QA
      job.status === META_STATUS.post || //In Post
      job.status === META_STATUS.review; //In Product Review
    _self.setExtraAssigneeData(job.tasks);

    if (job.assetJobs) {
      job.assetJobs.forEach(function(assetJob) {
        assetJob.relatedAssets = {};
        assetJob.cropTypes = false;
        assetJob.assetFilenames.forEach(function(filename) {
          job.product.assets.forEach(function(asset) {
            if (filename.toUpperCase() === asset.fileName.toUpperCase()) {
              if (asset.cropType) {
                if (
                  !assetJob.relatedAssets[asset.type] ||
                  (job.expectedCropTypes.length &&
                    job.expectedCropTypes[0] === asset.cropType)
                ) {
                  //this sets the related assets to the primary crop type
                  assetJob.relatedAssets[asset.type] = angular.copy(asset);
                }
                //if (['Deliverable', 'Published'].indexOf(asset.type) != -1) {
                if (!assetJob.cropTypes) {
                  assetJob.cropTypes = {};
                }
                assetJob.cropTypes[asset.cropType] = asset;
                //}
              } else {
                if (!assetJob.relatedAssets[asset.type]) {
                  assetJob.relatedAssets[asset.type] = angular.copy(asset);
                }
              }
              assetJob.viewCode = asset.viewCode;
            }
          });
        });
        // mark asset job as active or inactive
        assetJob.active = assetJob.tasks && assetJob.tasks.length > 0;

        _self.setExtraAssigneeData(assetJob.tasks);
      });

      // sort asset jobs by viewcode
      job.assetJobs = $filter('orderBy')(job.assetJobs, 'viewCode');
    }

    if (job.merchJob) {
      // mark merch job as active or inactive
      job.merchJob.active = job.merchJob.tasks.length ? true : false;

      _self.setExtraAssigneeData(job.merchJob.tasks);
    }

    return job;
  };

  _self.submitNewJob = function(newJob) {
    newJob.source = 'Manual';
    return $http.post(REST_URL.jobs, newJob);
  };

  _self.moveToProblemSolve = function(jobId, taskId, params) {
    var config = {
      properties: {
        problemSolve: true
      }
    };
    angular.merge(config.properties, params);
    return $http.post(
      [REST_URL.jobs, jobId, taskId, 'complete'].join('/'),
      config,
      _self.transformJobsConfig
    );
  };

  _self.resolveProblemSolve = function(jobId, taskId, props) {
    var config = { properties: props };
    return $http.post(
      [REST_URL.jobs, jobId, taskId, 'complete'].join('/'),
      config,
      _self.transformJobsConfig
    );
  };

  _self.completeJob = function(jobId, taskId, props) {
    var config = { properties: props };
    return $http.post(
      [REST_URL.jobs, jobId, taskId, 'complete'].join('/'),
      config,
      _self.transformJobsConfig
    );
  };

  _self.convertDate = function(data, format) {
    SEARCH_CONSTANTS.dateProps.forEach(function(date) {
      if (data[date] !== undefined) {
        data[date] = $filter('date')(data[date], format);
      }
      if (data.product && data.product[date] !== undefined) {
        data.product[date] = $filter('date')(data.product[date], format);
      }
      if (data.tasks) {
        data.tasks.forEach(function(task) {
          if (task[date] !== undefined) {
            task[date] = $filter('date')(task[date], format);
          }
        });
      }
    });
  };

  _self.getShipments = function() {
    return $http.get(REST_URL.jobs + '/shipped', _self.transformJobsConfig);
  };

  _self.getJobListDetail = function(ids) {
    var promises = ids.map(function(id) {
      return _self.getJobDetail(id);
    });
    return $q.all(promises);
  };

  _self.getJobDetail = function(id, customHeaders) {
    var headers = {};
    if (customHeaders) {
      headers = customHeaders;
    }

    var extra = _self.transformJobsConfig;
    extra.headers = headers;

    return $http.get(REST_URL.jobs + '/' + id, extra);
  };

  _self.getGtinJobDetail = function(id, customHeaders) {
    var headers = {};
    if (customHeaders) {
      $headers = customHeaders;
    }

    var extra = _self.transformJobsConfig;
    extra.headers = headers;

    //String.padStart polyfill
    var padStart = function(sourceString, targetLength, padString) {
      targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
      padString = String(padString || ' ');
      if (sourceString.length > targetLength) {
        return String(sourceString);
      } else {
        targetLength = targetLength - sourceString.length;
        if (targetLength > padString.length) {
          padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(sourceString);
      }
    };

    return $http.get(
      REST_URL.gtin + '?gtin=' + padStart(id, 14, '0'),
      extra
    );
  };

  _self.submitJobUpdate = function(updateJob, jobID) {
    return $http.put(REST_URL.jobs + '/' + jobID, updateJob);
  };

  _self.prepLaunchDate = function(job) {
    if (!job.launchDate || !moment(job.launchDate).isValid()) {
      job.launchDate = undefined;
    }
  };

  _self.update = function(job) {
    _self.prepLaunchDate(job);
    if (job.initialLaunchDate !== job.launchDate) {
      EventLoggerService.track('INITIATE_JOB_DATE_MISMATCH', {
        initiateLaunchDate: job.initialLaunchDate,
        launchDate: job.launchDate,
        productDbId: job.productDBId
      });
    }
    EventLoggerService.track(
      'UPDATE_JOB',
      {
        page: $state.current.name,
        location: _self.stateData ? ' ' + _self.stateData.location : '',
        jobId: job.id
      },
      true
    );
    return $http.put(
      REST_URL.jobs + '/' + job.id,
      job,
      _self.transformJobsConfig
    );
  };

  _self.create = function(job) {
    if (job.initialLaunchDate !== job.launchDate) {
      EventLoggerService.track('INITIATE_JOB_DATE_MISMATCH', {
        initiateLaunchDate: job.initialLaunchDate,
        launchDate: job.launchDate,
        jobId: job.id
      });
    }
    EventLoggerService.track(
      'INITIATE_JOB',
      {
        page: $state.current.name,
        location: _self.stateData ? ' ' + _self.stateData.location : '',
        jobId: job.id
      },
      true
    );
    _self.prepLaunchDate(job);
    return $http.post(REST_URL.jobs, job, _self.transformJobsConfig);
  };

  _self.convert = function(job) {
    _self.prepLaunchDate(job);
    return $http.post(
      [REST_URL.jobs, job.id, 'convert'].join('/'),
      job,
      _self.transformJobsConfig
    );
  };

  _self.closeJob = function(job) {
    var tasks = [];
    if (job.merchJob && job.merchJob.active) {
      tasks.push({
        jobId: job.merchJob.id,
        taskId: job.merchJob.tasks[0].id,
        properties: {
          finish: 'close'
        }
      });
    }
    tasks.push({
      jobId: job.id,
      taskId: job.tasks[0].id,
      properties: {
        finish: 'close'
      }
    });
    return $http.post([REST_URL.jobs, 'complete'].join('/'), {
      tasksToComplete: tasks
    });
  };

  _self.getAssignedUser = function(job, type) {
    var assignee;

    if (type === 'meta') {
      if (job.tasks && job.tasks.length) {
        assignee = job.tasks[0].assignee;
      }
    } else if (type === 'assetJobs' && job.assetJobs && job.assetJobs.length) {
      job.assetJobs.forEach(function(assetJob) {
        if (
          assetJob.status === ASSET_STATUS.postProd &&
          assetJob.tasks &&
          assetJob.tasks[0].assignee
        ) {
          assignee = assetJob.tasks[0].assignee;
        }
      });
    }

    return assignee;
  };

  _self.unclaimTask = function(jobId, taskId) {
    return $http.get(
      [REST_URL.jobs, jobId, taskId, 'unclaim'].join('/'),
      _self.transformJobsConfig
    );
  };

  // Unclaim all tasks associated with a job
  _self.unclaimTasks = function(jobs) {
    var deferred = $q.defer();
    var unclaimTaskCommand = jobs.map(function(job) {
      return job.tasks.map(function(task) {
        return {
          jobId: job.id,
          taskId: task.id
        };
      });
    });

    unclaimTaskCommand = [].concat.apply([], unclaimTaskCommand);

    $http
      .post(
        [REST_URL.jobs, 'unclaim', _self.transformJobsConfig].join('/'),
        unclaimTaskCommand
      )
      .then(function(response) {
        response.data = _self.filterDuplicateJobs(response.data);
        deferred.resolve(response);
      })
      .catch(function(response) {
        deferred.reject(response);
      });

    return deferred.promise;
  };

  // Return jobs of the specified type, filtered by assignee
  _self.getFilteredJobs = function(jobs, assignee, type) {
    if (!jobs || !assignee) {
      return jobs;
    }

    return jobs.filter(function(job) {
      var matchingSubJobs = [];

      if (type === 'meta') {
        if (!job.tasks.length) {
          return false;
        }
        return Session.compareUser(assignee.userName);
      } else {
        matchingSubJobs = job[type].filter(function(subJob) {
          if (!subJob.tasks.length) {
            return false;
          }
          return Session.compareUser(subJob.tasks[0].assignee);
        });

        if (matchingSubJobs.length) {
          return true;
        }
      }
    });
  };

  _self.canUpdate = function(status) {
    return (
      status === META_STATUS.jobPending || status === META_STATUS.problemSolve
    );
  };

  /**
   * Properties and methods related to operations on activity page.
   * @type {Object}
   */
  _self.activity = new function() {
    this.updateData = {};

    this.setUpdateData = function(newData) {
      angular.merge(this.updateData, newData);
    };

    this.resetUpdateData = function() {
      angular.copy(
        {
          copy: '',
          isUserJob: true,
          job: {},
          jobAction: '',
          jobDisplay: {},
          jobType: '',
          post: {},
          title: ''
        },
        this.updateData
      );
    };

    /**
     * Properties and methods related to duplicates.
     * @type {Object}
     */
    this.duplicates = new function() {
      this.working = false;

      /**
       * The system-created product object used in job detail state and is passed
       * from the activity state when resolving a duplicate by manually editing it.
       * @type {Object}
       */
      var systemCreatedProduct = {};

      this.setSystemCreatedProduct = function(newProduct) {
        angular.copy(newProduct, systemCreatedProduct);
      };

      this.submitOverwriteUpdate = function(postData) {
        var deferred = $q.defer();

        // http://apidoc.nike.ixxus.io/#!/default/post_cpspub_sync_resolve_duplicate
        // get data ready to submit
        postData.resolution =
          ACTIVITY.updateTypes.duplicate.overWriteManual.resolution;
        postData.userCreatedProduct = {};
        angular.copy(postData.currentJob.product, postData.userCreatedProduct);
        delete postData.currentJob;
        delete postData['@class'];

        this.working = true;

        $http({
          method: 'post',
          url: REST_URL.resolve.duplicate,
          data: postData
        }).then(
          function(response) {
            this.working = false;
            deferred.resolve(response);
          }.bind(this),
          function(response) {
            this.working = false;
            deferred.reject(response);
          }.bind(this)
        );

        return deferred.promise;
      };

      this.submitManualUpdate = function(updateJob) {
        var deferred = $q.defer();

        updateJob = {
          userCreatedProduct: updateJob.product,
          systemCreatedProduct: systemCreatedProduct,
          resolution: ACTIVITY.updateTypes.duplicate.editManual.resolution
        };
        $http({
          method: 'post',
          url: REST_URL.resolve.duplicate,
          data: updateJob
        }).then(
          function(response) {
            this.setSystemCreatedProduct({});
            deferred.resolve(response);
          }.bind(this),
          function(response) {
            deferred.reject(response);
          }
        );

        return deferred.promise;
      };
    }();

    /**
     * Properties and methods related to reactivations.
     * @type {Object}
     */
    this.reactivations = new function() {
      this.working = false;

      this.submit = function(postData, doProblemSolve) {
        var deferred = $q.defer();

        // http://apidoc.nike.ixxus.io/#!/default/post_cpspub_sync_resolve_reactivate
        // get data ready to submit
        // startFromBeginning = !doProblemSolve
        postData.problemSolve = doProblemSolve;
        postData.job = {};
        angular.copy(postData.currentJob, postData.job);
        delete postData.currentJob;
        delete postData['@class'];

        this.working = true;

        $http({
          method: 'post',
          url: REST_URL.resolve.reactivate,
          data: postData
        }).then(
          function(response) {
            this.working = false;
            deferred.resolve(response);
          }.bind(this),
          function(response) {
            this.working = false;
            deferred.reject(response);
          }.bind(this)
        );

        return deferred.promise;
      };
    }();

    this.resetUpdateData();
  }();

  _self.getMerch = function (merchId) {
    return $http.get(`${REST_URL.merch}/${merchId}`);
  };

  _self.putMerchManagementJobs = function(jobInput) {
    var merch = [];

    for (let i = 0; i < jobInput.length; i++) {
      var current = jobInput[i]._source;
      merch.push({
        merchId: current.merchId,
        size: current.size,
        merchType: jobInput[i].merchType || current.merchType,
        location: jobInput[i].location || current.location,
        businessPartner: jobInput[i].businessPartner || current.businessPartner,
        version: current.version,
        studioId: jobInput[i].studioId || current.studioId
      });
    }

    var payload = {
      merch: merch
    };
    return $http.put(REST_URL.merch, payload, '');
  };

  _self.deleteMerchManagementJob = function(job, reason, note) {
    const payload = {
      delete: true,
      reason,
      note,
    };
    return $http.patch([REST_URL.merch, job.merchId].join('/'), payload);
  };

  _self.getJobLinks = function(fromJobId) {
    return $http.get([REST_URL.jobs, fromJobId, 'links'].join('/'));
  };

  _self.validateMerchTypeShootTypeCombination = function(merchType, shootType, onError) {
    let isValid = true;
    if(_self.shootTypeMerchTypeMap == null) {
      _self.loadMerchTypeShootTypeMap();
      isValid = false;
    } else {
      isValid = !!_self.shootTypeMerchTypeMap[merchType] && _self.shootTypeMerchTypeMap[merchType].includes(shootType);
    }
    if(!isValid && !!onError && typeof(onError) === "function") {
      onError({
        data: {
          error: `Invalid Shoot Type / Merch Type combination: [${shootType}] [${merchType}]`
        }
      });
    }
    return isValid;
  };

  _self.loadMerchTypeShootTypeMap = function() {
    var deferred = $q.defer();
    if(_self.shootTypeMerchTypeMap == null) {
      try {
        $http.get(REST_URL.data.merchShootTypes).then(function(response) {
          _self.shootTypeMerchTypeMap = {};
          for(let merchTypeObject of response.data) {
            _self.shootTypeMerchTypeMap[merchTypeObject.id] = merchTypeObject.shootTypes.map(st => st.code);
          }
          deferred.resolve(_self.shootTypeMerchTypeMap);
        });
      } catch (ex) {
        console.log("Failed to load the merchType-shootType map for determining valid combinations.");
      }
    } else {
      return $q.resolve(_self.shootTypeMerchTypeMap);
    }
    return deferred.promise;
  };

};

module.exports = JobService;
