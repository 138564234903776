'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function dropdownMenuDirective() {
      return {
        restrict: 'A',
        require: '?^dropdown',
        link: function(scope, element, attrs, dropdownCtrl) {
          if (!dropdownCtrl) {
            return;
          }

          element.addClass('dropdown-menu');

          dropdownCtrl.dropdownMenu = element;

          // WAI-ARIA
          element.attr({
            'aria-labelledby': dropdownCtrl.getGuid()
          });
        }
      };
    }
  ];
}

module.exports = register;
