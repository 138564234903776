'use strict';

var angular = require('angular');

var shootDetailsInitiateJob = {
  bindings: {
    base: '<',
    model: '<',
    onChange: '&',
    onEdit: '&',
    delayUpdate: '<',
    disabled: '<',
    jobType: '<',
    merchType: '<',
    parentError: '=',
    parentFormValidate: '&'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $rootScope,
    $http,
    $timeout,
    JobService,
    REST_URL,
    SEARCH_CONSTANTS,
    AuthService,
    USER_ROLES,
    APP_DATA,
    $q
  ) {
    var _self = this,
      productIdentifier,
      productIdentifierType,
      businessPartner;

    _self.editMode = true;
    _self.displayViewCodes = true;
    _self.error = { title: '', data: '' };
    _self.viewCodes = { available: [], defaults: [] };

    $scope.$on('shootDetailsInitiateJob_fetchViewCodesFromShootType', function() {
      _self.model.expectedViews = [];
      _self.fetchViewCodesFromShootType();
    });

    $scope.$on('shootDetailsInitiateJob_hideShootDetailViewCodes', function() {
      _self.displayViewCodes = false;
    });

    _self.formData = {
      shootType: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['shootType.code'].name,
        required: true,
        options: []
      },
      expectedViewCodes: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['expectedViewCodes'].name,
        required: true,
        options: []
      }
    };

    _self.onSetShootType = function(option) {
      if (_self.model.expectedViews) {
        _self.model.expectedViews = [];
      }
      _self.formData.shootType.value = angular.copy(option);
      _self.setShootType(option);
      if(!!_self.merchType && !JobService.validateMerchTypeShootTypeCombination(_self.merchType, option.data.code, (response) => { _self.parentError = { title: response.data.error }; })) {
        _self.displayViewCodes = false;
        _self.parentFormValidate();
        return;
      }
      _self.fetchViewCodesFromShootType().then((res) => {});
    }

    _self.setShootType = function(option) {
      _self.displayViewCodes = true;
      _self.formData.shootType.value = angular.copy(option);
      if (_self.formData.shootType.value) {
        _self.updateViewCodesOnShootTypeSelect();
      }
      _self.updateShootDetails();
    };

    _self.setDefaultShootType = function(referenceJob) {
      if (referenceJob && referenceJob.shootType === undefined) {
        return;
      }

      var hasShootType = false;
      //Iterate over the shootType options and set the selected value if available
      _self.formData.shootType.options.map(function(option) {
        if (option.data.code === referenceJob.shootType.code) {
          hasShootType = true;
          _self.setShootType(option);
        }
      });

      if (!hasShootType) {
        _self.setShootType(undefined);
        _self.error.title = 'Base Job Shoot Type is invalid for this Product.';
      } else {
        _self.error.title = '';
      }
    };

    _self.setViewCodes = function(referenceJob) {
      _self.selectedViews = [];
      if (referenceJob.jobType === 'External Delivery') {
        referenceJob.expectedViews = ['A'];
      }
      _self.formData.expectedViewCodes.options.map(function(code) {
        //If the job defines expectedViews then use those, otherwise use the default views for the shootType
        if (referenceJob.expectedViews !== undefined) {
          if (referenceJob.expectedViews.indexOf(code.label) !== -1) {
            code.selected = true;
            code.disabled = false;
            _self.selectedViews.push(code);
          } else {
            code.selected = false;
            code.disabled = false;
          }

          if (
            [
              'External Delivery',
              'Variation',
              'Copy',
              'Copy with Edits',
              'Redelivery',
              'Take Up',
              'Take Down'
            ].indexOf(_self.jobType) !== -1 &&
            !code.selected
          ) {
            code.disabled = true;
          }
        } else {
          if (
            _self.formData.shootType &&
            _self.formData.shootType.value &&
            _self.formData.shootType.value.data.defaultExpectedViews.indexOf(
              code.label
            ) !== -1
          ) {
            code.selected = true;
            code.disabled = false;
            _self.selectedViews.push(code);
          } else {
            code.selected = false;
            code.disabled = false;
          }

          referenceJob.expectedViews = !!_self.formData.shootType.value ?
            _self.formData.shootType.value.data.defaultExpectedViews : [];
        }
      });

      _self.updateShootDetails();
    };

    _self.updateViewCodesFromFetch = function(res, expectedViewsOverride) {
      _self.viewCodes = res;
      _self.updateViewCodesOnShootTypeSelect(expectedViewsOverride);
      _self.updateShootDetails();
    };

    _self.fetchViewCodesFromShootType = function (expectedViewsOverride) {
      var deferred = $q.defer();
      if(!_self.formData.shootType.value) {
        _self.displayViewCodes = false;
        _self.updateViewCodesFromFetch({
          available: [],
          defaults: []
        }, expectedViewsOverride);
        deferred.reject();
      } else {
        JobService.getViewCodesFromShootTypeMerchType(_self.formData.shootType.value.data.code, _self.merchType).then(function (res) {
          _self.error = {};
          _self.displayViewCodes = true;
          _self.updateViewCodesFromFetch(res, expectedViewsOverride);
          deferred.resolve({ data: res });
        }, function (err) {
          _self.displayViewCodes = false;
          _self.updateViewCodesFromFetch({
            available: [],
            defaults: []
          }, expectedViewsOverride);
          deferred.reject();
        });
      }
      return deferred.promise;
    }

    _self.updateViewCodesOnShootTypeSelect = function(expectedViewsOverride) {
        _self.selectedViews = [];

        if (_self.jobType === 'External Delivery') {
          _self.viewCodes.defaults = ['A'];
        }

        if (_self.model && !_self.model.expectedViews) {
          _self.model.expectedViews = [];
        }

        if (expectedViewsOverride) {
          _self.viewCodes.defaults = expectedViewsOverride;
        }

        _self.formData.expectedViewCodes.options = _self.viewCodes.available.map(function (label) {
          const code = {
            label,
            default: false,
            selected: true,
            disabled: false
          };

          if ((_self.viewCodes.defaults.includes(code.label) && !_self.model)
            || (_self.viewCodes.defaults.includes(code.label) && _self.model && _self.model.expectedViews.length === 0)
            || (_self.model && _self.model.expectedViews.includes(code.label))
          ) {
            code.selected = true;
            code.disabled = false;
            _self.selectedViews.push(code);
          } else {
            code.selected = false;
            code.disabled = false;
          }

          if (
            [
              'External Delivery',
              'Variation',
              'Copy',
              'Copy with Edits',
              'Redelivery',
              'Take Up',
              'Take Down'
            ].includes(_self.jobType) &&
            !code.selected
          ) {
            code.disabled = true;
          }

          return code;
        });
      };

    _self.updateShootDetails = function() {
      var expectedViewCodes = _self.formData.expectedViewCodes.options
        .filter(function(code) {
          if (code.selected) {
            return true;
          }
        })
        .map(function(code) {
          return code.label;
        });

      if(!!_self.onChange) {
        _self.onChange({
          $event: {
            shootType: _self.formData.shootType.value,
            expectedViewCodes: expectedViewCodes
          }
        });
      }
    };

    _self.updateViewCodes = function(viewCode) {
      _self.selectedViews = [];
      _self.formData.expectedViewCodes.options = _self.formData.expectedViewCodes.options.map(
        function(code) {
          if (code.label === viewCode.label) {
            code.selected = viewCode.selected;
          }

          if (code.selected) {
            _self.selectedViews.push(code);
          }

          return code;
        }
      );

      _self.updateShootDetails();
    };

    _self.closeViewCodeToggle = function() {
      _self.editMode = false;
    };

    _self.toggleEditMode = function() {
      var boolean = (arguments[0] && arguments[0]['boolean']) || false;
      var $event = (arguments[0] && arguments[0]['$event']) || false;

      //Open it if it's closed, but clicking inside while open should not close it. Clicking outside will close it.s
      _self.editMode = _self.editMode ? _self.editMode : !_self.editMode;

      if ($event) {
        $event.stopPropagation();
      }
    };

    // Shoot type can only be edited on new jobs or existing job that has no assets yet.
    // Expected views can only be edited until all the expected views are approved (thus it leaves In Photography)
    _self.setPermissions = function() {
      if(!_self.model) {
        return;
      }
      _self.canEditShootType =
        !_self.base &&
        (!_self.model.assetJobs || _self.model.assetJobs.length === 0);
      _self.canEditViews =
        !!_self.base ||
        (_self.model.active &&
          !_self.model.expectedViewsApproved &&
          AuthService.isAuthorized([
            USER_ROLES.merchOps,
            USER_ROLES.studioStylist,
            USER_ROLES.studioOps,
            USER_ROLES.superUser
          ]));
    };

    _self.reset = function() {
      _self.formData.shootType.value = undefined;
      _self.formData.expectedViewCodes.options = [];
      _self.error = {};
      _self.disabled = false;
      _self.model = undefined;
      _self.base = undefined;
    };

    _self.$onInit = function() {
      _self.editMode = false;

      $scope.$on('closeViewCodeToggle', _self.closeViewCodeToggle);
      $scope.$watch('$ctrl.merchType', function() {
        _self.setPermissions();
      });
      $scope.$watch('$ctrl.jobType', function() {
        _self.setPermissions();
      });

      $q.all([
        JobService.getAvailableShootTypes(),
        JobService.getAvailableViewCodes(),
      ]).then(function(responses) {
        //Load the shootType options, if one is marked as the default then set it here.
        _self.formData.shootType.options = responses[0].data.map(function(
          data
        ) {
          if (data.shootTypeDefault) {
            _self.setShootType({
              label: `${data.code} ${data.name}`,
              data: data
            });
          }

          return {
            label: `${data.code} ${data.name}`,
            data: data
          };
        });

        _self.formData.expectedViewCodes.options = responses[1].data
          .sort()
          .map(function(data) {
            return {
              label: data.id,
              selected: false,
              disabled: false
            };
          });
      });
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (_self.model === undefined) {
          return;
        }
        _self.model = angular.copy(_self.model);
        _self.error = {};

        _self.formData.expectedViewCodes.options = _self.formData.expectedViewCodes.options.map(
          function(data) {
            return {
              label: data,
              selected: false,
              disabled: false
            };
          }
        );

        $q.all([
          JobService.getAvailableShootTypes(),
          JobService.getAvailableViewCodes(),
        ]).then(function(responses) {
          //Load the shootType options, if one is marked as the default then set it here.
          _self.formData.shootType.options = responses[0].data.map(function(
            data
          ) {
            if (data.shootTypeDefault) {
              _self.setShootType({
                label: `${data.code} ${data.name}`,
                data: data
              });
            }

            return {
              label: `${data.code} ${data.name}`,
              data: data
            };
          });

          _self.formData.expectedViewCodes.options = responses[1].data
            .sort()
            .map(function(data) {
              return {
                label: data.id,
                selected: false,
                disabled: false
              };
            });

          if (!changes.base) {
            _self.setDefaultShootType(_self.model);
            _self.setViewCodes(_self.model);
            _self.setPermissions();
            _self.fetchViewCodesFromShootType().then();
          }
        });
      }

      if (changes.base) {
        // Check if we are unsetting the base job and use model if so
        if (_self.base !== undefined && _self.base.shootType) {
          _self.base = angular.copy(_self.base);

          $q.all([
            JobService.getAvailableShootTypes(),
            JobService.getAvailableViewCodes(),
          ]).then(function(responses) {
            _self.formData.expectedViewCodes.options = responses[1].data
              .sort()
              .map(function(data) {
                return {
                  label: data.id,
                  selected: false,
                  disabled: true
                };
              });

            _self.setDefaultShootType(_self.base);
            _self.setViewCodes(_self.base);
            _self.setPermissions();
            _self.fetchViewCodesFromShootType(_self.base.expectedViews).then();
          });
        }
      }

      if (changes.disabled !== undefined && !changes.disabled) {
        _self.disabled = false;
      }
    };
  },
  templateUrl: 'templates/partials/detail/shootDetailsInitiateJob.tpl.html'
};

module.exports = shootDetailsInitiateJob;
