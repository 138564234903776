'use strict';

var angular = require('angular');
var moment = require('moment');

var initiateJobModalComponent = {
  bindings: {
    active: '=',
    model: '<',
    onUpdate: '&',
    release: '='
  },
  require: {
    parent: '^initiateJob'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $rootScope,
    $q,
    $window,
    $state,
    JobFormService,
    JobTypeService,
    AuthService,
    NotificationService,
    ScanTypeService,
    ScannerService,
    StatusService,
    META_STATUS,
    MERCH_STATUS,
    ASSET_STATUS,
    APP_DATA,
    USER_ROLES,
    JobService,
    $timeout,
    TransferService,
    NoteService,
    SearchService
  ) {
    var _self = this;
    _self.error = {};

    _self.merchTypeMismatchError = "Merch Type doesn't match job.";
    _self.merchLoading = false;

    _self.isAdmin = AuthService.isAdmin();

    // If the form is in a valid state
    _self.isValid = false;
    _self.merchIdTimer = null;

    // Default form data
    _self.formData = angular.copy(JobFormService.formData);
    _self.hasRefreshedProduct = false;
    _self.expectedMerchType = null;

    _self.formData.otherWork = [];
    _self.formData.otherJobs = [];
    _self.formData.otherSearch = [];

    _self.merchCallbackTimeout = 1000;

    // Data to send to modal component
    _self.modal = {
      data: {
        valid: false,
        cta: {
          primary: 'Submit',
          secondary: 'Cancel'
        }
      }
    };

    //Data to initialize IMG Modal for SPIL lineart
    _self.imgModal = {
      active: false,
      src: '',
      name: ''
    };

    // Notifications to show after job was created
    _self.notifications = {
      initiate: {
        merch:
          'Job {{job.id}} has been created for {{job.product.styleColor || job.product.productId}} and updated to {{job.merchJob.status}}.',
        noMerch:
          'Job {{job.id}} has been created for {{job.product.styleColor || job.product.productId}}.'
      },
      update: {
        merch: 'Job {{job.id}} has been updated to {{job.merchJob.status}}.',
        noMerch: 'Job {{job.id}} has been updated.'
      }
    };

    _self.hasMerchEditableJobType = function() {
      return !_self.formData.jobType || ['Standard', 'Quick Standard', 'Reshoot'].includes(_self.formData.jobType.value);
    };

    $scope.$on('scan.update', function(e, info) {
      if (!_self.active || _self.slateNoteHasFocus) {
        return;
      }
      if (ScanTypeService.isMerchId(info)) {
        _self.retrieveMerchData(info);
      }
    });

    $scope.$on('scan.merchId', function(e, params) {
      if (!_self.active || _self.slateNoteHasFocus) {
        return;
      }
      _self.retrieveMerchData(params.term);
    });

    _self.hasMerchTypeErrors = function() {
      return !!_self.expectedMerchType && !!_self.formData.merchType.value &&
        _self.expectedMerchType !== _self.formData.merchType.value;
    };

    _self.retrieveMerchData = function(merchId) {
      if(!_self.hasMerchEditableJobType()) {
        _self.merchLoading = false;
        return;
      }

      if (!merchId) {
        _self.expectedMerchType = null;
      } else {
        JobService.getMerch(merchId).then(function (response) {
          _self.formData.primaryMerchId.value = response.data.merchId;
          _self.expectedMerchType = response.data.merchType;
          setTimeout(function () {
            _self.merchLoading = false;
            _self.formValidate();
            $scope.$digest();
          }, _self.merchCallbackTimeout);
        });
      }
    };

    _self.refreshMerch = function() {
      if(_self.formData.primaryMerchId.value) {
        _self.merchLoading = true;
        _self.retrieveMerchData(_self.formData.primaryMerchId.value);
      }
    };

    _self.setSlateNoteFocus = function(focused) {
      _self.slateNoteHasFocus = focused;
    };

    // Private methods
    var areRequiredFieldsValid = function() {
      // If a form field is required but does not have a value, it is invalid
      var validData = true;
      angular.forEach(_self.formData, function(field) {
        if (validData && typeof field === "object") {
          if (field.required && !field.value) {
            validData = false;
          }
        }
      });
      return validData;
    };

    // Check if form is in valid state. Should run after any formData change
    _self.formValidate = function() {
      _self.error = {};
      var validBusinessPartner = !_self.businessPartnerError;
      var validNote = !_self.formData.note.required || _self.validNote;
      var validSlateNote =
        !_self.formData.slateNote.required || _self.validSlateNote;
      var validShootDetail = !_self.isEditingShootDetail;
      var validJobTypeConflict = !_self.jobTypeConflict;
      var validRequiredFields = areRequiredFieldsValid();
      var validViews = _self.hasValidViews();
      var validMerchTypeShootTypeCombo = _self.validateMerchTypeShootTypeSelections();
      var validMerchID = !_self.invalidMerchIdPattern;
      var validMerchType = !_self.hasMerchTypeErrors();

      if (
        validBusinessPartner && //validateBusinessPartnerRestrictions
        validNote && // onNoteTextChange
        validSlateNote && // onSlateNoteChange
        validShootDetail && //handleShootDetailsEdit
        validJobTypeConflict && //validateJobTypeSelection
        validViews &&
        validMerchTypeShootTypeCombo &&
        validMerchID &&
        validMerchType &&
        validRequiredFields // All fields valid.
      ) {
        _self.isValid = true;
        return;
      }

      _self.isValid = false;
    };

    _self.hasValidViews = function() {
      var retVal = false;
      if (
        _self.formData.jobType &&
        _self.formData.jobType.value === 'Duplicate'
      ) {
        //the job form service takes care of setting these to default values, this is good to go.
        retVal = true;
      }
      if (
        _self.formData.shootDetail &&
        _self.formData.shootDetail.value &&
        _self.formData.shootDetail.value.expectedViews
      ) {
        retVal = _self.formData.shootDetail.value.expectedViews.length > 0;
      }

      return retVal;
    };

    // Create a model with a sub-set of job data that will be passed to the Shoot Details component
    _self.setShootTypeModel = function(job) {
      job = job || _self.shootDetailModel;
      _self.shootDetailModel = {
        active: true,
        createdFrom: job.createdFrom,
        product: angular.copy(job.product),
        jobType: job.jobType || 'Standard',
        businessPartner: _self.formData.businessPartner.value || ''
      };

      // Do not copy expected views and shoot type if the existing job type is 'Duplicate'
      if (job.jobType !== 'Duplicate') {
        _self.shootDetailModel.expectedViews = angular.copy(job.expectedViews);
        _self.shootDetailModel.shootType = angular.copy(job.shootType);
      }
    };

    var setShootDetailJobType = function(jobType) {
      _self.setShootTypeModel(
        angular.merge({}, _self.shootDetailModel, { jobType: jobType })
      );
    };

    var resetShootTypeData = function() {
      _self.updateShootDetail({ job: _self.model });
      _self.setShootTypeModel(_self.shootDetailModel);
    };

    var setJobAction = function(job) {
      // If the selected job is in 'Job Pending' or 'Problem Solve', we're updating that job, not creating a new one
      if (JobService.canUpdate(job.status)) {
        _self.jobAction = 'update';
      } else {
        _self.jobAction = 'initiate';
      }
    };

    //check if current selected job type matches existing job type
    var isExistingJobType = function() {
      return (
        _self.jobAction === 'update' &&
        _self.formData.jobType.value === _self.model.jobType
      );
    };

    // Set job type options based on product & (optionally) selected job data
    // Available options and defaults are based on location, existing job data, and user permission level
    _self.setJobTypeOptions = function(model) {
      if (!_self.location) {
        return;
      }

      var jobTypes = {
        search: [
          'Standard',
          'Quick Standard',
          'Variation',
          'Copy',
          'Copy with Edits',
          'Take Up',
          'Take Down',
          'Reshoot',
          'Rework',
          'Redelivery'
        ],
        'queue.inbound': ['Standard', 'Quick Standard', 'Reshoot']
      };

      // Senior Studior Studio Ops (Superuser) can create External Delivery Jobs
      if (AuthService.isAuthorized([USER_ROLES.superUser])) {
        jobTypes.search.push('External Delivery');
      }

      // Detail has same options as search
      jobTypes['detail'] = angular.copy(jobTypes.search);

      var options = jobTypes[_self.location];
      var value = model.jobType || options[0];
      var disabled = false;

      // If we're updating a job, set the default to the current job type
      if (_self.jobAction === 'update') {
        if (model.jobType === 'Duplicate') {
          options = ['Standard', 'Reshoot'];
          value = options[0];
        } else {
          value = model.jobType;
        }
      }

      // Editing a duplicate job has special options
      if (model.jobType !== 'Duplicate') {
        var hasPreviouslyCompletedJob;

        if (_self.parent.relatedJobs) {
          _self.parent.relatedJobs.some(function(job) {
            if (!job.active) {
              hasPreviouslyCompletedJob = true;
            }
            return hasPreviouslyCompletedJob;
          });
        }

        // If a product has a previously completed job, show additional job type options
        if (hasPreviouslyCompletedJob) {
          var hasPendingMerchJob, hasInProgressJob;

          // If there is no Pending Standard or Reshoot Job for the product (hasPendingMerchJob)
          // AND
          // There is one or more completed Jobs for the product (hasPreviouslyCompletedJob) OR There is one or more In Progress Jobs for the product (hasInProgressJob)
          // The job type should be set to Duplicate.
          _self.parent.relatedJobs.forEach(function(job) {
            if (
              job.status === META_STATUS.jobPending &&
              (job.jobType === 'Standard' || job.jobType === 'Reshoot')
            ) {
              hasPendingMerchJob = true;
            }

            if (
              job.status === META_STATUS.inProgress ||
              job.status === META_STATUS.problemSolve ||
              job.status === META_STATUS.qa
            ) {
              hasInProgressJob = true;
            }
          });

          if (!_self.isAdmin) {
            disabled = true;
          }
        } else {
          value = value || options[0];
        }
      }

      if (_self.isAdmin) {
        options = [
          'Standard',
          'Quick Standard',
          'Variation',
          'Copy',
          'Copy with Edits',
          'Take Up',
          'Take Down',
          'Reshoot',
          'Rework',
          'Redelivery'
        ];
      }

      _self.formData.jobType = {
        required: true,
        label: 'Job Type',
        options: options,
        value: value,
        disabled: options.length === 1 || disabled,
        visible: true
      };
    };

    _self.validDate = function(input) {
      return moment(input, 'M/d/yy h:mm a', true).isValid();
    };

    // Require Merch Type when initiating a merch job (Job Status of 'In Ops')
    _self.setMerchTypeVisibility = function() {
      var jobType = _self.formData.jobType.value;

      // Exit if we're handling merchType explicitly
      // or if a Base Product is selected (merch visibility is handled separately for sub-jobs)
      if (
        _formDataDefaults[jobType].merchType ||
        _self.formData.baseProduct.value
      ) {
        return;
      }
      _self.formData.merchType.required = true;
      _self.formData.merchType.visible = true;
    };

    _self.validateBusinessPartnerRestrictions = function() {
      var completedBusinessPartners = [],
        relatedJobs = _self.parent.relatedJobs,
        publishedJobs = [];
      var hasDuplicateOpenJob, hasDuplicateClosedJob, duplicateOpenJobId;
      var shootDetailFormValue =
        _self.formData.shootDetail && _self.formData.shootDetail.value;
      var merchTypeFormValue =
        _self.formData.merchType && _self.formData.merchType.value;
      var jobTypeFormValue =
        _self.formData.jobType && _self.formData.jobType.value;

      // filter self from related jobs (will be present if updating)
      if (_self.jobAction === 'update' && _self.model.jobType !== 'Duplicate') {
        relatedJobs = relatedJobs.filter(function(job) {
          return job.id !== _self.model.id;
        });
      }

      relatedJobs.forEach(function(job) {
        if (
          job.status === META_STATUS.completed &&
          job.jobType !== 'Duplicate'
        ) {
          completedBusinessPartners.push(job.businessPartner);
        }
        if (
          job.status === META_STATUS.pendingRelease ||
          job.status === 'Ready To Publish'
        ) {
          publishedJobs.push(job);
        }

        // Check for duplicate jobs
        var jobIsOpen = !META_STATUS.closedJobs.includes(job.status);

        var shootTypeFormValue = shootDetailFormValue;
        if (
          shootDetailFormValue &&
          shootDetailFormValue.shootType &&
          shootDetailFormValue.shootType.data &&
          shootDetailFormValue.shootType.data.code
        ) {
          shootTypeFormValue =
            shootDetailFormValue.shootType.data.code;
        } else if (
          shootDetailFormValue &&
          shootDetailFormValue.shootType &&
          shootDetailFormValue.shootType.code
        ) {
          shootTypeFormValue = shootDetailFormValue.shootType.code;
        }
        var jobShootTypeCode = job.shootType && job.shootType.code;
        var shootTypeMatch = shootTypeFormValue === jobShootTypeCode;

        var merchTypeMatch = merchTypeFormValue === job.merchType;

        if (shootTypeMatch && merchTypeMatch) {
          if (jobIsOpen) {
            hasDuplicateOpenJob = true;
            duplicateOpenJobId = job.id;
          } else {
            hasDuplicateClosedJob = true;
          }
        }
      });

      // Warning message
      if (
        !['Redelivery', 'Copy', 'Rework', 'Reshoot'].includes(
          jobTypeFormValue
        ) &&
        hasDuplicateClosedJob
      ) {
        _self.businessPartnerWarning =
          'There appears to be a Completed Job with matching Shoot Type and Merch Type. Please review that Job and consider whether a Reshoot or Rework is more appropriate.';
      } else {
        delete _self.businessPartnerWarning;
      }

      // Error messages
      if (
        !['Redelivery', 'Copy', 'Reshoot', 'Rework'].includes(jobTypeFormValue) &&
        hasDuplicateOpenJob
      ) {
        delete _self.businessPartnerWarning;
        _self.businessPartnerError = `There appears to be an In Progress Job with matching Shoot Type and Merch Type: ${duplicateOpenJobId}.`;
      } else if (
        JobTypeService.requiresCompletedJob(jobTypeFormValue) && completedBusinessPartners.length === 0
      ) {
        delete _self.businessPartnerWarning;
        _self.businessPartnerError =
            "No completed jobs were found for this product, so 'Reshoot' and 'Rework' jobs cannot be initiated. Select a different job type.";
      } else {
        delete _self.businessPartnerError;
      }

      if (
        ['Reshoot', 'Rework'].indexOf(jobTypeFormValue) !== -1 &&
        publishedJobs.length > 0
      ) {
        delete _self.businessPartnerError;
      }
    };

    var jobStatusIsAssignable = function(jobStatus) {
      return (
        jobStatus === ASSET_STATUS.postProd ||
        jobStatus === META_STATUS.post ||
        jobStatus === META_STATUS.problemSolve ||
        jobStatus === META_STATUS.qa
      );
    };

    var getAssigneeRole = function(jobStatus, businessPartner) {
      var role;

      switch (jobStatus) {
        case META_STATUS.problemSolve:
          role = ['STUDIO_OPS'];
          break;
        case META_STATUS.qa:
          role = ['QA_REVIEWER'];

          if (businessPartner) {
            role.push('QA_REVIEWER_' + businessPartner);
          }
          break;
        default:
          role = ['IMAGE_EDITOR', 'SENIOR_IMAGE_EDITOR'];
          break;
      }

      return role;
    };

    // Default form values for each job type
    var _formDataDefaults = {
      Standard: {
        jobStatus: {
          options: [META_STATUS.jobPending, APP_DATA.MerchJobStatus[0]],
          value: META_STATUS.jobPending
        },
        slateNote: {
          visible: true
        },
        shootDetail: {
          required: true,
          visible: true,
          disabled: false
        },
        baseProduct: {
          required: false,
          visible: false,
          disabled: true
        },
        primaryMerchId: {
          required: true,
          disabled: false,
        },
      },
      'Quick Standard': {
        jobStatus: {
          options: [META_STATUS.jobPending, META_STATUS.inProgress],
          value: META_STATUS.jobPending,
          disabled: false
        },
        slateNote: {
          visible: true
        },
        shootDetail: {
          required: true,
          visible: true,
          disabled: false
        },
        baseProduct: {
          required: false,
          visible: false,
          disabled: true
        },
        primaryMerchId: {
          required: false,
          disabled: false,
        },
      },
      Variation: {
        jobStatus: {
          options: [META_STATUS.jobPending, META_STATUS.post],
          value: undefined,
          disabled: true
        },
        baseProduct: {
          required: true,
          visible: true
        },
        merchType: {
          required: false,
          visible: true,
          disabled: true
        },
        note: {
          required: false,
          value: '',
          type: 'general',
          visible: true
        },
        shootDetail: {
          required: true,
          visible: true,
          disabled: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
      Copy: {
        jobStatus: {
          options: [META_STATUS.jobPending, META_STATUS.qa],
          value: undefined,
          disabled: true
        },
        baseProduct: {
          required: true,
          visible: true
        },
        shootDetail: {
          required: true,
          visible: false,
          disabled: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
      'Copy with Edits': {
        jobStatus: {
          options: [META_STATUS.jobPending, META_STATUS.post],
          value: undefined,
          disabled: true
        },
        baseProduct: {
          required: true,
          visible: true
        },
        shootDetail: {
          required: true,
          visible: false,
          disabled: true
        },
        note: {
          required: true,
          value: '',
          type: 'general',
          visible: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
      Reshoot: {
        jobStatus: {
          options: [META_STATUS.jobPending, APP_DATA.MerchJobStatus[0]],
          value: META_STATUS.jobPending
        },
        reason: {
          required: true,
          visible: true
        },
        note: {
          required: true,
          value: '',
          type: 'general',
          visible: true
        },
        slateNote: {
          visible: true
        },
        primaryMerchId: {
          required: true,
          disabled: false,
        },
      },
      Rework: {
        jobStatus: {
          options: [META_STATUS.post],
          value: META_STATUS.post,
          disabled: true
        },
        assets: {
          required: true,
          visible: true
        },
        merchType: {
          visible: true,
          disabled: true
        },
        businessPartner: {
          disabled: false
        },
        baseProduct: {
          required: false,
          visible: false,
          disabled: true
        },
        reason: {
          required: true,
          visible: true
        },
        note: {
          required: true,
          value: '',
          type: 'general',
          visible: true
        },
        shootDetail: {
          disabled: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
      Redelivery: {
        jobStatus: {
          options: [META_STATUS.jobPending, META_STATUS.qa],
          value: undefined,
          disabled: true
        },
        assets: {
          visible: false,
          disabled: true
        },
        merchType: {
          visible: true,
          disabled: true
        },
        businessPartner: {
          disabled: false
        },
        baseProduct: {
          required: true,
          visible: false
        },
        shootDetail: {
          disabled: false,
          visible: false,
          required: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
      'Take Up': {
        note: {
          required: true,
          value: '',
          type: 'general',
          visible: true
        },
        baseProduct: {
          required: true,
          visible: true
        },
        shootDetail: {
          required: true,
          visible: false,
          disabled: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
      'Take Down': {
        note: {
          required: true,
          value: '',
          type: 'general',
          visible: true
        },
        baseProduct: {
          required: true,
          visible: true
        },
        shootDetail: {
          required: true,
          visible: false,
          disabled: true
        },
        primaryMerchId: {
          required: false,
          disabled: true,
        },
      },
    };

    // Take Up/Take Down have same configuration options as 'Copy with Edits'
    _formDataDefaults['Take Up'] = angular.copy(
      _formDataDefaults['Copy with Edits']
    );
    _formDataDefaults['Take Down'] = angular.copy(
      _formDataDefaults['Copy with Edits']
    );

    // Public methods
    var fetchBaseData = function(jobId) {
      _self.modal.working = true;
      return JobService.getJobDetail(jobId).finally(function() {
        _self.setModalFalse();
      });
    };

    // Set form data to pre-existing data
    _self.getFormDataFromExistingJob = function() {
      // Exit early if we're initiating a new job or the job type is different from what is set on the job
      if (!isExistingJobType()) {
        return $q.when(true);
      }

      //Hide note since we've already created one for this job type
      _self.formData.note.visible = false;
      _self.formData.note.required = false;

      if (_self.model.assetJobAssignee) {
        _self.formData.assign.value = _self.model.assetJobAssignee;
      }

      if (_self.model.reason) {
        _self.formData.reason.value = _self.model.reason;
      }

      if (_self.model.createdFrom) {
        return fetchBaseData(_self.model.createdFrom);
      } else {
        return $q.when(true);
      }
    };

    //check if current selected base product matches existing base product
    var isExistingBase = function() {
      return (
        isExistingJobType() &&
        _self.base &&
        _self.formData.baseProduct.value &&
        _self.formData.baseProduct.value.id === _self.base.id
      );
    };

    _self.setBusinessPartnerDefaultValue = function() {
      if (_self.model.businessPartner) {
        _self.formData.businessPartner.value = _self.model.businessPartner;
      }
    };

    _self.validateJobTypeSelection = function(jobType) {
      if (jobType === 'Redelivery' && _self.model.jobType === 'Redelivery') {
        _self.jobTypeConflict =
          'A Redelivery Job cannot be used as a Base. Please update with the original Base Job ID.';
      } else {
        delete _self.jobTypeConflict;
      }
    };

    _self.setDefaultsForBaseProductStatus = function() {
      // If we need to choose a base product, do a search with the selected product's style number or for redelivery the style color
      if (_self.formData.baseProduct.visible) {
        if (_self.formData.jobType.value === 'Redelivery') {
          _self.formData.baseProduct.searchTerm =
            _self.model.product.styleColor;
        } else {
          _self.formData.baseProduct.searchTerm =
            _self.formData.baseProduct.searchTerm ||
            _self.model.product.styleNumber;
        }
        // If a base product value is already set
        _self.onBaseProductSelection(_self.formData.baseProduct.value);
        // If base product is required but not visible, set it to selected job
      } else if (_self.formData.baseProduct.required) {
        _self.formData.merchType.value = _self.model.merchType;
        _self.formData.baseProduct.value = _self.model;
        // Otherwise, clear the base product data
      } else {
        _self.formData.baseProduct.searchTerm = undefined;
        _self.formData.baseProduct.value = undefined;
        // Reset shoot type data to currently selected job, unless it's a duplicate
        if (_self.model.jobType !== 'Duplicate') {
          _self.updateShootDetail({ job: _self.model });
          _self.setShootTypeModel(_self.model);
        }
      }
    };

    // Set form defaults based on Job Type selection
    _self.setJobTypeDefaults = function() {
      var jobType = _self.formData.jobType.value;

      _self.validateJobTypeSelection(jobType);
      if (_self.jobTypeConflict) {
        return;
      }
      // Merge the edited form data (user selections) with the default form data, and the job type default form data
      if (_formDataDefaults[jobType]) {
        _self.formData = angular.merge(
          {},
          _self.formData,
          JobFormService.formData,
          _formDataDefaults[jobType]
        );
      }
      _self.setBusinessPartnerDefaultValue();
      // When editing a duplicate job, we can move to any merch status
      if (_self.model.jobType === 'Duplicate') {
        _self.formData = angular.merge({}, _self.formData, {
          jobStatus: {
            options: APP_DATA.MerchJobStatus.slice(0, 4),
            value: APP_DATA.MerchJobStatus[0],
            disabled: false
          }
        });
      }

      if (_self.hasMerchEditableJobType()) {
        _self.formData.merchType.value = _self.model.merchType;
        _self.formData.primaryMerchId.value = _self.model.primaryMerchId;
      } else {
        _self.formData.merchType.value = null;
        _self.formData.primaryMerchId.value = null;
      }

      if (jobType === 'Rework') {
        _self.formData.merchType.value = _self.model.merchType;
        if (_self.model.assetJobs && _self.model.assetJobs.length) {
          _self.formData.assets.value = _self.model.assetJobs;
        }
        _self.formData.baseProduct.value = {
          active: true,
          id: _self.model.id
        };
        _self.onBaseProductSelection(_self.model);
      }

      //The formData has all been set. Now we remove Prop from it.
      if (_self.formData.merchType && _self.formData.merchType.options) {
        _self.formData.merchType.options = _self.formData.merchType.options.filter(
          type => (type !== 'Prop')
        );
      }

      // If we're updating a job and the job type hasn't changed,
      // override defaults with existing model data
      _self
        .getFormDataFromExistingJob()
        .then(function(response) {
          if (!response.data) {
            return;
          }
          // Set data from existing base job
          _self.base = response.data;
          _self.formData.baseProduct.searchTerm = _self.base.product.styleColor;
          _self.formData.baseProduct.value = _self.base;
          _self.formData.merchType.value = _self.base.merchType;
          _self.formData.primaryMerchId.value = _self.base.primaryMerchId;
        })
        .finally(function() {
          if (_self.formData.jobType.value === 'Variation') {
            _self.formData.shootDetail.visible = false;
          }
          //_self.setDefaultsForBaseProductStatus();
          // Reason should always be reset when switching job types, as the options will change
          if (!isExistingJobType()) {
            _self.formData.reason.value = undefined;
          }

          // If reason is required, get appropriate options
          if (_self.formData.reason.required) {
            switch (jobType) {
              case 'Reshoot':
                _self.formData.reason.options = APP_DATA.ReshootReason;
                break;
              case 'Rework':
                _self.formData.reason.options = APP_DATA.ReworkReason;
                break;
            }
          }

          //Set Job Type options
          // If assets aren't required, reset form data
          if (!_self.formData.assets.required) {
            _self.formData.assets.value = undefined;
            _self.showAssetError = false;
          }

          // Additional logic that applies when initiating a job from the Inbound queue:
          // If 'In Ops' is a valid Job Status for this job type, set it to that and disable the field
          if (_self.location === 'queue.inbound') {
            _self.setDefaultQueueInboundFields();
          }
          // The job type can restrict options available in shoot type
          setShootDetailJobType(jobType);

          // The job type (and maybe buisness partner) has changed, so re-validate BP restrictions
          _self.validateBusinessPartnerRestrictions();

          // Job status options have changed, so re-validate
          _self.onJobStatusSelection();

          if (_self.formData.businessPartner.value && !_self.originalBP) {
            _self.originalBP = _self.formData.businessPartner.value;
          }

          // if we aren't updating a job, or creating a rework job, default the BP to nothing.
          if (_self.jobAction !== 'update') {
            if (_self.formData.jobType.value !== 'Rework') {
              _self.formData.businessPartner.value = '';
            }
          }
        });

      _self.validateMerchIdInput();
    };

    _self.setDefaultQueueInboundFields = function() {
      if (
        _self.formData.jobStatus.options.indexOf(APP_DATA.MerchJobStatus[0]) >=
        0
      ) {
        _self.formData.jobStatus.value = APP_DATA.MerchJobStatus[0];
        _self.formData.jobStatus.disabled = true;
      }
      _self.validateBusinessPartnerRestrictions();
    };

    // Validate note text
    _self.onNoteTextChange = function($event) {
      _self.validNote = $event.valid;
      if (_self.validNote) {
        _self.formData.note.value = $event.text;
      }
      _self.onSelection();
    };

    // Validate note text
    _self.onSlateNoteChange = function($event) {
      _self.validSlateNote = $event.valid;
      if (_self.validSlateNote) {
        _self.formData.slateNote.value = $event.text;
      }
      _self.onSelection();
    };

    // Set shoot type data
    _self.updateShootDetail = function($event) {
      if (!$event.shootType) {
        delete _self.formData.shootDetail.value;
        //return;
      } else {
        _self.formData.shootDetail.value = {
          shootType: $event.shootType,
          expectedViews: $event.expectedViewCodes
        };
      }

      _self.validateBusinessPartnerRestrictions();
      _self.formValidate();
    };

    // Disable job creation when editing shoot detail
    _self.handleShootDetailsEdit = function($event) {
      _self.isEditingShootDetail = $event.boolean;
      _self.formValidate();
    };

    // Callbacks for various dropdown selections
    _self.onSelection = function() {
      _self.formValidate();
    };

    _self.onReworkAssetSelection = function($event) {
      _self.formData.assets.value = $event.assets.length ? $event.assets : [];
      // At least 1 asset must be selected for Rework jobs
      _self.showAssetError = !_self.formData.assets.value.length;
      _self.setShootTypeModel({
        product: _self.model.product,
        shootType: _self.model.shootType,
        expectedViews: _self.formData.assets.value.map(function(asset) {
          return asset.viewCode;
        })
      });

      if (_self.formData.shootDetail.value) {
        _self.formData.shootDetail.value.expectedViews =
          _self.shootDetailModel.expectedViews;
      }
      _self.onSelection();
    };

    _self.onJobStatusSelection = function() {
      _self.setMerchTypeVisibility();
      // A note is required if a job is going to problem solve
      if (_self.formData.note.type === 'problem') {
        _self.formData.note.required =
          _self.formData.jobStatus.value === META_STATUS.problemSolve;
        _self.formData.note.visible =
          _self.formData.jobStatus.value === META_STATUS.problemSolve;
      }


      _self.formData.primaryMerchId.required = (_self.formData.jobStatus.value !== META_STATUS.jobPending &&
        ['Standard', 'Reshoot'].includes(_self.formData.jobType.value));

      _self.validateMerchIdInput();
      _self.onSelection();
    };

    _self.onMerchTypeSelection = function() {
      _self.validateBusinessPartnerRestrictions();
      $scope.$broadcast('shootDetailsInitiateJob_fetchViewCodesFromShootType');
      _self.onSelection();
    };

    _self.validateMerchTypeShootTypeSelections = function() {
      let merchType;
      let shootType;
      try {
        merchType = _self.formData.merchType.value;
        shootType = _self.formData.shootDetail.value.shootType.data.code;
      } catch (ex) {
        // The data wasn't available in its complete form, so just pass and move on
        return true;
      }
      if(!JobService.validateMerchTypeShootTypeCombination(merchType, shootType, function (response) {
        if(!!merchType && !!shootType) {
          _self.setError(response);
        }
        $rootScope.$broadcast('shootDetailsInitiateJob_hideShootDetailViewCodes');
      })) {
        _self.isValid = false;
        return false;
      }
      return true;
    };

    // Check Merch ID field for 'valid' characters
    _self.onMerchIdTextChanged = function($event) {
      if (_self.merchIdTimer) {
        clearTimeout(_self.merchIdTimer);
        _self.merchIdTimer = null;
      }

      _self.validMerchIdText = $event.valid;
      if (_self.validMerchIdText) {
        _self.formData.primaryMerchId.value = $event.text;
      }
      _self.validateMerchIdInput();
      _self.onSelection();

      if (!_self.invalidMerchIdPattern) {
        _self.merchIdTimer = setTimeout(function() {
          _self.retrieveMerchData(_self.formData.primaryMerchId.value);
        }, 700);
      }
    };

    // Validate Merch ID field input to ensure good Merch ID pattern.
    _self.validateMerchIdInput = function() {
      const merchRegEx = /^I[0-9]{1,12}$/;

      if(!!_self.formData.primaryMerchId.value &&
        _self.formData.primaryMerchId.required &&
        !merchRegEx.test(_self.formData.primaryMerchId.value)) {
        _self.invalidMerchIdPattern = 'Please verify MerchID (e.g. I111111)';
      } else {
        delete _self.invalidMerchIdPattern;
      }
    };

    _self.onBusinessPartnerSelection = function() {
      _self.validateBusinessPartnerRestrictions();
      _self.onSelection();
    };

    _self.onAssigneeSelection = function(option) {
      _self.formData.assign.value = option;
      _self.onSelection();
    };

    _self.onBaseProductSelection = function(baseJob) {
      _self.formData.baseProduct.value = baseJob;

      // Allow user to edit shoot type/view codes once base product has been selected
      // Copy some values from base job to sub job
      if (baseJob) {
        _self.formData.baseProduct.value = {
          active: true,
          id: baseJob.id,
          createdFrom: baseJob.createdFrom,
          product: angular.copy(baseJob.product),
          jobType: baseJob.jobType,
          //businessPartner: baseJob.businessPartner.value || '',
          expectedViews: baseJob.expectedViews,
          shootType: baseJob.shootType,
          merchType: baseJob.merchType,
          primaryMerchId: baseJob.primaryMerchId
        };

        _self.formData.jobStatus.value = baseJob.expectedViews
          ? _self.formData.jobStatus.options[1]
          : _self.formData.jobStatus.options[0];
        _self.formData.shootDetail.visible = true;
        _self.formData.merchType.value = baseJob.merchType;
        _self.formData.merchType.disabled = true;
        _self.formData.merchType.required = false;
        _self.formData.merchType.visible = true;
        _self.setShootTypeModel(baseJob);
      } else {
        if (
          _self.formData.jobType &&
          [
            'External Delivery',
            'Variation',
            'Copy',
            'Copy with Edits',
            'Take Up',
            'Take Down'
          ].indexOf(_self.formData.jobType.value) !== -1
        ) {
          _self.formData.merchType.value = undefined;
          _self.formData.merchType.disabled = false;
          _self.formData.merchType.required = false;
          _self.formData.merchType.visible = false;
          _self.formData.shootDetail.visible = false;
        }
      }

      const primaryMerchId = !!_self.formData.primaryMerchId ? _self.formData.primaryMerchId.value : undefined;
      JobFormService.setFormData(baseJob, _self.formData, true, _self.error);
      if (!baseJob) {
        _self.formData.primaryMerchId.value = primaryMerchId;
      } else {
        _self.expectedMerchType = null;
      }
      _self.onSelection();
    };

    // Remove all user selections from the initiate job form
    _self.reset = function() {
      var jobModel, shootTypeModel;
      // Reset form data to initial state
      _self.hasRefreshedProduct = false;
      _self.formData = angular.copy(JobFormService.formData);
      _self.expectedMerchType = null;

      setJobAction(_self.model);

      // Only inherit values from selected job if we're updating
      if (_self.jobAction === 'update') {
        jobModel = _self.model;
        shootTypeModel = _self.model;
      } else {
        jobModel = {};
        shootTypeModel = { product: _self.model.product };
      }

      JobFormService.setFormData(jobModel, _self.formData, false, _self.error);
      if(!!_self.formData.primaryMerchId) {
        _self.formData.primaryMerchId.value = _self.model.primaryMerchId;
      }

      _self.setShootTypeModel(shootTypeModel);
      _self.setJobTypeOptions(_self.model);

      if (_self.formData.jobType.value) {
        _self.setJobTypeDefaults();
      }
    };

    _self.routeJobInitiation = function() {
      var action,
        isDuplicate = _self.model.jobType === 'Duplicate';

      switch (_self.jobAction) {
        case 'initiate':
          action = 'createJob';
          break;
        case 'update':
          action = isDuplicate ? 'resolveJob' : 'updateJob';
          break;
      }

      _self.modal.working = true;

      JobFormService[action](_self.model, _self.formData)
        .then(_self.routeJobInitiationJobUpdate)
        .then(_self.routeJobInitiationResponseUpdateState)
        .catch(function(response) {
          if (_self.printSlate) {
            _self.printSlate.close();
            _self.printSlate = null;
          }

          _self.setError(response);
        })
        .finally(_self.setModalFalse);
    };

    _self.routeJobInitiationJobUpdate = function(response) {
      // Complete the job if:
      // We updated the job (did not initiate a new job), and
      // It's not a duplicate job,
      // It's not a copy based job in pending, and
      // We did not change the job type.
      // (Converting a job to a different type will also move it)

      var job = response.data || response[0];
      _self.jobId = job.id;

      var isDuplicate = _self.model.jobType === 'Duplicate';
      var isCopyBased =
        _self.model.createdFrom != undefined &&
        job.status === META_STATUS.jobPending;
      if (
        _self.jobAction === 'update' &&
        !isDuplicate &&
        !isCopyBased &&
        response.data.jobType === _self.model.jobType &&
        _self.formData.jobStatus.value !== META_STATUS.jobPending
      ) {
        return StatusService.updateJobs([response.data], { type: 'tasks' });
      } else {
        return $q.when(response);
      }
    };

    _self.reload = function() {
      if ($state.current.name === 'queue.inbound') {
        $window.location.reload();
      }
    };

    _self.routeJobInitiationResponseUpdateState = function(response) {
      var action,
        isDuplicate = _self.model.jobType === 'Duplicate';

      switch (_self.jobAction) {
        case 'initiate':
          action = 'createJob';
          break;
        case 'update':
          action = isDuplicate ? 'resolveJob' : 'updateJob';
          break;
      }

      var job = response.data || response[0],
        notificationType = job.merchJob ? 'merch' : 'noMerch';

      var product =
        response.data === undefined
          ? response[0].product
          : response.data.product;

      // Merch Job Status of In Ops or Ready to Publish
      if ((
        _self.formData.jobStatus.value === MERCH_STATUS.inOps ||
        _self.formData.jobStatus.value === MERCH_STATUS.completed ||
        (_self.formData.jobStatus.value === META_STATUS.inProgress && _self.formData.jobType.value === 'Quick Standard')
      ) && _self.formData.jobType.value !== 'Rework') {
        _self.printSlate = $window.open('', '_blank');
      }

      var slateNote = _self.formData.slateNote.value;
      if (slateNote) {
        NoteService.addNote('slate', slateNote, job.id || _self.model.id).then(
          function(response) {
            if (response) {
              job.notes = response.data.notes;
            }
            _self.updateProductResponse(job, action, product);
            _self.updateSlate();
            _self.active = false;
            NotificationService.push(
              _self.notifications[_self.jobAction][notificationType],
              { job: job }
            );
          }
        );
      } else {
        _self.updateProductResponse(job, action, product);
        _self.updateSlate();
        _self.active = false;
        NotificationService.push(
          _self.notifications[_self.jobAction][notificationType],
          { job: job }
        );
      }
    };

    _self.updateSlate = function() {
      if (_self.printSlate) {
        _self.printSlate.location.href = $state.href(
          'slates',
          {
            ids: _self.jobId
          },
          { absolute: true }
        );
        _self.reload();
      } else {
        _self.reload();
      }
    };

    _self.updateProductResponse = function(job, action, productResponse) {
      job.product = productResponse;
      job.confidential = job.product.confidential;
      job.launchDate = job.product.releaseDate;
      _self.onUpdate({ $event: { job: job, action: action } });
    };

    _self.validDate = function(input) {
      return moment(input, 'M/d/yy h:mm a', true).isValid();
    };

    _self.setError = function(response) {
      _self.error.title = response.data.error;
      _self.setModalFalse();
    };

    _self.setModalFalse = function() {
      $timeout(function() {
        _self.modal.working = false;
      });
    };

    _self.downloadAttachments = function(file) {
      if (file.assetType !== 'attachment' && file.status !== 404 && file.status !== 412 && file.status !== 500) {
        _self.imgModal.active = true;
        _self.imgModal.src = file.url;
        _self.imgModal.name = file.name;
      } else if (file.status === 200 && file.assetType === 'attachment') {
        TransferService.downloadLineArt(file);
      }
    };

    _self.closeViewCodeToggle = function() {
      $rootScope.$broadcast('closeViewCodeToggle');
    };

    _self.$onInit = function() {
      _self.location = $state.current.name;

      $scope.$on('$stateChangeSuccess', function() {
        _self.location = $state.current.name;
      });

      $scope.$on('initiateJob.active', function($event, args) {
        if ($event.name === 'initiateJob.active') {
          if (args.status) {
            _self.formData = angular.merge(
              {},
              JobFormService.formData,
              _formDataDefaults['Standard']
            );
            //$scope.$broadcast('initiateJob.opened');
          }
        }
      });

      // Reset form when modal is enabled or disabled
      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          if (_self.active) {
            _self.reset();
          }

          if (_self.model && _self.model.notes) {
            _self.model.notes.map(function(note) {
              if (note.noteType === 'Slate Note') {
                _self.formData.slateNote.value = note.content;
              }
            });
          }
        }
      );

      $scope.$watch(
        function() {
          return (
            _self.formData.launchDate.required ||
            _self.formData.launchDate.value
          );
        },
        function() {
          _self.formValidate();
        }
      );

      $scope.$watch(
        function() {
          return _self.formData.launchDate.value;
        },
        function() {
          _self.formValidate();
        }
      );

      $scope.$watch(
        function() {
          return JobFormService.formData.studio.options;
        },
        function() {
          _self.formData.studio.options =
            JobFormService.formData.studio.options;
        }
      );
    };

    //Close Job Methods
    _self.initiateCloseJob = function() {
      _self.showCloseJobModal = true;
    };

    _self.refreshProduct = function (model) {
      if (!!_self.hasRefreshedProduct) {
        return;
      }
      if (model && model.product && model.product.productId) {
        _self.hasRefreshedProduct = true;
        SearchService.findProduct(
          undefined,
          'by-product-id',
          model.product.productId
        ).then(function (response) {
          let newModel = Object.assign({}, _self.model, { product: response.data.product });
          _self.model = newModel;
        });
      }
    }

    _self.$onChanges = function (changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        if (!angular.equals(changes.model.currentValue, changes.model.previousValue)) {
          _self.refreshProduct(_self.model);

          _self.model = angular.copy(_self.model);
          _self.formData = angular.merge(
            {},
            JobFormService.formData,
            _formDataDefaults['Standard']
          );
          if (_self.model.confidential === true) {
            _self.formData.confidentialCheck = true;
            _self.formData.launchDate.required = true;
            _self.formData.launchDate.value = new Date(_self.model.launchDate);
          }

          if (_self.model.priority) {
            _self.formData.priority.value = _self.model.priority;
          } else {
            _self.model.priority = 0;
            _self.formData.priority.value = 0;
          }

          _self.formData.primaryMerchId.value = _self.model.primaryMerchId;
          _self.formData.merchType.value = _self.model.merchType;
          if (_self.model.primaryMerchId && !_self.model.merchType) {
            _self.retrieveMerchData(_self.model.primaryMerchId);
          }

          _self.formData.slateNote.value = '';
          _self.formData.baseProduct.value = undefined;
        }

        //Get Line Art
        _self.model.lineArt = [
          {
            name: 'Primary',
            thumbnailUrl: 'defaultUrl',
            url: false,
            type: 'SPIL'
          }
        ];

        _self.lineArtErrors = {
          '400': {
            active: false,
            title: '',
            body: '',
          },
          '404': {
            active: false,
            title: '',
            body: ''
          },
          '412': {
            active: false,
            title: '',
            body: ''
          },
          '500': {
            active: false,
            title:
              'There was an issue communicating with Aurora.',
            body: ''
          }
        };

        TransferService.getExternalLineArt(_self.model.product, 'ALL')
          .then(function(response) {
          _self.model.lineArt = response;
        }).catch(function(err) {
          _self.lineArtErrors[err.status].body = err.message;
          _self.lineArtErrors[err.status].active = true;
        });
        _self.setJobTypeOptions(_self.model);

        _self.model.product.initialReleaseDate =
          _self.model.product.releaseDate;
      }
    };

    // This should be loaded ahead of any saves for validations
    JobService.loadMerchTypeShootTypeMap();
  },
  templateUrl: 'templates/partials/initiateJob/initiateJobModal.tpl.html'
};

module.exports = initiateJobModalComponent;
