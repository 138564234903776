'use strict';

var angular = require('angular');

var removeBestAssetsComponent = {
  bindings: {
    dnu: '<',
    onClose: '&',
  },
  controller: /*@ngInject*/ function(
    AssetSearchService,
    AssetDeleteService,
    NotificationService,
    $scope
  ) {
    var _self = this;
    const shootType = _self.dnu && _self.dnu.shootType;
    const merchType = _self.dnu && _self.dnu.merchType;
    _self.data = {
      assets: [],
      groupKey: [],
      loading: true,
      submitting: false,
      // fields=id,viewCode,cropType,imageType,links,imageSize
      filters: [
        {
          name: 'imageType',
          facetValues: [{ value: 'deliverable' }, { value: 'worker' }]
        },
        {
          name: 'shootType',
          facetValues: [{ value: shootType }]
        },
        {
          name: 'merchType',
          facetValues: [{ value: merchType }]
        }
      ],
      selectedViews: [],
    };

    _self.updateAssets = function() {
      _self.assets = [];
      _self.data.loading = true;
      AssetSearchService.search(
        _self.data.filters,
        [_self.dnu.styleColor],
        true,
        false
      ).then(
        function(results) {
          const objects = results && results.objects || [];
          _self.processAssets(objects);
          _self.data.loading = false;
        },
        function(error) {
          //it didn't work
          console.log('error getting assets for DNU');
        }
      );
    };

    _self.processAssets = function(objects) {
      const filteredAssets = objects.map(function(asset) {
        const thumbUrl = asset.links && asset.links.thumbs && asset.links.thumbs.all;
        return {
          id: asset.id,
          viewCode: asset.viewCode,
          cropType: asset.cropType,
          imageType: asset.imageType,
          thumbUrl,
        }
      });
      const structuredAssets = {};
      filteredAssets.forEach(function(asset) {
        const viewCode = asset.viewCode;
        const cropType = asset.cropType;
        const imageType = asset.imageType;
        if (!structuredAssets[viewCode]) {
          structuredAssets[viewCode] = {
            assets: {},
            other: {}
          };
        }
        if (cropType) {
          structuredAssets[viewCode].assets[cropType] = asset;
        } else if (imageType) {
          structuredAssets[viewCode].other[imageType] = asset;
        }
      });
      _self.data.structuredAssets = structuredAssets;

      const viewCodes = []
      for (const viewCode in structuredAssets) {
        const assets = []
        for (const cropType in structuredAssets[viewCode].assets) {
          assets.push(structuredAssets[viewCode].assets[cropType]);
        }
        viewCodes.push({
          code: viewCode,
          assets: assets.sort(function(a, b) {
            if (a.cropType > b.cropType) {
              return 1;
            } else if (a.cropType < b.cropType) {
              return -1;
            }
            return 0;
          }),
        })
      }
      _self.data.viewCodes = viewCodes.sort(function(a, b) {
        if (a.code > b.code) {
          return 1;
        } else if (a.code < b.code) {
          return -1;
        }
        return 0;
      });
    };

    _self.onViewClicked = function(viewCode) {
      const indexOfViewCode = _self.data.selectedViews.indexOf(viewCode);
      const isViewCurrentlySelected = indexOfViewCode !== -1;
      if (isViewCurrentlySelected) {
        // remove viewCode from selectedViews
        _self.data.selectedViews.splice(indexOfViewCode, 1);
      } else {
        // add viewCode to selectedViews
        _self.data.selectedViews.push(viewCode);
      }
    };

    _self.onCancelClick = function() {
      _self.onClose();
    };

    _self.isSubmitInvalid = function() {
      return !_self.data.selectedViews.length || _self.data.submitting;
    };

    _self.getAssetIdsByViewCode = function(viewCodes, structuredAssets) {
      return viewCodes.map(function(viewCode) {
        const assets = Object.values(structuredAssets[viewCode].assets);
        const other = Object.values(structuredAssets[viewCode].other);
        return assets.concat(other);
      }).flat().map(function(asset) {
        return asset.id;
      });
    };

    _self.onRemoveClick = function() {
      const assetIds = _self.getAssetIdsByViewCode(_self.data.selectedViews, _self.data.structuredAssets);
      _self.data.submitting = true;
      AssetDeleteService.deleteAssets(assetIds).then(function(response) {
        NotificationService.push('Assets have been removed successfully.');
        _self.data.submitting = false;
        _self.onClose();
      }).catch(function(e) {
        _self.data.submitting = false;
        console.log('ERROR with AssetDeleteService', e);
      });
    };

    _self.$onChanges = function(changes) {
      if (changes.dnu) {
        _self.updateAssets();
      }
    };
  },
  templateUrl: 'templates/partials/detail/removeBestAssets.tpl.html'
};

module.exports = removeBestAssetsComponent;
