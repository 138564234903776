'use strict';

var angular = require('angular');

var DownloadStatusService = /*@ngInject*/ function(Session, $cookieStore) {
  var _self = this;
  _self.cookieName = 'assetDownloadStatus';

  _self.readCookie = function(id) {
    var _userName = Session.get('userName'),
      _name = [_userName, id].join('-'),
      _downloadList = $cookieStore.get(_self.cookieName),
      _status = false;
    if (_downloadList) {
      _status = $cookieStore.get(_self.cookieName)[_name];
    }
    return _status;
  };

  _self.writeCookie = function(jobList, flag) {
    var _userName = Session.get('userName'),
      _name,
      _downloadList = $cookieStore.get(_self.cookieName) || {};

    if (!angular.isArray(jobList)) {
      jobList = [jobList];
    }

    if (flag === undefined) {
      flag = true;
    }

    for (var i = 0; i < jobList.length; i++) {
      _name = [_userName, jobList[i]].join('-');

      _downloadList[_name] = flag;
    }

    $cookieStore.put(_self.cookieName, _downloadList);
  };

  _self.removeCookie = function() {
    $cookieStore.remove(_self.cookieName);
  };
};

module.exports = DownloadStatusService;
