'use strict';

var angular = require('angular');

var jobLinkComponent = {
  bindings: {
    data: '<'
  },
  controllerAs: 'link',
  controller: function($window, $state) {
    var _self = this;
    _self.navigateToProduct = function() {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: _self.data.toStyleColor || null,
        jobId: null
      });
      $window.open(url, '_blank');
    };
    _self.$onChanges = function(changes) {
      if (changes.data && !!changes.data.currentValue) {
        _self.data = changes.data.currentValue;
      }
    };
  },
  templateUrl: 'templates/partials/job/jobLink.tpl.html'
};

module.exports = jobLinkComponent;
