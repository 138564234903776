'use strict';

var CSVDownloadService = /*@ngInject*/ function ($http, $q, REST_URL) {
  var _self = this;

  _self.generateFile = function(response, filename) {
    var headers = response.headers();
    var blob = new Blob([response.data], { type: headers['content-type'] });
    var link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  _self.downloadJobsInPostByUser = function(assignee) {
    var deferred = $q.defer();

    $http
      .get(`${REST_URL.base}/jobs/csv?status=In Post&assignee=${assignee}`)
        .then(function(response) {
          _self.generateFile(response, 'PostUserJobData.csv');
          deferred.resolve(response);
        })
        .catch(function(error) {
          deferred.reject(error);
        });

    return deferred.promise;
  };
};

module.exports = CSVDownloadService;
