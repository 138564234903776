'use strict';

var angular = require('angular');

var OKTA_REDIRECT_URI = {
  local : 'http://localhost:3000/',
  dev   : 'https://gbipuidev01.sandbox.dbt.svs.nike.com/',
  uat   : 'https://gbipuiuat.sandbox.dbt.svs.nike.com/',
  prod  : 'https://icon.svs.nike.com/'
};

var RedirectOktaURI = /*@ngInject*/ (function() {
  return {
    getOktaRedirectURL: function() {
      var redirectUri;

      if (location.href.indexOf('localhost') > -1) {
        redirectUri = OKTA_REDIRECT_URI.local;
      } else if (location.host.indexOf('gbipuidev01') > -1) {
        //DEV (in AWS)
        redirectUri = OKTA_REDIRECT_URI.dev;
      } else if (location.host.indexOf('gbipuiuat') > -1) {
        //UAT
        redirectUri = OKTA_REDIRECT_URI.uat;
      } else {
        //PROD
        redirectUri = OKTA_REDIRECT_URI.prod;
      }
      return redirectUri;
    }
  };
})();

module.exports = RedirectOktaURI;
