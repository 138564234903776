'use strict';

var ACTIVITY = {
  actions: {
    duplicate: 'Duplicate',
    reactivate: 'Reactivate',
    drop: 'Drop'
  },

  seasonReportActions: {
    add: 'Add',
    drop: 'Drop'
  },

  buttonText: {
    duplicateJob: {
      primary: 'Overwrite Manual',
      secondary: 'Edit Manual'
    },
    reactivationJob: {
      primary: 'Problem Solve',
      secondary: 'Start From the Beginning'
    }
  },

  updateTypes: {
    duplicate: {
      overWriteManual: {
        title: 'Overwrite Manual Data Confirmation',
        copy:
          'You are about to overwrite user-created data with system-generated data.',
        resolution: 'OverwriteOriginal'
      },
      editManual: {
        resolution: 'UpdateOriginal'
      }
    },
    reactivate: {
      startFromBeginning: {
        title: 'Start From the Beginning',
        copy:
          'You are about to send this product to the beginning of the pipeline.',
        problemSolve: false
      },
      problemSolve: {
        title: 'Send to Problem Solve',
        copy: 'You are about to send this product to Problem Solve.',
        problemSolve: true
      }
    }
  }
};

module.exports = ACTIVITY;
