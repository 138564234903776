'use strict';

var angular = require('angular');

var AssetQueueController = /*@ngInject*/ function(
  $scope,
  $rootScope,
  $state,
  $q,
  $timeout,
  META_STATUS,
  ASSET_STATUS,
  APP_DATA,
  USER_ROLES,
  QueueService,
  iScrollService,
  Session,
  AuthService,
  JobService,
  UPDATE_PARAMS,
  SearchService,
  RouterTracker,
  RESULTS_CONSTANT,
  EventLoggerService
) {
  var _self = this;

  //used privately
  _self.setUpdateParams = function(params) {
    _self.updateParams = params || _self.stateData.params.updateParams;
  };

  _self.getExpectedViewsStatus = function(jobs) {
    var allExpectedViewsComplete = true;
    jobs.some(function(job) {
      if (!job.expectedViews) {
        allExpectedViewsComplete = false;
      } else {
        job.assetJobs.some(function(assetJob) {
          var viewcode = assetJob.viewCode;

          if (
            job.expectedViews.indexOf(viewcode) >= 0 &&
            assetJob.status === ASSET_STATUS.postProd
          ) {
            allExpectedViewsComplete = false;
          }

          return !allExpectedViewsComplete;
        });
      }
      return !allExpectedViewsComplete;
    });
    return allExpectedViewsComplete;
  };

  // Allow view to access service methods
  _self.service = QueueService;

  _self.data = {
    allJobs: [], // full job list
    jobs: [], // job list after filtering
    viewType: 'list',
    disableAssets: true
  };

  _self.config = {
    page: 0,
    size: 50
  };

  _self.allResults = false;

  _self.toggleAll = function(jobs, boolean) {
    QueueService.toggleAll(jobs, boolean);

    jobs.forEach(function(job) {
      job.assetJobs.forEach(function(assetJob) {
        assetJob.selected = boolean;
      });
    });

    return jobs;
  };

  _self.permissions = {
    assign: (function() {
      return AuthService.isAuthorized([
        USER_ROLES.imageEditor,
        USER_ROLES.externalImageEditor,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    })(),
    showOthersAssignments: (function() {
      return !AuthService.hideOthersAssignments();
    })(),
    unassign: (function() {
      return AuthService.isAuthorized([
        USER_ROLES.imageEditor,
        USER_ROLES.externalImageEditor,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    })(),
    complete: (function() {
      return AuthService.isAuthorized([
        USER_ROLES.imageEditor,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    })()
  };

  _self.buildCompleteUpdateData = function(closedAssets) {
    return {
      heading: {
        title: 'Mark Complete',
        copy: 'Post Production tasks for the following asset(s) will be closed:'
      },
      status: {
        value: ASSET_STATUS.closed
      },
      type: 'completeAsset',
      preview: 'assets',
      notification: {
        single: '1 asset has been closed.',
        plural: '{{closedAssets.length}} assets have been closed.',
        context: {
          closedAssets: [].concat.apply([], closedAssets)
        }
      }
    };
  };

  _self.completeJobs = function() {
    var closedAssets = [];

    _self.data.selectedJobs.forEach(function(job) {
      var matchingAssets = job.assetJobs.filter(function(assetJob) {
        return assetJob.status === ASSET_STATUS.postProd;
      });
      closedAssets = closedAssets.concat(matchingAssets);
    });

    _self.service.confirmUpdate(
      _self.data.selectedJobs,
      _self.buildCompleteUpdateData(closedAssets),
      _self.stateData.location
    );
  };

  _self.doSearch = function(term) {
    Session.setSessionValue(
      _self.stateData.location + 'searchTerm',
      term,
      _self.state
    );
    _self.service.doSearch(_self, term).then(function(jobs) {
      if (term && jobs.length === 1 && _self.stateData.params.updateParams) {
        //Suppressing the assignment modal when searching from main
        if (_self.stateData.location === 'tray') {
          _self.service.confirmUpdate(
            jobs,
            _self.updateParams,
            _self.stateData.location
          );
        }
      }
    });
  };

  _self.toggleAssign = function(assign) {
    if (assign) {
      _self.data.assignee = Session.get();
      Session.setSessionValue('toggleAssign', true, _self.state);
    } else {
      _self.data.assignee = false;
      Session.setSessionValue('toggleAssign', false, _self.state);
    }
    _self.service.resetAndGetData(_self);
  };

  _self.confirmUpdate = function() {
    _self.service.confirmUpdate(
      _self.data.selectedJobs,
      _self.updateParams,
      _self.stateData.location
    );
  };

  _self.getJobs = function() {
    var deferred = $q.defer();

    _self.working = true;
    _self.loadingError = false;

    if (
      _self.data.allJobs === undefined ||
      _self.data.allJobs.length === 0 ||
      _self.data.allJobsUnfiltered === undefined ||
      _self.data.allJobsUnfiltered.length === 0
    ) {
      _self.config.page = 0;
    }

    _self.searchParams = angular.copy(_self.stateData.params.searchParams);

    if (_self.data.assignee !== undefined) {
      if (_self.data.assignee) {
        _self.searchParams.filters +=
          ',assignees=' + _self.data.assignee.userName;
      } else {
        _self.searchParams.filters += ',+assignees';
      }
    }
    QueueService.getJobs(
      _self.data.term,
      _self.searchParams,
      _self.config.page,
      _self.config.size,
      _self.stateData.location
    )
      .then(function(response) {
        _self.data.allJobsUnfiltered = JobService.filterDuplicateJobs(
          _self.data.allJobsUnfiltered.concat(response.data.results)
        );
        var queryResults = response;

        //use facet values from filtered query
        _self.data.filters = SearchService.transformFacets(
          response.data.facets
        );
        _self.data.filters.push({
          id: 'assignees',
          name: 'Assignee',
          options: undefined
        });
        //Only use the filters we pass through the router
        if (_self.filterOptions) {
          var temp = [];
          for (var i = 0; i < _self.data.filters.length; i++) {
            var current = _self.data.filters[i];
            if (_self.filterOptions.indexOf(current.id) >= 0) {
              temp.push(current);
            }
            //Season options are pre-populated, so we need a separate if loop.
            if (
              current.name === 'Seasons' &&
              _self.filterOptions.indexOf('seasons') >= 0
            ) {
              temp.push(current);
            }
          }
          _self.data.filters = temp;
          _self.data.buttonFilters = _self.stateData.params.buttonFilters || [];
        }

        _self.sessionFilters = Session.getSessionValue(
          _self.stateData.location + 'Filters',
          _self.state
        );
        if (
          _self.sessionFilters &&
          _self.sessionFilters.assignees &&
          _self.data.assignee
        ) {
          delete _self.sessionFilters.assignees;
        }
        _self.searchParams['filters'] +=
          ',' + Session.stringify(_self.sessionFilters);

        var deferredSessionFilters = $q.defer();
        var promiseSessionFilters = deferredSessionFilters.promise;

        //If we have filters saved in Session storage, apply them and call QueueService to get the new job list, otherwise just resolve the promise and use the results from the first job search
        if (
          _self.sessionFilters !== null &&
          Object.keys(_self.sessionFilters).length > 0
        ) {
          QueueService.getJobs(
            _self.data.term,
            _self.searchParams,
            _self.config.page,
            _self.config.size,
            _self.stateData.location
          ).then(function(responseSessionFilters) {
            _self.data.allJobs = JobService.filterDuplicateJobs(
              _self.data.allJobs.concat(responseSessionFilters.data.results)
            );
            queryResults = responseSessionFilters;
            deferredSessionFilters.resolve();
          });
        } else {
          _self.data.allJobs = _self.data.allJobsUnfiltered;
          deferredSessionFilters.resolve();
        }

        promiseSessionFilters.then(function() {
          _self.config.page++;
          EventLoggerService.track(
            'QUEUE_PAGINATION',
            {
              page: $state.current.name,
              location: _self.stateData ? ' ' + _self.stateData.location : '',
              pageNumber: _self.config.page
            },
            _self.config.page > 1
          );

          _self.data.total = queryResults.data.total;

          if (_self.config.size > queryResults.data.results.length) {
            _self.allResults = true;
          }

          _self.data.jobs = JobService.getFilteredJobs(
            _self.data.allJobs,
            _self.data.assignee,
            _self.stateData.params.assignType
          );

          _self.data.total = queryResults.data.total;

          if (
            _self.stateData.params.status === META_STATUS.problemSolve &&
            !_self.stateData.params.assignToggle
          ) {
            ProblemSolveIndicatorService.setCount(_self.data.jobs);
          }
          _self.working = false;

          deferred.resolve(_self.data.jobs);
        });
      })
      .catch(function(error) {
        _self.working = false;
        _self.loadingError = true;
      });

    return deferred.promise;
  };

  _self.onScrollEnd = function() {
    if (
      !_self.working &&
      _self.scrollerObj.y <= _self.scrollerObj.maxScrollY &&
      !_self.allResults
    ) {
      _self.getJobs();
    }

    $rootScope.$broadcast('scroll-stop');
  };

  _self.setSelectionCta = function() {
    if (
      _self.data.selectedJobs.length &&
      (AuthService.isAdmin() ||
        AuthService.isAuthorized([USER_ROLES.imageEditor, USER_ROLES.externalImageEditor, USER_ROLES.seniorImageEditor])) &&
      _self.stateData.params.assignToggle
    ) {
      _self.data.selectionCta = 'Reassign';
    } else {
      _self.data.selectionCta = _self.stateData.params.cta || 'Update Status';
    }
  };

  /* onClick funtions for cards */

  var lastSelected = null;
  var lastIndex = null;

  _self.cardClick = function(job, ev) {
    // Note: This is not the only click function for cards. This function runs AFTER the $element.on('click') in the job controller.
    if (lastSelected && lastIndex > -1) {
      if (ev.shiftKey) {
        // Just do nothing if they click the same job twice while holding shift.
        if (lastIndex === _self.data.jobs.indexOf(job)) {
          job.selected = !job.selected;
          return;
        }

        //select all between the previous and current selection
        var currentIndex = _self.data.jobs
          .map(function(a) {
            return a.id;
          })
          .indexOf(job.id);
        var selectingJobs =
          lastSelected.selected && job.selected ? true : false;
        if (currentIndex > lastIndex) {
          for (var i = lastIndex; i < currentIndex; i++) {
            if (selectingJobs) {
              _self.data.jobs[i].selected = true;
            } else {
              _self.data.jobs[i].selected = false;
              lastSelected.selected = false;
              job.selected = true;
            }
          }
        } else if (lastIndex > currentIndex) {
          for (var i = currentIndex; i < lastIndex; i++) {
            if (selectingJobs) {
              _self.data.jobs[i].selected = true;
            } else {
              _self.data.jobs[i].selected = false;
              lastSelected.selected = false;
              job.selected = true;
            }
          }
        }
        // This is to prevent the text in the cards from highlighting. We do still need this functionality though, so users can copy ids/style colors.
        window.getSelection().removeAllRanges();
      } else if (ev.metaKey) {
        //select both, original behavior
      } else {
        //no shift, no ctrl, only select one
        var currentlySelected = job;

        var match = function(a, b) {
          return (
            (a.id ? a.id : '') + (a.product.id ? a.product.id : '') ===
            (b.id ? b.id : '') + (b.product.id ? b.product.id : '')
          );
        };
        for (var i = 0; i < _self.data.jobs.length; i++) {
          if (
            !match(_self.data.jobs[i], currentlySelected) &&
            _self.data.jobs[i].selected
          ) {
            _self.data.jobs[i].selected = false;
          }
        }
      }
    }

    lastSelected = job;
    lastIndex = _self.data.jobs
      .map(function(a) {
        return a.id;
      })
      .indexOf(job.id);
  };

  /* Queue filters */
  _self.filtersActive = false;
  _self.toggleFilters = function() {
    _self.filtersActive = !_self.filtersActive;
  };

  _self.init = function() {
    _self.data.name = _self.service.data[_self.data.location];
    _self.data.allJobs = [];
    _self.data.allJobsUnfiltered = [];
    _self.state = $state.current.name;
    _self.data.showSecondaryCropTypes = false;

    _self.stateWatch = function() {
      var specialUpdateParams;
      var queueName = $state.current.name.substring(6);
      _self.stateData = angular.copy($scope.stateData);
      _self.filterOptions = _self.stateData.params.filters;

      _self.stateData = angular.copy($scope.stateData);
      _self.service.registerScope(_self, _self.stateData.location);

      _self.constant = AuthService.getAuthorizedResults(
        Session.get('roles'),
        $state.current.data.authorizedRoles,
        RESULTS_CONSTANT[
          _self.stateData.viewState + '.' + _self.stateData.location
        ],
        _self.stateData.location
      );

      _self.authorizedForAssets = AuthService.showSubSection(
        Session.get('roles'),
        $state.current.data.authorizedRoles,
        _self.stateData.location,
        'assets'
      );

      _self.authorizedForUnassign = AuthService.showSubSection(
        Session.get('roles'),
        $state.current.data.authorizedRoles,
        _self.stateData.location,
        'unassign'
      );

      var stateLocation = _self.stateData.location;
      var stateView = Session.getSessionValue(
        stateLocation + 'View',
        _self.state
      );
      _self.data.viewType =
        stateView !== null && _self.authorizedForAssets ? stateView : 'list';

      var sessionFilters = Session.getSessionValue(
        stateLocation + 'Filters',
        _self.state
      );
      if (sessionFilters !== null && Object.keys(sessionFilters).length > 0) {
        _self.filtersActive = true;
      }

      var routeHistory = RouterTracker.getRouteHistory();
      var lastRoute = routeHistory[routeHistory.length - 1];
      if (lastRoute.route.name === 'detail') {
        var term = Session.getSessionValue(
          stateLocation + 'searchTerm',
          _self.state
        );
        _self.data.term = term === null ? '' : term;
        _self.data.inputTerm = _self.data.term;
      } else {
        Session.setSessionValue(stateLocation + 'searchTerm', '', _self.state);
      }

      if (_self.stateData.params.assignToggle) {
        if (!_self.permissions.assign) {
          _self.stateData.params.assignToggle = false;
        }

        var sessionToggleAssign = Session.getSessionValue(
          'toggleAssign',
          _self.state
        );
        var toggleAssignDefault =
          sessionToggleAssign === null ? true : sessionToggleAssign;
        _self.toggleAssign(toggleAssignDefault);
      } else {
        _self.service.resetAndGetData(_self);
      }

      if (_self.stateData.params.showOthersAssets) {
        if (!_self.permissions.showOthersAssignments) {
          _self.stateData.params.showOthersAssets = false;
        }
      } else {
        _self.service.resetAndGetData(_self);
      }

      $scope.$on('queue.update.' + _self.stateData.location, function(
        e,
        params
      ) {
        _self.service.updateJobs(_self, params);
      });

      if (
        (AuthService.isAdmin() ||
          AuthService.isAuthorized([USER_ROLES.imageEditor, USER_ROLES.externalImageEditor, USER_ROLES.seniorImageEditor])) &&
        _self.stateData.params.assignToggle
      ) {
        specialUpdateParams = angular.merge(
          {},
          UPDATE_PARAMS[queueName].assign,
          UPDATE_PARAMS.special.reassign
        );
      }

      _self.setUpdateParams(specialUpdateParams);

      if (_self.stateData.location === 'tray' && !_self.permissions.assign) {
        _self.stateData.params.stop = true;
      }
    };

    $scope.$watch('stateData', _self.stateWatch);

    $scope.$watch(
      function() {
        return _self.data.jobs;
      },
      function() {
        _self.service.handleSelections(_self);
        _self.setSelectionCta();

        if (
          AuthService.isAdmin() ||
          AuthService.isAuthorized([USER_ROLES.seniorImageEditor])
        ) {
          _self.canUnassign = true;
        }

        if (
          AuthService.isAuthorized([
            USER_ROLES.seniorImageEditor,
            USER_ROLES.superUser
          ])
        ) {
          _self.canComplete = _self.getExpectedViewsStatus(
            _self.data.selectedJobs
          );
        } else {
          var mixedSelections = false;

          _self.data.selectedJobs.some(function(job) {
            job.assetJobs.some(function(assetJob) {
              // canComplete and canUnassign are set to FALSE if
              // The assetJob is not assigned to the user OR the user is not an imageEditor
              // and if the assetJob is not in post production (this ONLY applies to the post prod queue)
              if (
                (!Session.compareUser(job.tasks[0].assignee) ||
                  !AuthService.isAuthorized(USER_ROLES.imageEditor)) &&
                ($state.current.name === 'queue.post-prod'
                  ? assetJob.status === 'In Post Production'
                  : true)
              ) {
                mixedSelections = true;
              }
              return mixedSelections;
            });

            return mixedSelections;
          });

          if (!mixedSelections) {
            _self.canComplete =
              _self.permissions.complete &&
              _self.getExpectedViewsStatus(_self.data.selectedJobs);
            _self.canUnassign = _self.permissions.unassign;
          } else {
            _self.canComplete = false;
            _self.canUnassign = false;
          }
        }
      },
      true
    );

    $scope.$watch(
      function() {
        return _self.data.viewType;
      },
      function() {
        $timeout(function() {
          iScrollService.refresh(_self);
        }, 0);
      }
    );

    $timeout(function() {
      _self.scrollerObj = iScrollService.find(_self);

      if (_self.scrollerObj) {
        _self.scrollerObj = _self.scrollerObj[0].scrollerObj;
        _self.scrollerObj.on('scrollEnd', _self.onScrollEnd);
      }
    }, 0);
  };

  _self.init();
};

module.exports = AssetQueueController;
