'use strict';

var ADMIN_USER_LINKS = {
  external: {
    label: 'Go To Management Portal',
    url: 'https://extuiadmin.nike.com/customui/agency/141/ICON'
  },
  internal: [
    {
      label: 'Art Buyer',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.ArtBuyer,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Digi Tech',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.DigiTech,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Image Editor',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.ImageEditor,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'External Image Editor',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.ExternalImageEditor,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Merch Ops',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.MerchOps,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Photographer',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.Photographer,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Product Reviewer',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.ProductReviewer,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'QA Reviewer',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.QAReviewer,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Studio Ops',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.StudioOps,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Studio Stylist',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.StudioStylist,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    },
    {
      label: 'Super User',
      url:
        'https://arserver.nike.com/ARServerAdmin/CustomCommands.aspx?DN=CN%3DApplication.CDT.DevOps.GBIP.Superuser,OU%3DApplication,OU%3DGroups,DC%3Dad,DC%3Dnike,DC%3Dcom&TaskId=Member&TargetClass=group'
    }
  ]
};

module.exports = ADMIN_USER_LINKS;
