'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    '$templateCache',
    '$compile',
    '$timeout',
    '$document',
    '$$tooltip',
    function tooltipDirective(
      $templateCache,
      $compile,
      $timeout,
      $document,
      $$tooltip
    ) {
      return {
        restrict: 'A',
        controller: [
          '$scope',
          '$attrs',
          '$parse',
          function($scope, $attrs, $parse) {
            var _self = this,
              scope = $scope.$new();

            this.init = function() {
              if ($attrs.tooltip) {
                $scope.$watch($parse($attrs.tooltip), function(value) {
                  scope.label = value;
                });
              }

              if ($attrs.interpolateTooltip) {
                $attrs.$observe('tooltip', function(value) {
                  scope.label = value;
                });
              }

              if ($attrs.tooltipPosition) {
                $scope.$watch($parse($attrs.tooltipPosition), function(value) {
                  scope.options.position = value;
                });
              }

              return scope;
            };
          }
        ],
        link: function(scope, elem, attrs, ctrl) {
          scope = ctrl.init();
          scope.active = false;
          scope.options = $$tooltip.options(scope.options);

          if (attrs.tooltipDelay) {
            scope.options.delay = attrs.tooltipDelay;
          }

          var timer;
          var template = $templateCache.get('templates/tooltip.tpl.html');
          var linkFn = $compile(template);
          var tooltip = linkFn(scope);

          var getElemPosition = function() {
            var rect = elem[0].getBoundingClientRect();
            return {
              top: rect.top + $document[0].scrollingElement.scrollTop,
              left: rect.left + $document[0].scrollingElement.scrollLeft,
              width: rect.width,
              height: rect.height
            };
          };

          var getOuterHeight = function(elem) {
            var computedStyle = getComputedStyle(elem);

            return (
              parseInt(computedStyle.height, 10) +
              parseInt(computedStyle.marginBottom, 10) +
              parseInt(computedStyle.marginTop, 10)
            );
          };

          var convertToPx = function(number) {
            return [number, 'px'].join('');
          };

          var setTooltipPosition = function(elemPos) {
            var left, top;

            switch (scope.options.position) {
              case 'top':
                left = convertToPx(elemPos.left + elemPos.width / 2);
                top = convertToPx(elemPos.top - getOuterHeight(tooltip[0]));
                break;
              case 'bottom':
                left = convertToPx(elemPos.left + elemPos.width / 2);
                top = convertToPx(elemPos.top + elemPos.height);
                break;
            }

            tooltip[0].style.left = left;
            tooltip[0].style.top = top;
          };

          var onToolTipTrigger = function() {
            if (scope.label > '') {
              timer = $timeout(function() {
                var elemPos = getElemPosition(); // read this before appending tooltip to body
                $document.find('body').append(tooltip);
                setTooltipPosition(elemPos);
                $timeout(function() {
                  scope.active = true;
                }, 0);
              }, scope.options.delay);
            }
          };

          var offToolTipTrigger = function() {
            $timeout.cancel(timer);
            $timeout(function() {
              scope.active = false;
            }, 0);
            $timeout(function() {
              tooltip.remove();
            }, 200); // TODO cheater
          };

          elem.on(scope.options.trigger, onToolTipTrigger);
          elem.on(
            $$tooltip.triggerMap[scope.options.trigger],
            offToolTipTrigger
          );

          tooltip[0].style.position = 'absolute';

          scope.$on('$destroy', function() {
            $timeout.cancel(timer);
            tooltip.remove();
            elem.off(scope.options.trigger, onToolTipTrigger);
            elem.off(
              $$tooltip.triggerMap[scope.options.trigger],
              offToolTipTrigger
            );
          });
        }
      };
    }
  ];
}

module.exports = register;
