'use strict';

/*jshint unused: false*/
var InputDirective = /*@ngInject*/ function($filter, $timeout, $interpolate) {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      model: '=',
      modelOptions: '<',
      placeholder: '@',
      label: '<',
      disabled: '<',
      required: '<',
      onChange: '&',
      parentValidate: '&',
      icon: '@',
      minlength: '<',
      maxlength: '<'
    },
    controllerAs: '$ctrl',
    controller: /*@ngInject*/ function($element) {
      var _self = this,
        init;

      _self.validate = function() {
        _self.isActive = false;
        _self.invalid = false;

        if (_self.model === undefined) {
          return;
        }

        _self.invalid = _self.required
          ? _self.model === undefined || _self.model.length === 0
          : false;

        if (_self.onChange) {
          _self.onChange({
            $event: { text: _self.model, valid: !_self.invalid }
          });
        }

        if (!!_self.parentValidate) {
          _self.parentValidate();
        }
      };

      _self.getLabelDisplay = function(label, required) {
        return _self.label + (required ? '*' : '');
      };

      _self.$onChanges = function(changes) {
        if (changes.required) {
          var inputElem = $element[0].querySelector('input');
          inputElem.setAttribute('required', _self.required);

          if (_self.label) {
            _self.labelDisplay = _self.getLabelDisplay(
              _self.label,
              _self.required
            );
          }

          _self.validate();
        }

        if (changes.label) {
          _self.labelDisplay = _self.getLabelDisplay(
            _self.label,
            _self.required
          );
        }

        if (changes.modelOptions) {
          if (!_self.modelOptions) {
            _self.modelOptions = {};
          }
        }
      };
    },
    templateUrl: 'templates/partials/ui/input.tpl.html',
    link: function(scope, elem, attrs, ctrl) {}
  };
};

module.exports = InputDirective;
