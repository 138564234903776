'use strict';

//Adapted from https://github.com/meyfa/angular-filesize-filter
var capitalize = function() {
  return function(rawString, allWords) {
    var formattedWords = '';
    if (allWords) {
      formattedWords =
        rawString.charAt(0).toUpperCase() + rawString.substr(1).toLowerCase();
    } else {
      var wordArray = rawString.split(' ').map(function(word) {
        return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
      });

      formattedWords = wordArray.join(' ');
    }
    return formattedWords;
  };
};

module.exports = capitalize;
