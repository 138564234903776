'use strict';

var angular = require('angular');
var app = require('./modules/gbip.module.js');
// Configuration
app.config(require('./config/route.config.js'));
app.config(require('./config/http.config.js'));
app.config(require('./config/tooltip.config.js'));
app.config(require('./config/notifications.config.js'));

// Injectables
app.constant('AUTH_EVENTS', require('./config/authEvents.constant.js'));
app.constant('USER_ROLES', require('./config/userRoles.constant.js'));
app.constant(
  'USER_ROLES_LABEL',
  require('./config/userRolesLabel.constant.js')
);
app.constant('ROLE_NAV', require('./config/roleNav.constant.js'));
app.constant('REDIRECT', require('./config/redirect.constant.js'));
app.constant('LOGIN_URL', require('./config/loginUrl.constant.js'));
app.constant('MERCH_STATUS', require('./config/merchStatus.constant.js'));
app.constant('META_STATUS', require('./config/metaStatus.constant.js'));
app.constant('ASSET_STATUS', require('./config/assetStatus.constant.js'));
app.constant('JOB_TYPE', require('./config/jobType.constant.js'));
app.constant('UPDATE_PARAMS', require('./config/updateParams.constant.js'));
app.constant('RESULTS_CONSTANT', require('./config/results.constant.js'));
app.controller(
  'outreachPLMController',
  require('./queues/controllers/outreachPLM.controller.js')
);

app.constant('ASSET_HIGH_RES_WIDTH', 2000);
app.constant(
  'CHARACTER_LIMITS',
  require('./config/characterLimits.constant.js')
);
app.constant(
  'PRIORITY_CROP_TYPE_SIZES',
  require('./config/priorityCropTypeSizes.constant')
);
angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);

// Events
app.run(require('./config/routeChange.event.js'));
app.run(require('./config/history.event.js'));
app.run(require('./config/globals.js'));

// Controllers
app.controller('AppController', require('./controllers/app.controller.js'));
app.controller(
  'StyleguideController',
  require('./controllers/styleguide.controller.js')
);

app.controller(
  'PrintSlateController',
  require('./controllers/printSlate.controller.js')
);
app.controller('SlateController', require('./controllers/slate.controller.js'));
app.controller(
  'ShippingInfoController',
  require('./controllers/shippingInfo.controller.js')
);
app.controller(
  'RequestCodeController',
  require('./controllers/request.code.controller.js')
);

//Services
app.factory('AuthService', require('./services/auth.service.js'));
app.service('Session', require('./services/session.service.js'));
app.service('ScanTypeService', require('./services/scanType.service.js'));
app.service('AssetDeleteService', require('./services/assetDelete.service.js'));
app.service('AssetSearchService', require('./services/assetSearch.service.js'));
app.service('JobService', require('./services/job.service.js'));
app.service('HistoryService', require('./services/history.service.js'));
app.service('NoteService', require('./services/note.service.js'));
app.service('ReportsService', require('./services/reports.service.js'));
app.service('ScannerService', require('./services/scanner.service.js'));
app.service('RepublishService', require('./services/republish.service.js'));
app.service(
  'LinkedProductService',
  require('./services/linkedProduct.service.js')
);
app.service('BatchService', require('./services/batch.service.js'));
app.service('EventLoggerService', require('./services/eventLogger.service.js'));
app.service(
  'BaseProductSearchService',
  require('./services/baseProductSearch.service.js')
);
app.service('AssignedUsersService', require('./services/assignedUsers.service.js'));
app.service('CSVDownloadService', require('./services/csvDownload.service.js'));

app.factory(
  'ProblemSolveIndicatorService',
  require('./services/problemSolveIndicator.service.js')
);

app.factory('RouterTracker', require('./services/router.tracker.factory.js'));
app.factory('CSVExporter', require('./services/csv.factory.js'));
app.factory(
  'HttpErrorInterceptor',
  require('./services/httpErrorInterceptor.factory.js')
);


app.config([
  '$httpProvider',
  function($httpProvider) {
    $httpProvider.interceptors.push('HttpErrorInterceptor');
  }
]);

// Animation
app.service('AnimationService', require('./services/animation.service.js'));

// Filters
app.filter('regex', require('./filters/regex.filter.js'));
app.filter('tahead', require('./filters/tahead.filter.js'));
app.filter('ddisplay', require('./filters/dateDisplay.filter.js'));
app.filter('openAssets', require('./filters/openAssets.filter.js'));
app.filter('filesize', require('./filters/filesize.filter.js'));
app.filter('capitalize', require('./filters/capitalize.filter.js'));
app.filter('trust', require('./filters/trust.filter.js'));

// Directives
app.component('slateModal', require('./directives/slateModal.component.js'));
app.directive('slate', require('./directives/slate.directive.js'));
app.directive('search', require('./directives/ui/search.directive.js'));
app.directive('printModal', require('./directives/printModal.directive.js'));
app.component('gbipSort', require('./directives/ui/sort.component.js'));
app.component(
  'fileUploadGroup',
  require('./directives/ui/fileUploadGroup.component.js')
);
app.component('fcoGroup', require('./directives/ui/fcoGroup.component.js'));
app.component('otherAssetsGroup', require('./directives/ui/otherAssetsGroup.component.js'));
app.component('fileUpload', require('./directives/ui/fileUpload.component.js'));
app.component('gbipSort', require('./directives/ui/sort.component.js'));
app.component('imgModal', require('./directives/ui/imgModal.component.js'));
app.component('imgHero', require('./directives/ui/imgHero.component.js'));

// Admin View
app.constant('ADMIN_USER_LINKS', require('./admin/adminUserLinks.constant.js'));
app.controller('AdminController', require('./admin/admin.controller.js'));
app.component('versions', require('./admin/versions.component.js'));
app.component('assetRequests', require('./admin/assetRequests.component.js'));
app.component('bulkJobUpdate', require('./admin/bulkJobUpdate.component.js'));
app.component('manageStudios', require('./admin/manageStudios.component.js'));
app.component('productSync', require('./admin/productSync.component.js'));
app.component('publishAssets', require('./admin/publishAssets.component.js'));
app.component('recordLinks', require('./admin/recordLinks.component.js'));
app.component('studio', require('./admin/studio.component.js'));
app.component('studioModal', require('./admin/studio.modal.component.js'));
app.component('userAdmin', require('./admin/userAdmin.component.js'));

// Activity View
app.controller(
  'ActivityController',
  require('./controllers/activity.controller.js')
);
app.constant('ACTIVITY', require('./config/activity.constant.js'));
app.value('MOCK_DATA_ACTIVITY', require('./config/mockDataActivity.value.js'));
app.directive(
  'seasonBox',
  require('./directives/activity/seasonBox.directive.js')
);
app.directive(
  'userVsSystemJob',
  require('./directives/activity/userVsSystemJob.directive.js')
);
app.directive(
  'activityModal',
  require('./directives/activity/activityModal.directive.js')
);

// Job Stuff
app.controller('JobController', require('./controllers/job.controller.js'));

app.directive(
  'jobCompact',
  require('./directives/job/views/compact.directive.js')
);
app.directive(
  'jobLimited',
  require('./directives/job/views/limited.directive.js')
);
app.directive('jobFull', require('./directives/job/views/full.directive.js'));
app.directive(
  'newFull',
  require('./directives/job/views/fullNew.directive.js')
);
app.directive(
  'jobProblemSolve',
  require('./directives/job/views/problemSolve.directive.js')
);

app.component('task', require('./directives/job/task.component.js'));
app.component('thumbnail', require('./directives/job/thumbnail.component.js'));
app.directive(
  'jobShipment',
  require('./directives/job/views/shipment.directive.js')
);
app.service(
  'DownloadStatusService',
  require('./services/downloadStatus.service.js')
);
app.component(
  'downloadStatus',
  require('./directives/ui/downloadStatus.component.js')
);
app.component(
  'progressBar',
  require('./directives/ui/progressBar.component.js')
);

// Queue Views
app.constant('QUEUE_DATA', require('./queues/queueData.constant.js'));
app.service('QueueService', require('./queues/queue.service.js'));
app.controller(
  'QueueController',
  require('./queues/controllers/queue.controller.js')
);
app.controller(
  'MetaQueueController',
  require('./queues/controllers/meta.controller.js')
);
app.controller(
  'MerchQueueController',
  require('./queues/controllers/merch.controller.js')
);
app.controller(
  'AssetQueueController',
  require('./queues/controllers/asset.controller.js')
);
app.component('queueResults', require('./queues/results.component.js'));
app.component('newResults', require('./queues/resultsNew.component.js'));
app.component('filterCompact', require('./queues/filterCompact.component.js'));

//Outreach View
app.controller(
  'OutreachController',
  require('./queues/controllers/outreachQueue.controller.js')
);
app.component(
  'receiveMerchModal',
  require('./queues/controllers/receiveMerchModal.component.js')
);

// Search View
app.constant('SEARCH_CONSTANTS', require('./search/search.constant.js'));
app.service('SearchService', require('./search/search.service.js'));
app.controller(
  'SearchFilterController',
  require('./search/filter.controller.js')
);
app.controller('SearchController', require('./search/search.controller.js'));
app.controller('SearchTagController', require('./search/tag.controller.js'));

app.component('searchResults', require('./search/results.component.js'));
app.component('searchResultsHeader', require('./search/header.component.js'));
app.component('searchCompare', require('./search/compare.component.js'));
app.directive('tag', require('./directives/ui/tag.directive.js'));
app.component(
  'viewOptions',
  require('./directives/ui/viewOptions.component.js')
);

// Asset Download

app.component(
  'assetDownloadFilters',
  require('./directives/assetDownload/filters.component.js')
);
app.component(
  'assetDownloadResults',
  require('./directives/assetDownload/results.component.js')
);

// QA Detail View
app.controller(
  'QaDetailController',
  require('./controllers/qa.detail.controller.js')
);
app.service('QAService', require('./services/qa.service.js'));
app.service('OutreachService', require('./services/outreach.service.js'));
app.service('CanvasService', require('./services/canvas.service.js'));
app.component('qaRejection', require('./directives/qa/rejection.component.js'));
app.component('rejectionPanel', require('./directives/qa/rejectionPanel.component.js'));
app.component(
  'imageViewer',
  require('./directives/qa/imageViewer.component.js')
);
app.component(
  'imageViewerHero',
  require('./directives/qa/imageViewerHero.component.js')
);
app.directive('imageThumb', require('./directives/qa/imageThumb.directive.js'));
app.controller(
  'ImageThumbController',
  require('./directives/qa/imageThumb.controller.js')
);
app.component('annotation', require('./directives/qa/annotation.component.js'));
app.component(
  'imageZoom',
  require('./directives/qa/imageViewerZoom.component.js')
);
app.component('qaApprove', require('./directives/qa/approve.component.js'));

//Product Team Outreach
app.controller(
  'OutreachDetailController',
  require('./controllers/outreach.detail.controller.js')
);
app.component(
  'outreachViewer',
  require('./directives/qa/outreachViewer.component.js')
);
app.directive(
  'outreachImageThumb',
  require('./directives/qa/outreachImageThumb.directive.js')
);
app.component(
  'outreachStatus',
  require('./detail/outreachStatus.component.js')
);

// Data Transfer
app.service('TransferService', require('./transfer/transfer.service.js'));
app.component('transfer', require('./transfer/transfer.component.js'));

// Data Transfer
app.service('DeliverService', require('./deliver/deliver.service.js'));
app.component('deliver', require('./deliver/deliver.component.js'));

// Detail View
app.controller('DetailController', require('./detail/detail.controller.js'));
app.component('detailHeader', require('./detail/header.component.js'));
app.component('productDetail', require('./detail/productDetail.component.js'));
app.component(
  'productDetailModal',
  require('./detail/productDetailModal.component.js')
);
app.component('jobDetail', require('./detail/jobDetail.component.js'));
app.component('merchStatus', require('./detail/merchStatus.component.js'));
app.component('qaStatus', require('./detail/qaStatus.component.js'));
app.component(
  'assetJobDetail',
  require('./detail/assetJobDetail.component.js')
);
app.component('assetStatus', require('./detail/assetStatus.component.js'));
app.component('assetHero', require('./detail/assetHero.component.js'));
app.component('assetGallery', require('./detail/assetGallery.component.js'));
app.component(
  'photographyDetail',
  require('./detail/photographyDetail.component.js')
);
app.component('shootDetails', require('./detail/shootDetails.component.js'));
app.component(
  'shootDetailsInitiateJob',
  require('./detail/shootDetailsInitiateJob.component.js')
);
app.component('attachments', require('./detail/attachments.component.js'));
app.component('bestAssets', require('./detail/bestAssets.component.js'));
app.component('removeBestAssets', require('./detail/removeBestAssets.component.js'));
app.component(
  'productDelivery',
  require('./detail/productDelivery.component.js')
);
app.component(
  'productJobs',
  require('./detail/productJobs.component.js')
);
app.component('history', require('./detail/history.component.js'));
app.constant('HISTORY', require('./config/history.constant.js'));
app.directive('onLoad', require('./detail/onLoad.directive.js'));
app.component('jobNotes', require('./detail/jobNotes.component.js'));
app.component('note', require('./detail/note.component.js'));
app.component('problemModal', require('./detail/problemModal.component.js'));
app.component(
  'resolveProblemModal',
  require('./detail/resolveProblemModal.component.js')
);
app.service('DeliveryJobService', require('./services/deliveryJob.service.js'));

// Initiate Job
app.component('initiateJob', require('./initiateJob/initiateJob.component.js'));
app.component(
  'existingJobModal',
  require('./initiateJob/existingJobModal.component.js')
);
app.component(
  'initiateJobModal',
  require('./initiateJob/initiateJobModal.component.js')
);
app.component(
  'closeJobModal',
  require('./initiateJob/closeJobModal.component.js')
);
app.component(
  'postConversionJobModal',
  require('./initiateJob/postConversionJobModal.component.js')
);
app.component(
  'baseProductSelector',
  require('./initiateJob/baseProductSelector.component.js')
);
app.component(
  'baseProductSearch',
  require('./initiateJob/baseProductSearch.component.js')
);
app.component(
  'baseProductResults',
  require('./initiateJob/baseProductResults.component.js')
);
app.component('baseProduct', require('./initiateJob/baseProduct.component.js'));
app.component(
  'baseProductThumbnail',
  require('./initiateJob/baseProductThumbnail.component.js')
);
app.component(
  'priorityToggle',
  require('./initiateJob/priorityToggle.component.js')
);
app.component(
  'confidentialToggle',
  require('./initiateJob/confidentialToggle.component.js')
);
app.component(
  'viewCodeToggle',
  require('./initiateJob/viewCodeToggle.component.js')
);
app.service('JobFormService', require('./services/jobForm.service.js'));
app.service('JobTypeService', require('./services/jobType.service.js'));

//// UI Elements

// Icons
app.component('gbipIcon', require('./ui/icons/icon.component.js'));
app.component('confidential', require('./ui/icons/confidential.component.js'));
app.component('priority', require('./ui/icons/priority.component.js'));
app.component('jobType', require('./ui/icons/jobType.component.js'));
app.component(
  'rejectionIndicator',
  require('./ui/icons/rejectionIndicator.component.js')
);
app.component('cropTypes', require('./ui/icons/cropTypes.component.js'));

// Forms
app.component('gbipTextarea', require('./ui/forms/textarea.component.js'));
app.component('typeahead', require('./ui/forms/typeahead.component.js'));
app.component('formDropdown', require('./ui/forms/dropdown.component.js'));
app.directive('gbipInput', require('./ui/forms/input.directive.js'));
app.directive('checkbox', require('./ui/forms/checkbox.directive.js'));
app.component('datepicker', require('./directives/ui/datepicker.component.js'));

// Tabs
app.component('tabs', require('./ui/tabs/tabs.component.js'));
app.component('pane', require('./ui/tabs/pane.component.js'));

// Global
app.component('gbipHeader', require('./global/header.component.js'));
app.component('gbipModal', require('./ui/modal.component.js'));
app.component('loading', require('./ui/loading.component.js'));
app.directive('staticInclude', require('./ui/staticInclude.directive.js'));
app.directive('refresher', require('./ui/refresher.directive.js'));

app.service('StatusService', require('./statusChange/status.service.js'));
app.service('UsersService', require('./services/users.service.js'));
app.component(
  'statusChange',
  require('./statusChange/statusChange.component.js')
);
app.component(
  'expectedViews',
  require('./statusChange/expectedViews/expectedViews.component.js')
);
app.component('jobLink', require('./directives/job/jobLink.component.js'));

// CSV Download
app.component('csvDownload', require('./queues/csvDownload.component.js'));
