'use strict';

//http://http://odetocode.com/blogs/scott/archive/2013/07/05/a-file-input-directive-for-angularjs.aspx

/*jshint unused: false*/
function register(angular) {
  return [
    function fileInput() {
      function link(scope, element, attrs, ctrl) {
        // https://uncorkedstudios.com/blog/multipartformdata-file-upload-with-angularjs
        var fileElement = angular.element(element.find('input'))[0],
          updateModel = function() {
            scope.onChange({
              $event: {
                file: fileElement
              }
            });
          },
          $input = element.find('input');

        $input.bind('change', updateModel);

        element.bind('click', function(event) {
          $input[0].click();
        });
      }

      return {
        restrict: 'E',
        templateUrl: 'templates/fileInput.tpl.html',
        transclude: true,
        scope: {
          onChange: '&',
          accept: '<'
        },
        link: link
      };
    }
  ];
}

module.exports = register;
