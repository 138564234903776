'use strict';

var angular = require('angular');

var userAdminComponent = {
  bindings: {},
  controller: /*@ngInject*/ function(ADMIN_USER_LINKS) {
    var _self = this;
    _self.data = {
      userLinks: ADMIN_USER_LINKS,
      internalLabel: 'Select Role'
    };

    _self.selectInternalRole = function(option) {
      _self.data.selectedInternalLink = option.url;
    };
  },
  templateUrl: 'templates/partials/admin/userAdmin.tpl.html'
};

module.exports = userAdminComponent;
