'use strict';

var RouteConfig = /*@ngInject*/ function(
  $stateProvider,
  $urlRouterProvider,
  USER_ROLES,
  QUEUE_DATA
) {
  $urlRouterProvider.otherwise('search');

  $stateProvider
    .state('styleguide', {
      url: '/styleguide',
      pageTitle: 'Style Guide',
      templateUrl: 'templates/views/styleguide.view.html',
      controllerAs: 'styleguide',
      controller: 'StyleguideController'
    })
    .state('admin', {
      url: '/admin/:section',
      pageTitle: 'Admin',
      templateUrl: 'templates/views/admin.view.html',
      controllerAs: 'admin',
      controller: 'AdminController',
      data: {
        authorizedRoles: {
          [USER_ROLES.superUser]: {},
          [USER_ROLES.seniorImageEditor]: {}
        }
      }
    })
    .state('activity', {
      url: '/activity',
      pageTitle: 'Activity',
      templateUrl: 'templates/views/activity.view.html',
      controllerAs: 'activity',
      controller: 'ActivityController',
      data: {
        authorizedRoles: {
          [USER_ROLES.superUser]: {},
          [USER_ROLES.studioOps]: {}
        }
      }
    })
    .state('queue', {
      abstract: true,
      controllerAs: 'queue',
      controller: 'QueueController',
      templateUrl: 'templates/views/queue.view.html'
    })
    .state('queue.inbound', {
      url: '/queue/inbound',
      pageTitle: 'Inbound',
      data: {
        authorizedRoles: {
          [USER_ROLES.merchOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.studioOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },
        scan: true,
        display: {
          tray: QUEUE_DATA.jobPending,
          main: QUEUE_DATA.inOps
        }
      },
      anyTimeScan: true,
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'MetaQueueController',
          templateUrl: 'templates/partials/queue/inboundTray.tpl.html'
        },
        main: {
          controllerAs: 'main',
          controller: 'MerchQueueController',
          templateUrl: 'templates/partials/queue/inboundMain.tpl.html'
        }
      }
    })
    .state('queue.merch-prep', {
      url: '/queue/merch-prep',
      pageTitle: 'Merch Prep',
      data: {
        authorizedRoles: {
          [USER_ROLES.studioStylist]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },
        scan: true,
        display: {
          tray: QUEUE_DATA.readyForStyling,
          main: QUEUE_DATA.inStyling
        }
      },
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'MerchQueueController',
          templateUrl: 'templates/partials/queue/merchPrepTray.tpl.html'
        },
        main: {
          controllerAs: 'main',
          controller: 'MerchQueueController',
          templateUrl: 'templates/partials/queue/merchPrepMain.tpl.html'
        }
      }
    })
    .state('queue.merch-putaway', {
      url: '/queue/merch-putaway',
      pageTitle: 'Merch Putaway',
      data: {
        authorizedRoles: {
          [USER_ROLES.merchOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.studioOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.studioStylist]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },
        scan: true,
        display: {
          tray: QUEUE_DATA.prepForOutbound,
          main: QUEUE_DATA.completed
        },
        showViewsOnStatusChange: true
      },
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'MerchQueueController',
          templateUrl: 'templates/partials/queue/merchPutawayTray.tpl.html'
        },
        main: {
          controllerAs: 'main',
          controller: 'MerchQueueController',
          templateUrl: 'templates/partials/queue/merchPutawayMain.tpl.html'
        }
      }
    })
    .state('queue.post-prod', {
      url: '/queue/post-prod',
      pageTitle: 'Post Production',
      data: {
        authorizedRoles: {
          [USER_ROLES.digiTech]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.seniorImageEditor]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.imageEditor]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.externalImageEditor]: {
            sections: {
              main: {
                excludeColumns: ['Confidential'],
                hideOthersAssets: true
              }
            }
          },
          [USER_ROLES.photographer]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.studioOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },

        display: {
          tray: QUEUE_DATA.postProdUnclaimed,
          main: QUEUE_DATA.postProdClaimed
        },
        sortFields: ['priority', 'product.styleColor', 'rejectionStatus']
      },
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'AssetQueueController',
          templateUrl: 'templates/partials/queue/postProdTray.tpl.html'
        },
        main: {
          controllerAs: 'main',
          controller: 'AssetQueueController',
          templateUrl: 'templates/partials/queue/postProdMain.tpl.html'
        }
      }
    })
    .state('queue.problem-solve', {
      url: '/queue/problem-solve',
      pageTitle: 'Problem Solve',
      data: {
        authorizedRoles: {
          [USER_ROLES.studioOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },
        display: {
          tray: QUEUE_DATA.problemSolveUnclaimed,
          main: QUEUE_DATA.problemSolveClaimed
        }
      },
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'MetaQueueController',
          templateUrl: 'templates/partials/queue/problemSolveTray.tpl.html'
        },
        main: {
          controllerAs: 'main',
          controller: 'MetaQueueController',
          templateUrl: 'templates/partials/queue/problemSolveMain.tpl.html'
        }
      }
    })
    .state('outreachQueue', {
      abstract: true,
      controllerAs: 'queue',
      controller: 'OutreachController',
      templateUrl: 'templates/views/outreachQueue.view.html'
    })
    .state('outreachQueue.outreach', {
      url: '/outreachQueue',
      pageTitle: 'Product Team Outreach',
      data: {
        authorizedRoles: {
          [USER_ROLES.productReviewer]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.qaReviewer]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },
        display: {
          tray: QUEUE_DATA.outreach,
          main: QUEUE_DATA.problemSolveClaimed
        }
      },
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'outreachPLMController',
          templateUrl: 'templates/partials/queue/outreachTray.tpl.html'
        }
      }
    })
    .state('queue.qa', {
      url: '/queue/qa',
      pageTitle: 'Image QA',
      data: {
        authorizedRoles: {
          [USER_ROLES.qaReviewer]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.studioOps]: { sections: { tray: {}, main: {} } },
          [USER_ROLES.superUser]: { sections: { tray: {}, main: {} } }
        },
        display: {
          tray: QUEUE_DATA.qaUnclaimed,
          main: QUEUE_DATA.qaClaimed
        },
        sortFields: [
          'priority',
          'product.styleColor',
          'shootType.code',
          'rejectionStatus'
        ]
      },
      views: {
        tray: {
          controllerAs: 'tray',
          controller: 'MetaQueueController',
          templateUrl: 'templates/partials/queue/imageQATray.tpl.html'
        },
        main: {
          controllerAs: 'main',
          controller: 'MetaQueueController',
          templateUrl: 'templates/partials/queue/imageQAMain.tpl.html'
        }
      }
    })
    .state('request-code', {
      url: '/request-code',
      pageTitle: 'Request Code',
      templateUrl: 'templates/views/request.code.view.html',
      controllerAs: 'request',
      controller: 'RequestCodeController',
      data: {
        authorizedRoles: {
          [USER_ROLES.digiTech]: {},
          [USER_ROLES.seniorImageEditor]: {},
          [USER_ROLES.imageEditor]: {},
          [USER_ROLES.externalImageEditor]: {},
          [USER_ROLES.photographer]: {},
          [USER_ROLES.superUser]: {}
        }
      }
    })
    .state('detail', {
      url: '/detail/:productIdentifier/:jobId/:assetJobId/:tab',
      params: {
        jobId: null,
        assetJobId: null,
        tab: null,
        productIdentifierType: 'null'
      },
      pageTitle: 'Detail',
      templateUrl: 'templates/views/detail.view.html',
      controllerAs: '$ctrl',
      controller: 'DetailController',
      anyTimeScan: true,
      data: {
        authorizedRoles: {
          [USER_ROLES.digiTech]: {},
          [USER_ROLES.merchOps]: {},
          [USER_ROLES.photographer]: {},
          [USER_ROLES.seniorImageEditor]: {},
          [USER_ROLES.imageEditor]: {},
          [USER_ROLES.externalImageEditor]: {},
          [USER_ROLES.studioStylist]: {},
          [USER_ROLES.superUser]: {},
          [USER_ROLES.artBuyer]: {},
          [USER_ROLES.studioOps]: {},
          [USER_ROLES.qaReviewer]: {}
        },
        overlay: true
      }
    })
    .state('search', {
      url: '/search?q&f&c&t',
      pageTitle: 'Search',
      templateUrl: 'templates/views/search.view.html',
      controllerAs: 'search',
      controller: 'SearchController',
      anyTimeScan: true,
      data: {
        authorizedRoles: {
          [USER_ROLES.digiTech]: {},
          [USER_ROLES.merchOps]: {},
          [USER_ROLES.photographer]: {},
          [USER_ROLES.seniorImageEditor]: {},
          [USER_ROLES.imageEditor]: {},
          [USER_ROLES.studioStylist]: {},
          [USER_ROLES.superUser]: {},
          [USER_ROLES.artBuyer]: {},
          [USER_ROLES.studioOps]: {},
          [USER_ROLES.qaReviewer]: {},
          [USER_ROLES.productReviewer]: {}
        }
      }
    })
    .state('slates', {
      url: '/slates?ids',
      pageTitle: 'Slates',
      templateUrl: 'templates/views/printSlate.view.html',
      controllerAs: 'print',
      controller: 'PrintSlateController',
      data: {
        authorizedRoles: {
          [USER_ROLES.digiTech]: {},
          [USER_ROLES.merchOps]: {},
          [USER_ROLES.photographer]: {},
          [USER_ROLES.seniorImageEditor]: {},
          [USER_ROLES.imageEditor]: {},
          [USER_ROLES.studioStylist]: {},
          [USER_ROLES.superUser]: {},
          [USER_ROLES.artBuyer]: {},
          [USER_ROLES.studioOps]: {},
          [USER_ROLES.qaReviewer]: {}
        },
        overlay: true
      }
    })
    .state('shipping', {
      url: '/shipping-info?ids',
      pageTitle: 'Shipping',
      templateUrl: 'templates/views/shippingInfo.view.html',
      controllerAs: 'shippingInfo',
      controller: 'ShippingInfoController',
      data: {
        authorizedRoles: {
          [USER_ROLES.digiTech]: {},
          [USER_ROLES.merchOps]: {},
          [USER_ROLES.photographer]: {},
          [USER_ROLES.seniorImageEditor]: {},
          [USER_ROLES.imageEditor]: {},
          [USER_ROLES.studioStylist]: {},
          [USER_ROLES.superUser]: {},
          [USER_ROLES.artBuyer]: {},
          [USER_ROLES.studioOps]: {},
          [USER_ROLES.qaReviewer]: {}
        },
        overlay: true
      }
    })
    .state('qa', {
      url: '/qa/:id/:assetJobId',
      pageTitle: 'QA Details',
      templateUrl: 'templates/views/qa.view.html',
      controllerAs: '$ctrl',
      controller: 'QaDetailController',
      data: {
        authorizedRoles: {
          [USER_ROLES.qaReviewer]: {},
          [USER_ROLES.studioOps]: {},
          [USER_ROLES.superUser]: {}
        },
        overlay: true
      }
    })
    .state('outreach', {
      url: '/outreach/:id/',
      pageTitle: 'Product Team Outreach',
      templateUrl: 'templates/views/outreach.view.html',
      controllerAs: '$ctrl',
      controller: 'OutreachDetailController',
      data: {
        authorizedRoles: {
          [USER_ROLES.qaReviewer]: {},
          [USER_ROLES.productReviewer]: {},
          [USER_ROLES.superUser]: {}
        }
      }
    });
};

module.exports = RouteConfig;
