'use strict';

/*jshint unused: false*/
var tagDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    bindToController: {
      label: '@',
      parent: '@',
      onClick: '&'
    },
    controllerAs: 'tag',
    controller: /*@ngInject*/ function() {
      var _self = this;

      _self.click = function(event) {
        _self.onClick({ event: event });
      };
    },
    templateUrl: 'templates/partials/ui/tag.tpl.html',
    link: function(scope, elem, attrs, ctrl) {}
  };
};

module.exports = tagDirective;
