'use strict';

var angular = require('angular');

var OutreachService = /*@ngInject*/ function(
  $q,
  $http,
  REST_URL,
  APP_DATA,
  $state,
  Session
) {
  var _self = this;

  _self.queuedJobs = [];
  _self.currentJob = undefined;

  _self.getState = function() {
    return Session.getSessionValue('pageState', 'outreach');
  };

  _self.setState = function(state) {
    Session.setSessionValue('pageState', state, 'outreach');
  };

  _self.setQueue = function(jobs) {
    //Set the array and then send them to the modal
    _self.queuedJobs = jobs;
    _self.currentJob = 0;
    $state.go('outreach', {
      id: _self.queuedJobs[_self.currentJob].id
    });
  };

  //Progressing through the jobs for Reviewers to comment on
  _self.nextJob = function() {
    _self.currentJob++;
    // There are more jobs to work through.
    if (_self.queuedJobs.length > _self.currentJob) {
      $state.go('outreach', {
        id: _self.queuedJobs[_self.currentJob].id
      });
    } else {
      // There are no more jobs to go through, redirect to the queue.
      $state.go('outreachQueue.outreach', {});
    }
  };

  _self.init = function() {};
};

module.exports = OutreachService;
