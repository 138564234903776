'use strict';

var angular = require('angular');

var AdminController = /*@ngInject*/ function(
  $http,
  $state,
  $stateParams,
  $scope,
  REST_URL,
  AuthService,
  USER_ROLES,
  RepublishService
) {
  var _self = this;

  _self.setSubsection = function(id) {
    $state.go(
      'admin',
      {
        section: id
      },
      {
        location: 'replace',
        notify: false
      }
    );
  };

  _self.handlePaneSelection = function($event) {
    var section = _self.data.sections.filter(function(section) {
      return $event.pane.tabTitle === section.title;
    });

    _self.setSubsection(section[0].id);
  };

  _self.$onInit = function() {
    _self.data = {
      styleColorAndJob: '',
      studios: {},
      sections: [
        {
          id: 'bulk-job-update',
          title: 'Bulk Job Update',
          selected: false,
          authorized: AuthService.isAuthorized([USER_ROLES.superUser])
        },
        {
          id: 'user-admin',
          title: 'User Admin',
          selected: false,
          authorized: AuthService.isAuthorized([
            USER_ROLES.superUser,
            USER_ROLES.seniorImageEditor
          ])
        },
        {
          id: 'manage-studios',
          title: 'Manage Studios',
          selected: false,
          authorized: AuthService.isAuthorized([USER_ROLES.superUser])
        },
        {
          id: 'delivery-admin',
          title: 'Delivery Admin',
          selected: false,
          authorized: AuthService.isAuthorized([USER_ROLES.superUser])
        },
        {
          id: 'asset-requests',
          title: 'Asset Requests',
          selected: false,
          authorized: AuthService.isAuthorized([USER_ROLES.superUser])
        },
        {
          id: 'product-sync',
          title: 'Product Sync',
          selected: false,
          authorized: AuthService.isAuthorized([USER_ROLES.superUser])
        },
        {
          id: 'record-links',
          title: 'Record Links',
          selected: false,
          authorized: AuthService.isAuthorized([USER_ROLES.superUser])
        }
      ],
      version: {}
    };

    $scope.$on('adminResponse', function(event, args) {
      _self.data.response = args;
    });

    _self.setSelectedSection = function(section) {
      if ($stateParams.section === section.id && section.authorized) {
        section.selected = $stateParams.section === section.id;
        _self.setSubsection(section.id);
        return true;
      }
    };

    if (!!$stateParams.section) {
      _self.data.sections.some(_self.setSelectedSection);
    }

    _self.getVersionResponse = function(response) {
      _self.data.version.api = response.data.version;
    };

    $http
      .get([REST_URL.base, 'version'].join('/'))
      .then(_self.getVersionResponse);
  };
};

module.exports = AdminController;
