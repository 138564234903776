'use strict';

//http://stackoverflow.com/questions/12046928/how-to-use-regex-with-ng-repeat-in-angularjs
var regexFilter = function() {
  return function(input, regex, flags) {
    var patt = new RegExp(regex, flags);
    var out = [];

    for (var i = 0; i < input.length; i++) {
      if (patt.test(input[i])) {
        out.push(input[i]);
      }
    }
    return out;
  };
};

module.exports = regexFilter;
