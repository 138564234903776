'use strict';

var angular = require('angular');

var versionsComponent = {
  bindings: {},
  controllerAs: '$ctrl',
  controller: /*@ngInject*/ function(
    $http,
    REST_URL
  ) {
    var _self = this;
    const versionNames = ['versionNumber', 'version', 'git.build.version'];

    _self.getVersion = function(parentObject) {
      if (parentObject) {
        for (const versionName of versionNames) {
          if(parentObject.hasOwnProperty(versionName)) {
            return parentObject[versionName];
          }
        }
      }
      return '';
    };

    _self.convertName = function(name) {
      const lowercaseName = !!name ? name.toLowerCase() : '';
      switch (lowercaseName) {
        case 'api': return 'API';
        case 'alfresco': return 'Alfresco';
        case 'cps': return 'CPS';
        case 'filesapi': return 'Files API';
        case 'ehs': return 'EHS';
        default: return lowercaseName;
      }
    }

    _self.getAppData = function(name, data) {
      let appData = {
        name: _self.convertName(name),
        props: [],
        children: [],
        expanded: false
      };
      for (let prop in data) {
        if (typeof data[prop] === 'object') {
          appData.children.push(_self.getAppData(prop, data[prop]));
        } else if (versionNames.includes(prop)) {
          appData.version = data[prop];
        } else {
          appData.props.push({
            name: prop,
            value: data[prop],
          });
        }
      }
      return appData;
    }

    _self.$onInit = function() {

      _self.getVersionsResponse = function(response) {
        _self.versions = [];
        for (let appName in response.data) {
          _self.versions.push(_self.getAppData(appName, response.data[appName]))
        }
      };

      $http
        .get(`${REST_URL.base}/versions`)
        .then(_self.getVersionsResponse);
    };
  },
  templateUrl: 'templates/partials/admin/versions.tpl.html',
  link: function(scope, elem, attrs, ctrl) {}
};

module.exports = versionsComponent;
