'use strict';

var angular = require('angular');
var appendTransform = require('../vendor/appendTransform');

var StatusService = /*@ngInject*/ function(
  $http,
  $q,
  REST_URL,
  JobService,
  AuthService,
  Session,
  NotificationService,
  TransferService,
  USER_ROLES,
  BatchService,
  UsersService
) {
  var _self = this;

  _self.data = {
    params: {}
  };

  var unclaimMetaTasks = function(jobs) {
    var unclaimTaskCommands = jobs.map(function(job) {
      return {
        jobId: job.id,
        taskId: job.tasks[0].id
      };
    });

    return BatchService.batchPOST(
      [REST_URL.jobs, 'unclaim'].join('/'),
      unclaimTaskCommands,
      10,
      false,
      JobService.transformJobsConfig
    );
  };

  var claimMetaTasks = function(jobs) {
    var unclaimPromise = $q.when(true); // empty promise unless some jobs are already claimed

    var claimedJobs = jobs.filter(function(job) {
      return job.tasks[0].assignee;
    });

    var claimTaskCommands = jobs.map(function(job) {
      return {
        jobId: job.id,
        taskId: job.tasks[0].id,
        username: _self.data.params.assignee.userName
      };
    });

    if (claimedJobs.length) {
      unclaimPromise = unclaimMetaTasks(claimedJobs);
    }

    return unclaimPromise.then(function() {
      return BatchService.batchPOST(
        [REST_URL.jobs, 'claim'].join('/'),
        claimTaskCommands,
        10,
        false,
        JobService.transformJobsConfig
      );
    });
  };

  var completeMetaOrMechTasks = function(jobs) {
    var temp = angular.copy(jobs);
    var deferred = $q.defer();
    var promise = deferred.promise;
    var jobArrays = [];
    var promises = [];

    while (temp.length) {
      jobArrays.push(temp.splice(0, 10));
      promises.push($q.defer());
    }

    var assignJobs = function(i) {
      var current = jobArrays[i];
      var currentPromise = promises[i];

      var tasksToComplete = current.map(function(job) {
        var jobId =
          _self.data.params.type === 'tasks'
            ? job.id
            : job[_self.data.params.type].id;
        var taskId =
          _self.data.params.type === 'tasks'
            ? job[_self.data.params.type][0].id
            : job[_self.data.params.type].tasks[0].id;

        return {
          jobId: jobId,
          taskId: taskId,
          properties: _self.data.params.properties
        };
      });

      currentPromise.resolve(
        $http.post(
          [REST_URL.jobs, 'complete'].join('/'),
          { tasksToComplete: tasksToComplete },
          JobService.transformJobsConfig
        )
      );
    };

    for (var i = 0; i < jobArrays.length; i++) {
      assignJobs(i);
    }

    $q
      .all(
        promises.map(function(a) {
          return a.promise;
        })
      )
      .then(function(res) {
        res = [].concat.apply(
          [],
          res.map(function(a) {
            if (a.status >= 200 && a.status < 400) {
              return a;
            }
          })
        );
        deferred.resolve(res);
      });

    return promise;
  };

  _self.getSelectedAssets = function(jobs) {
    var selectedAssets = [];

    jobs.forEach(function(job) {
      job.assetJobs.forEach(function(assetJob) {
        selectedAssets.push(assetJob);
      });
    });

    return selectedAssets;
  };

  var filterAssetJob = function(selectedAssets) {
    var filteredAssets = [];

    // filter out inactive asset jobs
    filteredAssets = selectedAssets.filter(function(assetJob) {
      return (
        assetJob.tasks &&
        assetJob.tasks.length &&
        assetJob.status !== 'Ready to Publish'
      );
    });

    return filteredAssets;
  };

  var unclaimAssetTasks = function(jobs) {
    var claimTaskCommands = [],
      // filter out unselected & inactive asset jobs
      selectedAssets = filterAssetJob(_self.getSelectedAssets(jobs));

    // filter out unclaimed jobs
    var claimedAssets = selectedAssets.filter(function(assetJob) {
      return assetJob.tasks[0].assignee;
    });

    // get commands for each claimed job
    claimedAssets.forEach(function(assetJob) {
      claimTaskCommands.push({
        jobId: assetJob.id,
        taskId: assetJob.tasks[0].id
      });
    });

    return BatchService.batchPOST(
      [REST_URL.jobs, 'unclaim'].join('/'),
      claimTaskCommands,
      10,
      false,
      JobService.transformJobsConfig
    );
  };

  var claimAssetTasks = function(jobs) {
    var claimTaskCommands = [],
      // filter out unselected & inactive asset jobs
      selectedAssets = filterAssetJob(_self.getSelectedAssets(jobs)),
      unclaimPromise = $q.when(true), // empty promise unless some jobs are already claimed
      claimedAssets = [];

    // get commands for each unclaimed job
    selectedAssets.forEach(function(assetJob) {
      claimTaskCommands.push({
        jobId: assetJob.id,
        taskId: assetJob.tasks[0].id,
        username: _self.data.params.assignee.userName
      });
    });

    // check if there are assigned assets...
    claimedAssets = selectedAssets.filter(function(assetJob) {
      return assetJob.tasks[0].assignee;
    });

    //.. if so, unassign them before reassigning them
    if (claimedAssets.length) {
      unclaimPromise = unclaimAssetTasks(jobs);
    }

    return unclaimPromise.then(function() {
      return BatchService.batchPOST(
        [REST_URL.jobs, 'claim'].join('/'),
        claimTaskCommands,
        10,
        false,
        JobService.transformJobsConfig
      );
    });
  };

  _self.completeFilterIds = function(selectedAssets) {
    return selectedAssets
      .filter(function(assetJob) {
        if (assetJob.id && assetJob.status === 'In Post Production') {
          return true;
        }
      })
      .map(function(assetJob) {
        return assetJob.id;
      });
  };

  _self.completeAssetTasks = function(jobs) {
    var selectedAssets = _self.getSelectedAssets(jobs);

    return $http.post(
      [REST_URL.jobs, 'delete'].join('/'),
      {
        assetJobIds: _self.completeFilterIds(selectedAssets)
      },
      JobService.transformJobsConfig
    );
  };

  _self.createShipStoreJobData = function(jobs) {
    var jobData = {
        tasksToComplete: []
      },
      sampleData = {};

    jobs.forEach(function(job) {
      jobData.tasksToComplete.push({
        jobId: job.merchJob.id,
        taskId: job.merchJob.tasks[0].id,
        properties: {}
      });
    });

    for (var i in _self.data.params.formData) {
      sampleData[_self.data.params.formData[i].apiVar] =
        _self.data.params.formData[i].value;
    }

    if (_self.data.params.properties.storeSample) {
      jobData.storeSample = true;
      jobData.sample = { storage: sampleData };
    } else {
      jobData.ship = true;
      jobData.sample = { shipment: sampleData };
    }

    return jobData;
  };

  var getIndexOfSelf = function(userList) {
    var index = -1,
      currentUser = Session.get();

    userList.some(function(user, i) {
      if (user.userName === currentUser.userName) {
        index = i;
        return true;
      }
    });

    return index;
  };

  // If a status change needs an assignee, set values based on user role
  var setAssignmentValues = function(previewData) {
    var deferred = $q.defer();

    // Setting assignment values is only relevant if you're claiming tasks
    if (previewData.type !== 'claimAsset' && previewData.type !== 'claimMeta') {
      deferred.resolve(previewData);
      return deferred.promise;
    }

    previewData.status.label = 'Assign To';

    // If an assignment role is not set, or the user is not an admin/Sr. Image Editor,
    // set the assignee to the logged in user
    // This should only apply for the Post Prod task
    if (
      !previewData.assignmentRole ||
      (!AuthService.isAdmin() &&
        !AuthService.isAuthorized([USER_ROLES.imageEditor, USER_ROLES.externalImageEditor, USER_ROLES.seniorImageEditor]))
    ) {
      // if (!previewData.assignmentRole || !AuthService.isAdmin()) {
      previewData.assignees = [Session.get()];
      // set self as default assignee
      deferred.resolve(previewData);
      return deferred.promise;
    }

    previewData.showAssigneeDropdown = true;

    UsersService.getUsersByRoles(previewData.assignmentRole).then(function(
      response
    ) {
      previewData.assignees = _self.createAssignList(
        previewData.selfAssignmentRoles,
        response.data,
        previewData.reassign
      );
      deferred.resolve(previewData);
    });

    return deferred.promise;
  };

  _self.createAssignList = function(
    assignmentRoles,
    assigneesList,
    reassignFlag
  ) {
    // add self to list if not present and can self-assign
    if (
      getIndexOfSelf(assigneesList) === -1 &&
      AuthService.isAuthorized(assignmentRoles)
    ) {
      assigneesList.unshift(Session.get());
    }

    if (reassignFlag) {
      assigneesList.unshift({ name: 'Unassign', lastName: 'aaa' });
    }

    return assigneesList;
  };

  _self.setAssignee = function(user) {
    var optionKey = !user
      ? 'other'
      : Session.compareUser(user.userName) ? 'self' : 'other';

    if (_self.data.params.copyOptions) {
      _self.data.params.heading.copy = _self.data.params.copyOptions[optionKey];
    }

    if (_self.data.params.primaryCtaOptions) {
      _self.data.params.cta.primary =
        _self.data.params.primaryCtaOptions[optionKey];
    }

    if (user) {
      _self.data.params.status.value = user.name;
      _self.data.params.assignee = user;
    }
  };

  _self.downloadAssets = function(jobs) {
    var selectedJobs = angular.isArray(jobs) ? jobs : [jobs],
      jobList = [],
      filenames = selectedJobs.map(function(job) {
        jobList.push(job.product.styleColor);
        return job.assetJobs.map(function(assetJob) {
          return JobService.getDownloadAsset(assetJob).fileName;
        });
      });

    filenames = [].concat.apply([], filenames);

    TransferService.downloadAssets(filenames, jobList);
  };

  _self.setPreviewData = function(params) {
    var _defaults = {
        jobs: [],
        assignees: [],
        type: 'merchJob',
        preview: 'job',
        properties: {},
        status: {
          label: 'Status',
          value: ''
        },
        heading: {
          title: 'Update Status',
          copy: ''
        },
        cta: {
          primary: 'Submit',
          secondary: 'Cancel'
        }
      },
      previewData;

    previewData = angular.merge({}, _defaults, params);

    _self.data.working = true;

    return setAssignmentValues(previewData)
      .then(function() {
        _self.data.params = previewData;

        // set self as default assignee if in list
        if (!_self.data.params.reassign) {
          _self.setAssignee(
            _self.data.params.assignees[getIndexOfSelf(previewData.assignees)]
          );
        }
      })
      .finally(function() {
        _self.data.working = false;
      });
  };

  _self.setUpdateParams = function(updateParams) {
    var _defaults = {
      type: 'merchJob',
      properties: {}
    };

    _self.data.params = angular.merge({}, _defaults, updateParams);
  };

  _self.updateJobs = function(jobs, updateParams) {
    var deferred = $q.defer();
    var f;

    if (updateParams) {
      _self.setUpdateParams(updateParams);
    }

    switch (_self.data.params.type) {
      case 'merchJob':
      case 'tasks':
        f = completeMetaOrMechTasks;
        break;
      case 'claimAsset':
        f = claimAssetTasks;
        break;
      case 'unclaimAsset':
        f = unclaimAssetTasks;
        break;
      case 'completeAsset':
        f = _self.completeAssetTasks;
        break;
      case 'claimMeta':
        f = claimMetaTasks;
        break;
      case 'unclaimMeta':
        f = unclaimMetaTasks;
        break;
    }

    f(jobs)
      .then(function(response) {
        // shipstore response is wrapped in an array

        // was a batch call
        if (angular.isArray(response)) {
          var responseJobs = [];
          response.forEach(function(r) {
            responseJobs = [].concat.apply(responseJobs, r.data);
          });
          jobs = responseJobs;
        } else {
          jobs = angular.isArray(response.data) ? response.data : jobs;
        }
        // Display notification if one was set
        if (_self.data.params.notification) {
          var message =
              jobs.length === 1
                ? _self.data.params.notification.single
                : _self.data.params.notification.plural,
            context = _self.data.params.notification.context || {
              jobs: jobs,
              ids: jobs.map(function(job) {
                return job.id;
              }),
              user: 'you'
            };

          //for 'completeAsset' status type
          if (
            _self.data.params.notification.context &&
            _self.data.params.notification.context.closedAssets
          ) {
            message =
              _self.data.params.notification.context.closedAssets.length === 1
                ? _self.data.params.notification.single
                : _self.data.params.notification.plural;
          }

          if (_self.data.params.assignee) {
            context.user = Session.compareUser(
              _self.data.params.assignee.userName
            )
              ? 'you'
              : _self.data.params.assignee.name;

            // Download assets if necessary
            if (
              _self.data.params.download &&
              Session.compareUser(_self.data.params.assignee.userName)
            ) {
              _self.downloadAssets(jobs);
            }
          }

          NotificationService.push(message, context);
        }

        deferred.resolve(jobs);
      })
      .catch(deferred.reject);

    return deferred.promise;
  };

  return _self;
};

module.exports = StatusService;
