'use strict';

var angular = require('angular');

/*jshint unused: false*/
var downloadStatus = {
  bindings: {
    model: '<',
    onDownload: '&'
  },
  controller: /*@ngInject*/ function($element, TransferService, JobService) {
    var _self = this;

    _self.$postLink = function() {
      $element.on('click', function(e) {
        var filenames = _self.model.assetJobs.map(function(assetJob) {
          return JobService.getDownloadAsset(assetJob);
        });
        TransferService.downloadAssets(
          filenames,
          _self.model.product.styleColor
        ).then(function(response) {
          _self.onDownload({ $event: { response: response } });
        });
      });
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);
      }
    };
  },
  templateUrl: 'templates/partials/ui/downloadStatus.tpl.html'
};

module.exports = downloadStatus;
