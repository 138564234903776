'use strict';

var QueueController = /*@ngInject*/ function(
  $rootScope,
  $scope,
  $element,
  $timeout,
  $state,
  QueueService,
  KeymageService,
  JobService,
  META_STATUS,
  ASSET_STATUS,
  APP_DATA,
  StatusService,
  Session,
  AuthService,
  ScanTypeService,
  EventLoggerService
) {
  var _self = this;
  _self.showViews = false;
  _self.lastMerchIdScanned = null;

  // Allow view to access service methods
  _self.service = QueueService;

  var getFocusView = function() {
    return $element[0].querySelector('ui-view[name="' + _self.focus + '"]');
  };

  var focusResult = function(e) {
    var mod = e && e.keyCode === 74 ? 1 : -1,
      labels = Array.prototype.slice.call(
        getFocusView().querySelectorAll('.js-job-list .gbip-checkbox')
      ),
      index = labels.indexOf(document.activeElement),
      next = (index + 1 * mod) % labels.length;

    if (labels.length) {
      if (next < 0) {
        labels[0].focus();
      } else {
        labels[next].focus();
      }
    } else {
      // for outbound form
      var inputs = Array.prototype.slice.call(
        getFocusView().querySelectorAll(
          '.js-outbound-form input, .js-outbound-form .dropdown-toggle'
        )
      );
      if (inputs.length) {
        inputs[0].focus();
      }
    }
  };

  var setKeyBindings = function() {
    var bindings = [
      {
        shortcut: 'left',
        fn: function() {
          _self.focus = 'tray';
          focusResult();
        }
      },
      {
        shortcut: 'right',
        fn: function() {
          _self.focus = 'main';
          focusResult();
        }
      },
      {
        shortcut: '/',
        fn: function() {
          var input = getFocusView().querySelector('.js-search input');
          if (input) {
            input.focus();
          }
        },
        options: { preventDefault: true }
      },
      {
        shortcut: 'o',
        fn: function() {
          var sortElem = getFocusView().querySelector('[dropdown-toggle]');
          if (sortElem) {
            sortElem.focus();
          }
        }
      },
      {
        shortcut: 'u',
        fn: function() {
          $timeout(function() {
            _self.service.scopes[_self.focus].confirmUpdate();
          });
        }
      },
      {
        shortcut: 'j',
        fn: focusResult
      },
      {
        shortcut: 'k',
        fn: focusResult
      }
    ];

    KeymageService.set($scope, bindings);
  };

  var init = function() {
    _self.focus = 'tray';
    _self.state = $state.current.name;
    _self.displayData = $state.current.data.display;
    if ($state.current.data.showViewsOnStatusChange) {
      _self.showViews = $state.current.data.showViewsOnStatusChange;
    } else {
      _self.showViews = false;
    }
    _self.scan = $state.current.data.scan;
    _self.showDownload =
      _self.displayData.main.status === ASSET_STATUS.postProd;

    _self.data = {};

    _self.data.sort = {
      from: {},
      to: {}
    };

    _self.error = {
      active: false
    };

    _self.showTray = Session.getSessionValue('showTray', _self.state);
    _self.authorizedForTray = AuthService.isAuthorizedForSection(
      Session.get('roles'),
      $state.current.data.authorizedRoles,
      'tray'
    );

    if (_self.showTray === null || _self.showTray === true) {
      _self.showTray = _self.authorizedForTray;
    }

    _self.showMain = Session.getSessionValue('showMain', _self.state);
    _self.authorizedForMain = AuthService.isAuthorizedForSection(
      Session.get('roles'),
      $state.current.data.authorizedRoles,
      'main'
    );

    if (_self.showMain === null || _self.showMain === true) {
      _self.showMain = _self.authorizedForMain;
    }

    if (_self.showTray && !_self.showMain) {
      _self.trayHeaderType = 'full';
      _self.mainHeaderType = 'compact';
    } else if (_self.showMain && !_self.showTray) {
      _self.trayHeaderType = 'compact';
      _self.mainHeaderType = 'full';
    } else {
      _self.trayHeaderType = 'limited';
      _self.mainHeaderType = 'limited';
    }

    _self.showStatusModal = false;
    _self.newMerchJobs = [];
    _self.printJobs = [];
    _self.printFormData = {};
    _self.showSlateModal = false;
    _self.showPrintModal = false;

    _self.service.setParentScope(_self);

    _self.onSlateChange = function($event) {
      _self.newMerchJobs = $event.job;
    };

    _self.sortFields = [
      'priority',
      'product.styleColor',
      'product.currentSeason'
    ];
    _self.sortFields = $state.current.data.sortFields || _self.sortFields;
    _self.service.reset();
    _self.service.setSorting('tray', {
      predicate: _self.sortFields[0],
      reverse: true
    });
    _self.service.setSorting('main', {
      predicate: _self.sortFields[0],
      reverse: true
    });
  };

  _self.downloadAssets = function(jobs) {
    StatusService.downloadAssets(jobs);
  };

  _self.setErrorModal = function(job) {
    var data, onSubmit;

    _self.error.active = true;
    _self.error.job = job;

    if (job.status === META_STATUS.problemSolve) {
      data = {
        heading: {
          title: 'Product in Problem Solve',
          copy:
            'A problem has been reported with this Job; hand to Studio Ops or place in the Problem Solve area for follow up.'
        },
        cta: {
          primary: 'View Details',
          secondary: 'Cancel'
        }
      };
      onSubmit = function() {
        _self.error.active = false;
        $state.go('detail', {
          productIdentifierType: 'by-product-id',
          productIdentifier: _self.error.job.product.productId,
          jobId: _self.error.job.id
        });
      };
    } else {
      data = {
        heading: {
          title: 'The scanned product is not in this queue',
          copy: job.merchJob
            ? 'The merchandise is <b class="h-near-black">' +
              job.merchJob.status +
              '</b>.'
            : 'The job is <b class="h-near-black">' + job.status + '</b>.'
        },
        cta: {
          primary: 'View Details',
          secondary: 'Cancel'
        }
      };
      onSubmit = function() {
        _self.error.active = false;
        $state.go('detail', {
          productIdentifierType: 'by-product-id',
          productIdentifier: _self.error.job.product.productId,
          jobId: _self.error.job.id
        });
      };
    }

    _self.error.data = data;
    _self.error.onSubmit = onSubmit;
  };

  _self.getMainJobType = function() {
    return _self.showTray ? 'limited' : 'full';
  };
  _self.getTrayJobType = function() {
    return _self.showMain ? 'limited' : 'full';
  };

  _self.toggleTray = function(value) {
    var show = value || !_self.showTray;
    _self.showTray = show;
    _self.mainHeaderType = _self.showTray ? 'limited' : 'full';
  };

  _self.toggleLeft = function() {
    if (_self.showTray && _self.showMain) {
      _self.showTray = false;
    } else if (!_self.showMain) {
      _self.showMain = true;
    }
    _self.mainHeaderType = _self.showTray ? 'limited' : 'full';
    _self.trayHeaderType = _self.showMain ? 'limited' : 'full';

    //Record state change in session storage
    Session.setSessionValue('showTray', _self.showTray, _self.state);
    Session.setSessionValue('showMain', _self.showMain, _self.state);
  };

  _self.toggleRight = function(value) {
    if (_self.showMain && _self.showTray) {
      _self.showMain = false;
    } else if (!_self.showTray) {
      _self.showTray = true;
    }
    _self.mainHeaderType = _self.showTray ? 'limited' : 'full';
    _self.trayHeaderType = _self.showMain ? 'limited' : 'full';

    //Record state change in session storage
    Session.setSessionValue('showTray', _self.showTray, _self.state);
    Session.setSessionValue('showMain', _self.showMain, _self.state);
  };

  _self.confirmStatusChange = function(jobs, params, location) {
    StatusService.setPreviewData(params);
    _self.jobsToUpdate = jobs;
    _self.updateLocation = location;
    _self.showStatusModal = true;
  };

  _self.initiateJob = function(job) {
    if (!!_self.lastMerchIdScanned) {
      job.primaryMerchId = _self.lastMerchIdScanned;
    }
    _self.lastMerchIdScanned = null;

    _self.showInitiateJobModal = true;
    _self.jobToInitiate = job;
  };

  _self.onJobInitiation = function($event) {
    _self.showInitiateJobModal = $event.active;

    if (!$event.job) {
      return;
    }

    _self.onJobsUpdate({ jobs: [$event.job], new: true });
  };

  _self.onJobsUpdate = function($event) {
    _self.newMerchJobs = [];
    _self.printJobs = [];
    _self.printFormData = {};

    $event.params = $event.params || {};

    if (!$event.jobs) {
      return;
    }

    if ($event.params.type === 'outboundJob') {
      _self.printFormData.formData = $event.params.formData;
      _self.printFormData.properties = $event.params.properties;
      _self.printJobs = $event.jobs;
      _self.showPrintModal = true;

      $rootScope.$broadcast('queue.update.tray', {
        type: $event.params.status.value,
        jobs: $event.jobs
      });
    } else if ($event.new === true) {
      if (JobService.canUpdate($event.jobs[0].status)) {
        $rootScope.$broadcast('queue.update.tray', {
          type: 'push',
          jobs: $event.jobs
        });
      } else {
        if (
          $event.jobs[0].merchJob &&
          $event.jobs[0].merchJob.status === APP_DATA.MerchJobStatus[0]
        ) {
          $rootScope.$broadcast('queue.update.main', {
            type: 'push',
            jobs: $event.jobs
          });

          _self.newMerchJobs = $event.jobs;
        }

        $rootScope.$broadcast('queue.update.tray', {
          type: 'filter',
          jobs: $event.jobs
        });
      }
    } else {
      if (
        $event.params.type === 'unclaimAsset' ||
        $event.params.type === 'unclaimMeta'
      ) {
        $rootScope.$broadcast('queue.update.tray', {
          type: 'push',
          jobs: $event.jobs
        });
        $rootScope.$broadcast('queue.update.main', {
          type: 'filter',
          jobs: $event.jobs
        });
      } else if (_self.updateLocation === 'tray') {
        $rootScope.$broadcast('queue.update.tray', {
          type: 'filter',
          jobs: $event.jobs
        });
        if ($event.params.status.value === 'Completed') {
          $rootScope.$broadcast('queue.update.main', {
            type: 'complete',
            jobs: $event.jobs
          });
        } else {
          $rootScope.$broadcast('queue.update.main', {
            type: 'push',
            jobs: $event.jobs
          });
        }
      } else {
        $rootScope.$broadcast('queue.update.main', {
          type: 'filter',
          jobs: $event.jobs
        });
      }
    }

    _self.showStatusModal = false;
    delete _self.jobsToUpdate;
    delete _self.jobToInitiate;
    delete _self.updateLocation;
  };

  _self.setProblemJob = function(problemJob) {
    _self.problemJob = problemJob;
  };

  _self.openResolveProblemModal = function() {
    _self.showResolveProblemModal = true;
  };

  _self.handleResolve = function($event) {
    _self.problemJob = undefined;
    _self.showResolveProblemModal = false;
    //Adding in a delay because an immediate refetch of the jobs doesn't reflect the status change
    setTimeout(function() {
      $rootScope.$broadcast('queue.update.main', { type: 'default' });
    }, 3000);
  };

  // abstract states don't automatically refresh on state change
  // check if we came from a queue state, otherwise init gets called twice
  $scope.$on('$stateChangeSuccess', function(
    event,
    toState,
    toParams,
    fromState
  ) {
    if (fromState.name.indexOf('queue') >= 0) {
      init();
    }
  });

  $scope.$on('scan.update', function(e, info) {
    // Exit if the queue doesn't support scanning
    if (!_self.scan) {
      return;
    }

    if (_self.showInitiateJobModal) {
      return;
    }

    //If the scanned value comprises only 12 digits, assume it's a gtin
    if (ScanTypeService.isGtin(info)) {
      JobService.getGtinJobDetail(info)
        .then(function(response) {
          if (response.data.products.length == 1) {
            var styleColor = response.data.products[0].styleColor;
            //There is a listener on the child scopes that will trigger the default search
            $scope.$broadcast('scan.gtinSearch', { term: styleColor });
          } else if (response.data.products.length > 1) {
            //search for multiple stylecolors
            var styleColor = '';
            for (var i = 0; i < response.data.products.length; i++) {
              styleColor += response.data.products[i].styleColor + ' ';
            }
            $scope.$broadcast('scan.gtinSearch', { term: styleColor });
          } else {
            // there were no products returned
            $scope.$emit('error', {
              title: 'No Match Found',
              copy:
                '<strong>Product not found for given barcode.</strong><br />Search manually by Style-Color.'
            });
          }
        })
        .catch(function(response) {
          switch (response.status) {
            case 404:
              $scope.$emit('error', {
                title: 'No Match Found',
                copy:
                  '<strong>Product not found for given barcode.</strong><br />Search manually by Style-Color.'
              });
              break;
            default:
              $scope.$emit('error', {
                title: 'Error',
                copy:
                  '<strong>Error communicating with Catalog V2 service</strong><br />' +
                  response.error
              });
          }
        });
    } else if (ScanTypeService.isMerchId(info)) {
      $scope.$broadcast('scan.merchId', { term: info });
      _self.lastMerchIdScanned = info;
      EventLoggerService.track(
        'PREPRO_MERCH_SCAN',
        {
          page: $state.current.name,
          location: _self.stateData ? ' ' + _self.stateData.location : ''
        },
        true
      );
    } else {
      // backwards compatability with old slate models
      var jobId, scannedCode;
      if (ScanTypeService.isJobSlate(info)) {
        scannedCode = info.split('_');
        jobId = scannedCode[1];
      }
      if (jobId) {
        JobService.getJobDetail(jobId)
          .then(function(response) {
            var job = response.data,
              // Return which queue (if any) the scanned job is in
              queue = _self.service.isValidScan(_self.displayData, job);

            // Show the update modal if job is in a queue and not in Problem Solve
            if (queue && job.status !== META_STATUS.problemSolve) {
              _self.service.confirmUpdate(
                [job],
                _self.displayData[queue].updateParams,
                queue
              );
            } else {
              _self.setErrorModal(job);
            }
          })
          .catch(function() {
            $scope.$emit('scan.error');
          });
      }
    }
  });

  // Rock n Roll
  init();
  setKeyBindings();
};

module.exports = QueueController;
