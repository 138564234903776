'use strict';

var angular = require('angular');

var activityModalDirective = /*@ngInject*/ function(
  $q,
  $timeout,
  ReportsService,
  JobService,
  KeymageService,
  AuthService
) {
  return {
    restrict: 'E',
    scope: {},
    bindToController: {
      active: '=',
      handleActionSuccess: '&'
    },
    controllerAs: 'activityModal',
    controller: /*@ngInject*/ function($scope) {
      var _self = this;

      var doSubmitOverwriteUpdate = function() {
        var deferred = $q.defer();

        JobService.activity.duplicates
          .submitOverwriteUpdate(JobService.activity.updateData.post)
          .then(
            function(response) {
              if (response.status > 400) {
                deferred.reject();
              }
              _self.handleActionSuccess({
                type: 'duplicate',
                idOfJobToHide: JobService.activity.updateData.job.id
              });
              deferred.resolve();
            },
            function() {
              deferred.reject();
            }
          );

        return deferred.promise;
      };

      var doReactivationStartFromBeginning = function() {
        var deferred = $q.defer();

        JobService.activity.reactivations
          .submit(JobService.activity.updateData.post, false)
          .then(
            function() {
              _self.handleActionSuccess({
                type: 'reactivate',
                idOfJobToHide: JobService.activity.updateData.job.id
              });
              deferred.resolve();
            },
            function() {
              deferred.reject();
            }
          );

        return deferred.promise;
      };

      _self.jobService = JobService;

      _self.working = false;

      _self.isAdmin = AuthService.isAdmin();

      _self.closeModal = function() {
        JobService.activity.resetUpdateData();

        _self.working = false;
        _self.active = false;
      };

      _self.doUpdate = function() {
        if (_self.working === true) {
          return;
        }

        var updateActions = {
          duplicate: {
            overWriteManual: doSubmitOverwriteUpdate
          },
          reactivate: {
            startFromBeginning: doReactivationStartFromBeginning
          },
          dropped: {}
        };

        _self.working = true;
        updateActions[JobService.activity.updateData.jobType]
          [JobService.activity.updateData.jobAction]()
          .then(
            function() {
              _self.working = false;
              _self.active = false;
            }.bind(this)
          );
      };

      _self.keybindFilter = function(event) {
        if (!_self.active) {
          return;
        }
        $timeout(function() {
          if (event.which === 13) {
            // return
            _self.doUpdate();
          } else if (event.keyCode === 27) {
            // escape
            _self.closeModal();
          }
        }, 0);
      };

      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          KeymageService.toggle(!_self.active);

          if (_self.active) {
            angular.element(document.body).on('keyup', _self.keybindFilter);
          } else {
            angular.element(document.body).off('keyup', _self.keybindFilter);
          }
        }
      );
    },
    templateUrl: 'templates/partials/activity/activityModal.tpl.html',
    link: function(scope, elem, attrs, ctrl) {
      scope.$on('$destroy', function() {
        angular.element(document.body).off('keyup', ctrl.keybindFilter);
      });
    }
  };
};

module.exports = activityModalDirective;
