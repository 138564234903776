'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    '$scope',
    '$element',
    '$timeout',
    '$attrs',
    'modalService',
    function modalController($scope, $element, $timeout, $attrs, modalService) {
      var _self = this,
        modalElem = $element[0].querySelector('[role="document"]'),
        lastFocus,
        showModal,
        hideModal,
        focusRestrict,
        keyupCheck,
        clickCheck;

      _self.toggleModal = function(newVal) {
        if (newVal === false) {
          hideModal();
        } else if (newVal === true) {
          showModal();
        }
      };

      focusRestrict = function(event) {
        if (!modalElem.contains(event.target)) {
          event.stopPropagation();
          modalElem.focus();
        }
      };

      keyupCheck = function(event) {
        if (event.keyCode === 27) {
          $scope.$apply(function() {
            // _self.active = false;
            _self.onHide();
          });
        }
      };

      clickCheck = function(event) {
        if (event.target === $element[0]) {
          $scope.$apply(function() {
            // _self.active = false;
            _self.onHide();
          });
        }
      };

      hideModal = function() {
        document.body.classList.remove('modal-is-active');
        $element.attr('aria-hidden', 'true');
        if ($attrs.static === undefined || $attrs.static === 'false') {
          $element.off('click', clickCheck);
          document.removeEventListener('keyup', keyupCheck);
        }
        document.removeEventListener('focus', focusRestrict, true);
        modalElem.setAttribute('tabindex', '-1');
        if (lastFocus) {
          lastFocus.focus();
          lastFocus = undefined;
        }
      };

      showModal = function() {
        document.body.classList.add('modal-is-active');
        $element.attr('aria-hidden', 'false');
        if ($attrs.static === undefined || $attrs.static === 'false') {
          $element.on('click', clickCheck);
          document.addEventListener('keyup', keyupCheck);
        }
        document.addEventListener('focus', focusRestrict, true);
        modalElem.setAttribute('tabindex', '0');
        lastFocus = document.activeElement;
        $timeout(function() {
          modalElem.focus();
        }, 0);
      };

      $scope.$watch(
        function() {
          return _self.active;
        },
        function handleActiveChange(newVal, oldVal) {
          newVal = !!newVal;
          oldVal = !!oldVal;

          if (newVal === oldVal) {
            return;
          }

          _self.toggleModal(_self.active);
        }
      );
    }
  ];
}

module.exports = register;
