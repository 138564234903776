'use strict';

var angular = require('angular');
var moment = require('moment');

var closeJobModalComponent = {
  bindings: {
    active: '=',
    model: '<'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $rootScope,
    $q,
    JobService,
    NotificationService,
    REST_URL,
    $window,
    $timeout
  ) {
    var _self = this;

    _self.data = {
      working: false,
      params: {
        cta: {
          primary: 'Confirm',
          secondary: 'Cancel'
        }
      },
      error: undefined,
      isValid: false
    };

    _self.closeJob = function() {
      _self.data.error = undefined;
      _self.data.working = true;
      JobService.closeJob(_self.model).then(function() {
        NotificationService.push('Job ' + _self.model.id + ' has been closed.');
        if (_self.parent && _self.parent.active) {
          _self.parent.active = false;
        } else {
          $timeout(function() {
            $window.location.reload();
          }, 700);
        }
        _self.data.working = false;
        _self.active = false;
      })
        .catch((e = {}) => {
          _self.data.error = e && e.data && e.data.error;
          _self.data.working = false;
          _self.data.params.cta.primary = 'Try Again';
          console.log(_self.data.error);
        });
    };

    _self.cancelCloseJob = function() {
      _self.active = false;
    };

    _self.$onInit = function() {};
  },
  templateUrl: 'templates/partials/initiateJob/closeJobModal.tpl.html'
};

module.exports = closeJobModalComponent;
