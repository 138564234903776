'use strict';

var angular = require('angular');

var baseProductComponent = {
  bindings: {
    model: '='
  },
  require: {
    parent: '^^baseProductResults'
  },
  controller: /*@ngInject*/ function(JobService, $window) {
    var _self = this;

    _self.selectJob = function() {
      //_self.model.selected = !_self.model.selected;
      _self.parent.setBaseProduct(_self.model);
    };

    _self.openJob = function() {
      if (_self.model.product.productId && _self.model.id) {
        $window.open(
          '/#/detail/' +
            _self.model.product.productId +
            '/' +
            _self.model.id +
            '//',
          '_blank'
        );
      }
    };

    _self.$onChanges = function(changes) {
      if (changes.model && changes.model.currentValue) {
        _self.model = angular.copy(changes.model.currentValue);
      }
      if (_self.model) {
        _self.model.assetApprovalStatus = _self.model.expectedViewsApproved
          ? 'Approved'
          : 'Unapproved';
        if (_self.model.assetJobs) {
          _self.model.referenceAsset = JobService.getReferenceAsset(
            _self.model.assetJobs[0]
          );
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/baseProduct.tpl.html'
};

module.exports = baseProductComponent;
