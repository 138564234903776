'use strict';

var infiniteScrollDirective = require('./infiniteScroll.directive.js');

function register(angular) {
  var app = angular.module('infinite-scroll', ['ng']);

  app.value('THROTTLE_MILLISECONDS', null);
  app.directive('infiniteScroll', infiniteScrollDirective(angular));

  return 'infinite-scroll';
}

module.exports = register;
