'use strict';

var dropdownController = require('./dropdown.controller.js');
var dropdownService = require('./dropdown.service.js');
var dropdownDirective = require('./dropdown.directive.js');
var dropdownMenuDirective = require('./dropdown.menu.directive.js');
var dropdownToggleDirective = require('./dropdown.toggle.directive.js');

function register(angular) {
  var app = angular.module('dropdown', ['ng']);

  app.controller('dropdownController', dropdownController(angular));
  app.service('dropdownService', dropdownService(angular));
  app.directive('dropdown', dropdownDirective(angular));
  app.directive('dropdownMenu', dropdownMenuDirective(angular));
  app.directive('dropdownToggle', dropdownToggleDirective(angular));

  return 'dropdown';
}

module.exports = register;
