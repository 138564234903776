'use strict';

var problemSolveJobListDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {},
    bindToController: {
      data: '<'
    },
    controllerAs: 'job',
    controller: 'JobController',
    templateUrl: 'templates/partials/job/lists/problemSolve.tpl.html'
  };
};

module.exports = problemSolveJobListDirective;
