'use strict';

var angular = require('angular');
var appendTransform = require('../vendor/appendTransform');

var UsersService = /*@ngInject*/ function($http, REST_URL, $q) {
  var _self = this;

  _self.assigneeOptions = {};

  _self.getUsersByRoles = function(roles) {
    if (!angular.isArray(roles)) {
      roles = [roles];
    }

    if (
      !roles.some(function(role) {
        return role === 'SUPERUSER';
      })
    ) {
      // always include super users when getting assignee lists
      roles.push('SUPERUSER');
    }

    // convert roles array into comma separated list
    var rolesStr = roles
      .map(function(r) {
        return 'ROLE_' + r;
      })
      .join(',');

    if (_self.assigneeOptions[rolesStr]) {
      var deferred = $q.defer();
      //copy to avoid changing data elsewhere
      deferred.resolve({ data: angular.copy(_self.assigneeOptions[rolesStr]) });
      return deferred.promise;
    } else {
      return _self.call(rolesStr);
    }
  };

  _self.call = function(rolesStr) {
    return $http
      .get([REST_URL.base, '/account/roles/', rolesStr, '/users'].join(''))
      .then(function(response) {
        response.data.forEach(function(user) {
          user.name = [user.firstName, user.lastName].join(' ');
        });
        //copy to avoid changing data elsewhere
        _self.assigneeOptions[rolesStr] = JSON.parse(
          JSON.stringify(angular.copy(response.data))
        );
        return response;
      });
  };
};

module.exports = UsersService;
