'use strict';

var angular = require('angular');

// https://docs.angularjs.org/api/ng/service/$http
var appendTransform = function(defaults, transform) {
  // We can't guarantee that the default transformation is an array
  defaults = angular.isArray(defaults) ? defaults : [defaults];

  // Append the new transformation to the defaults
  return defaults.concat(transform);
};

module.exports = appendTransform;
