'use strict';

var angular = require('angular');

var postConversionJobModal = {
  bindings: {
    active: '<'
  },
  require: {
    parent: '^initiateJob'
  },
  controller: /*@ngInject*/ function($state, $interpolate) {
    var _self = this;

    var setHeading = function(jobs, data, template) {
      var context = {
          styleColor: jobs[0].product.styleColor
        },
        titleTemplate =
          jobs.length === 1 ? template.title.single : template.title.plural,
        copyTemplate =
          jobs.length === 1 ? template.copy.single : template.copy.plural;

      data.title = $interpolate(titleTemplate)(context);
      data.copy = $interpolate(copyTemplate)(context);
    };

    _self.model = {};

    _self.modal = {
      working: false,
      template: {
        heading: {
          title: {
            single: 'Close Job?',
            plural: 'Close Jobs?'
          },
          copy: {
            single:
              'There is currently a Pending job for {{styleColor}}. Would you like to close the job?',
            plural:
              'There are currently Pending jobs for {{styleColor}}. Would you like to close the jobs?'
          }
        }
      },
      data: {
        heading: {},
        cta: {
          primary: 'Close',
          secondary: 'Keep'
        }
      }
    };

    _self.$onChanges = function(changes) {
      if (changes.active) {
        if (!_self.active) {
          return;
        }
        _self.model = angular.copy(_self.parent.pendingAssetOnlyJobs);

        setHeading(
          _self.model,
          _self.modal.data.heading,
          _self.modal.template.heading
        );
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/postConversionJobModal.tpl.html'
};

module.exports = postConversionJobModal;
