'use strict';

var angular = require('angular');
var moment = require('moment-timezone');

var outreachViewerComponent = {
  bindings: {
    job: '<',
    onComplete: '&'
  },
  controller: /*@ngInject*/ function(
    $state,
    JobService,
    TransferService,
    QAService,
    Session,
    NoteService,
    META_STATUS,
    ASSET_STATUS,
    SearchService,
    NotificationService,
    $http,
    REST_URL,
    USER_DATA,
    OutreachService,
    $stateParams
  ) {
    var _self = this;
    _self.rejectReason = '';
    _self.userComments = '';
    _self.isValid = false;
    _self.readonly = true;

    //An HTTP request will provide this

    _self.notifications = {
      'In Image QA':
        'Job {{id}} has been sent to the Product Team for clarification.',
      'Product Review': 'Job {{id}} has been returned to the QA Reviewer.'
    };

    _self.dateConvert = function(date) {
      return moment(date).format('M/D/YYYY hh:mm A');
    };

    _self.onCommentChange = function($event) {
      // Checks if input has exceeded max length.
      _self.userComments = $event.text;
      _self.validate();
    };

    _self.validate = function() {
      _self.isValid = !!_self.userComments;
    };

    _self.completeOutreach = function() {
      _self.working = true;

      var tasksToComplete = [];
      //This is the meta job
      tasksToComplete.push({
        jobId: _self.job.id,
        taskId: _self.job.tasks[0].id,
        properties: {}
      });

      // && asset.annotation
      //These are the asset jobs
      angular.forEach(QAService.assets, function(asset) {
        if (
          asset.job.status === ASSET_STATUS.qa ||
          asset.job.status === ASSET_STATUS.review
        ) {
          var properties = {};
          if (_self.outreachState === 'image_qa') {
            properties.reason = 'Requires additional editing';
          }

          tasksToComplete.push({
            jobId: asset.job.id,
            taskId: asset.job.tasks[0].id,
            properties: properties
          });
        }
      });

      var properties = {
        tasksToComplete: tasksToComplete
      };

      if (_self.outreachState === 'image_qa') {
        for (var i = 0; i < tasksToComplete.length; i++) {
          properties.tasksToComplete[i].properties.productReview = true;
          properties.tasksToComplete[i].properties.reject = false;
        }
      }

      NoteService.addNote(
        'productReview',
        _self.userComments,
        _self.job.id
      ).finally(function(res) {
        var completeEndPoint = 'asynccomplete';
        //Wait for the Note Service to respond successfully, then call completeReview
        QAService.completeReview({ outreach: true })
          .then(function(res) {
            //Save the annotations
            return $http.post(
              [REST_URL.jobs, completeEndPoint].join('/'),
              properties,
              JobService.transformJobsConfig
            );
          }, function(error) {})
          .finally(function() {
            switch (_self.outreachState) {
              case 'image_qa':
                _self.qaSuccess();
                break;
              case 'outreachQueue':
                NotificationService.push(
                  _self.notifications['Product Review'],
                  { id: _self.job.id }
                );
                OutreachService.nextJob();
                _self.working = false;
                break;
              default:
                _self.working = false;
                $state.go('search');
                break;
            }
          });
      });
    };

    _self.completeToQA = function() {
      if (
        _self.job.tasks[0].assignee &&
        USER_DATA.userName === _self.job.tasks[0].assignee.username
      ) {
        OutreachService.setState('product_review');
        $state.go('qa', {
          id: _self.job.id,
          assetJobId: _self.job.assetJobs[0].id
        });
      } else {
        $state.go('queue.qa');
      }
    };

    _self.qaSuccess = function() {
      var searchParams = {
        term: '*',
        page: 0,
        size: 50,
        sort: 'priority|desc,tasks.startedOn|asc',
        filters:
          'status=' +
          META_STATUS.qa +
          ',processedForSeconds=60' +
          ',assignees=' +
          Session.get('userName')
      };

      SearchService.search(searchParams)
        .then(function(response) {
          // Filter self out from response
          // (The search service updates asyncronously, so it may still appear as "In Image QA")
          var jobs = response.data.results.filter(function(job) {
            return job.id !== _self.job.id;
          });

          // If there is another QA job assignee to the user, load that job data
          // Otherwise, redirect to QA queue
          if (jobs.length) {
            $state.go(
              'qa',
              {
                id: jobs[0].id,
                assetJobId: undefined
              },
              {
                location: 'replace'
              }
            );
          } else {
            $state.go('queue.qa');
          }
          NotificationService.push(_self.notifications['In Image QA'], {
            id: _self.job.id
          });
        })
        .catch(function() {
          $state.go('queue.qa');
        })
        .finally(function() {
          _self.working = false;
        });
    };

    _self.cancel = function() {
      switch (_self.outreachState) {
        case 'image_qa_review':
        case 'image_qa':
          $state.go('qa', {
            id: _self.job.id,
            assetJobId: _self.job.assetJobs[0].id
          });
          break;
        case 'outreachQueue':
          $state.go('outreachQueue.outreach');
          break;
        default:
          $state.go('search');
          break;
      }
    };

    _self.currentIndex = 0;

    _self.setHeroImage = function(assetJob) {
      if (
        !assetJob ||
        (assetJob.status !== ASSET_STATUS.qa &&
          assetJob.status !== ASSET_STATUS.review)
      ) {
        return false;
      }
      _self.selectedAsset = assetJob;
      _self.currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset);
      _self.heroImage = JobService.getImageByType(
        assetJob.relatedAssets.Deliverable,
        'large'
      );
      _self.qaData = QAService.get(_self.selectedAsset.id);
      return true;
    };

    _self.getNextAssetIndexForReview = function() {
      var _currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset);

      for (var i = 0; i < _self.job.assetJobs.length; i++) {
        var index = (i + _currentIndex) % _self.job.assetJobs.length;
        var qaAsset = QAService.get(_self.job.assetJobs[index].id);

        if (qaAsset && qaAsset.approved === undefined) {
          return index;
        }
      }
    };

    _self.prevImg = function(wrap) {
      var _currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset),
        _validateQA;

      if (_currentIndex === 0 && wrap) {
        _currentIndex = _self.job.assetJobs.length - 1;
      } else {
        _currentIndex--;
      }

      _validateQA = _self.setHeroImage(_self.job.assetJobs[_currentIndex]);
      _self.currentIndex = _currentIndex;

      if (!_validateQA) {
        if (wrap) {
          //only wrap  when there is at least 1 validate job
          if (_self.totalQAJob > 0) {
            _self.prevImg(wrap);
          }
        } else if (_currentIndex >= 0) {
          _self.prevImg(wrap);
        }
      }
    };

    _self.nextImg = function(wrap) {
      var _currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset),
        _validateQA;

      if (_currentIndex >= _self.job.assetJobs.length - 1 && wrap) {
        _currentIndex = 0;
      } else {
        _currentIndex++;
      }

      _validateQA = _self.setHeroImage(_self.job.assetJobs[_currentIndex]);
      _self.currentIndex = _currentIndex;

      if (!_validateQA) {
        if (wrap) {
          //only wrap  when there is at least 1 validate job
          if (_self.totalQAJob > 0) {
            _self.nextImg(wrap);
          }
        } else if (_currentIndex < _self.job.assetJobs.length) {
          _self.nextImg(wrap);
        }
      }
    };

    _self.setAnnotation = function(flag) {
      _self.rejectAnnotationFlag = flag;
      _self.qaDetailFlag = !_self.rejectAnnotationFlag;
      _self.qaData = QAService.get(_self.selectedAsset.id);

      if (!flag) {
        _self.setHeroImage(
          _self.job.assetJobs[_self.getNextAssetIndexForReview()]
        );
      }
    };

    _self.showAnnotations = function(assetJob) {
      _self.setHeroImage(assetJob);
      _self.qaDetailFlag = false;
      _self.rejectAnnotationFlag = true;
    };

    _self.setViewData = function(job) {
      //until the backend returns library viewcode in assetJob we have to pull it from product.assets
      if (_self.showLibraryViewCode) {
        _self.assignAssetJobLibraryViewCode(job);
      }

      _self.totalQAJob = job.assetJobs.length;

      if (job.assetJobs.length) {
        QAService.init(_self.job);
        job.assetJobs.forEach(function(assetJob) {
          if (!_self.heroImage) {
            if ($stateParams.assetJobId) {
              if ($stateParams.assetJobId === assetJob.id) {
                _self.selectedTab = 1;
                _self.setHeroImage(assetJob);
              }
            } else {
              _self.setHeroImage(assetJob);
            }
          }
        });
      }
    };

    _self.$onChanges = function(changes) {
      if (changes.job) {
        if (!_self.job) {
          return;
        }

        _self.comments = _self.job.notes.filter(function(a) {
          return a.noteType === 'Product Review';
        });

        _self.job = angular.copy(_self.job);
        _self.setViewData(_self.job);

        _self.outreachState = OutreachService.getState();
        switch (_self.outreachState) {
          case 'image_qa':
            _self.readonly = false;
            break;
          case 'image_qa_review':
            _self.readonly = true;
            break;
          case 'outreachQueue':
            _self.readonly = false;
            break;
          default:
            _self.readonly = false;
            _self.outreachState = null;
        }
      }
    };

    _self.$onInit = function() {
      _self.qaServiceData = QAService.data;
      _self.download = TransferService.data;
      _self.canZoom = true;
      _self.rejectAnnotationFlag = false;
      _self.qaDetailFlag = true;
      _self.validate();
    };
  },
  templateUrl: 'templates/partials/qa/outreachViewer.tpl.html'
};

module.exports = outreachViewerComponent;
