'use strict';

var angular = require('angular');

var notesComponent = {
  bindings: {
    job: '<',
    onChange: '&'
  },
  controller: /*@ngInject*/ function(
    META_STATUS,
    USER_ROLES,
    AuthService,
    NoteService,
    CHARACTER_LIMITS,
    APP_DATA
  ) {
    var _self = this,
      isFirstChange = true;

    _self.setNotesData = function(notes) {
      _self.notes.problem.data = [];
      _self.notes.slate.data = [];
      _self.notes.general.data = [];
      _self.notes.productReview.data = [];

      notes.forEach(function(note) {
        switch (note['@class']) {
          case NoteService.noteTypes.problem.type:
            _self.notes.problem.data.push(note);
            break;
          case NoteService.noteTypes.slate.type:
            _self.notes.slate.data.push(note);
            break;
          case NoteService.noteTypes.general.type:
            _self.notes.general.data.push(note);
            _self.notes.general.open = true;
            break;
          case NoteService.noteTypes.productReview.type:
            _self.notes.productReview.data.push(note);
            break;
        }
      });

      if (_self.isProblem) {
        _self.notes.problem.open = true;
      }

      if (isFirstChange) {
        if (!_self.notes.problem.open) {
          _self.notes.slate.open = !!_self.notes.slate.data.length;
        }
        isFirstChange = false;
      }

      _self.notes.problem.showForm = !_self.notes.problem.data.length;
      _self.notes.general.showForm = !_self.notes.general.data.length;
      //cant add if completed, dropped or closed
      _self.notes.general.canAdd =
        !(_self.job.status === META_STATUS.completed) &&
        !(_self.job.status === META_STATUS.dropped) &&
        !(_self.job.status === META_STATUS.closed);
      _self.notes.productReview.showForm = !_self.notes.productReview.data
        .length;
    };

    _self.CHARACTER_LIMITS = CHARACTER_LIMITS;

    _self.PROBLEM_NOTE_FORM_ADD = 'add';
    _self.PROBLEM_NOTE_FORM_RESOLVE = 'resolve';

    _self.notes = {
      problem: {
        data: [], // individual notes
        open: false, // is accordion open
        showForm: false, // is form being displayed
        formData: '' // temporary form data
      },
      slate: {
        data: [],
        open: false,
        showForm: false,
        formData: ''
      },
      general: {
        data: [],
        open: false,
        showForm: false,
        formData: ''
      },
      productReview: {
        data: [],
        open: true,
        formData: ''
      }
    };

    _self.print = function() {
      _self.showSlateModal = true;
    };

    _self.resolveProblem = function() {
      _self.onChange({ $event: { type: 'resolveproblem' } });
    };

    _self.clearNote = function(type) {
      if (type === 'slate' && _self.notes.slate.data.length) {
        _self.notes.slate.data[0].content = NoteService.convertLineBreaks(
          _self.notes.slate.data[0].content,
          true
        );
      }
      _self.notes[type].showForm = false;
      _self.notes[type].open = !!_self.notes[type].data.length;
      _self.notes[type].formData = '';
    };

    _self.showNoteForm = function(type, state) {
      if (state) {
        _self.problemNoteState = state;
      }

      _self.notes[type].showForm = true;
    };

    _self.editNote = function(type) {
      _self.notes[type].formData = _self.notes[type].data[0].content;
      _self.notes[type].showForm = true;
    };

    _self.showEditSlate = function() {
      if (_self.notes.slate.data.length) {
        _self.notes.slate.data[0].content = NoteService.convertLineBreaks(
          _self.notes.slate.data[0].content,
          false
        );
        _self.editNote('slate');
      } else {
        _self.showNoteForm('slate');
      }
    };

    _self.onNoteTextChange = function($event) {
      // Checks if input has exceeded max length.
      _self.notes[$event.type].formData = $event.text;
      _self.notes[$event.type].invalid = !$event.valid;
    };

    _self.addNote = function(type, note) {
      note = note || _self.notes[type].formData;
      _self.working = true;
      if (type === 'slate') {
        note = NoteService.convertLineBreaks(note, true);
      }

      NoteService.addNote(type, note, _self.job.id)
        .then(function(response) {
          //Hide the form. What does onChange do???
          _self.notes[type].showForm = false;
          _self.onChange({ $event: { job: response.data } });
        })
        .finally(function() {
          //No longer loading, set textarea to blank.
          _self.working = false;
          _self.notes[type].formData = '';
        });
    };

    _self.updateParent = function($event) {
      _self.onChange({ $event: $event });
    };

    _self.$onChanges = function(changes) {
      if (changes.job) {
        _self.job = angular.copy(_self.job);

        if (_self.job !== undefined) {
          _self.isProblem = _self.job.status === META_STATUS.problemSolve;
          _self.hasAssignee =
            _self.job.tasks.length && _self.job.tasks[0].assignee;
          _self.canResolve =
            _self.isProblem && AuthService.isAdmin() && _self.hasAssignee;

          //cant add if completed, dropped or closed
          _self.canAddSlateNote =
            AuthService.isAuthorized([
              USER_ROLES.merchOps,
              USER_ROLES.studioOps,
              USER_ROLES.studioStylist,
              USER_ROLES.superUser
            ]) &&
            !(_self.job.status === META_STATUS.completed) &&
            !(_self.job.status === META_STATUS.dropped) &&
            !(_self.job.status === META_STATUS.closed);

          _self.setNotesData(_self.job.notes);
        }
      }
    };
  },
  templateUrl: 'templates/partials/detail/jobNotes.tpl.html'
};

module.exports = notesComponent;
