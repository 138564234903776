'use strict';

var angular = require('angular');

var HistoryService = /*@ngInject*/ function($http, REST_URL) {
  var _self = this;

  _self.getHistoryData = function(job) {
    if (!job || !job.id) {
      return;
    }
    _self.historyPromise =
      _self.historyPromise ||
      $http.get([REST_URL.base, 'jobs', job.id, 'history'].join('/'), {
        params: { pageSize: 10000 }
      });
    return _self.historyPromise;
  };
};

module.exports = HistoryService;
