'use strict';

var angular = require('angular');

var ScanTypeService = /*@ngInject*/ function() {
  var _self = this,
    shipIdRegex = new RegExp('^S[0-9]{6,15}$'),
    merchIdRegex = new RegExp('^I[0-9]{6,15}$'),
    gtinRegex = new RegExp('^[0-9]{12}$'),
    jobSlateRegex = new RegExp('[_]');

  _self._scanType = {
    merch: 'Merch ID',
    ship: 'Ship ID',
    gtin: 'GTIN',
    job: 'Job Slate',
    unknown: 'UNKNOWN'
  };

  _self.isShipId = function(idStr) {
    if (shipIdRegex.test(idStr)) {
      return true;
    } else {
      return false;
    }
  };

  _self.isMerchId = function(idStr) {
    if (merchIdRegex.test(idStr)) {
      return true;
    } else {
      return false;
    }
  };

  _self.isGtin = function(idStr) {
    if (gtinRegex.test(idStr)) {
      return true;
    } else {
      return false;
    }
  };

  _self.isJobSlate = function(idStr) {
    if (jobSlateRegex.test(idStr)) {
      return true;
    } else {
      return false;
    }
  };

  _self.getScanType = function(idStr) {
    switch (true) {
      case _self.isMerchId(idStr):
        return _self._scanType.merch;
        break;
      case _self.isShipId(idStr):
        return _self._scanType.ship;
        break;
      case _self.isGtin(idStr):
        return _self._scanType.gtin;
        break;
      case _self.isJobSlate(idStr):
        return _self._scanType.job;
        break;
      default:
        return _self._scanType.unknown;
    }
  };
};

module.exports = ScanTypeService;
