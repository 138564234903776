'use strict';

var angular = require('angular');
var moment = require('moment');

var confidentialToggleComponent = {
  transclude: true,
  bindings: {
    isConfidential: '=',
    launchDate: '=',
    disabled: '<',
    onChange: '&'
  },
  controller: /*@ngInject*/ function(JobService) {
    var _self = this;
    var fakeDate = new Date('2099-01-01T12:00:00');

    _self.handleToggle = function() {
      if (!_self.disabled) {
        _self.isConfidential = !_self.isConfidential;
      }

      if (_self.isConfidential && !_self.launchDate) {
        _self.launchDate = fakeDate;
      }

      if (!_self.isConfidential) {
        _self.launchDate = undefined;
      }

      _self.model = _self.isConfidential;
    };

    _self.$onInit = function() {};

    _self.$onChanges = function(changes) {
      if (changes.disabled) {
        if (_self.disabled) {
          _self.currentIndex = 0;
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/confidentialToggle.tpl.html'
};

module.exports = confidentialToggleComponent;
