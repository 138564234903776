'use strict';

var angular = require('angular');
var oktaService = require('../services/okta.service.js');

var QueueService = /*@ngInject*/ function(
  $rootScope,
  $q,
  $timeout,
  iScrollService,
  SearchService,
  JobService,
  AuthService,
  USER_ROLES,
  SEARCH_CONSTANTS,
  REST_URL,
  $http
) {
  var _self = this;

  _self.scopes = {};

  var init = function() {
    _self.data = {};
    _self.reset();
  };

  _self.registerScope = function(scope, name) {
    _self.scopes[name] = scope;
  };

  _self.setParentScope = function(scope) {
    _self.parentScope = scope;
  };

  _self.reset = function() {
    _self.data.initiateJob = {};
    _self.data.filter = {
      tray: {},
      main: {}
    };
    _self.data.sort = {
      tray: {},
      main: {}
    };
  };

  _self.filterResults = function(location, $event) {
    var filter = angular.copy($event.filterObj);
    _self.data.filter[location] = filter;
    $rootScope.$broadcast('queue.update.' + location, { type: 'fetch' });
  };

  _self.sortResults = function(location, $event) {
    var sortObj = angular.copy($event.sortObj);
    sortObj.reverse =
      sortObj.reverse === undefined
        ? _self.data.sort[location].reverse
        : sortObj.reverse;

    _self.data.sort[location] = sortObj;
    $rootScope.$broadcast('queue.update.' + location, { type: 'fetch' });
  };

  _self.setSorting = function(location, $event) {
    var sortObj = angular.merge(
      {},
      SEARCH_CONSTANTS.jobDetailHeadersHash[$event.predicate],
      { reverse: $event.reverse }
    );

    _self.sortResults(location, { sortObj: sortObj });
  };

  _self.toggleAll = function(jobs, boolean) {
    jobs.forEach(function(job) {
      job.selected = boolean;
    });

    return jobs;
  };

  _self.updateJobs = function(scope, params) {
    var f;

    switch (params.type) {
      case 'push':
        f = 'addUpdatedJobs';
        break;
      case 'complete':
        f = 'addCompletedJobs';
        break;
      case 'filter':
        f = 'filterUpdatedJobs';
        break;
      default:
        f = 'resetAndGetData';
        break;
    }

    _self[f](scope, params.jobs);
  };

  _self.resetData = function(scope) {
    scope.data.allJobs = [];
    scope.data.allJobsUnfiltered = [];
    scope.data.jobs = [];
    scope.data.selectedJobs = [];
    scope.allResults = false;
  };

  _self.resetAndGetData = function(scope) {
    _self.resetData(scope);
    scope.getJobs();
  };

  _self.filterUpdatedJobs = function(scope, jobs) {
    var jobsRemovedFromVisibleList = 0;
    var listToDelete = jobs.map(function(job) {
      return job.id;
    });

    scope.data.jobs = scope.data.jobs.filter(function(job) {
      if (listToDelete.indexOf(job.id) === -1) {
        return true;
      } else {
        jobsRemovedFromVisibleList++;
        return false;
      }
    });

    scope.service.toggleAll(scope.data.jobs, false);
    scope.data.total -= jobsRemovedFromVisibleList;
  };

  _self.addUpdatedJobs = function(scope, jobs) {
    // filter jobs that don't match the assignee
    jobs = jobs.filter(function(job) {
      return (
        !scope.assignee ||
        scope.assignee ===
          JobService.getAssignedUser(job, scope.stateData.params.assignType)
      );
    });
    scope.data.jobs = scope.data.jobs.concat(jobs);
    scope.service.toggleAll(scope.data.jobs, false);
    scope.data.total += jobs.length;
  };

  _self.addCompletedJobs = function(scope, jobs) {
    // filter jobs that don't match the assignee
    jobs = jobs.filter(function(job) {
      return (
        !scope.assignee ||
        scope.assignee ===
          JobService.getAssignedUser(job, scope.stateData.params.assignType)
      );
    });
    scope.data.jobs = jobs.concat(scope.data.jobs);
    scope.service.toggleAll(scope.data.jobs, false);
    scope.data.total += jobs.length;
  };

  // When a job or product is selected, send data back to the controller
  _self.handleSelections = function(scope) {
    scope.data.selectedJobs = scope.data.jobs.filter(function(job) {
      return !!job.selected;
    });

    scope.data.selections = !!scope.data.selectedJobs.length;

    _self.refreshScroll(scope);
  };

  _self.refreshScroll = function(scope) {
    $timeout(function() {
      iScrollService.refresh(scope);
    }, 0);
  };

  _self.doSearch = function(scope, term, force) {
    if (term === scope.data.term && force !== true) {
      return $q.when(scope.data.jobs);
    }

    _self.resetData(scope);
    scope.data.term = term;
    return scope.getJobs();
  };

  _self.isValidScan = function(data, job) {
    var checkJob = function(queue) {
      var match;

      if (!queue.updateParams) {
        return false;
      }

      switch (queue.updateParams.type) {
        case 'tasks':
          match = job.status === queue.status;
          break;
        case 'merchJob':
          match = job.merchJob && job.merchJob.status === queue.status;
          break;
      }

      return match;
    };

    if (checkJob(data.tray)) {
      return 'tray';
    } else if (checkJob(data.main)) {
      return 'main';
    }

    return false;
  };

  _self.confirmUpdate = function(jobs, params, location) {
    _self.parentScope.confirmStatusChange(jobs, params, location);
  };

  _self.initiateJob = function(jobs) {
    _self.parentScope.initiateJob(jobs[0]);
  };

  _self.getJobs = function(term, params, page, size, location, status) {
    var sort = SearchService.getSortingString(
        _self.data.sort[location].predicate,
        _self.data.sort[location].reverse
      ),
      filters = [];

    params = params || {};

    if (params.filters && params.filters.length) {
      filters.push(params.filters);
    }

    filters = filters.join(',');

    var sortParam =
      status === 'Completed' ? params.sort : (sort += ',tasks.startedOn|asc');

    return SearchService.search(
      {
        term: term,
        filters: filters,
        queue: params.queue,
        page: page,
        size: size,
        sort: sortParam // Always sort results by tasks.startedOn (time in Queue) additionally
      },
      params.type === undefined ? 'jobs' : params.type
    );
  };

  _self.getMerchManagementJobs = function(params) {
    return SearchService.searchElastic(REST_URL.merchSearch, params);
  };

  _self.getMerchManagementShipments = function(params) {
    return SearchService.searchMerchShipments(params);
  };

  _self.getShipmentDetails = function(shipmentId) {
    var deferred = $q.defer();
    var url = REST_URL.merchShipments + '/' + shipmentId;

    $http
      .get(url)
      .then(function(response) {
        if (response) {
          deferred.resolve(response);
        } else {
          deferred.reject();
        }
      })
      .catch(deferred.reject);

    return deferred.promise;
  };

  _self.associateMerchToShipment = function(merchId, shipmentId) {
    return $http.put(REST_URL.merch + '/' + merchId, {
      shipmentId: shipmentId
    });
  };

  init();

  return _self;
};

module.exports = QueueService;
