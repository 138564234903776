'use strict';

var angular = require('angular');

var assetJobDetailComponent = {
  bindings: {
    metaJob: '<',
    assetJobId: '<',
    cropType: '<',
    onChange: '&'
  },
  controller: /*@ngInject*/ function(JobService, $state) {
    var _self = this;
    _self.selectedAsset = undefined;

    _self.getAssetList = function() {
      const assets = [];
      for (let assetJobIndex in _self.metaJob.assetJobs) {
        let assetJob = _self.metaJob.assetJobs[assetJobIndex];
        if (!!assetJob.cropTypes) {
          for (let i in _self.metaJob.expectedCropTypes) {
            let expectedCropType = _self.metaJob.expectedCropTypes[i];
            if(!!assetJob.cropTypes[expectedCropType]) {
              const cropAsset = assetJob.cropTypes[expectedCropType];
              cropAsset.assetJobId = assetJob.id;
              assets.push(cropAsset);
            }
          }
        } else if (!!assetJob.relatedAssets) {
          let asset = assetJob.relatedAssets.Worker || assetJob.relatedAssets.Unedited;
          asset.assetJobId = assetJob.id;
          assets.push(asset);
        }
      }
      return assets;
    }

    _self.changeAsset = function($event) {
      var currentIndex = _self.metaJob.assetJobs.indexOf(_self.model),
        newIndex;

      if ($event.close) {
        delete _self.revision;
        _self.onChange({ $event: { closeAssetDetail: true } });
        return;
      }

      if ($event.boolean) {
        newIndex = (currentIndex + 1) % _self.metaJob.assetJobs.length;
      } else if (currentIndex === 0) {
        newIndex = _self.metaJob.assetJobs.length - 1;
      } else {
        newIndex = currentIndex - 1;
      }

      _self.onChange({
        $event: { assetJobId: _self.metaJob.assetJobs[newIndex].id }
      });
    };

    _self.onHistorySelection = function($event) {
      _self.revision = $event;
      _self.showControls = false;
    };

    _self.closeRevision = function() {
      delete _self.revision;
      _self.showControls = true;
    };

    _self.getCropTypes = function(assetJobs) {
      var cropTypes = [];
      for(let cropType in assetJobs) {
        cropTypes.push(cropType);
      }
      return cropTypes;
    };

    _self.showAsset = function() {
      _self.selectedCropType = '';
      if (_self.selectedAsset) {
        _self.selectedCropType = _self.selectedAsset.cropType ? _self.selectedAsset.cropType : '';
        try {
          _self.heroImage = JobService.getImageByType(
            _self.selectedAsset,
            'large'
          );
        } catch (error) {
          console.log('unable to set heroImage.', error);
        }
      } 
      
      $state.go(
        'detail',
        {
          productIdentifierType: 'by-product-id',
          productIdentifier: _self.metaJob.product.productId,
          jobId: _self.metaJob.id,
          assetJobId: !!_self.selectedAsset ? _self.selectedAsset.assetJobId : '' // note: router requires an empty string to update properly
        },
        {
          location: 'replace',
          notify: false
        }
      );
    };

    _self.prevImg = function(wrap) {
      const assetList = _self.getAssetList();
      if(assetList.length == 0) {
        return;
      }
      if(_self.currentIndex === 0) {
        if(wrap) {
          _self.currentIndex = assetList.length - 1;
        } else {
          return;
        }
      } else {
        _self.currentIndex = _self.currentIndex - 1;
      } 
      _self.setSelectedAndShow();
    };

    _self.nextImg = function(wrap) {
      const assetList = _self.getAssetList();
      if(assetList.length == 0) {
        return;
      }
      if(_self.currentIndex === assetList.length - 1) {
        if(wrap) {
          _self.currentIndex = 0;
        } else {
          return;
        }
      } else {
        _self.currentIndex = _self.currentIndex + 1;
      } 
      _self.setSelectedAndShow();
    };

    _self.setSelectedAndShow = function() {
      const assetList = _self.getAssetList();
      _self.selectedAsset = assetList[_self.currentIndex];
      _self.referenceAsset = _self.selectedAsset;
      _self.showAsset();
    };

    _self.close = function() {
      _self.changeAsset({ close: true });
    };

    _self.$onInit = function() {
      _self.showControls = true;
      _self.setSelectedAndShow();
    };

    _self.$onChanges = function(changes) {
      if (changes.metaJob) {
        _self.metaJob = angular.copy(_self.metaJob);
        //_self.assets = _self.metaJob.assetJobs;
        var uploadedViews = {};
        var expectedViews = {};

        if (_self.metaJob.expectedViews) {
          _self.metaJob.expectedViews.forEach(function(view) {
            expectedViews[view] = {
              viewCode: view,
              loaded: true
            };
          });
        }

        var expectedCropTypes = _self.metaJob.expectedCropTypes;

        _self.metaJob.assetJobs.forEach(function(assetJob) {
          assetJob.expectedCropTypes = expectedCropTypes;
          var referenceAsset = JobService.getReferenceAsset(assetJob);
          referenceAsset.expectedCropTypes = expectedCropTypes;

          uploadedViews[referenceAsset.viewCode] = {
            asset: referenceAsset,
            id: assetJob.id,
            viewCode: referenceAsset.viewCode,
            cropType: referenceAsset.cropType,
            cropTypes: assetJob.cropTypes,
            expectedCropTypes: _self.metaJob.expectedCropTypes,
            relatedAssets: assetJob.relatedAssets,
            status: assetJob.status
          };
        });
        _self.assets = angular.merge(expectedViews, uploadedViews);

        _self.flatAssets = [];
        for (var viewCode in _self.assets) {
          _self.flatAssets.push(_self.assets[viewCode]);
        }
        _self.assets = _self.flatAssets;
        _self.assetCount = _self.assets.length;
      }

      if (!!changes.cropType) {
        _self.selectedCropType = !!changes.cropType.currentValue ? changes.cropType.currentValue.cropType : null;
      }

      if (changes.assetJobId) {
        if (_self.metaJob && _self.assetJobId) {          
          _self.currentIndex = null;
          const assetList = _self.getAssetList();
          for (var x = 0; x < assetList.length; x++) {
            if (_self.currentIndex === null
              && assetList[x].assetJobId === changes.assetJobId.currentValue
              && (!_self.selectedCropType || !assetList[x].cropType || _self.selectedCropType === assetList[x].cropType)) {
              _self.currentIndex = x;
            }
          }
          if(_self.currentIndex === null) {
            _self.currentIndex = 0;
          }
          _self.setSelectedAndShow();
        }
      }
    };
  },
  templateUrl: 'templates/partials/detail/assetJobDetail.tpl.html'
};

module.exports = assetJobDetailComponent;
