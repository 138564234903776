'use strict';

var angular = require('angular');

var existingJobModalComponent = {
  bindings: {
    active: '<',
    product: '<',
    onComplete: '&'
  },
  require: {
    parent: '^initiateJob'
  },
  controller: /*@ngInject*/ function(
    $state,
    $interpolate,
    META_STATUS,
    APP_DATA
  ) {
    var _self = this;

    var setHeading = function(product, job, data, template) {
      var context = {
        styleColor: product.styleColor,
        status: job.status === META_STATUS.jobPending ? 'Pending' : job.status,
        type: job.jobType
      };
      data.title = $interpolate(template.title)(context);
      data.copy = $interpolate(template.copy)(context);
    };

    _self.model = {};

    _self.modal = {
      working: false,
      template: {
        heading: {
          title: '{{status}} job for {{styleColor}}',
          copy:
            'This product currently has an existing <b class="h-near-black">{{type}}</b> job with a status of <b class="h-near-black">{{status}}</b>.'
        }
      },
      data: {
        heading: {},
        cta: {
          primary: 'Next',
          secondary: 'Cancel'
        }
      }
    };

    _self.showJob = function(index) {
      _self.model.currentJobIndex = index || 0;
      setHeading(
        _self.model.product,
        _self.model.jobs[_self.model.currentJobIndex],
        _self.modal.data.heading,
        _self.modal.template.heading
      );
    };

    _self.showNextJob = function() {
      var nextIndex = _self.model.currentJobIndex + 1;

      if (nextIndex < _self.model.jobs.length) {
        _self.showJob(nextIndex);
      } else {
        _self.onComplete();
      }
    };

    _self.handleAssetClick = function($event) {
      $state.go('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: _self.model.product.productId,
        jobId: _self.model.jobs[_self.model.currentJobIndex].id,
        assetJobId: $event.assetJob.id
      });
    };

    _self.$onChanges = function(changes) {
      if (changes.product) {
        if (!_self.product) {
          return;
        }
        _self.model.product = angular.copy(_self.product);
      }

      if (changes.active) {
        if (!_self.active) {
          return;
        }
        _self.model.jobs = angular.copy(_self.parent.relatedJobs);
        _self.showJob();
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/existingJobModal.tpl.html'
};

module.exports = existingJobModalComponent;
