'use strict';

var angular = require('angular');

var assetDownloadResultsComponent = {
  bindings: {
    results: '<',
    loading: '<',
    searchFilters: '<',
    filteredText: '<',
    reset: '&',
    errorMsg: '='
  },
  controller: /*@ngInject*/ function(AssetSearchService, NotificationService) {
    var _self = this;
    _self.errors = [];
    _self.productCount = undefined;
    _self.assetCount = undefined;
    _self.errors = undefined;
    _self.errorCount = undefined;
    _self.downloadStarted = false;

    _self.downloading = false;

    _self.download = () => {
      _self.downloading = true;
      AssetSearchService.download(_self.searchFilters, _self.filteredText)
        .then(
          res => {
            NotificationService.push(
              'Assets are being prepared and should be available within 15 minutes in your FTP Bulk Downloads folder.'
            );
          },
          error => {
            _self.errorMsg =
              "There was a problem processing your download. Please try again and reach out to Icon Support if your download isn't successful.";
          }
        )
        .finally(() => {
          _self.downloading = false;
          _self.downloadStarted = true;
        });
    };

    _self.formReset = () => {
      _self.productCount = undefined;
      _self.assetCount = undefined;
      _self.errors = undefined;
      _self.errorCount = undefined;
      _self.errorMsg = '';
      _self.downloadStarted = false;

      _self.reset();
    };

    _self.$onChanges = function(changes) {
      if (changes.results && changes.results.currentValue) {
        if (_self.results === 500) {
          _self.errorMsg =
            "There was a problem processing your search. Please try again and reach out to Icon Support if your download isn't successful.";
          _self.errors = [];
          _self.productCount = undefined;
          _self.assetCount = undefined;
          _self.errors = undefined;
          _self.errorCount = undefined;
        } else {
          _self.errorMsg = '';
          _self.assetCount = _self.results.pages.totalResources || 0;
          _self.errors = errorCounter(_self.results, _self.filteredText) || [];
          _self.errorCount = _self.errors.length;
          _self.downloadStarted = false;

          if (_self.assetCount > 2000) {
            _self.errorMsg =
              'The maximum number of assets that can be downloaded at once is 2000, please reduce the number of products to continue.';
          }
        }
      }
    };

    let errorCounter = (results, styleColors) => {
      // check for SCs with no assets
      let facetList = results.facets.find(e => e.name === 'styleColor')
        .facetValues;
      _self.productCount = facetList.length || 0;
      facetList = facetList.map(cur => cur.value);
      return styleColors.filter(item => {
        return facetList.indexOf(item) === -1;
      });
    };
  },
  templateUrl: 'templates/partials/assetDownload/results.tpl.html'
};

module.exports = assetDownloadResultsComponent;
