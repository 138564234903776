'use strict';

var angular = require('angular');

var union = function(a, b) {
  if (!angular.isArray(a) || !a.length) {
    return b;
  } else if (!angular.isArray(b) || !b.length) {
    return a;
  }

  return a.concat(
    b.filter(function(item) {
      return a.indexOf(item) < 0;
    })
  );
};

module.exports = union;
