'use strict';

var angular = require('angular');

var ISSUER = {
  local : 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  dev   : 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  uat   : 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  prod  : 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7'
};

var OktaIssuer = /*@ngInject*/ (function() {
  return {
    getOktaIssuer: function () {
      var issuer;

      if (location.href.indexOf(':3000') > -1) {
        issuer = ISSUER.local;
      } else if (location.host.indexOf('gbipuidev01') > -1) {
        //DEV (in AWS)
        issuer = ISSUER.dev;
      } else if (location.host.indexOf('gbipuiuat') > -1) {
        //UAT
        issuer = ISSUER.uat;
      } else {
        //PROD
        issuer = ISSUER.prod;
      }
      return issuer;
    }
  };
})();

module.exports = OktaIssuer;
