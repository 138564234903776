'use strict';

/*jshint unused: false*/
var angular = require('angular');
var modal = require('../vendor/modal/_modal.module.js');
var iscroll = require('../vendor/iscroll/_iscroll.module.js');
var dropdown = require('../vendor/dropdown/_dropdown.module.js');
var accordion = require('../vendor/accordion/_accordion.module.js');
var qr = require('../vendor/qr/_qr.module.js');
var tooltip = require('../vendor/tooltip/_tooltip.module.js');
var infiniteScroll = require('../vendor/infiniteScroll/_infiniteScroll.module.js');
var keymage = require('../vendor/keymage/_keymage.module.js');
var fileReader = require('../vendor/fileReader/_fileReader.module.js');
var notification = require('../vendor/notification/_notification.module.js');
var httpSrc = require('../vendor/httpSrc/_httpSrc.module.js');

module.exports = angular.module('gbip', [
  require('angular-ui-router'),
  require('angular-cookies'),
  require('angular-sanitize'),
  require('angular-animate'),
  require('angular-local-storage'),
  modal(angular),
  dropdown(angular),
  accordion(angular),
  iscroll(angular),
  qr(angular),
  tooltip(angular),
  infiniteScroll(angular),
  keymage(angular),
  fileReader(angular),
  notification(angular),
  httpSrc(angular),
]);
