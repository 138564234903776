'use strict';

// http://www.codelord.net/2016/04/21/angular-performance-updating-bind-once-elements/
// "why not a component? because components have to have isolated scopes, which I don’t want in this case"

var RefresherDirective = function() {
  return {
    transclude: true,
    controller: /*@ngInject*/ function($scope, $transclude, $attrs, $element) {
      var childScope;

      $scope.$watch($attrs.condition, function() {
        $element.empty();
        if (childScope) {
          childScope.$destroy();
          childScope = null;
        }

        $transclude(function(clone, newScope) {
          childScope = newScope;
          $element.append(clone);
        });
      });
    }
  };
};

module.exports = RefresherDirective;
