'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function dropdownDirective() {
      return {
        controller: 'dropdownController',
        link: function(scope, element, attrs, dropdownCtrl) {
          dropdownCtrl.init();
        }
      };
    }
  ];
}

module.exports = register;
