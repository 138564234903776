'use strict';

var RouterTracker = /*@ngInject*/ function($rootScope, $state) {
  var routeHistory = [];
  var defaultState = 'search';

  function getRouteHistory() {
    return routeHistory;
  }

  function getPreviousState() {
    return routeHistory[routeHistory.length - 1];
  }

  function checkPreviousState(target) {
    var history = getPreviousState();
    return history.route && history.route.name === target;
  }

  function gotoPreviousState() {
    var state = getPreviousState();

    if (state.route.name) {
      $state.go(state.route.name, state.routeParams);
    } else {
      $state.go(defaultState);
    }
  }

  function init() {
    $rootScope.$on('$stateChangeSuccess', function(
      ev,
      to,
      toParams,
      from,
      fromParams
    ) {
      if (to.data.overlay && (from.data && from.data.overlay)) {
        return;
      }
      routeHistory.push({ route: from, routeParams: fromParams });
    });
  }

  return {
    getRouteHistory: getRouteHistory,
    gotoPreviousState: gotoPreviousState,
    getPreviousState: getPreviousState,
    checkPreviousState: checkPreviousState,
    init: init
  };
};

module.exports = RouterTracker;
