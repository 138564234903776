'use strict';

var moment = require('moment');
moment.updateLocale('en', {
  relativeTime: {
    future: '%s',
    past: '%s',
    s: '<1m',
    ss: '<1m',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy'
  }
});

var RESULTS_FILTER = {
  queue: {},
  orderingQueue: [
    {
      label: 'Style-Color',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      }
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Product ID',
      styling: {
        width: '80px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.productId',
      value: function(a) {
        return a.product.productId;
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-grow': '1'
      },
      stylingResults: {
        'flex-grow': '1',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      }
    },
    {
      label: 'Division',
      styling: {
        width: '55px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.division',
      value: function(a) {
        return a.product.division;
      }
    },
    {
      label: 'Category',
      styling: {
        width: '200px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.category',
      value: function(a) {
        return a.product.category;
      }
    },
    {
      label: 'Season',
      styling: {
        width: '42px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.currentSeason',
      value: function(a) {
        return a.product.currentSeason;
      }
    },
    {
      label: 'Subcategory',
      styling: {
        width: '180px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.subcategory',
      value: function(a) {
        return a.product.subcategory;
      }
    },
    {
      label: 'Age',
      styling: {
        width: '110px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.age',
      value: function(a) {
        return a.product.age;
      }
    },
    {
      label: 'Gender',
      styling: {
        width: '61px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.gender',
      value: function(a) {
        return a.product.gender;
      }
    },
    {
      label: 'League',
      styling: {
        width: '42px'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'product.league',
      value: function(a) {
        return a.product.league;
      }
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      displayType: 'limited',
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.product.confidential;
      },
      icon: 'lock'
    }
  ],
  outreachQueue: [
    {
      label: 'Style-Color',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      }
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Prod. ID',
      styling: {
        width: '80px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.productId',
      value: function(a) {
        return a.product.productId;
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-grow': '1',
        'flex-shrink': '1',
        'min-width': '150px'
      },
      stylingResults: {
        'flex-grow': '1',
        'flex-shrink': '1',
        'font-weight': 'bold',
        'white-space': 'wrap',
        'min-width': '150px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      }
    },
    {
      label: 'Color',
      styling: {
        'min-width': '122px',
        width: '8%'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      }
    },
    {
      label: 'Category',
      styling: {
        width: '90px'
      },
      stylingResults: {
        width: '90px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.category',
      value: function(a) {
        return a.product.category;
      }
    },
    {
      label: 'Division',
      styling: {
        width: '150px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.division',
      value: function(a) {
        return a.product.division;
      }
    },
    {
      label: 'Season',
      styling: {
        width: '42px',
        'border-right': '1px solid #8D8D8D',
        padding: '0 10px'
      },
      stylingResults: {
        width: '42px',
        'border-right': '1px solid #E5E5E5',
        padding: '0 10px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.currentSeason',
      value: function(a) {
        return a.product.currentSeason;
      }
    },
    {
      label: 'Job ID',
      styling: {
        'min-width': '128px',
        width: '8%',
        'padding-left': '10px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      }
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Merch Type',
      styling: {
        width: '5%',
        'min-width': '80px'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'merchType',
      value: function(a) {
        return a.merchType;
      }
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '4%',
        'min-width': '66px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      }
    },
    {
      label: 'Bus. Partner',
      styling: {
        width: '83px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'businessPartner',
      value: function(a) {
        return a.businessPartner;
      }
    },
    {
      label: 'Init PLM',
      styling: {
        width: '75px'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'product.initiatingPlm',
      value: function(a) {
        return a.product.initiatingPlm;
      }
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.confidential;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.post-prod.tray': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Age',
      styling: {
        width: '50px',
        'flex-grow': 1,
        'flex-shrink': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1,
        'flex-shrink': 1
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.age',
      value: function(a) {
        return a.product.age;
      },
      icon: ''
    },
    {
      label: 'Category',
      styling: {
        width: '74px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '74px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--category',
      resultClass: 'gbip-new-queue-job__section--category',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.category',
      value: function(a) {
        return a.product.category;
      },
      icon: ''
    },
    {
      label: 'Division',
      styling: {
        width: '105px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '105px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--division',
      resultClass: 'gbip-new-queue-job__section--division',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.division',
      value: function(a) {
        return a.product.division;
      },
      icon: ''
    },
    {
      label: 'Season',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--season',
      resultClass: 'gbip-new-queue-job__section--season',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.currentSeason',
      value: function(a) {
        return a.product.currentSeason;
      },
      icon: ''
    },
    {
      label: 'Product ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--productId gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--productId',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.productId',
      value: function(a) {
        return a.product.productId;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Assets',
      styling: {
        width: '80px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '80px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--assetStatus',
      resultClass: 'gbip-new-queue-job__section--assetStatus',
      displayType: 'full',
      sortable: false,
      refHeader: 'assetJobs',
      value: function(a) {
        var viewCodes = [];
        for (var x = 0; x < a.assetJobs.length; x++) {
          viewCodes.push(a.assetJobs[x].viewCode);
        }
        return viewCodes.join(', ');
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Business Partner',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--businessPartner gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--businessPartner',
      displayType: 'full',
      sortable: true,
      refHeader: 'businessPartner',
      value: function(a) {
        return a.businessPartner;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Rejected Assets',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'rejectionStatus',
      value: function(a) {
        return a.product.assets;
      },
      icon: 'refresh'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.post-prod.main': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Age',
      styling: {
        width: '50px',
        'flex-grow': 1,
        'flex-shrink': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1,
        'flex-shrink': 1
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.age',
      value: function(a) {
        return a.product.age;
      },
      icon: ''
    },
    {
      label: 'Category',
      styling: {
        width: '74px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '74px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--category',
      resultClass: 'gbip-new-queue-job__section--category',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.category',
      value: function(a) {
        return a.product.category;
      },
      icon: ''
    },
    {
      label: 'Division',
      styling: {
        width: '105px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '105px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--division',
      resultClass: 'gbip-new-queue-job__section--division',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.division',
      value: function(a) {
        return a.product.division;
      },
      icon: ''
    },
    {
      label: 'Season',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--season',
      resultClass: 'gbip-new-queue-job__section--season',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.currentSeason',
      value: function(a) {
        return a.product.currentSeason;
      },
      icon: ''
    },
    {
      label: 'Product ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--productId gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--productId',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.productId',
      value: function(a) {
        return a.product.productId;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Assets',
      styling: {
        width: '80px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '80px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--assetStatus',
      resultClass: 'gbip-new-queue-job__section--assetStatus',
      displayType: 'full',
      sortable: false,
      refHeader: 'assetJobs',
      value: function(a) {
        var viewCodes = [];
        for (var x = 0; x < a.assetJobs.length; x++) {
          viewCodes.push(a.assetJobs[x].viewCode);
        }
        return viewCodes.join(', ');
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Business Partner',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--businessPartner gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--businessPartner',
      displayType: 'full',
      sortable: true,
      refHeader: 'businessPartner',
      value: function(a) {
        return a.businessPartner;
      },
      icon: ''
    },
    {
      label: 'Assignee',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px',
        overflow: 'hidden'
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'assignees',
      value: function(a) {
        return a.tasks[0].assignee.name;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Rejected Assets',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'rejectionStatus',
      value: function(a) {
        return a.product.assets;
      },
      icon: 'refresh'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.problem-solve.tray': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Category',
      styling: {
        width: '74px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '74px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--category',
      resultClass: 'gbip-new-queue-job__section--category',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.category',
      value: function(a) {
        return a.product.category;
      },
      icon: ''
    },
    {
      label: 'Division',
      styling: {
        width: '105px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '105px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--division',
      resultClass: 'gbip-new-queue-job__section--division',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.division',
      value: function(a) {
        return a.product.division;
      },
      icon: ''
    },
    {
      label: 'Season',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--season',
      resultClass: 'gbip-new-queue-job__section--season',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.currentSeason',
      value: function(a) {
        return a.product.currentSeason;
      },
      icon: ''
    },
    {
      label: 'Product ID',
      styling: {
        width: '50px'
      },
      stylingResults: {
        width: '50px'
      },
      headerClass:
        'gbip-new-queue-job__section--productId gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--productId',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.productId',
      value: function(a) {
        return a.product.productId;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Assets',
      styling: {
        width: '80px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '80px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--assetStatus',
      resultClass: 'gbip-new-queue-job__section--assetStatus',
      displayType: 'full',
      sortable: false,
      refHeader: 'assetJobs',
      value: function(a) {
        var viewCodes = [];
        for (var x = 0; x < a.assetJobs.length; x++) {
          viewCodes.push(a.assetJobs[x].viewCode);
        }
        return viewCodes.join(', ');
      },
      icon: ''
    },
    {
      label: 'Merch Type',
      styling: {
        width: '5%',
        'min-width': '80px',
        'flex-grow': 1
      },
      displayType: 'full',
      sortable: false,
      refHeader: 'merchType',
      value: function(a) {
        return a.merchType;
      }
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Business Partner',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--businessPartner gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--businessPartner',
      displayType: 'full',
      sortable: true,
      refHeader: 'businessPartner',
      value: function(a) {
        return a.businessPartner;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio.studioName;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Rejected Assets',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'rejectionStatus',
      value: function(a) {
        return a.product.assets;
      },
      icon: 'refresh'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.problem-solve.main': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Category',
      styling: {
        width: '74px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '74px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--category',
      resultClass: 'gbip-new-queue-job__section--category',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.category',
      value: function(a) {
        return a.product.category;
      },
      icon: ''
    },
    {
      label: 'Division',
      styling: {
        width: '105px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '105px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--division',
      resultClass: 'gbip-new-queue-job__section--division',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.division',
      value: function(a) {
        return a.product.division;
      },
      icon: ''
    },
    {
      label: 'Season',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--season',
      resultClass: 'gbip-new-queue-job__section--season',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.currentSeason',
      value: function(a) {
        return a.product.currentSeason;
      },
      icon: ''
    },
    {
      label: 'Product ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--productId gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--productId',
      displayType: 'full',
      sortable: true,
      refHeader: 'product.productId',
      value: function(a) {
        return a.product.productId;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Assets',
      styling: {
        width: '80px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '80px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--assetStatus',
      resultClass: 'gbip-new-queue-job__section--assetStatus',
      displayType: 'full',
      sortable: false,
      refHeader: 'assetJobs',
      value: function(a) {
        var viewCodes = [];
        for (var x = 0; x < a.assetJobs.length; x++) {
          viewCodes.push(a.assetJobs[x].viewCode);
        }
        return viewCodes.join(', ');
      },
      icon: ''
    },
    {
      label: 'Merch Type',
      styling: {
        width: '5%',
        'min-width': '80px'
      },
      displayType: 'full',
      sortable: false,
      refHeader: 'merchType',
      value: function(a) {
        return a.merchType;
      }
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Business Partner',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--businessPartner gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--businessPartner',
      displayType: 'full',
      sortable: true,
      refHeader: 'businessPartner',
      value: function(a) {
        return a.businessPartner;
      },
      icon: ''
    },
    {
      label: 'Assignee',
      styling: {
        width: '80px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '80px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'assignees',
      value: function(a) {
        return a.tasks[0].assignee.name;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio.studioName;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Rejected Assets',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'rejectionStatus',
      value: function(a) {
        return a.product.assets;
      },
      icon: 'refresh'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'merch-management.views': [
    {
      label: 'Studio',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px'
      },
      headerClass: 'gbip-new-queue-job__section--studio',
      resultClass: 'gbip-new-queue-job__section--studio',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studioName',
      value: function(a) {
        return a._source.studioName;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: this.styling,
      headerClass: 'gbip-new-queue-job__section--vendor',
      resultClass: 'gbip-new-queue-job__section--vendor',
      displayType: 'limited',
      sortable: true,
      refHeader: 'vendorCode',
      value: function(a) {
        if (a._source.vendorCode) {
          return a._source.vendorCode.slice(0, 4).toUpperCase();
        }
      },
      icon: ''
    },
    {
      label: 'Product Record',
      styling: {
        width: '115px'
      },
      stylingResults: {
        fontWeight: 'bold',
        width: '115px'
      },
      headerClass: 'gbip-new-queue-job__section--product--record',
      resultClass: 'gbip-new-queue-job__section--product--record',
      displayType: 'limited',
      sortable: true,
      refHeader: 'productRecord',
      value: function(a) {
        return a._source.productRecord;
      },
      icon: ''
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        fontWeight: 'normal',
        'flex-grow': 1
      },
      stylingResults: {
        fontWeight: 'normal',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'styleName',
      value: function(a) {
        return a._source.styleName;
      },
      icon: ''
    },
    {
      label: 'Size',
      styling: {
        width: '50px',
        'padding-left': '15px'
      },
      stylingResults: {
        width: '50px',
        'font-weight': 'bold',
        'padding-left': '15px'
      },
      headerClass: 'gbip-new-queue-job__section--size',
      resultClass: 'gbip-new-queue-job__section--size',
      displayType: 'limited',
      sortable: true,
      refHeader: 'size',
      value: function(a) {
        return a._source.size;
      },
      icon: ''
    },
    {
      label: 'Merch Type',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px'
      },
      headerClass: 'gbip-new-queue-job__section--merch-type',
      resultClass: 'gbip-new-queue-job__section--merch-type',
      displayType: 'limited',
      sortable: true,
      refHeader: 'merchType',
      value: function(a) {
        return a._source.merchType;
      },
      icon: ''
    },
    {
      label: 'Season',
      styling: {
        width: '60px'
      },
      stylingResults: this.styling,
      headerClass: 'gbip-new-queue-job__section--initSeason',
      resultClass: 'gbip-new-queue-job__section--initSeason',
      displayType: 'limited',
      sortable: true,
      refHeader: 'initiatingSeason',
      value: function(a) {
        return a._source.initiatingSeason;
      },
      icon: ''
    },
    {
      label: 'Location',
      styling: {
        width: '120px'
      },
      stylingResults: {
        width: '120px',
        fontWeight: 'bold'
      },
      headerClass: 'gbip-new-queue-job__section--location',
      resultClass: 'gbip-new-queue-job__section--location',
      displayType: 'limited',
      sortable: true,
      refHeader: 'location',
      value: function(a) {
        return a._source.location;
      },
      icon: ''
    },
    {
      label: 'Merch ID',
      styling: {
        width: '60px'
      },
      stylingResults: {
        width: '60px'
      },
      headerClass: 'gbip-new-queue-job__section--merchId',
      resultClass: 'gbip-new-queue-job__section--merchId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'merchId',
      value: function(a) {
        return a._source.merchId;
      },
      icon: ''
    },
    {
      label: 'Primary Color',
      styling: {
        width: '105px'
      },
      stylingResults: {
        width: '105px'
      },
      headerClass: 'gbip-new-queue-job__section--primary-color',
      resultClass: 'gbip-new-queue-job__section--primary-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'primaryColor',
      value: function(a) {
        return a._source.primaryColor;
      },
      icon: ''
    },
    {
      label: 'Category',
      styling: {
        fontWeight: 'normal',
        'flex-shrink': 1,
        width: '105px',
        'flex-grow': 0.5
      },
      stylingResults: {
        fontWeight: 'normal',
        'flex-shrink': 1,
        width: '105px',
        'flex-grow': 0.5
      },
      headerClass: 'gbip-new-queue-job__section--category',
      resultClass: 'gbip-new-queue-job__section--category',
      displayType: 'limited',
      sortable: true,
      refHeader: 'category',
      value: function(a) {
        return a._source.category;
      },
      icon: ''
    },
    {
      label: 'Gender',
      styling: {
        width: '65px'
      },
      stylingResults: {
        width: '65px'
      },
      headerClass: 'gbip-new-queue-job__section--gender',
      resultClass: 'gbip-new-queue-job__section--gender',
      displayType: 'limited',
      sortable: true,
      refHeader: 'gender',
      value: function(a) {
        return a._source.gender;
      },
      icon: ''
    },
    {
      label: 'Age',
      styling: {
        width: '65px'
      },
      stylingResults: {
        width: '65px'
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'age',
      value: function(a) {
        return a._source.age;
      },
      icon: ''
    },
    {
      label: 'Last Updated',
      styling: {
        width: '105px',
        'white-space': 'normal'
      },
      stylingResults: {
        width: '105px'
      },
      headerClass: 'gbip-new-queue-job__section--last-updated',
      resultClass: 'gbip-new-queue-job__section--last-updated',
      displayType: 'limited',
      sortable: true,
      refHeader: 'lastUpdated',
      value: function(a) {
        return moment(a._source.lastUpdated).format('M/D/YYYY h:mmA');
      },
      icon: ''
    },
    {
      label: 'Shipment ID',
      styling: {
        width: '80px',
        'white-space': 'normal'
      },
      stylingResults: {
        width: '80px'
      },
      type: 'link',
      typeValue: function(a) {
        if (a._source.shipmentId) {
          return '#/merch-management/shipment/' + a._source.shipmentId;
        } else {
          return null;
        }
      },
      headerClass: 'gbip-new-queue-job__section--shipment-id',
      resultClass: 'gbip-new-queue-job__section--shipment-id',
      displayType: 'limited',
      sortable: false,
      refHeader: 'shipmentId',
      value: function(a) {
        return a._source.shipmentId || '-';
      },
      icon: ''
    }
  ],
  'merch-management.shipmentDetails': [
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: this.styling,
      headerClass: 'gbip-new-queue-job__section--vendor',
      resultClass: 'gbip-new-queue-job__section--vendor',
      displayType: 'limited',
      sortable: true,
      refHeader: 'vendorCode',
      value: function(a) {
        if (a._source.vendorCode) {
          return a._source.vendorCode.slice(0, 4).toUpperCase();
        }
      },
      icon: ''
    },
    {
      label: 'Product Record',
      styling: {
        fontWeight: 'bold',
        width: '115px'
      },
      stylingResults: {
        fontWeight: 'bold',
        width: '115px'
      },
      headerClass: 'gbip-new-queue-job__section--product--record',
      resultClass: 'gbip-new-queue-job__section--product--record',
      displayType: 'limited',
      sortable: true,
      refHeader: 'productRecord',
      value: function(a) {
        return a._source.productRecord;
      },
      icon: ''
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        fontWeight: 'normal',
        'flex-grow': 1
      },
      stylingResults: {
        fontWeight: 'normal',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'styleName',
      value: function(a) {
        return a._source.styleName;
      },
      icon: ''
    },
    {
      label: 'Size',
      styling: {
        width: '50px',
        'font-weight': 'normal',
        'padding-left': '15px'
      },
      stylingResults: this.styling,
      headerClass: 'gbip-new-queue-job__section--size',
      resultClass: 'gbip-new-queue-job__section--size',
      displayType: 'limited',
      sortable: true,
      refHeader: 'size',
      value: function(a) {
        return a._source.size;
      },
      icon: ''
    },
    {
      label: 'Merch Type',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px'
      },
      headerClass: 'gbip-new-queue-job__section--merch-type',
      resultClass: 'gbip-new-queue-job__section--merch-type',
      displayType: 'limited',
      sortable: true,
      refHeader: 'merchType',
      value: function(a) {
        return a._source.merchType;
      },
      icon: ''
    },
    {
      label: 'Season',
      styling: {
        width: '60px'
      },
      stylingResults: this.styling,
      headerClass: 'gbip-new-queue-job__section--initSeason',
      resultClass: 'gbip-new-queue-job__section--initSeason',
      displayType: 'limited',
      sortable: true,
      refHeader: 'initiatingSeason',
      value: function(a) {
        return a._source.initiatingSeason;
      },
      icon: ''
    },
    {
      label: 'Merch ID',
      styling: {
        width: '60px'
      },
      stylingResults: {
        width: '60px'
      },
      headerClass: 'gbip-new-queue-job__section--merchId',
      resultClass: 'gbip-new-queue-job__section--merchId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'merchId',
      value: function(a) {
        return a._source.merchId;
      },
      icon: ''
    },
    {
      label: 'Primary Color',
      styling: {
        width: '105px'
      },
      stylingResults: {
        width: '105px',
        'font-weight': 'bold'
      },
      headerClass: 'gbip-new-queue-job__section--primary-color',
      resultClass: 'gbip-new-queue-job__section--primary-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'primaryColor',
      value: function(a) {
        return a._source.primaryColor;
      },
      icon: ''
    },
    {
      label: 'Category',
      styling: {
        fontWeight: 'normal',
        width: '175px'
      },
      stylingResults: {
        fontWeight: 'normal',
        width: '175px'
      },
      headerClass: 'gbip-new-queue-job__section--category',
      resultClass: 'gbip-new-queue-job__section--category',
      displayType: 'limited',
      sortable: true,
      refHeader: 'category',
      value: function(a) {
        return a._source.category;
      },
      icon: ''
    },
    {
      label: 'Gender',
      styling: {
        width: '65px'
      },
      stylingResults: {
        width: '65px'
      },
      headerClass: 'gbip-new-queue-job__section--gender',
      resultClass: 'gbip-new-queue-job__section--gender',
      displayType: 'limited',
      sortable: true,
      refHeader: 'gender',
      value: function(a) {
        return a._source.gender;
      },
      icon: ''
    },
    {
      label: 'Age',
      styling: {
        width: '65px'
      },
      stylingResults: {
        width: '65px'
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'age',
      value: function(a) {
        return a._source.age;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '105px',
        'white-space': 'normal'
      },
      stylingResults: {
        width: '105px'
      },
      headerClass: 'gbip-new-queue-job__section--last-updated',
      resultClass: 'gbip-new-queue-job__section--last-updated',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studioName',
      value: function(a) {
        return a._source.studioName;
      },
      icon: ''
    }
  ],
  'merch-management.ship': [
    {
      label: 'Shipment ID',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--shipment-id',
      resultClas: 'gbip-merch-mng-ship--shipment-id',
      displayType: 'limited',
      sortable: false,
      refHeader: 'shipmentId',
      value: function(a) {
        return a.shipmentId || '-';
      }
    },
    {
      label: 'Shipment Nickname',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%',
        fontWeight: 'bold'
      },
      headerClass: 'gbip-merch-mng-ship--shipment-nickname',
      resultClas: 'gbip-merch-mng-ship--shipment-nickname',
      displayType: 'limited',
      sortable: false,
      refHeader: 'nickName',
      value: function(a) {
        return a.nickName || '-';
      }
    },
    {
      label: 'Shipment Type',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--shipment-type',
      resultClas: 'gbip-merch-mng-ship--shipment-type',
      displayType: 'limited',
      sortable: false,
      refHeader: 'shipmentType',
      value: function(a) {
        return a.shipmentType || '-';
      }
    },
    {
      label: 'Prepared By',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--prepared-by',
      resultClas: 'gbip-merch-mng-ship--prepared-by',
      displayType: 'limited',
      sortable: false,
      refHeader: 'preparedBy',
      value: function(a) {
        return a.preparedBy || '-';
      }
    },
    {
      label: 'From',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--from',
      resultClas: 'gbip-merch-mng-ship--from',
      displayType: 'limited',
      sortable: false,
      refHeader: 'from',
      value: function(a) {
        return a.from || '-';
      }
    },
    {
      label: 'Recipient',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--recipient',
      resultClas: 'gbip-merch-mng-ship--recipient',
      displayType: 'limited',
      sortable: false,
      refHeader: 'recipient',
      value: function(a) {
        return a.recipient || '-';
      }
    },
    {
      label: 'Destination',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%',
        fontWeight: 'bold'
      },
      headerClass: 'gbip-merch-mng-ship--destination',
      resultClas: 'gbip-merch-mng-ship--destination',
      displayType: 'limited',
      sortable: false,
      refHeader: 'destination',
      value: function(a) {
        return a.destination || '-';
      }
    },
    {
      label: 'Tracking Number',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--tracking-number',
      resultClas: 'gbip-merch-mng-ship--tracking-number',
      displayType: 'limited',
      sortable: false,
      refHeader: 'trackingNumber',
      value: function(a) {
        return a.trackingNumber || '-';
      }
    },
    {
      label: 'Shipped On',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--shipped-on',
      resultClas: 'gbip-merch-mng-ship--shipped-on',
      displayType: 'limited',
      sortable: false,
      refHeader: 'shippedOn',
      value: function(a) {
        return a.shippedOn
          ? moment(a.shippedOn).format('M/D/YYYY h:mmA') || '-'
          : '';
      }
    },
    {
      label: 'Qty',
      styling: {
        width: '10%'
      },
      stylingResults: {
        width: '10%'
      },
      headerClass: 'gbip-merch-mng-ship--qty',
      resultClas: 'gbip-merch-mng-ship--qty',
      displayType: 'limited',
      sortable: false,
      refHeader: 'quantity',
      value: function(a) {
        return a.merchCount || 0;
      }
    }
  ],
  'merch-management.packing-slip': [
    {
      label: 'Product Record',
      styling: {},
      stylingResults: { fontWeight: 'bold', width: '16%' },
      headerClass: 'gbip-merch-mng-packing-slip--product-record',
      resultClass: 'gbip-merch-mng-packing-slip--product-record',
      displayType: 'limited',
      sortable: false,
      refHeader: 'productRecord',
      value: function(a) {
        return a._source.productRecord || '';
      }
    },
    {
      label: 'Style Name',
      styling: {},
      stylingResults: { width: '22%' },
      headerClass: 'gbip-merch-mng-packing-slip--style-name',
      resultClass: 'gbip-merch-mng-packing-slip--style-name',
      displayType: 'limited',
      sortable: false,
      refHeader: 'styleName',
      value: function(a) {
        return a._source.styleName || '';
      }
    },
    {
      label: 'Size',
      styling: {},
      stylingResults: { fontWeight: 'bold', width: '5%' },
      headerClass: 'gbip-merch-mng-packing-slip--size',
      resultClass: 'gbip-merch-mng-packing-slip--size',
      displayType: 'limited',
      sortable: false,
      refHeader: 'size',
      value: function(a) {
        return a._source.size || '';
      }
    },
    {
      label: 'Merch Type',
      styling: {},
      stylingResults: { width: '16%' },
      headerClass: 'gbip-merch-mng-packing-slip--merch-type',
      resultClass: 'gbip-merch-mng-packing-slip--merch-type',
      displayType: 'limited',
      sortable: false,
      refHeader: 'merchType',
      value: function(a) {
        return a._source.merchType || '';
      }
    },
    {
      label: 'Merch ID',
      styling: {},
      stylingResults: { width: '16%' },
      headerClass: 'gbip-merch-mng-packing-slip--merchId',
      resultClass: 'gbip-merch-mng-packing-slip--merchId',
      displayType: 'limited',
      sortable: false,
      refHeader: 'merchId',
      value: function(a) {
        return a._source.merchId || '';
      }
    },
    {
      label: 'Primary Color',
      styling: {},
      stylingResults: { width: '21%' },
      headerClass: 'gbip-merch-mng-packing-slip--primary-color',
      resultClass: 'gbip-merch-mng-packing-slip--primary-color',
      displayType: 'limited',
      sortable: false,
      refHeader: 'primaryColor',
      value: function(a) {
        return a._source.primaryColor || '';
      }
    }
  ],
  'queue.qa.tray': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Rejected Assets',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'rejectionStatus',
      value: function(a) {
        return a.product.assets;
      },
      icon: 'refresh'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.qa.main': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Assignee',
      styling: {
        width: '80px'
      },
      stylingResults: {
        width: '80px',
        overflow: 'hidden'
      },
      headerClass: 'gbip-new-queue-job__section--age',
      resultClass: 'gbip-new-queue-job__section--age',
      displayType: 'limited',
      sortable: true,
      refHeader: 'assignees',
      value: function(a) {
        return a.tasks[0].assignee.name;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Rejected Assets',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'rejectionStatus',
      value: function(a) {
        return a.product.assets;
      },
      icon: 'refresh'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.merch-putaway.tray': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio.studioName;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.merch-putaway.main': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: false,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: false,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: false,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: false,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: false,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: false,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType ? a.shootType.code : '';
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio.studioName;
      },
      icon: ''
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: false,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes ? a.expectedCropTypes.length > 1 : false;
      },
      icon: 'clone'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: false,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.merch-prep.tray': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio.studioName;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.merch-prep.main': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType.code;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio.studioName;
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.inbound.tray': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType ? a.shootType.code : '';
      },
      icon: ''
    },
    {
      label: 'Business Partner',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--businessPartner gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--businessPartner',
      displayType: 'limited',
      sortable: true,
      refHeader: 'businessPartner',
      value: function(a) {
        return a.businessPartner;
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio ? a.studio.studioName : '';
      },
      icon: ''
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ],
  'queue.inbound.main': [
    {
      label: 'Style-Color',
      styling: {
        width: '75px'
      },
      stylingResults: {
        width: '75px'
      },
      headerClass: 'gbip-new-queue-job__section--style-color',
      resultClass: 'gbip-new-queue-job__section--style-color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleColor',
      value: function(a) {
        return a.product.styleColor;
      },
      icon: ''
    },
    {
      label: 'Vendor',
      styling: {
        width: '40px'
      },
      stylingResults: {
        width: '40px',
        'font-weight': 'bold'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.vendorCode',
      value: function(a) {
        if (a.product.vendorCode) {
          return a.product.vendorCode.substring(0, 4).toUpperCase();
        }
      }
    },
    {
      label: 'Style Name',
      styling: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      stylingResults: {
        'flex-shrink': 1,
        'flex-grow': 2
      },
      headerClass: 'gbip-new-queue-job__section--name',
      resultClass: 'gbip-new-queue-job__section--name',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.styleName',
      value: function(a) {
        return a.product.styleName;
      },
      icon: ''
    },
    {
      label: 'Color',
      styling: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-shrink': 1,
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--color',
      resultClass: 'gbip-new-queue-job__section--color',
      displayType: 'limited',
      sortable: true,
      refHeader: 'product.color',
      value: function(a) {
        return a.product.color;
      },
      icon: ''
    },
    {
      label: 'Job Type',
      styling: {
        width: '15px',
        padding: '0px 18px 0px 7px'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'jobType',
      value: function(a) {
        return a.jobType;
      },
      icon: 'standard-job'
    },
    {
      label: 'Job ID',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'id',
      value: function(a) {
        return a.id;
      },
      icon: ''
    },
    {
      label: 'Shoot Type',
      styling: {
        width: '45px',
        'flex-grow': 1,
        'white-space': 'normal'
      },
      stylingResults: {
        width: '45px',
        'flex-grow': 1
      },
      headerClass:
        'gbip-new-queue-job__section--shootType gbip-new-queue-job__section--wrap',
      resultClass: 'gbip-new-queue-job__section--shootType',
      displayType: 'limited',
      sortable: true,
      refHeader: 'shootType.code',
      value: function(a) {
        return a.shootType ? a.shootType.code : '';
      },
      icon: ''
    },
    {
      label: 'Studio',
      styling: {
        width: '50px',
        'flex-grow': 1
      },
      stylingResults: {
        width: '50px',
        'flex-grow': 1
      },
      headerClass: 'gbip-new-queue-job__section--jobId',
      resultClass: 'gbip-new-queue-job__section--jobId',
      displayType: 'limited',
      sortable: true,
      refHeader: 'studio.studioName',
      value: function(a) {
        return a.studio ? a.studio.studioName : '';
      },
      icon: ''
    },
    {
      label: 'Time',
      styling: {
        width: '25px'
      },
      stylingResults: {
        width: '25px'
      },
      displayType: 'limited',
      sortable: true,
      refHeader: 'tasks.startedOn',
      value: function(a) {
        return moment(a.tasks[0].startedOn).fromNow(true);
      },
      icon: 'clock'
    },
    {
      label: 'Confidential',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'confidential',
      value: function(a) {
        return a.value;
      },
      icon: 'lock'
    },
    {
      label: 'Crop Types',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      displayType: 'limited',
      sortable: false,
      refHeader: 'crop-type',
      value: function(a) {
        return a.expectedCropTypes.length > 1;
      },
      icon: 'clone'
    },
    {
      label: 'Priority',
      styling: {
        width: '15px'
      },
      stylingResults: {
        width: '15px',
        fill: 'red'
      },
      headerClass: 'gbip-new-queue-job__section--icon',
      resultClass: 'gbip-new-queue-job__section--icon',
      displayType: 'limited',
      sortable: true,
      refHeader: 'priority',
      value: function(a) {
        return a.priority;
      },
      icon: 'flag'
    }
  ]
};

module.exports = RESULTS_FILTER;
