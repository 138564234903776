'use strict';

var moment = require('moment-timezone');

var openAssets = function() {
  return function(jobs) {
    var filtered = [];
    angular.forEach(jobs, function(e) {
      if (e.status !== 'Closed') {
        filtered.push(e);
      }
    });
    return filtered;
  };
};

module.exports = openAssets;
