'use strict';

var angular = require('angular');
var appendTransform = require('../vendor/appendTransform');

var historyComponent = {
  bindings: {
    model: '<',
    types: '<',
    assetId: '<',
    onSelection: '&'
  },
  controller: /*@ngInject*/ function(
    $http,
    $interpolate,
    $state,
    $window,
    JobService,
    TransferService,
    AuthService,
    HistoryService,
    USER_ROLES,
    USER_ROLES_LABEL,
    REST_URL,
    HISTORY,
    NotificationService
  ) {
    var _self = this;

    _self.downloadImage = function(fileName) {
      TransferService.downloadAssets(fileName);
    };

    _self.viewAsset = function(asset, annotation) {
      _self.onSelection({ $event: { asset: asset, annotation: annotation } });
    };

    _self.republished = '';
    _self.showButton = true;
    _self.retryWorking = false;
    _self.isJobArchived = false;

    _self.retryPublish = function() {
      _self.retryWorking = true;
      $http
        .post(
          [
            REST_URL.jobs,
            _self.model.id,
            _self.model.tasks[0].id,
            'complete'
          ].join('/'),
          {},
          JobService.transformJobsConfig
        )
        .then(
          function(res) {
            _self.republished = true;
            NotificationService.push('Attempting to republish job {{id}}', {
              id: _self.model.id
            });
          },
          function(res) {
            _self.republished = false;
          }
        )
        .finally(function() {
          _self.retryWorking = false;
        });
    };


    _self.filterHistory = function(historyPromise) {
      if (!historyPromise) {
        return;
      }
      historyPromise.then(function(response) {
        // get events that match types and (optionally) view code
        _self.events = response.data.events.filter(function(event) {
          // Events should be an accepted type or the UpdateProductProperties event.
          // Also the UpdateProductProperties should only display if they were triggered after the metajob started
          if (
            (_self.types.indexOf(event.type) === -1 &&
              event.action !== 'UpdateProductProperties') ||
            (event.action === 'UpdateProductProperties' &&
              _self.model.createdOn > event.createdOn)
          ) {
            return false;
          }
          return _self.assetId ? event.objectId === _self.assetId : true;
        });

        _self.events.forEach(function(event) {
          var uiTemplate = HISTORY[event.action] || HISTORY['Default'];

          event.ui = angular.copy(uiTemplate);
          event.ui.description = $interpolate(event.ui.description)(event);

          event.ui.asset = event.payload;

          if (event.ui.asset.thumbnails || event.ui.asset.downloadUrl) {
            event.ui.thumbnail = JobService.getImageByType(
              event.payload,
              'all'
            );
          }

          if (
            [
              'AssetAnnotated',
              'RejectAsset',
              'SendAssetToProductReview',
              'ReturnAssetToImageQA'
            ].indexOf(event.action) !== -1
          ) {
            JobService.fetchAnnotation(event.payload).then(function(response) {
              event.ui.annotation = response.data;
            });
          }

          var priorities = ['Null', 'Priority', 'Rush'];
          // Converting property names to title names for display. i.e. "businessPartner" to "Business Partner"
          if(event.action === 'UpdateMetaJobProperties') {
            for(let propName in event.payload) {
              //source doesn't have before/after fields so it will break if it tries to look
              if (propName.toLowerCase() != "source") {
                event.payload[propName].title = propName.replace(/([A-Z])/, " $1").replace(/(^.)/, function(v) { return v.toUpperCase(); });
                // Need to convert the priority integer to something readable
                if(propName.toLowerCase() === 'priority') {
                  if(typeof(event.payload[propName].before) === "number") {
                    event.payload[propName].before = priorities[event.payload[propName].before];
                  }
                  if(typeof(event.payload[propName].after) === "number") {
                    event.payload[propName].after = priorities[event.payload[propName].after];
                  }
                }
                // Shoot Type is an object, so just restrict it down to its code
                if(propName.toLowerCase() === 'shoottype') {
                  if(!!event.payload[propName].before.code) {
                    event.payload[propName].before = event.payload[propName].before.code;
                  }
                  if(!!event.payload[propName].after.code) {
                    event.payload[propName].after = event.payload[propName].after.code;
                  }
                }
                if(propName.toLowerCase() === 'studio') {
                  if(!!event.payload[propName].before.studioName) {
                    event.payload[propName].before = event.payload[propName].before.studioName;
                  }
                  if(!!event.payload[propName].after.studioName) {
                    event.payload[propName].after = event.payload[propName].after.studioName;
                  }
                }
              }
            }
          }

          if (
            event.createdBy &&
            event.createdBy.username &&
            !event.createdBy.firstName &&
            !event.createdBy.lastName &&
            !event.createdBy.roles
          ) {
            event.ui.description +=
              $interpolate(
                ' {{payload.cropType? "(" + payload.cropType + ")": ""}} {{ui.separator || "by"}} {{createdBy.username}}'
              )(event);
          } else if (event.createdBy && event.createdBy.username !== 'system') {
            event.createdBy.name = [
              event.createdBy.firstName,
              event.createdBy.lastName
            ].join(' ');
            event.createdBy.roles.some(function(role) {
              if (USER_ROLES_LABEL[role]) {
                event.createdBy.roleLabel = USER_ROLES_LABEL[role];
                return true;
              }
            });
            event.ui.description +=
              $interpolate(
                ' {{payload.cropType? "(" + payload.cropType + ")": ""}} {{ui.separator || "by"}} {{createdBy.name}} ({{createdBy.roleLabel}})'
              )(event);

            if(event.action === 'ClaimMetaJob' && 'firstName' in event.payload) {
              if (USER_ROLES_LABEL[event.payload.role]) {
                event.payload.roleLabel = USER_ROLES_LABEL[event.payload.role];
              } else {
                event.payload.roleLabel = event.payload.role;
              }

              event.ui.description +=
                $interpolate(
                  ' to {{payload.firstName}} {{payload.lastName}} ({{payload.roleLabel}})'
                )(event);
            }

          }

          //Add message indicating source of change, if availables
          if (event.payload.hasOwnProperty('source') && event.payload['source'] !== null) {
            event.ui.description +=
              $interpolate(
                ' via {{payload.source}}'
              )(event);
          }

          if (event.ui.sections) {
            event.ui.sections.forEach(function(section) {
              section.value = $interpolate(section.value)(event);
            });
          }
        });
      });
    };

    _self.viewProduct = function() {
      var url = $state.href('detail', {
        productIdentifierType: 'by-product-id',
        productIdentifier: _self.model.product.productId,
        jobId: null,
        tab: 'deliveries'
      });
      $window.open(url, '_blank');
    };

    _self.$onInit = function() {
      _self.canDownloadAsset = AuthService.isAuthorized([
        USER_ROLES.studioOps,
        USER_ROLES.digiTech,
        USER_ROLES.imageEditor,
        USER_ROLES.superUser,
        USER_ROLES.qaReviewer,
        USER_ROLES.photographer
      ]);
      if (_self.model && !_self.model.jobArchive) {
        _self.filterHistory(HistoryService.getHistoryData(_self.model));
      }
    };

    _self.$onChanges = function(changes) {

      if (changes.model) {
        _self.model = angular.copy(_self.model);
        _self.isJobArchived = !!(_self.model && _self.model.jobArchive);
        if(!_self.isJobArchived) {
          _self.filterHistory(HistoryService.getHistoryData(_self.model));
        }
      }

      if(!_self.isJobArchived) {
        if (changes.types) {
          _self.types = angular.copy(_self.types);
          _self.filterHistory(HistoryService.getHistoryData(_self.model));
        }

        if (changes.assetId) {
          _self.assetId = angular.copy(_self.assetId);
          _self.filterHistory(HistoryService.getHistoryData(_self.model));
        }
      }
    };

    _self.$onDestroy = function() {
      if (HistoryService.historyPromise) {
        delete HistoryService.historyPromise;
      }
    };
  },
  templateUrl: 'templates/partials/detail/history.tpl.html'
};

module.exports = historyComponent;
