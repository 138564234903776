'use strict';

var angular = require('angular');

var bulkJobUpdateComponent = {
  bindings: {},
  controller: /*@ngInject*/ function(
    REST_URL,
    NotificationService,
    TransferService,
    $scope
  ) {
    var _self = this;

    _self.data = {
      uploadResponse: function(response) {
        if (response.data && response.data.successes.length) {
          NotificationService.push(
            '{{count}} Jobs successfully created/updated.',
            {
              count: response.data.successes.length
            }
          );
        }
        $scope.$emit('adminResponse', response.data);
      },
      uploadError: function(response) {
        $scope.$emit('adminResponse', {
          failures: [response.data.error]
        });
      },
      upload: function($event) {
        TransferService.uploadFile($event.file, REST_URL.upload.bulkJob)
          .then(this.uploadResponse)
          .catch(this.uploadError);
      },
      downloadTemplate: TransferService.downloadBulkJobsTemplate
    };
  },
  templateUrl: 'templates/partials/admin/bulkJobUpdate.tpl.html'
};

module.exports = bulkJobUpdateComponent;
