'use strict';

var angular = require('angular');

var studioModalComponent = {
  bindings: {
    active: '=',
    model: '<',
    type: '=',
    onSubmit: '&'
  },
  controller: /*@ngInject*/ function(
    $http,
    $interpolate,
    NotificationService,
    REST_URL
  ) {
    var _self = this;

    _self.setHeading = function(model, data, template) {
      var context = {
        type: !model || !model.id ? 'Add' : 'Edit'
      };
      data.title = $interpolate(template.title)(context);
    };

    _self.modal = {
      working: false,
      template: {
        heading: {
          title: '{{type}} Studio Location'
        }
      },
      data: {
        heading: {},
        cta: {
          primary: 'Submit',
          secondary: 'Cancel'
        }
      }
    };

    _self.formData = {
      studioName: {
        label: 'Studio Name',
        required: true
      },
      firstName: {
        label: 'First Name',
        required: true
      },
      lastName: {
        label: 'Last Name',
        required: true
      },
      email: {
        label: 'Email',
        required: true
      },
      address1: {
        label: 'Address 1',
        required: true
      },
      address2: {
        label: 'Address 2',
        required: false
      },
      city: {
        label: 'City',
        required: true
      },
      state: {
        label: 'State',
        options: [
          'Alabama',
          'Alaska',
          'Arizona',
          'Arkansas',
          'California',
          'Colorado',
          'Connecticut',
          'Delaware',
          'District of Columbia',
          'Florida',
          'Georgia',
          'Hawaii',
          'Idaho',
          'Illinois',
          'Indiana',
          'Iowa',
          'Kansas',
          'Kentucky',
          'Louisiana',
          'Maine',
          'Maryland',
          'Massachusetts',
          'Michigan',
          'Minnesota',
          'Mississippi',
          'Missouri',
          'Montana',
          'Nebraska',
          'Nevada',
          'New Hampshire',
          'New Jersey',
          'New Mexico',
          'New York',
          'North Carolina',
          'North Dakota',
          'Ohio',
          'Oklahoma',
          'Oregon',
          'Pennsylvania',
          'Rhode Island',
          'South Carolina',
          'South Dakota',
          'Tennessee',
          'Texas',
          'Utah',
          'Vermont',
          'Virginia',
          'Washington',
          'West Virginia',
          'Wisconsin',
          'Wyoming'
        ],
        required: true
      },
      zipCode: {
        label: 'Zip',
        required: true
      },
      ftpHost: {
        label: 'Host',
        required: false
      },
      ftpPort: {
        label: 'Port',
        required: false
      },
      studioType: {
        required: true,
        value: 'Internal'
      }
    };

    _self.setStudioType = function() {
      _self.formData.studioType.value = _self.formData.ftpHost.value
        ? 'External'
        : 'Internal';
    };

    _self.onFtpDataChange = function() {
      _self.setStudioType();
      _self.validate();
    };

    _self.validate = function() {
      var isValid = true;
      _self.error = {};

      angular.forEach(_self.formData, function(item) {
        if (item.required && !item.value) {
          isValid = false;
        }
      });

      _self.isValid = isValid;
    };

    _self.resetData = function(studioData) {
      angular.forEach(_self.formData, function(item, key) {
        item.value =
          studioData && studioData[key] ? studioData[key] : undefined;
      });

      _self.setStudioType();
      _self.validate();
    };

    _self.submitStudio = function() {
      var action,
        apiData = {},
        apiRequest;

      angular.forEach(_self.formData, function(item, key) {
        apiData[key] = item.value;
      });

      if (apiData.ftpPort) {
        apiData.ftpPort = parseInt(apiData.ftpPort, 10);
      }

      if (_self.model.id) {
        apiRequest = $http
          .put([REST_URL.base, 'studios', _self.model.id].join('/'), apiData)
          .then(_self.submitStudioPutResponse)
          .catch(_self.submitStudioCatch)
          .finally(_self.submitStudioFinally);
      } else {
        apiRequest = $http
          .post([REST_URL.base, 'studios'].join('/'), apiData)
          .then(_self.submitStudioPostResponse)
          .catch(_self.submitStudioCatch)
          .finally(_self.submitStudioFinally);
      }

      _self.modal.working = true;
    };

    _self.submitStudioPutResponse = function(response) {
      NotificationService.push(
        '"{{studio.studioName}}" was successfully updated',
        { studio: response.data }
      );
      _self.onSubmit({ $event: { studio: response.data } });
    };

    _self.submitStudioPostResponse = function(response) {
      NotificationService.push(
        '"{{studio.studioName}}" was successfully created',
        { studio: response.data }
      );
      _self.onSubmit({ $event: { studio: response.data } });
    };

    _self.submitStudioCatch = function(response) {
      _self.error.title = response.data.error;
    };

    _self.submitStudioFinally = function() {
      _self.modal.working = false;
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        _self.model = angular.copy(_self.model);

        _self.setHeading(
          _self.model,
          _self.modal.data.heading,
          _self.modal.template.heading
        );
        _self.resetData(_self.model);
      }
    };
  },
  templateUrl: 'templates/partials/admin/studio.modal.tpl.html'
};

module.exports = studioModalComponent;
