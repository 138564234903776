'use strict';

var angular = require('angular');

var newResults = {
  templateUrl: 'templates/partials/queue/resultsNew.tpl.html',
  bindings: {
    model: '<', // array of jobs
    sort: '<',
    jobParams: '<',
    showRejected: '<',
    showDownload: '<', // show the download column
    displayType: '<', // compact, limited, full
    onSortChange: '&',
    constant: '=',
    showSort: '<'
  },
  controller: /*@ngInject*/ function(
    AuthService,
    RESULTS_CONSTANT,
    SearchService
  ) {
    var _self = this;

    _self.working = true;
    _self.sort.reverse = true;

    _self.isAdmin = AuthService.isAdmin();

    _self.handleSorting = function(predicate, sortable) {
      if (sortable === false) {
        return;
      }
      _self.sort.reverse =
        _self.sort.predicate !== predicate ? false : !_self.sort.reverse;
      _self.sort.predicate = predicate;
      SearchService.setSorting(_self.sort.predicate, _self.sort.reverse);
      _self.onSortChange({ $event: _self.sort });
      _self.working = true;
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);
        _self.working = false;
      }

      if (changes.sort) {
        if (!_self.sort) {
          return;
        }
        _self.sort = angular.copy(_self.sort);
      }
    };
  }
};

module.exports = newResults;
