'use strict';

var tooltipDirective = require('./tooltip.directive.js');
var tooltipProvider = require('./tooltip.provider.js');

function register(angular) {
  var app = angular.module('tooltip', ['ng']);

  app.directive('tooltip', tooltipDirective(angular));
  app.provider('$$tooltip', tooltipProvider(angular));
  app.run([
    '$templateCache',
    function($templateCache) {
      $templateCache.put(
        'templates/tooltip.tpl.html',
        '<div ng-class="[options.class, options.class+\'--\'+options.position, {\'is-active\': active}]" ng-bind-html="label | trust"></div>'
      );
    }
  ]);

  return 'tooltip';
}

module.exports = register;
