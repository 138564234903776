'use strict';

var angular = require('angular');

var BatchService = /*@ngInject*/ function($http, $q) {
  var _self = this;

  var callSingleUrlBatch = function(method, url, dataArray, transformer) {
    var promise = $q.defer(),
      httpPromise,
      httpConfig = transformer ? transformer : {};

    // I'm using $http.get/put/post instead of $http() to allow more flexible testing (ex: spyOn($http, 'GET'))
    switch (method.toUpperCase()) {
      case 'GET':
        httpPromise = $http.get(url, dataArray, httpConfig);
        break;
      case 'PUT':
        httpPromise = $http.put(url, dataArray, httpConfig);
        break;
      case 'POST':
        httpPromise = $http.post(url, dataArray, httpConfig);
        break;
    }

    httpPromise.then(
      function(response) {
        promise.resolve(response);
      },
      function(error) {
        promise.reject(error);
      }
    );

    return promise.promise;
  };

  var callSingleUrlBatchProcessAsync = function(
    method,
    url,
    dataArray,
    itemsPerCallSize,
    transformer,
    parentPromise
  ) {
    var status = {};
    var promises = [];

    while (dataArray && dataArray.length > 0) {
      var batchData = dataArray.splice(0, itemsPerCallSize);
      promises.push(callSingleUrlBatch(method, url, batchData, transformer));
    }

    $q.all(promises).then(function(res) {
      status.successes = res.filter(function(r) {
        if (r.status <= 399) {
          return r;
        }
      });
      status.failures = res.filter(function(r) {
        if (r.status >= 400) {
          return r;
        }
      });
      parentPromise.resolve(concatenateSuccessAndFailures(status));
    });
  };

  var callSingleUrlBatchProcessSync = function(
    method,
    url,
    dataArray,
    itemsPerCallSize,
    transformer,
    parentPromise,
    status
  ) {
    var batchData = dataArray.splice(0, itemsPerCallSize);

    var responseCallback = function(response) {
      if (response.status <= 399) {
        status.successes.push(response);
      } else {
        status.failures.push(response);
      }

      // If we have more data, call this function recursively
      if (dataArray && dataArray.length > 0) {
        callSingleUrlBatchProcessSync(
          method,
          url,
          dataArray,
          itemsPerCallSize,
          transformer,
          parentPromise,
          status
        );
      } else {
        // TODO: When we want the successes and failures broken out, uncomment this line below and remove the line below it
        //parentPromise.resolve(status);
        parentPromise.resolve(concatenateSuccessAndFailures(status));
      }
    };
    callSingleUrlBatch(method, url, batchData, transformer).then(
      responseCallback,
      responseCallback
    );
  };

  // TODO: We can remove this once we can handle the successes and failures properly
  var concatenateSuccessAndFailures = function(status) {
    var responses = [];
    responses =
      status.successes.length > 0
        ? [].concat.apply(responses, status.successes)
        : responses;
    responses =
      status.failures.length > 0
        ? [].concat.apply(responses, status.failures)
        : responses;

    return responses;
  };

  var callSingleUrlBatchProcess = function(
    method,
    url,
    dataArray,
    itemsPerCallSize,
    isAsync,
    transformer
  ) {
    // For the singleUrl/payloadArray calls, we want to make sure there's data or just pass back a resolved promise
    if (!dataArray || dataArray.length === 0) {
      return $q.when(true);
    } else {
      // Break the reference so we don't effect the dataArray outside of the BatchService
      dataArray = angular.copy(dataArray);
    }
    var parentPromise = $q.defer();

    if (isAsync) {
      callSingleUrlBatchProcessAsync(
        method,
        url,
        dataArray,
        itemsPerCallSize,
        transformer,
        parentPromise
      );
    } else {
      callSingleUrlBatchProcessSync(
        method,
        url,
        dataArray,
        itemsPerCallSize,
        transformer,
        parentPromise,
        { successes: [], failures: [] }
      );
    }

    return parentPromise.promise;
  };

  _self.batchGET = function(
    url,
    queryParamArray,
    itemsPerCallSize,
    isAsync,
    transformer
  ) {
    return callSingleUrlBatchProcess(
      'GET',
      url,
      queryParamArray,
      itemsPerCallSize,
      isAsync,
      transformer
    );
  };

  _self.batchPOST = function(
    url,
    payloadDataArray,
    itemsPerCallSize,
    isAsync,
    transformer
  ) {
    return callSingleUrlBatchProcess(
      'POST',
      url,
      payloadDataArray,
      itemsPerCallSize,
      isAsync,
      transformer
    );
  };

  _self.batchPUT = function(
    url,
    payloadDataArray,
    itemsPerCallSize,
    isAsync,
    transformer
  ) {
    return callSingleUrlBatchProcess(
      'PUT',
      url,
      payloadDataArray,
      itemsPerCallSize,
      isAsync,
      transformer
    );
  };
};

module.exports = BatchService;
