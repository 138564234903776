'use strict';

/*jshint unused: false*/
var loadingComponent = {
  scope: {},
  bindings: {
    size: '@'
  },
  controller: /*@ngInject*/ function() {
    var _self = this;

    _self.$onInit = function() {
      _self.styles = {
        container:
          'top: calc(50% - ' +
          _self.size / 2 +
          'px);left: calc(50% - ' +
          _self.size / 2 +
          'px);',
        bubble: 'width: ' + _self.size + 'px; height: ' + _self.size + 'px;'
      };
    };
  },
  templateUrl: 'templates/partials/ui/loading.tpl.html'
};

module.exports = loadingComponent;
