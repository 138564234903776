'use strict';

var angular = require('angular');

var initiateJobComponent = {
  bindings: {
    active: '<',
    model: '<',
    onChange: '&',
    release: '='
  },
  controller: /*@ngInject*/ function(
    $rootScope,
    $scope,
    $state,
    $timeout,
    $http,
    JobService,
    JobTypeService,
    StatusService,
    META_STATUS,
    REST_URL,
    APP_DATA
  ) {
    var _self = this;

    _self.fetchJobs = function(productIdentifierType, productIdentifier) {
      return $http.get(
        [
          REST_URL.base,
          'products',
          productIdentifierType,
          productIdentifier,
          'jobs'
        ].join('/'),
        JobService.transformJobsConfig
      );
    };

    _self.startJobReview = function() {
      _self.showExistingJobModal = true;
      _self.showInitiateJobModal = false;
    };

    _self.startJobInitiation = function() {
      $timeout(function() {
        _self.showExistingJobModal = false;
        _self.showInitiateJobModal = true;
      }, 0);
    };

    _self.route = function(jobs) {
      var needsReview = false;
      if (!jobs) {
        return;
      }

      // Show existing job modal if the selected product has an asset-only job, or an in progress job of any type
      jobs.some(function(job) {
        needsReview =
          job.id &&
          (JobTypeService.isAssetOnly(job) ||
            job.status !== META_STATUS.jobPending);
        return needsReview;
      });

      if (needsReview && $state.current.name === 'queue.inbound') {
        _self.startJobReview();
      } else {
        _self.startJobInitiation();
      }
    };

    _self.cancel = function() {
      _self.onChange({ $event: { active: false } });
    };

    _self.onJobUpdate = function($event) {
      _self.newJob = $event.job;
      _self.pendingAssetOnlyJobs = _self.relatedJobs.filter(function(job) {
        return (
          job.id &&
          (JobTypeService.isAssetOnly(job) &&
            job.status === META_STATUS.jobPending)
        );
      });

      // If we converted a duplicate to a different job type, AND
      // If there are any pending asset-only jobs,
      // ask user if they want to close them
      if ($event.action === 'resolveJob' && _self.pendingAssetOnlyJobs.length) {
        _self.showPostConversionJobModal = true;
      }

      // Update parent no matter what;
      // keep the initiate job modal active if we're showing the post conversion job modal
      _self.onChange({
        $event: { active: _self.showPostConversionJobModal, job: $event.job }
      });
    };

    _self.closeAssetOnlyJobs = function() {
      _self.working = true;
      _self.showPostConversionJobModal = false;

      StatusService.updateJobs(_self.pendingAssetOnlyJobs, {
        type: 'tasks',
        properties: {
          finish: 'close',
          newJobId: _self.newJob.id
        }
      })
        .then(function() {
          _self.onChange({ $event: { active: false, job: _self.newJob } });
        })
        .finally(function() {
          _self.stopWorking();
        });
    };

    _self.stopWorking = function() {
      $timeout(function() {
        _self.working = false;
      });
    };

    _self.finishReview = function() {
      _self.startJobInitiation();
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);
        if (_self.model.id) {
          const primaryMerchId = _self.model.primaryMerchId;
          JobService.getJobDetail(_self.model.id, {}).then(function(response) {
            _self.model = response.data;
            if (!!primaryMerchId) {
              _self.model.primaryMerchId = primaryMerchId;
            }
          });
        }
      }

      if (changes.active) {
        $scope.$broadcast('initiateJob.active', { status: _self.active });
        if (_self.active) {
          _self.working = true;

          _self
            .fetchJobs(
              JobService.getProductIdentifierType(_self.model),
              JobService.getProductIdentifier(_self.model)
            )
            .then(function(response) {
              _self.relatedJobs = response.data;
              _self.route(_self.relatedJobs);
            })
            .catch(function(response) {
              $rootScope.$broadcast('error', { error: response.data.error });
            })
            .finally(function() {
              _self.stopWorking();
            });
        } else {
          _self.showExistingJobModal = false;
          _self.showInitiateJobModal = false;
          _self.showPostConversionJobModal = false;
        }
      }
    };
  },
  templateUrl: 'templates/partials/initiateJob/initiateJob.tpl.html'
};

module.exports = initiateJobComponent;
