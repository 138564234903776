'use strict';

var AnimationService = /*@ngInject*/ function($q, $timeout, $animate) {
  var _self = this;

  _self.init = function() {
    _self.expandAnimationClass = 'is-animating';
    _self.isOpenClass = 'is-open';
    _self.animation = {};
  };

  _self.expand = function(elem, showFull) {
    var animationClass = _self.expandAnimationClass,
      fromHeight,
      toHeight,
      naturalHeight,
      deferred = $q.defer();

    elem.classList.add(animationClass);
    naturalHeight = elem.getBoundingClientRect().height;
    elem.classList.remove(animationClass);
    if (_self.animation[elem]) {
      $animate.cancel(_self.animation[elem]);
    }
    if (showFull) {
      fromHeight = 0;
      toHeight = naturalHeight;
    } else {
      fromHeight = naturalHeight;
      toHeight = 0;
      elem.classList.remove(_self.isOpenClass);
    }
    _self.animation[elem] = $animate
      .animate(
        elem,
        { height: fromHeight + 'px' },
        { height: toHeight + 'px' },
        animationClass
      )
      .then(function() {
        elem.style.height = null;
        if (showFull) {
          elem.classList.add(_self.isOpenClass);
        }
        deferred.resolve();
      });
    return deferred.promise;
  };

  _self.init();
};

module.exports = AnimationService;
