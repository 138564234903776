'use strict';

var angular = require('angular');
var oktaService = require('../services/okta.service.js');

var SearchService = /*@ngInject*/ function(
  $http,
  $q,
  $stateParams,
  JobService,
  AuthService,
  REST_URL,
  SEARCH_CONSTANTS
) {
  var _self = this,
    facetPromise,
    searchPromise;

  _self.fetchSearchResults = function(params, type, canceller) {
    var deferred = $q.defer(),
      _url = REST_URL.search,
      _defaults = {
        term: '*',
        page: 0,
        size: 50,
        sort: ''
      };

    params = angular.forEach(params, function(value, key) {
      if (!value) {
        delete params[key];
      }
    });

    params = angular.merge(_defaults, params);

    if (type) {
      _url = [_url, type].join('/');
    }

    $http({
      method: 'post',
      url: _url,
      data: params,
      timeout: canceller
    })
      .then(function(response) {
        response.data.results.forEach(function(job) {
          if (job['@class'] === 'Product') {
            job['product'] = angular.copy(job);
            for (var i in job) {
              if (i !== 'product') {
                delete job[i];
              }
            }
            job['@class'] = 'MetaJob';
          }
          JobService.setExtraJobData(job);
        });
        deferred.resolve(response);
      })
      .catch(deferred.reject);
    return deferred.promise;
  };

  _self.transformFacets = function(facetsFromServer) {
    var filteredFacets, transformedFacets;

    // remove facets that aren't listed or don't have options
    // remove confidential facet if user doesn't have rights to see it
    filteredFacets = facetsFromServer.filter(function(facet) {
      var listedFacet =
          facet.title !== 'Confidential' &&
          SEARCH_CONSTANTS.facetOrder.indexOf(facet.name) >= 0 &&
          facet.facetValues.length > 0,
        confidential = AuthService.isAdmin() && facet.title === 'Confidential';

      return confidential || listedFacet;
    });

    // sort facets according to pre-determined listing
    filteredFacets.sort(function(a, b) {
      if (
        SEARCH_CONSTANTS.facetOrder.indexOf(a.name) === -1 ||
        SEARCH_CONSTANTS.facetOrder.indexOf(b.name) === -1
      ) {
        return 1;
      }
      return (
        SEARCH_CONSTANTS.facetOrder.indexOf(a.name) -
        SEARCH_CONSTANTS.facetOrder.indexOf(b.name)
      );
    });

    transformedFacets = filteredFacets.map(function(facet) {
      var facetGroup = {
        name: facet.title,
        id: facet.name,
        options: []
      };

      facet.facetValues.forEach(function(facetValue) {
        switch (facetGroup.name) {
          case 'Confidential':
            facetValue.label =
              SEARCH_CONSTANTS.confidentialLabel[facetValue.label];
            break;
          case 'Priority':
            facetValue.label = SEARCH_CONSTANTS.priorityLabel[facetValue.label];
            break;
        }

        facetGroup.options.push(facetValue);
      });

      return facetGroup;
    });

    return transformedFacets;
  };

  var fetchFacetList = function() {
    var deferred = $q.defer();

    $http
      .get(REST_URL.facets)
      .then(function(response) {
        if (response.data) {
          _self.data.filters = _self.transformFacets(response.data);
          deferred.resolve(_self.data.filters);
        } else {
          deferred.reject();
        }
      })
      .catch(deferred.reject);

    return deferred.promise;
  };

  _self.setSorting = function(predicate, reverse) {
    _self.data.sort = {
      predicate: predicate,
      reverse: reverse
    };
  };

  _self.getSortingString = function(predicate, reverse) {
    //Search controller calls this method without arguments, check if the data is in the data object
    predicate = predicate === undefined ? _self.data.sort.predicate : predicate;
    reverse = reverse === undefined ? _self.data.sort.reverse : reverse;

    if (predicate || reverse) {
      //assignee used to sort by email on the backend, doing this to force sorting by last, first
      if (predicate === 'assignees') {
        var order = reverse ? 'desc' : 'asc';
        return 'lastName|' + order + ',firstName|' + order;
      } else if (predicate === 'rejectionStatus') {
        predicate = 'rejectionStatus.keyword';
      }
      return [predicate, reverse ? 'desc' : 'asc'].join('|');
    } else {
      if (!_self.data.sort) {
        return '';
      }

      return [
        _self.data.sort.predicate,
        _self.data.sort.reverse ? 'desc' : 'asc'
      ].join('|');
    }
  };

  _self.setFilters = function(filters) {
    filters = filters || [];
    _self.data.filters = filters;
  };

  _self.getFacetString = function() {
    var metaList = [];

    if (_self.data.filters) {
      _self.data.filters.forEach(function(filter) {
        var filterList = [];

        filter.options.forEach(function(option) {
          if (option.selected) {
            if (filter.id === 'confidential') {
              if (option.value === '0') {
                filterList.push(false);
              } else if (option.value === '1') {
                filterList.push(true);
              }
            } else {
              filterList.push(option.value);
            }
          }
        });

        if (filterList.length) {
          metaList.push([filter.id, filterList.join('|')].join('='));
        }
      });
    }

    return metaList.join(',');
  };

  var init = function() {
    _self.data = {};
    _self.data.term = '*';
  };

  _self.getFacets = function() {
    if (!facetPromise || facetPromise.$$state.status === 1) {
      facetPromise = fetchFacetList();
    }

    return facetPromise;
  };

  _self.removeAssineesFilter = function(filters) {
    var assigneesKey = 'assignees=';
    var indexOfAssignees = filters.indexOf(assigneesKey);
    var firstEntry;
    if (indexOfAssignees > 0) {
      //not first entry, remove preceeding comma
      indexOfAssignees--;
    } else {
      firstEntry = true;
    }
    var updatedFilters = filters.substring(0, indexOfAssignees);
    for (
      var i = indexOfAssignees + assigneesKey.length;
      i < filters.length;
      i++
    ) {
      //if not the last entry, add remaining filters
      if (filters[i] === ',') {
        if (firstEntry) {
          //remove next comma
          i++;
        }
        updatedFilters += filters.substring(i, filters.length);
        break;
      }
    }
    return updatedFilters;
  };

  _self.search = function(params, type, canceller) {
    var searchPromise;
    if (AuthService.canSearchAllJobs(params.filters)) {
      searchPromise = _self.fetchSearchResults(params, type, canceller);
    } else {
      params.filters = _self.removeAssineesFilter(params.filters);
      searchPromise = _self.fetchSearchResults(params, 'myjobs', canceller);
    }
    return searchPromise;
  };

  /**********************************************
   * url: 'string'
   * params: {
   *  term: 'string'
   *  sort: [ {field:'fieldName', order:'[asc|desc]'}, ... ]
   *  page: integer
   * }
   * *******************************************/
  _self.searchElastic = function(url, params) {
    var deferred = $q.defer();
    var fields = [];

    fields.push(`q=${params.predicate}:(${params.term || ''})`);
    if (params.sort) {
      var sort = params.sort
        .map(function(s) {
          return 'sort=' + s.field + ':' + (s.order || 'asc');
        })
        .join('&');
      fields.push(sort);
    }
    var size = params['size'];
    if (params['size']) {
      fields.push('size=' + size);
    }
    if (params['from']) {
      fields.push('from=' + (params.from || 0));
    }
    if (params['page']) {
      fields.push('page=' + (params.page || 0));
    }

    $http
      .get(url + '?' + fields.join('&'))
      .then(function(response) {
        if (response) {
          deferred.resolve(response);
        } else {
          deferred.reject();
        }
      })
      .catch(deferred.reject);

    return deferred.promise;
  };

  _self.searchMerchShipments = function(params) {
    var deferred = $q.defer();
    var url = REST_URL.merchShipments;
    if (params.status != undefined) {
      url += '?status=' + params.status;
      if (params.shippedOn != undefined) {
        url += '&shippedOn=' + params.shippedOn;
      }
    } else if (params.shipmentId != undefined) {
      url += '/' + params.shipmentId;
    }
    $http
      .get(url)
      .then(function(response) {
        if (response) {
          deferred.resolve(response);
        } else {
          deferred.reject();
        }
      })
      .catch(deferred.reject);

    return deferred.promise;
  };

  /**********************************************
   * term: 'string'
   * *******************************************/
  _self.syncSingle = function(term) {
    var deferred = $q.defer();
    if (!term) {
      term = $stateParams.productIdentifier;
    }

    $http
      .get([REST_URL.base, 'cpspub', 'adhoc?q=' + term].join('/'))
      .then(function(response) {
        if (response.data) {
          deferred.resolve(_self.data);
        } else {
          deferred.reject();
        }
      })
      .catch(deferred.reject);

    return deferred.promise;
  };

  _self.findProduct = function(
    transform,
    productIdentifierType,
    productIdentifier
  ) {
    var productIdentifier = $stateParams.productIdentifier || productIdentifier;
    var productIdentifierType =
      $stateParams.productIdentifierType || productIdentifierType;

    return $http.get(
      [
        REST_URL.base,
        'products',
        productIdentifierType,
        productIdentifier,
        'details'
      ].join('/'),
      transform
    );
  };

  init();
};

module.exports = SearchService;
