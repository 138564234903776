'use strict';

/*jshint unused: false*/
var staticInclude = /*@ngInject*/ function() {
  return {
    restrict: 'AE',
    templateUrl: function(elem, attrs) {
      return attrs.staticInclude || attrs.src;
    }
  };
};

module.exports = staticInclude;
