'use strict';

/*jshint unused: false*/
var ConfidentialComponent = {
  bindings: {
    releaseDate: '=',
    confidential: '='
  },
  controller: /*@ngInject*/ function() {
    var moment = require('moment');
    var _self = this;

    _self.getLabelText = function() {
      var releaseDate = _self.releaseDate;
      var confidential = _self.confidential;
      var labelText = '';
      if (releaseDate && confidential) {
        labelText =
          'Confidential + Release(' + moment(releaseDate).format('l') + ')';
      } else if (releaseDate) {
        labelText = 'Release(' + moment(releaseDate).format('l') + ')';
      } else if (confidential) {
        labelText = 'Confidential';
      }
      return labelText;
    };

    _self.confidentialLabel = _self.getLabelText();

    _self.$onChanges = function() {
      if (_self.releaseDate || _self.confidential) {
        _self.confidentialLabel = _self.getLabelText();
      }
    };
  },
  templateUrl: 'templates/partials/ui/confidential.tpl.html'
};

module.exports = ConfidentialComponent;
