'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function accorionHeaderDirective() {
      return {
        restrict: 'A',
        require: '?^accordionGroup',
        link: function(scope, element, attrs, accordionGroupCtrl) {
          if (!accordionGroupCtrl) {
            return;
          }

          element.addClass('accordion-header');

          var toggleDropdown = function(event) {
            event.preventDefault();

            if (!element.hasClass('disabled') && !attrs.disabled) {
              scope.$apply(function() {
                accordionGroupCtrl.toggle();
              });
            }
          };

          element.bind('click', toggleDropdown);

          element.attr('tabindex', '0');

          accordionGroupCtrl.headerElement = element;

          // WAI-ARIA
          element.attr({ 'aria-haspopup': true, 'aria-expanded': false });

          scope.$watch(accordionGroupCtrl.isOpen, function(isOpen) {
            element.attr('aria-expanded', !!isOpen);
          });

          scope.$on('$destroy', function() {
            element.unbind('click', toggleDropdown);
          });
        }
      };
    }
  ];
}

module.exports = register;
