'use strict';

var angular = require('angular');
var moment = require('moment-timezone');

/*jshint unused: false*/
var datepickerComponent = {
  restrict: 'E',
  bindings: {
    model: '=',
    default: '<'
  },
  controller: /*@ngInject*/ function($rootScope) {
    var _self = this;
    _self.timezoneOffset = '';
    _self.timeout = null;

    _self.selectOption = function(option) {
      clearTimeout(_self.timeout);
      if (option !== undefined) {
        _self.model = option[_self.optionKey] || option;
      }
      //Adding the timeout to prevent auto-formatting of date while typing
      _self.timeout = setTimeout(function() {
        _self.updateModel();
        _self.timeout = null;
      }, 500);
    };

    _self.updateTimezoneOffset = function() {
      _self.timezoneOffset = moment
        .tz(_self.model, _self.timezone)
        .format('Z')
        .replace(':', '');
      _self.updateModel();
    };

    _self.updateModel = function() {
      var time = moment
        .tz(_self.model, _self.timezone)
        .format('YYYY-MM-DD HH:mm:00Z');
      if (time !== 'Invalid date') {
        _self.model = new Date(time);
      }
    };

    _self.init = function() {
      _self.timezones = moment.tz.names();

      for (var i = 0; i < _self.timezones.length; i++) {
        if (_self.timezones[i] === moment.tz.guess()) {
          _self.timezone = _self.timezones[i];
          _self.updateTimezoneOffset();
          break;
        }
      }
    };

    _self.init();
  },
  templateUrl: 'templates/partials/ui/datepicker.tpl.html'
};

module.exports = datepickerComponent;
