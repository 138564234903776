'use strict';

var keymage = require('keymage');

/*jshint unused: false*/
function register(angular) {
  return [
    '$document',
    function KeymageService($document) {
      var defaultScope = '$$keymage';

      var disable = function() {
        keymage.setScope('disable');
      };

      var enable = function() {
        keymage.setScope(defaultScope);
      };

      var getScope = function() {
        return keymage.getScope();
      };

      var getDefaultScope = function() {
        return defaultScope;
      };

      var toggle = function(boolean) {
        if (!!boolean) {
          enable();
        } else {
          disable();
        }
      };

      var escapeDisabledArea = function(e) {
        $document[0].activeElement.blur();
      };

      var pushScope = function(scope, name) {
        keymage.pushScope(name);

        scope.$on('$destroy', function() {
          keymage.popScope(name);
        });
      };

      var add = function(bindings) {
        bindings.forEach(function(binding) {
          keymage(defaultScope, binding.shortcut, binding.fn, binding.options);
        });
      };

      var remove = function(bindings) {
        bindings.forEach(function(binding) {
          keymage.unbind(
            defaultScope,
            binding.shortcut,
            binding.fn,
            binding.options
          );
        });
      };

      var set = function(scope, bindings) {
        add(bindings);

        scope.$on('$destroy', function() {
          remove(bindings);
        });
      };

      var init = function() {
        keymage('disable', 'esc', escapeDisabledArea);
        keymage.pushScope(defaultScope);
      };

      init();

      return {
        // public methods
        disable: disable,
        enable: enable,
        toggle: toggle,
        set: set,
        getScope: getScope,
        getDefaultScope: getDefaultScope
      };
    }
  ];
}

module.exports = register;
