'use strict';

var REDIRECT = require('./redirect.constant.js');

var requestWasUnAuthorized = function(response) {
  return (
    response.status === 401 ||
    response.status === 419 ||
    response.status === 440
  );
};

var handleError = function(response) {
  if (requestWasUnAuthorized(response)) {
    REDIRECT.login();
  }
};

module.exports = {
  requestWasUnAuthorized: requestWasUnAuthorized,
  handleError: handleError
};
