'use strict';

/*jshint unused: false*/
function register(angular) {
  return {
    bindings: {
      active: '<'
    },
    controller: [
      '$scope',
      '$compile',
      '$element',
      '$timeout',
      '$animate',
      '$$notifications',
      function(
        $scope,
        $compile,
        $element,
        $timeout,
        $animate,
        $$notifications
      ) {
        var _self = this,
          notificationScopes = [];

        var notificationFactory = function(data) {
          var scope = angular.extend($scope.$new(true), data);
          var linkFn = $compile($$notifications.template());
          var content = linkFn(scope);

          notificationScopes.push(scope);
          _self.container.append(content);

          $animate.addClass(content, _self.options.classIn).then(function() {
            scope.timeout = $timeout(function() {
              $animate
                .addClass(content, _self.options.classOut)
                .then(function() {
                  scope.$destroy();
                });
            }, _self.options.delay);
          });

          if (_self.options.pauseOnHover) {
            content.on('mouseenter', function() {
              scope.$apply(function() {
                $timeout.cancel(scope.timeout);
              });
            });

            content.on('mouseleave', function() {
              scope.timeout = $timeout(function() {
                $animate
                  .addClass(content, _self.options.classOut)
                  .then(function() {
                    scope.$destroy();
                  });
              }, _self.options.delay);
            });
          }

          content.find('button').on('click', function() {
            scope.$apply(function() {
              $animate
                .addClass(content, _self.options.classOut)
                .then(function() {
                  scope.$destroy();
                });
            });
          });

          scope.$on('$destroy', function() {
            content.remove();
          });
        };

        _self.$onInit = function() {
          _self.options = $$notifications.options();
          _self.container = angular.element($$notifications.container());
          $element.append(_self.container);

          $scope.$on(_self.options.baseClass, function(e, d) {
            if (_self.active) {
              notificationFactory(d);
            }
          });
        };

        _self.$onDestroy = function() {
          notificationScopes.forEach(function(scope) {
            scope.$destroy();
          });
        };
      }
    ]
  };
}

module.exports = register;
