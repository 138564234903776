'use strict';

/*jshint unused: false*/
var slateDirective = /*@ngInject*/ function($state, NoteService) {
  return {
    restrict: 'E',
    replace: true,
    require: '?^^slateModal',
    scope: {},
    bindToController: {
      job: '='
    },
    controllerAs: 'slate',
    controller: 'SlateController as ctrl',
    templateUrl: 'templates/partials/slate/slate.tpl.html',
    link: function(scope, elem, attrs, ctrl, x) {
      scope.$watch(
        function() {
          scope.slate.job.alert = false;
          if (
            scope.slate.job.priority > 0 ||
            scope.slate.job.jobType == 'Reshoot'
          ) {
            scope.slate.job.alert = true;
          }

          var priorities = ['', 'Priority', 'Rush'];
          scope.slate.job.priorityLabel = priorities[scope.slate.job.priority];

          return scope.slate.job;
        },
        scope.slate.init,
        true
      );
    }
  };
};

module.exports = slateDirective;
