'use strict';

var angular = require('angular');

var productSyncComponent = {
  controller: /*@ngInject*/ function(
    $http,
    REST_URL,
    $timeout,
    NotificationService,
    TransferService
  ) {
    var _self = this;
    _self.requests = [];
    _self.requestsPending = false;
    _self.showUploadFeedback = false;
    _self.requestType = 'THIRD_PARTY';

    _self.getRequests = function() {
      $http
        .get([REST_URL.base, 'productSync', 'bulk'].join('/'))
        .then(_self.updateRequests)
        .catch(function(error) {
          console.log(error);
        })
        .finally(function() {});
    };

    _self.updateRequests = function(response) {
      _self.requestsPending = false;
      response.data.forEach(request => {
        if (request.status === 'PENDING') {
          _self.requestsPending = true;
        }
        var existingItem = _self.requests.filter(
          item => item.id == request.id
        )[0];
        if (existingItem) {
          existingItem.status = request.status;
          existingItem.completedCount = request.completedCount;
          existingItem.errorCount = request.errorCount;
        } else {
          request.csvFile = [
            REST_URL.base,
            'productSync',
            'bulk',
            request.id
          ].join('/');
          _self.requests.push(request);
        }
      });
      _self.requests.forEach(function(request, idx, object) {
        var newItem = response.data.filter(item => item.id == request.id)[0];
        if (!newItem) {
          request.deleted = true;
        }
      });
      if (_self.requestsPending) {
        $timeout(_self.getRequests, 10000);
      }
    };

    _self.deleteRequest = function(id) {
      $http
        .delete([REST_URL.base, 'productSync', 'bulk', id].join('/'))
        .then(function(response) {
          _self.getRequests();
        })
        .catch(function(error) {
          _self.getRequests();
        });
    };

    _self.upload = {
      url: [REST_URL.base, 'productSync', 'bulk?type='].join('/'),
      uploadResponse: function(response) {
        if (
          response.data &&
          response.data.rowsCount &&
          response.data.rowsCount > 0
        ) {
          _self.showUploadFeedback = true;
          if (response.data) {
            _self.uploadFeedback = {
              success: 'Uploaded file was processed successfully.',
              error: ''
            };
          } else {
            _self.uploadFeedback = {
              success: '',
              error:
                'There was an error. No requests were imported, please try again or contact support if this continues.'
            };
          }
        }
        $timeout(_self.getRequests, 500);
      },
      uploadError: function(response) {
        if (response) {
          _self.showUploadFeedback = true;
          var msg, rows;
          if (response.data.data) {
            msg =
              'There were ' +
              response.data.data.length +
              ' errors. No requests were imported, please correct all errors and re-upload the file.';
            rows = response.data.data;
          } else {
            msg = 'There was an error. ' + response.data.error;
          }
          _self.uploadFeedback = {
            success: '',
            error: msg,
            rows: rows
          };
        }
      },
      upload: function($event) {
        _self.showUploadFeedback = false;
        TransferService.uploadFile(
          $event.file,
          _self.upload.url + _self.requestType
        )
          .then(this.uploadResponse)
          .catch(this.uploadError);
      }
    };

    _self.downloadBulkProductSync = TransferService.downloadBulkProductSync;

    _self.init = function() {
      _self.getRequests();
    };

    _self.init();
  },
  templateUrl: 'templates/partials/admin/productSync.tpl.html'
};

module.exports = productSyncComponent;
