'use strict';

var angular = require('angular');

var slateModalComponent = {
  bindings: {
    active: '=',
    jobs: '<',
    onChange: '&'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $timeout,
    $window,
    $state,
    USER_ROLES,
    NoteService,
    KeymageService,
    AuthService,
    META_STATUS,
    APP_DATA
  ) {
    var _self = this;

    _self.slateNotes = [];
    _self.displayJobs = [];
    _self.showNotePanel = false;
    _self.note = '';
    _self.validNote = true;

    _self.toggleNotePanel = function(value) {
      var show = value || !_self.showNotePanel;
      _self.showNotePanel = show;
      _self.tempNote = NoteService.convertLineBreaks(_self.note, false);
    };

    _self.onNoteTextChange = function($event) {
      _self.validNote = $event.valid;
      _self.tempNote = $event.text;
    };

    _self.updateNote = function() {
      _self.note = NoteService.convertLineBreaks(_self.tempNote, true);
      _self.working = true;

      NoteService.addNote('slate', _self.note, _self.displayJobs[0].id)
        .then(function(response) {
          _self.onChange({ $event: { job: response.data } });
          // TODO is this needed once above method works correctly?
          if (angular.isArray(_self.jobs)) {
            _self.jobs[0].notes = response.data.notes;
          } else {
            _self.jobs.notes = response.data.notes;
          }
          _self.setJobData();
        })
        .finally(function() {
          _self.working = false;
          _self.toggleNotePanel(false);
        });
    };

    _self.getSlateNote = function() {
      _self.slateNotes = NoteService.getNotes(
        _self.displayJobs[0],
        'SlateNote'
      );

      if (_self.slateNotes.length) {
        _self.note = _self.slateNotes[0].content;
      }
    };

    _self.closeSlateModal = function(print) {
      var jobIds = _self.displayJobs.map(function(job) {
        return job.id;
      });

      if (print) {
        $window.open(
          $state.href(
            'slates',
            {
              ids: jobIds.join()
            },
            { absolute: true }
          ),
          '_blank'
        );
      }

      _self.active = false;
    };

    _self.setJobData = function() {
      if (angular.isArray(_self.jobs)) {
        _self.displayJobs = _self.jobs;
      } else {
        _self.displayJobs = [_self.jobs];
      }

      if (_self.displayJobs.length === 1) {
        _self.getSlateNote();
      }
    };

    _self.keybindFilter = function(event) {
      if (!_self.active) {
        return;
      }
      $timeout(function() {
        if (event.which === 13) {
          // return
          if (!_self.showNotePanel) {
            _self.closeSlateModal(true);
          }
        } else if (event.keyCode === 27) {
          // escape
          if (_self.showNotePanel) {
            _self.toggleNotePanel(false);
          } else {
            _self.active = false;
          }
        }
      }, 0);
    };

    _self.$onInit = function() {
      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          KeymageService.toggle(!_self.active);

          if (_self.active) {
            angular.element(document.body).on('keyup', _self.keybindFilter);
          } else {
            angular.element(document.body).off('keyup', _self.keybindFilter);
          }
        }
      );
    };

    _self.$onChanges = function(changes) {
      if (changes.jobs) {
        if (_self.jobs) {
          //cant add if completed, dropped or closed
          _self.canAddSlateNote =
            AuthService.isAuthorized([
              USER_ROLES.merchOps,
              USER_ROLES.studioOps,
              USER_ROLES.studioStylist,
              USER_ROLES.superUser
            ]) &&
            !(_self.jobs.status === META_STATUS.completed) &&
            !(_self.jobs.status === META_STATUS.dropped) &&
            !(_self.jobs.status === META_STATUS.closed);

          _self.setJobData();
        }
      }
    };

    _self.$onDestroy = function() {
      angular.element(document.body).off('keyup', _self.keybindFilter);
    };
  },
  templateUrl: 'templates/partials/slate/modal.tpl.html'
};

module.exports = slateModalComponent;
