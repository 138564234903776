'use strict';

var angular = require('angular');

var SessionService = /*@ngInject*/ function($log, USER_DATA) {
  var _self = this,
    data;

  _self.get = function(param) {
    return param ? data[param] : data;
  };

  _self.compareUser = function(userName) {
    userName = angular.isObject(userName) ? userName.userName : userName;
    return userName === data.userName;
  };

  _self.setSessionValue = function(key, value, pageId) {
    var sessionObj = {};

    if (!sessionStorage[data.userName]) {
      sessionObj = {};
    } else {
      sessionObj = JSON.parse(sessionStorage[data.userName]);
    }
    if (!sessionObj[pageId]) {
      sessionObj[pageId] = {};
    }
    sessionObj[pageId][key] = value;

    sessionStorage[data.userName] = JSON.stringify(sessionObj);
  };

  _self.getSessionValue = function(key, pageId) {
    var retVal = null;
    if (!sessionStorage[data.userName]) {
      return retVal;
    }

    var sessionObj = JSON.parse(sessionStorage[data.userName]);
    if (!sessionObj[pageId]) {
      return retVal;
    }

    if (sessionObj[pageId][key] !== undefined) {
      retVal = sessionObj[pageId][key];
    }

    return retVal;
  };

  _self.getSession = function() {
    return sessionStorage;
  };

  _self.stringify = function(sessionObj) {
    var pairDelim = arguments[1] === undefined ? '=' : arguments[1];
    var itemDelim = arguments[2] === undefined ? ',' : arguments[2];
    var retVal = [];
    for (var key in sessionObj) {
      if (key === 'rejectionStatus') {
        retVal.push(`${sessionObj[key] === true ? '+' : '-'}${key}`);
      } else {
        retVal.push(key + pairDelim + sessionObj[key]);
      }
    }

    return retVal.join(',');
  };

  var init = function() {
    data = USER_DATA;
    data.name = [data.firstName, data.lastName].join(' ');
    $log.info('User Info', data);
  };

  init();
};

module.exports = SessionService;
