'use strict';

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

/*jshint unused: false*/
function register(angular) {
  return [
    '$scope',
    '$element',
    '$attrs',
    '$document',
    '$parse',
    'dropdownService',
    function dropdownController(
      $scope,
      $element,
      $attrs,
      $document,
      $parse,
      dropdownService
    ) {
      var self = this,
        scope = $scope.$new(), // create a child scope so we are not polluting original one
        openClass = 'is-open',
        getIsOpen,
        setIsOpen = angular.noop;

      $element.addClass('dropdown');
      self.guid = guid(); //generate unique id for WAI-ARIA

      this.init = function() {
        if ($attrs.isOpen) {
          getIsOpen = $parse($attrs.isOpen);
          setIsOpen = getIsOpen.assign;

          $scope.$watch(getIsOpen, function(value) {
            scope.isOpen = !!value;
          });
        }
      };

      this.getGuid = function() {
        return self.guid;
      };

      this.toggle = function(open) {
        var newState = (scope.isOpen = arguments.length
          ? !!open
          : !scope.isOpen);
        return newState;
      };

      // Allow other directives to watch status
      this.isOpen = function() {
        return scope.isOpen;
      };

      scope.getElement = function() {
        return $element;
      };

      scope.getToggleElement = function() {
        return self.toggleElement;
      };

      scope.getDropdownElement = function() {
        return self.dropdownMenu;
      };

      scope.focusToggleElement = function() {
        if (self.toggleElement) {
          self.toggleElement[0].focus();
        }
      };

      scope.scrollMenu = function() {
        if (
          self.dropdownMenu[0].offsetHeight + self.dropdownMenu[0].scrollTop <=
          self.selectedOption.offsetTop + self.selectedOption.offsetHeight
        ) {
          self.dropdownMenu[0].scrollTop = self.selectedOption.offsetTop;
        } else if (
          self.dropdownMenu[0].scrollTop >=
          self.selectedOption.offsetTop + self.selectedOption.offsetHeight
        ) {
          self.dropdownMenu[0].scrollTop = self.selectedOption.offsetTop;
        }
      };

      scope.focusDropdownEntry = function(keyCode) {
        var elems = angular.element(self.dropdownMenu.children());

        switch (keyCode) {
          case 40: {
            // down
            if (!self.selectedOption) {
              self.selectedOption = elems[0];
            } else {
              self.selectedOption =
                self.selectedOption === elems[elems.length - 1]
                  ? elems[0]
                  : self.selectedOption.nextElementSibling;
            }
            break;
          }
          case 38: {
            // up
            if (!self.selectedOption) {
              self.selectedOption = elems[elems.length - 1];
            } else {
              self.selectedOption =
                self.selectedOption === elems[0]
                  ? elems[elems.length - 1]
                  : self.selectedOption.previousElementSibling;
            }
            break;
          }
          default: {
            self.selectedOption = elems[0];
          }
        }

        scope.scrollMenu();

        if (self.typeahead) {
          elems.removeClass('is-focused');
          self.selectedOption.classList.add('is-focused');
        } else {
          self.selectedOption.setAttribute('tabindex', '-1');
          self.selectedOption.focus();
        }
      };

      scope.selectActiveOption = function() {
        if (self.selectedOption) {
          self.selectedOption.click();
        }
      };

      scope.$watch('isOpen', function(isOpen) {
        if (self.dropdownMenu) {
          self.dropdownMenu[0].classList.toggle(openClass, !!isOpen);
        }

        if (self.toggleElement) {
          self.toggleElement[0].classList.toggle(openClass, !!isOpen);
        }

        if (isOpen) {
          scope.focusDropdownEntry();
          dropdownService.open(scope);
        } else {
          dropdownService.close(scope);
        }
      });
    }
  ];
}

module.exports = register;
