'use strict';

var angular = require('angular');

var publishAssetsComponent = {
  bindings: {},
  controller: /*@ngInject*/ function(DeliverService) {
    var _self = this;

    _self.fetchDeliverData = function() {
      _self.delivery = { data: DeliverService.data };
      DeliverService.reset();
    };

    _self.prepareDeliveryDetails = function() {
      _self.delivery.data.prepareDeliveryDetails();
    };

    _self.$onInit = function() {
      _self.fetchDeliverData();
    };
  },
  templateUrl: 'templates/partials/admin/publishAssets.tpl.html'
};

module.exports = publishAssetsComponent;
