'use strict';

var angular = require('angular');
var oktaService = require('../services/okta.service.js');

var DetailController = /*@ngInject*/ function(
  $log,
  $q,
  $timeout,
  $http,
  $state,
  $stateParams,
  JobService,
  AuthService,
  NoteService,
  SearchService
) {
  var _self = this;

  _self.dnu = {
    showDNUPanel: false,
    shootType: undefined,
    merchType: undefined,
  };

  _self.error = {
    active: false,
    data: {
      current: {},
      product: {
        type: 'error',
        heading: {
          title: 'Product Not Found',
          copy: 'This product does not exist, or is no longer in the pipeline.'
        },
        cta: {
          primary: 'Go To Search'
        }
      },
      job: {
        type: 'error',
        heading: {
          title: 'Job Not Found',
          copy: 'This job does not exist, or is no longer in the pipeline.'
        },
        cta: {
          primary: 'Go To Search'
        }
      },
      jobs: []
    },
    onSubmit: function() {
      $state.go('search');
    }
  };

  _self.closeRemoveAssetsPanel = function () {
    _self.dnu.styleColor = undefined;
    _self.dnu.shootType = undefined;
    _self.dnu.merchType = undefined;
    _self.dnu.showDNUPanel = false;
  };

  _self.openRemoveAssetsPanel = function (options) {
    _self.dnu.styleColor = _self.data.product.styleColor;
    _self.dnu.shootType = options.shootType;
    _self.dnu.merchType = options.merchType;
    _self.dnu.showDNUPanel = true;
  };



  _self.problemsolve = {
    disabled: true
  };

  // Private Methods
  _self.fetchBaseData = function(jobId) {
    JobService.getJobDetail(jobId).then(_self.fetchBaseDataResponse);
  };

  _self.fetchBaseDataResponse = function(response) {
    _self.update({ base: response.data });
  };

  _self.fetchRelatedData = function(jobIds) {
    JobService.getJobListDetail(jobIds).then(_self.fetchRelatedDataResponse);
  };

  _self.fetchRelatedDataResponse = function(response) {
    var jobList = response.map(_self.extractJobData);
    _self.update({ relatedJobs: jobList });
  };

  _self.extractJobData = function(job) {
    return job.data;
  };

  // https://docs.angularjs.org/api/ng/service/$http
  _self.appendTransform = function(defaults, transform) {
    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
  };

  _self.showError = function(type) {
    _self.error.data.current = _self.error.data[type];
    _self.error.active = true;
  };

  _self.setAssetDetail = function(assetJobId, cropType) {
    _self.selectedAssetId = assetJobId;
    _self.selectedCropType = cropType;

    $state.go(
      'detail',
      {
        productIdentifierType: 'by-product-id',
        productIdentifier: _self.data.product.productId,
        jobId: _self.data.job.id,
        assetJobId: _self.selectedAssetId // note: router requires an empty string to update properly
      },
      {
        location: 'replace',
        notify: false
      }
    );
  };

  _self.setJobDetail = function(jobs, jobId) {
    if (angular.isArray(jobs)) {
      jobs.some(function(job) {
        if (job.id === jobId) {
          _self.data.job = job;
          return true;
        }
      });
    } else {
      _self.data.job = jobs;
    }

    if (!_self.data.job) {
      if (jobId) {
        _self.showError('job');
      } else if (AuthService.isAdmin()) {
        _self.data.newJob = {
          product: angular.copy(_self.data.product)
        };
        _self.initiateJobAction = true;
      }
      return;
    } else {
      _self.initiateJobAction = false;
    }

    if (_self.data.job.createdFrom) {
      _self.fetchBaseData(_self.data.job.createdFrom);
    }

    if (_self.data.job.relatedJobs.length) {
      _self.fetchRelatedData(_self.data.job.relatedJobs);
    }

    $log.info('Job Data', _self.data.job);
  };

  _self.init = function() {
    _self.data = {};
    _self.initializing = true;
    _self.working = true;
    _self.tab = $stateParams.tab;
    SearchService.findProduct({
      transformResponse: _self.appendTransform(
        $http.defaults.transformResponse,
        _self.initGetProductsSetExtraJobData
      )
    })
      .then(_self.initGetProductsResponse)
      .catch(_self.initGetProductsCatch)
      .finally(_self.initGetProductsFinally);
  };

  _self.initGetProductsSetExtraJobData = function(response) {
    _self.data.jobs = [];
    if (response.jobs) {
      _self.data.jobs = response.jobs;
      response.jobs.forEach(function(job) {
        JobService.setExtraJobData(job);
      });
    }

    return response;
  };

  _self.initGetProductsResponse = function(response) {
    response.data.assetJobId = $stateParams.assetJobId;
    _self.update(response.data);
  };

  _self.initGetProductsCatch = function() {
    _self.showError('job');
  };

  _self.initGetProductsFinally = function() {
    _self.initializing = false;
    _self.working = false;
  };

  // Public Methods

  _self.update = function($event) {
    if ($event.type === 'problemsolve') {
      _self.showProblemModal = true;
      return;
    }

    if ($event.type === 'resolveproblem') {
      _self.showResolveProblemModal = true;
      return;
    }

    if ($event.type === 'newjob') {
      _self.data.newJob = angular.copy(_self.data.job);
      _self.showInitiateJobModal = true;
      return;
    }

    if ($event.product) {
      _self.data.product = $event.product;
      $log.info('Product Data', _self.data.product);
    }

    if ($event.job) {
      _self.setJobDetail($event.job, $stateParams.jobId);
    }

    if ($event.jobs) {
      _self.setJobDetail($event.jobs, $stateParams.jobId);
    }

    if ($event.base) {
      _self.data.base = $event.base;
      $log.info('Parent Data', _self.data.base);
    }

    if ($event.relatedJobs) {
      _self.data.relatedJobs = $event.relatedJobs;
      $log.info('relatedJobs Data', _self.data.relatedJobs);
    }

    if ($event.assetJobId) {
      _self.setAssetDetail($event.assetJobId, $event.cropType);
    }

    if ($event.closeAssetDetail) {
      _self.selectedAssetId = '';
      _self.setAssetDetail('');
    }

    _self.data = angular.copy(_self.data);
  };

  _self.handleResolve = function($event) {
    var promise;

    _self.showResolveProblemModal = false;

    if ($event.type === 'newjob') {
      _self.update($event);
      return;
    }

    if ($event.note) {
      _self.working = true;
      promise = NoteService.addNote('problem', $event.note, $event.job.id);
    } else {
      promise = $q.when({ data: $event.job });
    }

    promise
      .then(_self.handleResolveResponse)
      .finally(_self.handleResolveFinally);
  };

  _self.handleResolveResponse = function(response) {
    var job = response.data;

    _self.update({
      product: job.product,
      job: job
    });
  };

  _self.handleResolveFinally = function() {
    _self.working = false;
  };

  _self.handleJobCreation = function($event) {
    _self.showInitiateJobModal = $event.active;

    if (!$event.job) {
      return;
    }

    $state.go(
      'detail',
      {
        productIdentifierType: 'by-product-id',
        productIdentifier: $event.job.product.productId,
        jobId: $event.job.id
      },
      {
        location: 'replace',
        notify: false
      }
    );

    $timeout(_self.handleJobCreationTimeout($event), 0);
  };

  _self.handleJobCreationTimeout = function($event) {
    _self.update({
      product: $event.job.product,
      job: $event.job
    });
  };

  _self.init();
};

module.exports = DetailController;

//todo add the history query here
