'use strict';

var USER_ROLES = {
  all: 'User',
  digiTech: 'Digi Tech',
  merchOps: 'Merch Ops',
  photographer: 'Photographer',
  seniorImageEditor: 'Senior Image Editor',
  imageEditor: 'Image Editor',
  studioStylist: 'Studio Stylist',
  superUser: 'Super User',
  artBuyer: 'Art Buyer',
  studioOps: 'Studio Ops',
  qaReviewer: 'QA Reviewer',
  productReviewer: 'Product Reviewer',
  externalImageEditor: 'External Image Editor'
};

module.exports = USER_ROLES;
