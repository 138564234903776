'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    function accordionBodyDirective() {
      return {
        restrict: 'A',
        require: '?^accordionGroup',
        link: function(scope, element, attrs, accordionGroupCtrl) {
          element.addClass('accordion-body');

          accordionGroupCtrl.bodyElement = element;

          scope.$watch(accordionGroupCtrl.isOpen, function(isOpen) {
            element[0].classList.toggle('is-open', !!isOpen);
          });
        }
      };
    }
  ];
}

module.exports = register;
