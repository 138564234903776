'use strict';

/*jshint unused: false*/
var imgModalComponent = {
  bindings: {
    model: '<'
  },
  controller: /*@ngInject*/ function(TransferService) {
    var _self = this;

    _self.closeModal = function() {
      _self.model.active = false;
      _self.model.src = '';
    };

    _self.saveLineArt = function() {
      TransferService.saveLineArt({
        url: _self.model.src,
        name: _self.model.name
      });
    };
  },
  templateUrl: 'templates/partials/ui/imgModalComponent.tpl.html'
};

module.exports = imgModalComponent;
