'use strict';

/*jshint unused: false*/
var shipmentListDirective = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {},
    bindToController: {
      data: '='
    },
    controllerAs: 'shipment',
    controller: 'ShipmentController',
    templateUrl: 'templates/partials/job/lists/shipment.tpl.html'
  };
};

module.exports = shipmentListDirective;
