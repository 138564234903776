'use strict';

var union = require('../vendor/union.js');

var globalHeaderComponent = {
  templateUrl: 'templates/partials/global/header.tpl.html',
  bindings: {
    onLogOut: '&'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $element,
    $state,
    AuthService,
    Session,
    ProblemSolveIndicatorService,
    KeymageService,
    AnimationService,
    localStorageService,
    ROLE_NAV,
    USER_ROLES,
    USER_ROLES_LABEL,
    SearchService
  ) {
    var _self = this;

    _self.setKeyBindings = function() {
      if (!_self.roleNav) {
        return;
      }

      var bindings = [],
        pushBinding = function(index) {
          bindings.push({
            shortcut: 'ctrl-' + (index + 1).toString(),
            fn: function() {
              $state.go(_self.navItems[index].state);
            }
          });
        };

      for (var i = 0; i < 9; i++) {
        if (_self.navItems[i]) {
          pushBinding(i);
        }
      }

      bindings.push({
        shortcut: 'ctrl-9',
        fn: function() {
          $state.go(_self.navItems[_self.navItems.length - 1].state);
        }
      });

      bindings.push({
        shortcut: 'ctrl-option-right',
        fn: function() {
          _self.navItems.forEach(function(navItem, index) {
            if (navItem.state === $state.current.name) {
              var newIndex = (index + 1) % _self.navItems.length;
              $state.go(_self.navItems[newIndex].state);
            }
          });
        }
      });

      bindings.push({
        shortcut: 'ctrl-option-left',
        fn: function() {
          _self.navItems.forEach(function(navItem, index) {
            if (navItem.state === $state.current.name) {
              var newIndex =
                index - 1 >= 0 ? index - 1 : _self.navItems.length - 1;
              $state.go(_self.navItems[newIndex].state);
            }
          });
        }
      });

      bindings.push({
        shortcut: 'ctrl-s',
        fn: function() {
          $state.go('search');
        }
      });

      bindings.push({
        shortcut: 'ctrl-a',
        fn: function() {
          $state.go('admin');
        }
      });

      KeymageService.set($scope, bindings);
    };

    _self.setUserData = function() {
      _self.user = {
        name: Session.get('name')
      };
    };

    _self.setProblemSolveIndicator = function() {
      if (AuthService.isAdmin()) {
        ProblemSolveIndicatorService.update();
        _self.PSdata = ProblemSolveIndicatorService.data;
      }
    };

    _self.setNavIcon = function() {
      localStorageService.set('showNav', _self.showNav);
      localStorageService.set('showQueueNav', _self.showQueueNav);
      _self.navIcon = _self.showNav ? 'x' : 'menu';
    };

    _self.setNav = function() {
      // Get the pages for each role that the user has
      _self.navItems = [];
      Session.get('roles').forEach(function(role) {
        _self.navItems = union(
          _self.navItems,
          ROLE_NAV[USER_ROLES_LABEL[role]]
        );
      });

      // Place them in the correct order)
      _self.navItems = ROLE_NAV['Page Order']
        .map(function(curr) {
          var temp = _self.navItems.filter(function(a) {
            return a.state === curr;
          });
          if (temp) {
            return temp[0];
          } else {
            return false;
          }
        })
        .filter(function(a) {
          return a;
        });

      _self.navItems.forEach(function(link) {
        if (
          link.state.indexOf('queue') !== -1 ||
          link.state.indexOf('outreachQueue') !== -1 ||
          link.state.indexOf('merch-management') !== -1
        ) {
          _self.queueNav.push(link);
        } else {
          _self.roleNav.push(link);
        }
      });
    };

    _self.showLogoutModal = function() {
      _self.onLogOut();
    };

    _self.$onInit = function() {
      _self.showNav = localStorageService.get('showNav');
      _self.showQueueNav = localStorageService.get('showQueueNav');
      _self.roleNav = [];
      _self.queueNav = [];
      _self.showAdminLink = AuthService.isAuthorized([
        USER_ROLES.superUser,
        USER_ROLES.seniorImageEditor
      ]);
      _self.showMerchLink = AuthService.isAuthorized([
        USER_ROLES.superUser,
        USER_ROLES.merchOps,
        USER_ROLES.studioOps
      ]);
      _self.canSearch = AuthService.isAuthorized([
        USER_ROLES.digiTech,
        USER_ROLES.merchOps,
        USER_ROLES.photographer,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.imageEditor,
        USER_ROLES.studioStylist,
        USER_ROLES.superUser,
        USER_ROLES.artBuyer,
        USER_ROLES.studioOps,
        USER_ROLES.qaReviewer,
        USER_ROLES.productReviewer
      ]);

      _self.setUserData();
      _self.setProblemSolveIndicator();
      _self.setNav();
      _self.setNavIcon();
      _self.setKeyBindings();
    };

    //Navbar search code

    _self.searchContents = '';
    _self.navSearchActive = false;

    _self.toggleSearchInput = function(bool) {
      if (!bool) {
        _self.navSearchActive = false;
      } else if (bool) {
        _self.navSearchActive = true;
        document.getElementById('navSearch').focus();
      }
    };

    _self.navSearch = function(input, event) {
      //Do nothing if there's no text to be searched
      if (!input) {
        return;
      }
      event.preventDefault(); //http://stackoverflow.com/questions/25937962/question-mark-added-in-middle-of-url-on-form-submit

      SearchService.search(
        {
          term: input
        },
        null
      )
        .then(function(response) {
          if (response.data.total === 1) {
            // Product reviewers cannot access the details page. Send them to Search instead.
            if (AuthService.isAuthorized(USER_ROLES.productReviewer)) {
              $state.go('search', { q: input });
            }
            //If it returned one result, we're going to the details page.
            _self.searchContents = '';
            input = '';
            var job = response.data.results[0];
            $state.go('detail', {
              productIdentifierType: 'by-product-id',
              productIdentifier: job.product.productId,
              jobId: job.id
            });
          } else {
            //For 0 or 2+, going to search page
            $state.go('search', { q: input });
            _self.searchContents = '';
          }
        })
        .catch(function(response) {
          // error handling
        });
    };
  }
};

module.exports = globalHeaderComponent;
