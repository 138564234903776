var ImageThumbController = function(JobService, QAService, $scope) {
  var _self = this;

  _self.tempLibraryViewCode = null;
  _self.errorMessage = null;
  _self.qaData = QAService.get();
  _self.libraryViewCodeModified = false;
  _self.originalLibraryViewCode = _self.assetJob.libraryViewCode;
  _self.lvcOptions = QAService.getValidLibraryViewCodes();

  if (!_self.model.cropType) {
    _self.model.cropType = _self.model.relatedAssets.Deliverable
      ? _self.model.relatedAssets.Deliverable.cropType
      : null;
  }

  _self.expectedCropTypes = _self.job.expectedCropTypes;

  _self.displayDeliverableImage = function(isCropTypeDeliverable) {
    var deliverableAsset = isCropTypeDeliverable
      ? $scope.image.model
      : $scope.image.model.relatedAssets.Deliverable;
    $scope.image.url = JobService.getImageByType(deliverableAsset, 'all');
  };

  $scope.$watch('image.model', function(currentVal, prevVal) {
    var isCropTypeDeliverable = $scope.image.model.type === 'Deliverable';
    if (
      !$scope.image.model ||
      (!isCropTypeDeliverable && !$scope.image.model.relatedAssets.Deliverable)
    ) {
      return;
    }
    _self.displayDeliverableImage(isCropTypeDeliverable);
  }, true);

  $scope.$watch('image.assetJob.libraryViewCode', function(currentVal, prevVal) {
    _self.validateLibraryViewCodes(); 
  });
};

module.exports = ImageThumbController;
