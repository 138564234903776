'use strict';

var angular = require('angular');
var JobTypeService = /*@ngInject*/ function(JOB_TYPE) {
  var _self = this;

  _self.getTypes = function() {
    return JOB_TYPE.all;
  };

  _self.isAssetOnly = function(type) {
    type = angular.isObject(type) ? type.jobType : type;

    return JOB_TYPE.assetOnly.includes(type);
  };

  _self.requiresCompletedJob = function(type) {
    type = angular.isObject(type) ? type.jobType : type;

    return JOB_TYPE.requiresCompletedJob.includes(type);
  };

  return _self;
};

module.exports = JobTypeService;
