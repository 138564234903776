'use strict';

var angular = require('angular');

var storageKeyEnv = /*@ngInject*/ (function() {
  return {
    getStorageKeyEnv: function () {
      var env = '';
      if (location.href.indexOf('localhost') > -1) {
        env = '-local';
      } else if (location.host.indexOf('gbipuidev01') > -1) {
        env = '-dev';
      } else if (location.host.indexOf('gbipuiuat') > -1) {
        env = '-uat';
      }
      return env;
    }
  };
})();

module.exports = storageKeyEnv;
