'use strict';

/*jshint unused: false*/
function register(angular) {
  return [
    '$interpolate',
    '$log',
    '$rootScope',
    '$$notifications',
    function NotificationService(
      $interpolate,
      $log,
      $rootScope,
      $$notifications
    ) {
      var _self = this,
        history = [],
        init;

      _self.push = function(message, patterns) {
        var options = $$notifications.options();
        var event = {
          timestamp: new Date(),
          message: patterns ? $interpolate(message)(patterns) : message
        };
        history.push(event);
        $log.log('Notification', event, {
          message: message,
          patterns: patterns
        });
        $rootScope.$broadcast(options.baseClass, event);
      };

      _self.get = function() {
        return history;
      };
    }
  ];
}

module.exports = register;
