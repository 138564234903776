'use strict';

var angular = require('angular');

var sortComponent = {
  bindings: {
    model: '<',
    type: '@',
    options: '<',
    onChange: '&'
  },
  controller: /*@ngInject*/ function(SEARCH_CONSTANTS) {
    var _self = this;

    _self.data = {};

    var getOptionObject = function(options) {
      return options.map(function(option) {
        return angular.copy(SEARCH_CONSTANTS.jobDetailHeadersHash[option]);
      });
    };

    _self.sortResults = function(option, reverse) {
      reverse =
        _self.model && _self.model.predicate === option.predicate
          ? !_self.model.reverse
          : reverse;

      _self.model = angular.extend({}, option, { reverse: reverse });

      _self.onChange({
        $event: {
          sortObj: _self.model
        }
      });
    };

    _self.toggleSortDirection = function() {
      _self.sortResults(_self.model);
    };

    _self.$onInit = function() {};

    _self.$onChanges = function(changes) {
      if (changes.options) {
        if (!_self.options) {
          return;
        }

        _self.options = angular.copy(_self.options);
        _self.data.optionList = getOptionObject(_self.options);
      }

      if (changes.model) {
        if (!_self.model) {
          return;
        }

        _self.model = angular.copy(_self.model);
      }
    };
  },
  templateUrl: 'templates/partials/ui/sort.tpl.html'
};

module.exports = sortComponent;
