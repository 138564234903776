'use strict';

var preflight = require('./preflight.js');
var httpError = require('./config/httpError.config.js');
var angular = require('angular'); // jshint ignore:line
var app = require('./modules/gbip.module.js');
var downloadUrl = require('./config/devDownloadUrl.constant.js');
var restUrl = require('./config/devRestUrl.constant.js');
var auroraRestUrl = require('./config/auroraDevRestUrl.constant.js');
var oktaService = require('./services/okta.service.js');
var initInjector = angular.injector(['ng']);
var $log = initInjector.get('$log');

// Fetch user info, then bootstrap app
let promise = oktaService.authenticate();
promise
  .then(function(response) {
    return preflight.fetchData(restUrl);
  }, function(error) {
    $log.error('fetchData Error:', error);
  })
  .then(preflight.bootstrapApplication, httpError.handleError);

require('./app.setup.js');
app.constant('REST_URL', restUrl);
app.constant('AURORA_REST_URL', auroraRestUrl);
app.constant('DOWNLOAD_URL', downloadUrl);

app.run(['$http', function($http) {
  $http.defaults.headers.common['Authorization'] = oktaService.fetchAuthToken();
}]);
