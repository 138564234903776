'use strict';

var StyleguideController = /*@ngInject*/ function(
  $log,
  $timeout,
  NotificationService
) {
  var _self = this;

  _self.dropdownText = '';

  _self.btnDropdownText = 'sort by';

  _self.isChecked = true;

  _self.openBtnDropdown = _self.openDropdown = false;

  _self.showModal = false;

  _self.tagClick = function(event) {
    $log.info(event);
  };

  _self.doSearch = function(value, event) {
    $log.info(value, event);
  };

  _self.log = function(output) {
    $log.info(output);
  };

  _self.setDropdown = function(text) {
    _self.dropdownText = text;
  };

  _self.toggleDropdown = function($event) {
    $event.preventDefault();
    $event.stopPropagation();
    _self.openDropdown = !_self.openDropdown;
  };

  _self.setBtnDropdown = function(text) {
    _self.btnDropdownText = text;
  };

  _self.toggleBtnDropdown = function($event) {
    $event.preventDefault();
    $event.stopPropagation();
    _self.btnDropdownText = !_self.btnDropdownText;
  };

  _self.notification = {
    message: '',
    generate: function() {
      NotificationService.push(_self.notification.message);
    }
  };

  _self.ta = {
    isOpen: false,
    disabled: false,
    label: 'State',
    model: '',
    options: [
      'New York',
      'New Jersey',
      'Maine',
      'Florida',
      'South Carolina',
      'North Carolina',
      'South Dakota',
      'North Dakota',
      'California',
      'Navada',
      'Texas',
      'Michigan'
    ]
  };
};

module.exports = StyleguideController;
