'use strict';

var angular = require('angular');

var expectedViewsComponent = {
  bindings: {
    model: '<' //the job
  },
  controller: /*@ngInject*/ function() {
    var _self = this;

    function aggregateData() {
      // create the empty arrays
      _self.model.expectedViewObjects = [];
      _self.model.componentViewObjects = [];

      //fill the expected view objects array
      _self.model.expectedViews.forEach(view => {
        _self.model.expectedViewObjects.push({
          viewCode: view,
          hasAsset: false
        });
      });

      _self.model.product.assets.forEach(asset => {
        var expectedView = _self.model.expectedViewObjects.filter(
          object => object.viewCode == asset.viewCode
        );
        if (expectedView.length == 1) {
          var view = expectedView[0];
          var assetJobs = _self.model.assetJobs.filter(
            assetJob => assetJob.viewCode == asset.viewCode
          );
          if (assetJobs.length > 0) {
            var assetJob = assetJobs[0];
            view.status = assetJob.status;
          }
          view.hasAsset = true;
          if (view.cropType !== '1x1') {
            view = Object.assign(view, asset);
          }
        } else {
          var assetJobs = _self.model.assetJobs.filter(
            assetJob => assetJob.viewCode == asset.viewCode
          );
          if (assetJobs.length > 0) {
            var assetJob = assetJobs[0];
            asset.status = assetJob.status;
          }
          var idx = _self.model.componentViewObjects
            .map(function(e) {
              return e.viewCode;
            })
            .indexOf(asset.viewCode);
          if (idx == -1) {
            _self.model.componentViewObjects.push(asset);
          } else if (
            _self.assetBetter(_self.model.componentViewObjects[idx], asset)
          ) {
            _self.model.componentViewObjects[idx] = asset;
          }
        }
      });

      _self.model.componentViewObjects.sort(function(a, b) {
        if (a.viewCode < b.viewCode) {
          return -1;
        }
        if (a.viewCode > b.viewCode) {
          return 1;
        }
        return 0;
      });
    }

    _self.assetBetter = function(original, asset) {
      var statusOrder = ['Unedited', 'Worker', 'Deliverable'];
      if (asset.assetVersion > original.assetVersion) {
        return true;
      }
      if (
        statusOrder.indexOf(asset.type) > statusOrder.indexOf(original.type)
      ) {
        return true;
      }
      return false;
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);

        aggregateData();
      }
    };
  },
  templateUrl: 'templates/partials/expectedViews.tpl.html'
};

module.exports = expectedViewsComponent;
