'use strict';

var Delegate = require('dom-delegate').Delegate;
var keymageDisableDirective = require('./keymageDisable.directive.js');
var KeymageService = require('./keymage.service.js');

function register(angular) {
  var app = angular.module('keymage', ['ng']);

  app.service('KeymageService', KeymageService(angular));
  app.directive('keymageDisable', keymageDisableDirective(angular));
  app.run([
    'KeymageService',
    function(KeymageService) {
      var delegate = new Delegate(document.body);

      var setEvents = function() {
        delegate.on(
          'focus',
          'input:not([type="checkbox"]), textarea',
          KeymageService.disable
        );
        delegate.on(
          'blur',
          'input:not([type="checkbox"]), textarea',
          KeymageService.enable
        );
      };

      if (document.readyState === 'complete') {
        setEvents();
      } else {
        window.addEventListener('load', setEvents);
      }
    }
  ]);

  return 'keymage';
}

module.exports = register;
