'use strict';

var searchCompareComponent = {
  restrict: 'E',
  bindings: {
    ids: '=',
    displayType: '='
  },
  controller: /*@ngInject*/ function(
    $scope,
    $q,
    $timeout,
    JobService,
    NoteService
  ) {
    var _self = this,
      idWatcher,
      noteTypeHash = {
        list: 'row',
        grid: 'column'
      };

    _self.data = {};

    _self.isOpen = true;
    _self.showNotes = false;

    _self.onOpen = function() {};

    _self.toggleNotes = function(job) {
      job.showNotes = !job.showNotes;
    };

    var makeDetailsRequests = function() {
      var promises = _self.ids.map(function(id) {
        return JobService.getJobDetail(id);
      });

      return $q.all(promises);
    };

    idWatcher = $scope.$watch(
      function() {
        return _self.ids;
      },
      function() {
        if (_self.ids && _self.ids.length) {
          makeDetailsRequests().then(function(response) {
            _self.data.jobs = response.map(function(response) {
              var job = response.data;
              return {
                details: job,
                notes: NoteService.getNotes(job, 'ProblemSolveNote')
              };
            });
          });
          idWatcher();
        }
      }
    );

    $scope.$watch(
      function() {
        return _self.displayType;
      },
      function() {
        _self.noteType = noteTypeHash[_self.displayType];
      }
    );
  },
  templateUrl: 'templates/partials/search/compare.tpl.html'
};

module.exports = searchCompareComponent;
