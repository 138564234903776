'use strict';

var angular = require('angular');
var moment = require('moment');

var productDetailModalComponent = {
  bindings: {
    model: '<'
  },
  controller: /*@ngInject*/ function(SearchService, $timeout) {
    var _self = this;

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (changes.model.isFirstChange()) {
          return;
        }

        _self.model = angular.copy(_self.model);
      }
    };
  },
  templateUrl: 'templates/partials/detail/productDetailModal.tpl.html'
};

module.exports = productDetailModalComponent;
