'use strict';

var angular = require('angular');

var attachmentsComponent = {
  bindings: {
    model: '<', // the job
    onChange: '&'
  },
  controller: /*@ngInject*/ function(
    $rootScope,
    AuthService,
    TransferService,
    USER_ROLES
  ) {
    var _self = this;

    _self.postTransfer = function() {
      _self.working = false;
    };

    _self.updateJob = function(response) {
      _self.model = response.data;
      if (response.data.attachments) {
        _self.onChange({ $event: { job: response.data } });
      }
    };

    _self.handleError = function(response) {
      var responseError = response.data.error;
      var responseStatus = response.status;
      if (responseStatus === 500) {
        if (responseError.indexOf('SizeLimitExceededException') !== -1) {
          $rootScope.$broadcast('error', {
            error: 'This file exceeds the limit of 200MB.'
          });
        } else {
          $rootScope.$broadcast('error', { error: response.data.error });
        }
      } else {
        $rootScope.$broadcast('error', { error: response.data.error });
      }
    };

    _self.uploadAttachment = function($event) {
      _self.working = true;

      TransferService.uploadFile(
        $event.file,
        TransferService.getAttachmentUrl(_self.model.id)
      )
        .then(_self.updateJob)
        .catch(_self.handleError)
        .finally(_self.postTransfer);
    };

    _self.deleteAttachment = function(filename) {
      _self.working = true;

      TransferService.deleteAttachment(_self.model.id, filename)
        .then(_self.updateJob)
        .catch(_self.handleError)
        .finally(_self.postTransfer);
    };

    _self.downloadAttachments = function(filename) {
      _self.working = true;

      TransferService.downloadAttachments(_self.model.id, filename)
        .catch(_self.handleError)
        .finally(_self.postTransfer);
    };

    _self.$onInit = function() {
      _self.canDownload = AuthService.isAuthorized([
        USER_ROLES.merchOps,
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.studioStylist,
        USER_ROLES.digiTech,
        USER_ROLES.imageEditor,
        USER_ROLES.superUser,
        USER_ROLES.qaReviewer,
        USER_ROLES.photographer
      ]);
      _self.canDelete = AuthService.isAuthorized([
        USER_ROLES.seniorImageEditor,
        USER_ROLES.studioOps,
        USER_ROLES.superUser
      ]);
    };

    _self.$onChanges = function(changes) {
      if (changes.model) {
        if (!_self.model) {
          return;
        }
        _self.model = angular.copy(_self.model);
        _self.canUpload =
          _self.model.active &&
          AuthService.isAuthorized([
            USER_ROLES.seniorImageEditor,
            USER_ROLES.studioOps,
            USER_ROLES.superUser,
            USER_ROLES.qaReviewer
          ]);
      }
    };
  },
  templateUrl: 'templates/partials/detail/attachments.tpl.html'
};

module.exports = attachmentsComponent;
