'use strict';

var angular = require('angular');
var moment = require('moment-timezone');

var imageViewerComponent = {
  bindings: {
    job: '<',
    onComplete: '&',
    reviewComments: '<',
    initiateProblemSolve: '<',
    onChange: '&',
  },
  controller: /*@ngInject*/ function(
    $rootScope,
    $scope,
    $element,
    $timeout,
    $state,
    USER_ROLES,
    JobService,
    TransferService,
    AuthService,
    CanvasService,
    QAService,
    StatusService,
    Session,
    KeymageService,
    NoteService,
    ProblemSolveIndicatorService,
    META_STATUS,
    ASSET_STATUS,
    APP_DATA,
    UPDATE_PARAMS,
    OutreachService,
    RouterTracker,
    SearchService,
    LinkedProductService,
    EventLoggerService
  ) {
    var _self = this;
    _self.libraryViewCodeErrorMessages;
    _self.rejectReason = '';
    _self.showAllCropTypes = true;
    _self.hasAssets = false;
    _self.annotations = {};
    _self.appData = APP_DATA;
    _self.userSelectedRejectionReasons = [];
    _self.rejectedAssetJobs = [];
    _self.lvcPredictions = null;
    _self.showRejectionPanel = false;
    _self.containerWidth = 0;
    _self.showEditControls = false;
    _self.strokeColor = '';
    _self.model = {
      zoom: true,
      drawing: false,
      notes: false
    };
    _self.canvasService = CanvasService;

    _self.colorList = [
      {
        value: 'red',
        hex: '#FE0000'
      },
      {
        value: 'yellow',
        hex: '#FFF400'
      },
      {
        value: 'blue',
        hex: '#0009FF'
      },
      {
        value: 'black',
        hex: '#111111'
      }
    ];

    _self.error = {
      active: false,
      data: {
        type: 'error',
        heading: {
          title: 'Error',
          copy:
            "The system encountered an issue. If you're unable to proceed after retrying, please contact Production Support for assistance."
        },
        cta: {
          primary: 'Return to Queue'
        }
      },
      onSubmit: function() {
        $state.go('queue.qa');
      }
    };

    var setKeyBindings = function() {
      var bindings = [
        {
          shortcut: 'r',
          fn: function() {
            $timeout(function() {
              _self.approveAsset(false);
            });
          }
        },
        {
          shortcut: 'a',
          fn: function() {
            $timeout(function() {
              _self.approveAsset(true);
            });
          }
        },
        {
          shortcut: 'j',
          fn: function() {
            $timeout(function() {
              if (_self.totalQAJob > 1) {
                _self.prevImg();
              }
            });
          }
        },
        {
          shortcut: 'left',
          fn: function(e) {
            e.preventDefault();

            $timeout(function() {
              if (_self.totalQAJob > 1) {
                _self.prevImg();
              }
            });
          }
        },
        {
          shortcut: 'k',
          fn: function() {
            $timeout(function() {
              if (_self.totalQAJob > 1) {
                _self.nextImg();
              }
            });
          }
        },
        {
          shortcut: 'right',
          fn: function(e) {
            e.preventDefault();

            $timeout(function() {
              if (_self.totalQAJob > 1) {
                _self.nextImg();
              }
            });
          }
        }
      ];

      KeymageService.set($scope, bindings);
    };

    _self.toggleControl = function(view) {
      EventLoggerService.track('QA:IMAGE_VIEWER MENU', {
        metaJobId: _self.job.id,
        action: view
      }, true);

      _self.cursorStyle = '';
      for (var i in _self.model) {
        if (i !== view) {
          _self.model[i] = false;
        } else {
          _self.model[view] = !_self.model[view];
          if (_self.model[i] === true) {
            _self.cursorStyle = i;
          }
        }
      }
      _self.updateView();
    };

    _self.setControls = function(isVisible) {
      if (isVisible && !_self.readonly) {
        _self.showEditControls = true;
        _self.model['zoom'] = true;
        _self.model['drawing'] = false;
        _self.cursorStyle = 'drawing';
        _self.model['notes'] = false;
        _self.canvasService.reset();
        _self.activePastAnnotation = null;
        _self.updateView();
        _self.updateStrokeColor(_self.colorList[0]);
      } else {
        _self.cursorStyle = 'drawing';
        _self.model['zoom'] = false;
        _self.model['drawing'] = false;
        _self.model['notes'] = false;
        _self.showEditControls = false;
        _self.updateView();
      }
    };

    _self.updateStrokeColor = function(color) {
      EventLoggerService.track('QA:IMAGE_VIEWER SET_COLOR', {
        metaJobId: _self.job.id,
        color: color
      }, true);

      $timeout(function() {
        _self.strokeColor = color;
        _self.canvasService.setStrokeColor(_self.strokeColor.hex);
      }, 0);
    };

    _self.setCurrentJobAnnotations = function() {
      var annotationData = _self.canvasService.getAnnotationData();
      if (!_self.selectedAsset) {
        return;
      }
      QAService.setAnnotation(
        _self.selectedAsset.id,
        _self.selectedCropType,
        annotationData
      );
    };

    _self.loadAlternateAnnotation = function(annotation, $index, focusType) {
      $timeout(function() {
        _self.canvasService.loadJSONDemo(annotation);
      }, 0);

      if (focusType === 'click') {
        _self.setControls(false);
        _self.activePastAnnotation = $index;
        _self.clickedAnnotation = annotation;
      }
    };

    _self.removeHoverFocus = function($index) {
      if (_self.clickedAnnotation) {
        _self.canvasService.loadJSONDemo(_self.clickedAnnotation);
      } else {
        if (_self.readonly) {
          _self.canvasService.reset();
          _self.canvasService.showStaticCanvas();
        } else {
          _self.canvasService.showActiveCanvas();
        }
      }
    };

    _self.updateView = function() {
      _self.canvasService.setNotesMode(_self.model['notes']);
      _self.canvasService.setDrawingMode(_self.model['drawing']);
      if (_self.readonly) {
        _self.canvasService.showStaticCanvas();
      }
    };

    _self.onHistorySelection = function($event) {
      _self.revision = $event;
      _self.canZoom = false;
      _self.infoDisplayFlag = false;
    };

    _self.closeRevision = function() {
      delete _self.revision;
      _self.canZoom = true;
      _self.infoDisplayFlag = true;
    };

    _self.completeReview = function() {
      _self.canvasService.resetZoom();
      _self.setCurrentJobAnnotations();
      _self.modal.productLinks.error = undefined;
      const hasRejections = _self.userSelectedRejectionReasons.length;
      if (_self.job.product.linkedProducts && !hasRejections) {
        if (_self.job.product.id) {
          TransferService.getExternalLineArt(_self.job.product, 'Line Art').then(
            function(response) {
              if (response[0].status === 200) {
                _self.job.product.lineArtThumb = response[0].thumbnailUrl;
              }
            }
          );
        }
        _self.searchItems = _self.job.product.linkedProducts.filter(function(
          product
        ) {
          return product.productId;
        });
        if (_self.searchItems.length > 0) {
          _self.modal.productLinks.active = true;
          _self.modal.productLinks.working = true;
          var searchParams = {};
          searchParams.term = _self.searchItems
            .map(l => l.styleColor)
            .join(' ');
          searchParams.size = 1000;
          SearchService.search(searchParams)
            .then(function(response) {
              // Filter self out from response
              // (The search service updates asyncronously, so it may still appear as "In Image QA")
              var products = response.data.results.map(job => job.product);
              _self.searchItems.forEach(link => {
                link.product = products.filter(
                  product => product.styleColor === link.styleColor
                )[0];
                if (link.product.id) {
                  TransferService.getExternalLineArt(link.product, 'Line Art').then(
                    function(response) {
                      if (response[0].status === 200) {
                        link.product.lineArtThumb = response[0].thumbnailUrl;
                      }
                    }
                  );
                }
                link.jobs = response.data.results.filter(function(job) {
                  var isPotentialOverwrite = false;
                  if (
                    job.id &&
                    job.jobType !== 'Copy' &&
                    job.product.styleColor === link.styleColor
                  ) {
                    if (job.shootType.code === _self.job.shootType.code) {
                      if (
                        isMerchTypeGreater(_self.job.merchType, job.merchType)
                      ) {
                        isPotentialOverwrite = true;
                      }
                    }
                  }
                  return isPotentialOverwrite;
                });
              });
            })
            .catch(function() {
              //do something
              _self.modal.productLinks.error =
                'Unable to get product details, please try again. Contact ICON product support if problem persists.';
            })
            .finally(function() {
              //do something else
              _self.modal.productLinks.working = false;
            });
        } else {
          _self.sendReviewComplete();
        }
      } else {
        _self.sendReviewComplete();
      }
    };

    var isMerchTypeGreater = function(merchType1, merchType2) {
      var greater = false;
      var mt1, mt2;
      if (merchType1) {
        mt1 = merchType1.toUpperCase();
      }
      if (merchType2) {
        mt2 = merchType2.toUpperCase();
      }
      var merchTypes = {
        'AF SAMPLE': 1,
        'PRO SAMPLE': 1,
        'GTM SAMPLE': 2,
        'KAPM SAMPLE': 2,
        PRODUCT: 3
      };
      if (merchTypes[mt1] >= merchTypes[mt2]) {
        greater = true;
      }
      return greater;
    };

    _self.sendReviewComplete = function() {
      _self.setCurrentJobAnnotations();
      if (_self.qaServiceData.allAssetsReviewed) {
        let payload = {
          metaJobId: _self.job.id,
          status: !!_self.userSelectedRejectionReasons && _self.userSelectedRejectionReasons.length > 0 ?
            "REJECTED" : "APPROVED",
          rejectedAssets: _self.userSelectedRejectionReasons.map(r => { return r.rejectedAssets; }).flat()
        };
        _self.working = true;
        QAService.completeReview({payload: payload, predictedLVCs: _self.lvcPredictions})
          .then(function(res) {
            if (res.error) {
              //Did the service return an error?
              _self.error.details =
                'Error ' +
                res.error.data.text.status +
                ': ' +
                res.error.data.text.error;
              _self.error.active = true;

              EventLoggerService.track('QA:IMAGE_VIEWER REVIEW_COMPLETE', {
                metaJobId: _self.job.id,
                status: 'error',
                details: _self.error.details
              }, true);

              return;
            }

            EventLoggerService.track('QA:IMAGE_VIEWER REVIEW_COMPLETE', {
              metaJobId: _self.job.id,
              status: 'success'
            }, true);

            _self.onComplete({
              $event: {
                notification: 'Review of Job {{id}} has been completed.',
                job: _self.job
              }
            });
          })
          .catch(function(response) {
            $rootScope.$broadcast('error', { error: response });
          })
          .finally(function() {
            _self.working = false;
          });
      } else if (!_self.hasAssets) {
        EventLoggerService.track('QA:IMAGE_VIEWER REVIEW_COMPLETE_NO_ASSETS', {
          metaJobId: _self.job.id,
          status: 'success'
        }, true);

        // work around a bug where the job is in QA but all assets are Approved
        _self.working = true;
        JobService.completeJob(_self.job.id, _self.job.tasks[0].id, {
          reject: false
        })
          .then(function(res) {
            _self.onComplete({
              $event: {
                notification: 'Review of Job {{id}} has been completed.',
                job: _self.job
              }
            });
          })
          .catch(function() {
            $rootScope.$broadcast('error', { error: response });
          })
          .finally(function() {
            _self.working = false;
          });
      }
    };

    _self.confirmLink = function(link) {
      LinkedProductService.confirmLink(link, _self.job.id)
        .then(function(response) {
          link.complete = true;
          _self.nextLink();
        })
        .catch(function() {
          _self.modal.productLinks.error =
            'Problem communicating with ICON, please try again. Contact ICON product support if problem persists.';
        });
    };

    _self.skipLink = function(link) {
      LinkedProductService.skipLink(link, _self.job.id)
        .then(function(response) {
          link.complete = true;
          _self.nextLink();
        })
        .catch(function() {
          _self.modal.productLinks.error =
            'Problem communicating with ICON, please try again. Contact ICON product support if problem persists.';
        });
    };

    _self.nextLink = function() {
      var length = _self.searchItems.filter(item => item.complete).length;
      if (length === _self.searchItems.length) {
        _self.sendReviewComplete();
        _self.modal.productLinks.active = false;
        _self.modal.productLinks.working = false;
      }
    };

    _self.assignJob = function() {
      StatusService.setPreviewData(UPDATE_PARAMS.qa.assign);
      _self.showStatusModal = true;
    };

    _self.unassignJob = function() {
      StatusService.setPreviewData(UPDATE_PARAMS.qa.unassign);
      _self.showStatusModal = true;
    };

    _self.onStatusUpdate = function($event) {
      _self.setViewData($event.jobs[0]);
      _self.showStatusModal = false;
    };

    _self.modal = {};
    _self.modal.unassign = {
      active: false,
      working: false,
      data: {
        heading: {
          title: 'Unassign Asset',
          copy: 'The following assets will be unassigned:'
        },
        cta: {
          primary: 'Unassign',
          secondary: 'Cancel'
        }
      }
    };

    _self.modal.productLinks = {
      active: false,
      working: false,
      data: {
        heading: {
          title: 'Linked Record'
        }
      },
      closeModal: function() {
        _self.modal.productLinks.active = false;
      }
    };

    _self.canUnassign = function() {
      var assetJob = _self.selectedAsset,
        task;

      if (assetJob && assetJob.tasks.length) {
        task = assetJob.tasks[0];

        if (task && task.name !== ASSET_STATUS.postProd) {
          return false;
        }
      }

      if (task && task.assignee) {
        if (AuthService.isAdmin() || Session.compareUser(task.assignee)) {
          return true;
        }
      }

      return false;
    };

    _self.currentIndex = 0;

    _self.confirmUnassign = function() {
      _self.modal.unassign.active = true;
    };

    _self.unassignAssets = function($event) {
      _self.modal.active = false;
      // get last item in array; earlier items don't have updates that were performed later.
      _self.job = $event.response.data[$event.response.data.length - 1];

      _self.job.assetJobs.some(function(assetJob) {
        if (assetJob.id === _self.selectedAsset.id) {
          _self.setHeroImage(assetJob);
          return true;
        }
      });
    };

    _self.updateAssetUrl = function(assetJobID) {
      $state.go(
        'qa',
        {
          styleColor: _self.job.product.id,
          assetJobId: assetJobID
        },
        {
          location: 'replace',
          notify: false
        }
      );
    };

    _self.update = function($event) {
      _self.job.attachments = $event.job.attachments;
      _self.onChange({ $event: $event });
    };

    _self.setHeroImage = function(assetJob, cropType) {

      if (!assetJob || assetJob.status !== ASSET_STATUS.qa) {
        return false;
      }
      if (_self.selectedAsset && _self.canvasService.getAnnotationData()) {
        _self.setCurrentJobAnnotations();
        _self.canvasService.reset();
      }
      _self.selectedAsset = assetJob;
      _self.selectedCropType = cropType || _self.job.expectedCropTypes[0];
      _self.currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset);
      _self.heroImage = JobService.getImageByType(
        assetJob.cropTypes[_self.selectedCropType] ||
        assetJob.relatedAssets.Deliverable ||
        assetJob.relatedAssets.Worker ||
        assetJob.relatedAssets.Unedited,
        'large'
      );
      _self.qaData = QAService.get(_self.selectedAsset.id);
      // this can and should be optimized
      for (let i = 0; i < _self.job.assetJobs.length; i++) {
        const current = _self.job.assetJobs[i];
        _self.annotations[current.id] = QAService.get(current.id);
      }
      _self.updateAssetUrl(_self.selectedAsset.id);
      _self.setImageRejectionsList();
      // make sure there are documented issues
      let rejectedAssets = _self.getRejectedAssetsList();
      let key = _self.getRejectAssetKey(assetJob.tasks[0].id, _self.selectedCropType);
      if (rejectedAssets.includes(key) && !_self.showRejectionPanel) {
        _self.setControls(true);
        _self.imageObj = $element[0].querySelector('.gbip-image-viewer__image');
        _self.containerWidth = _self.imageObj.getBoundingClientRect().width;

        if (_self.containerWidth > 0) {
          var annotationData = QAService.get(_self.selectedAsset.id).cropTypes[
            _self.selectedCropType
            ].annotation;

          if (annotationData) {
            if (Array.isArray(annotationData)) {
              annotationData = annotationData[0];
            }
          }

          var cropTypesLinks = assetJob.cropTypes[
            _self.selectedCropType
            ].links.filter(function(link) {
            return link.rel !== 'annotation';

          });

          var deliverableLinks = assetJob.relatedAssets.Deliverable.links.filter(
            function(link) {
              return link.rel !== 'annotation';
            }
          );

          var newUrl = _self.selectedCropType ? cropTypesLinks[0].href : deliverableLinks[0].href;

          _self.canvasService.init(_self.imageObj, annotationData, newUrl);

          //We need to do this here rather than init because the canvasService has to be initialized first
          if (_self.readonly === true) {
            _self.canvasService.showStaticCanvas();
          }
        }
      } else {
        _self.setControls(false);
      }

      if(_self.annotations && assetJob && cropType &&
      _self.annotations[assetJob.id] && _self.annotations[assetJob.id].cropTypes[cropType]) {
        _self.selectedAsset.pastAnnotations = _self.annotations[assetJob.id].cropTypes[cropType].pastAnnotations;
      }

      return true;
    };

    /** Note Changes  **********************************************/
    _self.onNoteTextChange = function($event, note) {
      note.text = $event.text;
      _self.validateNoteList();
    };

    _self.validateNoteList = function() {
      _self.validFlag = _self.canvasService.data.notes.length > 0;
      var notesText = _self.canvasService.data.notes;
      for (var i = 0; i < notesText.length; i++) {
        _self.validFlag = true;
        if (notesText[i].text.length === 0) {
          _self.validFlag = false;
          break;
        }
      }
    };

    _self.removeNotes = function(index) {
      _self.canvasService.removeNotes(index);
      _self.validateNoteList();
    };

    _self.onRejectionThumbClick = function(asset) {
      let assetJob = _self.job.assetJobs.find(aj => aj.viewCode === asset.viewCode);
      _self.setHeroImage(assetJob, asset.cropType);
    };

    _self.setImageRejectionsList = function() {
      _self.imageRejectionsList =
        _self.userSelectedRejectionReasons.filter(function(rejection) {
          return rejection.rejectedAssets.find(function(rejectedAsset) {
            return rejectedAsset.taskId === _self.selectedAsset.tasks[0].id &&
              rejectedAsset.cropType === _self.selectedCropType;
          });
        });
    };

    _self.clearRejectionReasonsForAssetJobTaskId = function (assetJobTaskId) {
      if(!_self.userSelectedRejectionReasons || !_self.userSelectedRejectionReasons.length) {
        return;
      }
      for(var i = _self.userSelectedRejectionReasons.length - 1; i >= 0; i--) {
        let rejection = _self.userSelectedRejectionReasons[i];
        rejection.rejectedAssets = rejection.rejectedAssets.filter(r => { return r.taskId !== assetJobTaskId; });
        if(rejection.rejectedAssets.length === 0) {
          _self.userSelectedRejectionReasons.splice(i, 1);
        }
      }
    };

    _self.approveAsset = function(flag) {
      if (!flag) {
        _self.setCurrentJobAnnotations();
        _self.canvasService.reset();
        _self.showRejectionPanel = true;
        _self.setControls(false);
      } else {
        QAService.set(_self.selectedAsset.id, true);
        QAService.setAnnotation(
          _self.selectedAsset.id,
          _self.selectedCropType,
          null
        );
        _self.clearRejectionReasonsForAssetJobTaskId(_self.selectedAsset.tasks[0].id);
        _self.setHeroImage(
          _self.job.assetJobs[_self.getNextAssetIndexForReview()]
        );
      }
    };

    _self.getNextAssetIndexForReview = function() {
      var _currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset);

      for (var i = 0; i < _self.job.assetJobs.length; i++) {
        var index = (i + _currentIndex) % _self.job.assetJobs.length;
        var qaAsset = QAService.get(_self.job.assetJobs[index].id);

        if (qaAsset && qaAsset.approved === undefined) {
          return index;
        }
      }
    };

    _self.rejectAsset = function($event) {
      if ($event.annotate) {
        _self.reviewCompleteFlag = QAService.allReviewSelected();
        _self.showRejectionPanel = false;
        _self.setAnnotation(true);
        _self.rejectReason = $event.psData.note;
        _self.rejectionReason = $event.rejectionReason;
        _self.rejectionReasonDetail = $event.rejectionReasonDetail;
        NoteService.addNote(
          'general',
          $event.psData.note,
          _self.job.id,
          $event.psData.type
        );
      } else if ($event.contactProductTeam) {
        OutreachService.setState('image_qa');
        $state.go('outreach', {
          id: _self.job.id
        });
      } else {
        JobService.moveToProblemSolve(_self.job.id, _self.job.tasks[0].id)
          .finally(function() {
            ProblemSolveIndicatorService.update();
            return NoteService.addNote(
              'problem',
              $event.psData.note,
              _self.job.id,
              $event.psData.type
            );
          })
          .finally(function() {
            _self.onComplete({
              $event: {
                notification: 'Job {{id}} was sent to Problem Solve',
                job: _self.job
              }
            });
          });
      }
    };

    var ERR_DUPLICATE = 'Duplicate Library View Code.';
    var ERR_NO_CODE = 'Library View Code is Unknown';

    _self.isDuplicateLibraryViewCode = function(currentJob, assetJobs) {
      var isDuplicate = false;
      _self.job.assetJobs.forEach(function(assetJob) {
        if (
          assetJob.id !== currentJob.id &&
          assetJob.libraryViewCode &&
          assetJob.libraryViewCode === currentJob.libraryViewCode
        ) {
          isDuplicate = true;
        }
      });
      return isDuplicate;
    };

    _self.validateLibraryViewCodes = function() {
      _self.hasLibraryViewCodeError = false;
      var errorMessage = null;
      if (_self.showLibraryViewCode) {
        _self.job.assetJobs.forEach(function(assetJob, index) {
          if(_self.requiresLibraryViewCode(assetJob)) {
            var assetErrorMessages = [];
            if (_self.isDuplicateLibraryViewCode(assetJob, _self.job.assetJobs)) {
              assetErrorMessages.push(ERR_DUPLICATE);
            } else if (!assetJob.libraryViewCode || !QAService.getValidLibraryViewCodes().includes(assetJob.libraryViewCode)) {
              assetErrorMessages.push(ERR_NO_CODE);
            }
            _self.hasLibraryViewCodeError =
              _self.hasLibraryViewCodeError || assetErrorMessages.length > 0;
            _self.libraryViewCodeErrorMessages[index] = assetErrorMessages;
          }
        });
      }
    };

    _self.prevImg = function(wrap) {
      var _currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset),
        _validateQA;
      var selectedCropTypeIndex = _self.job.expectedCropTypes.indexOf(
        _self.selectedCropType
      );
      var selectedCropType = null;

      if (selectedCropTypeIndex == 0) {
        if (_currentIndex === 0 && wrap) {
          _currentIndex = _self.job.assetJobs.length - 1;
        } else {
          _currentIndex--;
        }
        selectedCropType =
          _self.job.expectedCropTypes[_self.job.expectedCropTypes.length - 1];
      } else {
        selectedCropType =
          _self.job.expectedCropTypes[selectedCropTypeIndex - 1];
      }

      _validateQA = _self.setHeroImage(
        _self.job.assetJobs[_currentIndex],
        selectedCropType
      );
      _self.currentIndex = _currentIndex;

      if (!_validateQA) {
        if (wrap) {
          //only wrap  when there is at least 1 validate job
          if (_self.totalQAJob > 0) {
            _self.prevImg(wrap);
          }
        } else if (_currentIndex >= 0) {
          _self.prevImg(wrap);
        }
      }
    };

    _self.nextImg = function(wrap) {
      var _currentIndex = _self.job.assetJobs.indexOf(_self.selectedAsset),
        _validateQA;
      var selectedCropTypeIndex = _self.job.expectedCropTypes.indexOf(
        _self.selectedCropType
      );
      var selectedCropType = null;

      if (selectedCropTypeIndex == _self.job.expectedCropTypes.length - 1) {
        if (_currentIndex >= _self.job.assetJobs.length - 1 && wrap) {
          _currentIndex = 0;
        } else {
          _currentIndex++;
        }
        selectedCropType = _self.job.expectedCropTypes[0];
      } else {
        selectedCropType =
          _self.job.expectedCropTypes[selectedCropTypeIndex + 1];
      }

      _validateQA = _self.setHeroImage(
        _self.job.assetJobs[_currentIndex],
        selectedCropType
      );
      _self.currentIndex = _currentIndex;

      if (!_validateQA) {
        if (wrap) {
          //only wrap  when there is at least 1 validate job
          if (_self.totalQAJob > 0) {
            _self.nextImg(wrap);
          }
        } else if (_currentIndex < _self.job.assetJobs.length) {
          _self.nextImg(wrap);
        }
      }
    };

    _self.toggleInfo = function(flag) {
      _self.infoDisplayFlag = flag;
    };

    _self.toggleCropTypes = function() {
      _self.showAllCropTypes = !_self.showAllCropTypes;
    };

    _self.dateConvert = function(date) {
      return moment(date).format('M/D/YYYY hh:mm A');
    };

    _self.setAnnotation = function(flag) {
      _self.qaDetailFlag = !flag;
      _self.qaData = QAService.get(_self.selectedAsset.id);

      if (!flag) {
        _self.setHeroImage(
          _self.job.assetJobs[_self.getNextAssetIndexForReview()]
        );
      }
    };

    _self.setPermissionData = function(job) {
      var metaAssignee = JobService.getAssignedUser(job, 'meta');

      _self.canReview = Session.compareUser(metaAssignee);
      _self.canAssign =
        !metaAssignee &&
        AuthService.isAuthorized([
          USER_ROLES.studioOps,
          USER_ROLES.superUser,
          USER_ROLES.qaReviewer
        ]);
      _self.canUnassign =
        _self.canReview || (metaAssignee && AuthService.isAdmin());
      _self.canAct =
        job.status === META_STATUS.qa && (_self.canAssign || _self.canUnassign);
    };

    _self.assignAssetJobLibraryViewCode = function(job) {
      job.assetJobs.forEach(function(assetJob) {
        var validQaAssetImages = [];
        assetJob.assetFilenames.forEach(function(assetFilename) {
          if (assetFilename.indexOf('_D_') === -1) {
            return; //only compair file names if it is in the qa state (_D_)
          }
          job.product.assets.forEach(function(asset) {
            if (assetFilename.toUpperCase() === asset.fileName.toUpperCase()) {
              validQaAssetImages.push(asset);
            }
          });
        });
        var largestVersionNum = 0.0;
        validQaAssetImages.forEach(function(validAsset) {
          var currentVersionNum = parseFloat(validAsset.assetVersion);
          if (currentVersionNum > largestVersionNum) {
            largestVersionNum = currentVersionNum;
            assetJob.libraryViewCode = validAsset.libraryViewCode;
          }
        });
      });
    };

    _self.setViewData = function(job) {
      _self.setPermissionData(job);
      _self.showLibraryViewCode =
        job.product.vendorCode.toUpperCase() !== 'CONVERSE';
      _self.hasLibraryViewCodeError = false;

      // Only show assets that need to be reviewed
      job.assetJobs = job.assetJobs.filter(function(assetJob) {
        return assetJob.status === ASSET_STATUS.qa;
      });

      //until the backend returns library viewcode in assetJob we have to pull it from product.assets
      if (_self.showLibraryViewCode) {
        _self.assignAssetJobLibraryViewCode(job);
      }

      _self.totalQAJob = job.assetJobs.length;

      if (job.assetJobs.length) {
        _self.hasAssets = true;
        QAService.init(_self.job);
        job.assetJobs.forEach(function(assetJob) {
          if (!_self.heroImage) {
            _self.selectedTab = 0;
            _self.setHeroImage(assetJob);
          }
        });
      }
    };

    _self.onCloseRejectionPanel = function() {
      _self.showRejectionPanel = false;
    };

    _self.onSaveAndCloseRejectionPanel = function(rejectionsList) {
      _self.userSelectedRejectionReasons = rejectionsList;
      _self.updateRejectedAssetJobs();
      _self.setImageRejectionsList();
      _self.showRejectionPanel = false;
    };

    _self.$onInit = function() {
      _self.qaServiceData = QAService.data;
      _self.download = TransferService.data;
      _self.canZoom = true;
      _self.infoDisplayFlag = true;
      _self.qaDetailFlag = true;

      setKeyBindings();
    };

    $scope.$watch('$ctrl.userSelectedRejectionReasons',
      function(after, before) {
        if(after !== before) {
          _self.updateRejectionFlags();
          _self.clearUnrejectedAnnotations();
        }
      }
    )

    $scope.$watch('$ctrl.showRejectionPanel',
      (after, before) => {
        if (!after) {
          _self.setHeroImage(_self.selectedAsset, _self.selectedCropType);
        } else {
          _self.setControls(false);
        }
      }
    )

    $scope.$watch('$ctrl.canvasService.data',
      (after, before) => {
        if (after !== before) {
          _self.updateView();
        }
      }
    )

    _self.getRejectedAssetTaskIds = function() {
      return _self.userSelectedRejectionReasons.map(r => { return r.rejectedAssets.map(r => { return r.taskId }); }).flat();
    };

    _self.getRejectedAssetIdHashes = function() {
      return _self.userSelectedRejectionReasons.map(r => { return r.rejectedAssets.map(r => { return `${r.taskId}|${r.cropType}` }); }).flat();
    };

    _self.getRejectAssetKey = function(taskId, cropType) {
      return taskId + "|" + cropType;
    };

    _self.getRejectedAssetsList = function() {
      return _self.userSelectedRejectionReasons.map(r => { return r.rejectedAssets.map(r => { return _self.getRejectAssetKey(r.taskId, r.cropType); }); }).flat();
    };

    _self.updateRejectedAssetJobs = function() {
      let rejectedAssetTaskIds = _self.getRejectedAssetTaskIds();
      _self.rejectedAssetJobs = angular.copy(_self.job.assetJobs.filter(assetJob => { return rejectedAssetTaskIds.includes(assetJob.tasks[0].id); }));
    };

    _self.updateRejectionFlags = function() {
      // Grab the list of rejected assetJob task ids
      let rejectedAssetTaskIds = _self.getRejectedAssetTaskIds();
      // Go through the assetJobs and if its task is in the rejected list, set the annotation object to not approved
      angular.forEach(this.job.assetJobs, function(assetJob) {
        if(rejectedAssetTaskIds.includes(assetJob.tasks[0].id)) {
          QAService.set(assetJob.id, false);
          _self.annotations[assetJob.id].approved = false;
        } else if(_self.annotations[assetJob.id].approved === false) {
          delete _self.annotations[assetJob.id].approved;
          QAService.removeChangeData(assetJob.id);
        }
      });
    };

    _self.clearUnrejectedAnnotations = function() {
      // Grab the list of rejected assetJob task ids
      let rejectedAssetIds = _self.getRejectedAssetIdHashes();
      // Go through the assetJobs and if its task is in the rejected list, set the annotation object to not approved
      angular.forEach(this.job.assetJobs, function(assetJob) {
        for(const cropType in assetJob.cropTypes) {
          const hash = `${assetJob.tasks[0].id}|${cropType}`;
          if(!rejectedAssetIds.includes(hash)) {
            QAService.clearAnnotation(
              assetJob.id,
              cropType
            );
          }
        }
      });
    };

    _self.getUtilizedCropTypes = function(metaJob, assetJob) {
      let cropTypes = metaJob.expectedCropTypes.filter((cropType) => !!assetJob.cropTypes[cropType]);
      return JobService.prioritySortCropTypes(cropTypes);
    }

    _self.updateLibraryViewCodes = function(response) {
      try {
        let refreshRequired = false;
        // After we get back the predictions, update the Library View Codes on assetJobs that don't have one already
        if(response.status === 200) {
          _self.lvcPredictions = response.data.predictions;
          for(let assetJob of _self.job.assetJobs) {
            if(!assetJob.libraryViewCode && _self.requiresLibraryViewCode(assetJob)) {
              assetJob.libraryViewCode = _self.lvcPredictions[assetJob.viewCode];
              refreshRequired = true;
            }
          }
          if(refreshRequired) {
            _self.validateLibraryViewCodes();
          }
        } else {
          _self.loadingLVCs = false;
        }
      } finally {
        _self.loadingLVCs = false;
      }
    }

    _self.requiresLibraryViewCode = function(assetJob) {
      return !!assetJob.cropTypes && !!assetJob.cropTypes['1x1'];
    }

    _self.hasAllLibraryViewCodes = function(metaJob) {
      for(let assetJob of metaJob.assetJobs) {
        if(!assetJob.libraryViewCode && _self.requiresLibraryViewCode(assetJob)) {
          return false;
        }
      }
      return true;
    };

    _self.$onChanges = function(changes) {
      if (changes.job) {
        if (!_self.job) {
          return;
        }

        var previousRoute = RouterTracker.getPreviousState();

        _self.job = angular.copy(_self.job);
        _self.lvcPredictions = null;
        if(_self.showLibraryViewCode !== false) {
          // Only put it into loading if LVCs are missing and will change
          _self.loadingLVCs = !_self.hasAllLibraryViewCodes(_self.job);
          QAService.getLibraryViewCodePredictionsForMetaJob(_self.job.id)
            .then(_self.updateLibraryViewCodes)
            .catch(_self.onFailedLVCPredictions);
        }

        _self.libraryViewCodeErrorMessages = new Array(
          _self.job.assetJobs.length
        );
        _self.setViewData(_self.job);
        window.setTimeout(function() {
          if (_self.showLibraryViewCode) {
            _self.validateLibraryViewCodes();
          }
        }, 1);

        _self.assetGroups = {};

        JobService.getAvailableCropTypes().then(function() {
          var expectedCropTypes = JobService.prioritySortCropTypes(_self.job.expectedCropTypes);

          _self.job.assetJobs.forEach(function(assetJob) {
            var referenceAsset = JobService.getReferenceAsset(assetJob);
            _self.assetGroups[referenceAsset.viewCode] = { cropTypes: [] };
            expectedCropTypes.forEach(function(cropType) {
              if (referenceAsset.cropType === cropType) {
                _self.assetGroups[referenceAsset.viewCode].cropTypes.push(
                  assetJob
                );
              }
            });
          });
        });

        if (
          previousRoute.route.name === 'outreach' &&
          !previousRoute.route.flag
        ) {
          //When clicking through jobs, we want to have the first image pre-selected.
          //Since the state does not change when approving assets, we need this flag to prevent nextImg() from running every time we load assets.
          previousRoute.route.flag = true;
          _self.nextImg();
        }
      } else {
        if (_self.showLibraryViewCode) {
          _self.validateLibraryViewCodes();
        }
      }
    };

    _self.outreachContact = function () {
      OutreachService.setState('image_qa');
      $state.go('outreach', {
        id: _self.job.id
      });
    };

    this.$onDestroy = function() {
      CanvasService.destroy();
    };
  },
  templateUrl: 'templates/partials/qa/imageViewer.tpl.html'
};

module.exports = imageViewerComponent;
