'use strict';

var angular = require('angular');

var MerchQueueController = /*@ngInject*/ function(
  $scope,
  $rootScope,
  $timeout,
  $q,
  QueueService,
  iScrollService,
  JobService,
  MERCH_STATUS,
  $state,
  Session,
  RouterTracker,
  EventLoggerService,
  RESULTS_CONSTANT,
  AuthService
) {
  var _self = this;

  var getUpdateParams = function() {
    return _self.stateData.params.updateParams;
  };

  // Allow view to access service methods
  _self.service = QueueService;

  _self.data = {
    jobs: []
  };

  _self.config = {
    page: 0,
    size: 50
  };

  _self.allResults = false;

  _self.toggleAll = _self.service.toggleAll;

  _self.confirmUpdate = function() {
    _self.service.confirmUpdate(
      _self.data.selectedJobs,
      getUpdateParams(),
      _self.stateData.location
    );
  };

  _self.doSearch = function(term) {
    Session.setSessionValue(
      _self.stateData.location + 'searchTerm',
      term,
      _self.state
    );
    _self.service.doSearch(_self, term).then(function(jobs) {
      if (term && jobs.length === 1 && _self.stateData.params.updateParams) {
        _self.service.confirmUpdate(
          jobs,
          getUpdateParams(),
          _self.stateData.location
        );
      }
    });
  };

  _self.getJobs = function() {
    var deferred = $q.defer();

    _self.working = true;
    _self.loadingError = false;

    if (_self.data.jobs === undefined || _self.data.jobs.length === 0) {
      _self.config.page = 0;
    }

    QueueService.getJobs(
      _self.data.term,
      _self.stateData.params.searchParams,
      _self.config.page,
      _self.config.size,
      _self.stateData.location,
      _self.stateData.params.status
    )
      .then(function(response) {
        _self.data.jobs = JobService.filterDuplicateJobs(
          _self.data.jobs.concat(response.data.results)
        );

        _self.config.page++;
        EventLoggerService.track(
          'QUEUE_PAGINATION',
          {
            page: $state.current.name,
            location: _self.stateData ? ' ' + _self.stateData.location : '',
            pageNumber: _self.config.page
          },
          _self.config.page > 1
        );
        _self.data.total = response.data.total;

        if (_self.data.jobs.length === response.data.total) {
          _self.allResults = true;
        }

        _self.working = false;

        deferred.resolve(_self.data.jobs);
      })
      .catch(function(err) {
        _self.working = false;
        _self.loadingError = true;
      });

    return deferred.promise;
  };

  _self.onScrollEnd = function() {
    if (
      !_self.working &&
      _self.scrollerObj.y <= _self.scrollerObj.maxScrollY &&
      !_self.allResults
    ) {
      _self.getJobs();
    }

    $rootScope.$broadcast('scroll-stop');
  };

  /* onClick funtions for cards */
  _self.shiftKey = function() {
    return event.shiftKey;
  };
  _self.metaKey = function() {
    return event.metaKey;
  };

  var lastSelected = null;
  var lastIndex = null;

  _self.cardClick = function(job, ev) {
    if (_self.stateData.params.title === MERCH_STATUS.completed) {
      return;
    }
    // Note: This is not the only click function for cards. This function runs AFTER the $element.on('click') in the job controller.
    if (lastSelected && lastIndex > -1) {
      if (ev.shiftKey) {
        // Just do nothing if they click the same job twice while holding shift.
        if (lastIndex === _self.data.jobs.indexOf(job)) {
          job.selected = !job.selected;
          return;
        }

        //select all between the previous and current selection
        var currentIndex = _self.data.jobs
          .map(function(a) {
            return a.id;
          })
          .indexOf(job.id);
        var selectingJobs =
          lastSelected.selected && job.selected ? true : false;
        if (currentIndex > lastIndex) {
          for (var i = lastIndex; i < currentIndex; i++) {
            if (selectingJobs) {
              _self.data.jobs[i].selected = true;
            } else {
              _self.data.jobs[i].selected = false;
              lastSelected.selected = false;
              job.selected = true;
            }
          }
        } else if (lastIndex > currentIndex) {
          for (var i = currentIndex; i < lastIndex; i++) {
            if (selectingJobs) {
              _self.data.jobs[i].selected = true;
            } else {
              _self.data.jobs[i].selected = false;
              lastSelected.selected = false;
              job.selected = true;
            }
          }
        }
        // This is to prevent the text in the cards from highlighting. We do still need this functionality though, so users can copy ids/style colors.
        window.getSelection().removeAllRanges();
      } else if (ev.metaKey) {
        //select both, original behavior
      } else {
        //no shift, no ctrl, only select one
        var currentlySelected = job;

        var match = function(a, b) {
          return (
            (a.id ? a.id : '') + (a.product.id ? a.product.id : '') ===
            (b.id ? b.id : '') + (b.product.id ? b.product.id : '')
          );
        };

        for (var i = 0; i < _self.data.jobs.length; i++) {
          if (
            !match(_self.data.jobs[i], currentlySelected) &&
            _self.data.jobs[i].selected
          ) {
            _self.data.jobs[i].selected = false;
          }
        }
      }
    }

    lastSelected = job;
    lastIndex = _self.data.jobs
      .map(function(a) {
        return a.id;
      })
      .indexOf(job.id);
  };

  _self.init = function() {
    _self.state = $state.current.name;

    _self.stateWatch = function() {
      _self.stateData = angular.copy($scope.stateData);
      _self.service.registerScope(_self, _self.stateData.location);
      var stateLocation = _self.stateData.location;

      _self.constant = AuthService.getAuthorizedResults(
        Session.get('roles'),
        $state.current.data.authorizedRoles,
        RESULTS_CONSTANT[
          _self.stateData.viewState + '.' + _self.stateData.location
        ],
        _self.stateData.location
      );

      var routeHistory = RouterTracker.getRouteHistory();
      var lastRoute = routeHistory[routeHistory.length - 1];
      if (lastRoute.route.name === 'detail') {
        var term = Session.getSessionValue(
          stateLocation + 'searchTerm',
          _self.state
        );
        _self.data.term = term === null ? '' : term;
        _self.data.inputTerm = _self.data.term;
      } else {
        Session.setSessionValue(stateLocation + 'searchTerm', '', _self.state);
      }

      $scope.$on('queue.update.' + _self.stateData.location, function(
        e,
        params
      ) {
        _self.service.updateJobs(_self, params);
      });
      _self.service.resetAndGetData(_self);

      _self.data.selectionCta = _self.stateData.params.cta || 'Update Status';
    };

    $scope.$watch('stateData', _self.stateWatch);

    $scope.$watch(
      function() {
        return _self.data.jobs;
      },
      function() {
        _self.service.handleSelections(_self);
      },
      true
    );

    $timeout(function() {
      _self.scrollerObj = iScrollService.find(_self);

      if (_self.scrollerObj) {
        _self.scrollerObj = _self.scrollerObj[0].scrollerObj;
        _self.scrollerObj.on('scrollEnd', _self.onScrollEnd);
      }
    }, 0);
  };

  _self.init();
};

module.exports = MerchQueueController;
