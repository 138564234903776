'use strict';

var angular = require('angular');

var viewCodeToggleComponent = {
  bindings: {
    model: '=',
    label: '<',
    onChange: '&',
    disabled: '<',
    expectedViews: '<',
    editable: '<'
  },
  controller: /*@ngInject*/ function(JobService) {
    var _self = this;

    _self.handleToggle = function($event) {
      if (!_self.disabled && _self.editable) {
        _self.model.selected = !_self.model.selected;
        _self.onChange(_self.model);
      }
    };

    _self.$onInit = function() {};

    _self.$onChanges = function(changes) {};
  },
  templateUrl: 'templates/partials/initiateJob/viewCodeToggle.tpl.html'
};

module.exports = viewCodeToggleComponent;
