'use strict';

var SearchTagController = /*@ngInject*/ function($scope, SearchService) {
  var _self = this;

  $scope.$watch(
    function() {
      return SearchService.data.filters;
    },
    function() {
      var activeFilters = [];

      if (SearchService.data.filters) {
        SearchService.data.filters.forEach(function(filter) {
          filter.options.forEach(function(option) {
            if (option.selected) {
              option.parent = filter.name;
              activeFilters.push(option);
            }
          });
        });
      }

      _self.activeFilters = activeFilters;
    },
    true
  );
};

module.exports = SearchTagController;
