'use strict';

var newFull = /*@ngInject*/ function() {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {},
    bindToController: {
      data: '<',
      view: '=',
      showDownload: '<',
      disableSelection: '=',
      viewState: '=?',
      constant: '<',
      greyCondition: '<',
      dblClick: '&?',
      displayType: '<',
      toggleCropTypes: '&?',
      showSort: '<'
    },
    controllerAs: 'job',
    controller: 'JobController',
    templateUrl: 'templates/partials/job/lists/fullNew.tpl.html'
  };
};

module.exports = newFull;
