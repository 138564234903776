'use strict';
var angular = require('angular');

var DeliveryJobService = /*@ngInject*/ function(
  $http,
  $q,
  REST_URL,
  AssetSearchService
) {
  var _self = this;
  _self.currentBaseProductIndex = undefined;
  _self.previousSearchTerm = '';
  _self.working = false;

  _self.getDeliveryJobsByStyleColor = function(styleColor, page) {
    var deferred = $q.defer(),
      _url = REST_URL.deliveryJobs + '?styleColor=' + styleColor;

    if (page) {
      _url += '&page=' + page;
    }

    $http({
      method: 'get',
      url: _url
    })
      .then(function(response) {
        deferred.resolve(response.data);
      })
      .catch(deferred.reject);
    return deferred.promise;
  };

  _self.getFailedDeliveries = function(page) {
    var deferred = $q.defer(),
      _url = REST_URL.deliveryJobs + '/failed';

    if (page) {
      _url += '?page=' + page;
    }

    $http({
      method: 'get',
      url: _url
    })
      .then(function(response) {
        deferred.resolve(response.data);
      })
      .catch(deferred.reject);
    return deferred.promise;
  };

  _self.getDeliveryImageDetails = function(styleColor, arrIds) {
    var arrFilters = [{ name: 'id' }];
    arrFilters[0].facetValues = arrIds.map(id => {
      return { value: id };
    });
    var arrStyleColors = [styleColor];

    return AssetSearchService.search(arrFilters, arrStyleColors, true, false);
  };

  _self.retryDeliveries = function(arrDeliveryIds) {
    var deferred = $q.defer(),
      _url =
        REST_URL.deliveryJobs +
        '/retryPublish?jobIds=' +
        arrDeliveryIds.join(',');

    $http({
      method: 'put',
      url: _url
    })
      .then(function(response) {
        deferred.resolve(response);
      })
      .catch(deferred.reject);
    return deferred.promise;
  };

  _self.completeDelivery = function(deliveryId) {
    var deferred = $q.defer(),
      _url =
        REST_URL.deliveryJobs +
        '/retryPublish?jobIds=' +
        deliveryId +
        '&forcePublish=true';

    $http({
      method: 'put',
      url: _url
    })
      .then(function(response) {
        deferred.resolve(response);
      })
      .catch(deferred.reject);
    return deferred.promise;
  };
};

module.exports = DeliveryJobService;
