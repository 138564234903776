'use strict';

var angular = require('angular');

var problemModalDirective = {
  bindings: {
    active: '=',
    job: '<',
    activity: '@',
    onChange: '&'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $timeout,
    JobService,
    NoteService,
    ProblemSolveIndicatorService,
    KeymageService,
    NotificationService,
    APP_DATA
  ) {
    var _self = this;

    _self.checkValidation = function() {
      if (_self.working || !_self.issueType || !_self.validNote) {
        return true;
      }
      return false;
    };

    _self.onNoteTextChange = function($event) {
      _self.tempNote = $event.text;
      _self.validNote = $event.valid;
      _self.checkValidation();
    };

    _self.submit = function() {
      if (_self.checkValidation()) {
        return false;
      }

      _self.working = true;

      if (_self.activity === 'true') {
        JobService.activity.reactivations
          .submit(JobService.activity.updateData.post, true)
          .then(function(response) {
            _self.onChange({
              type: 'reactivate',
              idOfJobToHide: JobService.activity.updateData.job.id
            });
            ProblemSolveIndicatorService.update();
            return NoteService.addNote(
              'problem',
              _self.tempNote,
              response.data.id,
              _self.issueType
            );
          })
          .then(function(response) {
            _self.job.notes = response.data.notes;
            _self.closeModal();
          });
      } else {
        JobService.moveToProblemSolve(_self.job.id, _self.job.tasks[0].id)
          .then(function(response) {
            _self.job = response.data;
            ProblemSolveIndicatorService.update();
            NotificationService.push(
              'Job {{id}} has been moved to Problem Solve',
              response.data
            );
            return NoteService.addNote(
              'problem',
              _self.tempNote,
              _self.job.id,
              _self.issueType
            );
          })
          .then(function(response) {
            _self.job.notes = response.data.notes;
            _self.onChange({ $event: { job: _self.job } });
            _self.closeModal();
          });
      }
    };

    _self.closeModal = function() {
      _self.active = false;
      _self.issueType = '';
      _self.tempNote = '';
      _self.working = false;
    };

    _self.keybindFilter = function(event) {
      if (!_self.active) {
        return;
      }
      $timeout(function() {
        if (event.which === 13) {
          // return
          _self.submit();
        } else if (event.keyCode === 27) {
          // escape
          _self.closeModal();
        }
      }, 0);
    };

    _self.$onInit = function() {
      _self.tempNote = '';
      _self.issueType = '';
      _self.issueOptions = APP_DATA.ProblemSolveIssueType;
      if (_self.issueOptions.indexOf('Pre-production') > -1) {
        _self.issueOptions.splice(
          _self.issueOptions.indexOf('Pre-production'),
          1
        );
      }
      if (_self.issueOptions.indexOf('Duplicate') > -1) {
        _self.issueOptions.splice(_self.issueOptions.indexOf('Duplicate'), 1);
      }
      _self.working = false;

      $scope.$watch(
        function() {
          return _self.active;
        },
        function() {
          KeymageService.toggle(!_self.active);

          if (_self.active) {
            angular.element(document.body).on('keyup', _self.keybindFilter);
          } else {
            angular.element(document.body).off('keyup', _self.keybindFilter);
          }
        }
      );
    };

    _self.$onChanges = function(changes) {
      if (changes.job) {
        _self.job = angular.copy(_self.job);
      }
    };

    _self.$onDestroy = function() {
      angular.element(document.body).off('keyup', _self.keybindFilter);
    };
  },
  templateUrl: 'templates/partials/problem/modal.tpl.html'
};

module.exports = problemModalDirective;
