'use strict';

var annotationComponent = {
  bindings: {
    assetJob: '<',
    allJobs: '<',
    job: '<',
    url: '<',
    updateParentView: '&',
    show: '<',
    readonly: '<',
    selectedCropType: '<',
    displayCropTypes: '<',
    userSelectedRejectionReasons: '<'
  },
  controller: /*@ngInject*/ function(
    $scope,
    $element,
    $timeout,
    CanvasService,
    QAService,
    KeymageService,
    NoteService,
    HistoryService,
    JobService,
    OutreachService
  ) {
    var _self = this;

    _self.working = false;
    _self.intvBgImage = null;
    _self.imageObj = null;
    _self.containerWidth = 0;
    _self.assetJobs = [];
    _self.currentIndex = 0;
    _self.selectedCropType = null;
    _self.assetRejectedReasons = [];
    _self.currentJob = {};
    _self.pastAnnotations = [];
    _self.showEditControls = true;
    _self.activePastAnnotation = null;
    _self.url = '';
    _self.strokeColor = '';
    _self.model = {
      zoom: false,
      drawing: true,
      notes: false
    };
    _self.canvasService = CanvasService;

    _self.colorList = [
      {
        value: 'red',
        hex: '#FE0000'
      },
      {
        value: 'yellow',
        hex: '#FFF400'
      },
      {
        value: 'blue',
        hex: '#0009FF'
      },
      {
        value: 'black',
        hex: '#111111'
      }
    ];

    _self.loadRejectedReasonsForAsset = function(selectedAsset) {
      _self.assetRejectedReasons = [];
      if(!selectedAsset || !_self.selectedCropType || !_self.userSelectedRejectionReasons) {
        return;
      }
      angular.forEach(_self.userSelectedRejectionReasons, function(rejectionReason) {
        if(rejectionReason.rejectedAssets.find(r => { return r.taskId === selectedAsset.assetJobTaskId && selectedAsset.data.cropType === r.cropType; })) {
          _self.assetRejectedReasons.push({
            previewText: rejectionReason.title,
            comment: rejectionReason.comment,
          });
        }
      });
    };

    _self.$onChanges = function(changingObj) {
      _self.resetView();

      var assetJob = changingObj['assetJob'];
      var assetJobs = changingObj['allJobs'];
      var _showFlag = changingObj['show'];
      _self.job = changingObj['job']
        ? changingObj['job'].currentValue
        : _self.job;

      if (
        assetJobs &&
        assetJobs.currentValue &&
        assetJobs.currentValue.length
      ) {
        _self.assetJobs = angular
          .copy(assetJobs.currentValue)
          .filter(function(a) {
            return a.status !== 'Closed';
          });
        if(!_self.assetJob) {
          _self.assetJob = _self.assetJobs[0];
        }
        if(!_self.selectedCropType) {
          _self.selectedCropType = _self.job
            ? _self.job.expectedCropTypes[0]
            : '1x1';
        }
        _self.setCurrentJob();
      }

      if (assetJob && assetJob.currentValue) {
        _self.assetJob = angular.copy(assetJob.currentValue);
        _self.setControls(true);
      }

      //Find the index of the selected asset in the list of assets
      if (_self.assetJobs && _self.assetJob) {
        for (var i = 0; i < _self.assetJobs.length; i++) {
          if (_self.assetJob.id === _self.assetJobs[i].id) {
            _self.currentIndex = i;
            _self.setCurrentJob();
            break;
          }
        }
      }

      if (_showFlag && _showFlag.currentValue === true) {
        _self.init();
        KeymageService.disable();
      } else {
        KeymageService.enable();
      }
    };

    _self.resetView = function() {
      CanvasService.reset();
      _self.infoDisplayFlag = true; //Display the notes panel
      _self.noteRequired = true;
      _self.cursorStyle = 'drawing';
      _self.validFlag = false;
      _self.model = {
        zoom: false,
        drawing: true,
        notes: false
      };
    };

    _self.init = function() {
      _self.working = true;
      clearInterval(_self.intvBgImage);
      _self.intvBgImage = setInterval(_self.setBackgroundImage, 500);

      if (_self.readonly === undefined) {
        _self.readonly = false;
      }

      _self.activePastAnnotation = null;
      _self.clickedAnnotation = null;

      $scope.$watch(
        function() {
          return _self.canvasService.data.notes.length;
        },
        _self.focusTextareaTimeout,
        false
      );
    };

    _self.focusTextareaTimeout = function(newValue, oldValue) {
      if (newValue > oldValue) {
        setTimeout(_self.focusTextarea, 100);
      }
    };

    _self.focusTextarea = function() {
      var noteItems = document.getElementsByClassName(
        'gbip-annotation-notes__item'
      );
      var textarea = noteItems
        .item(noteItems.length - 1)
        .getElementsByTagName('textarea')
        .item(0);
      if (textarea !== null) {
        textarea.focus();
      }
    };

    _self.setBackgroundImage = function() {
      _self.imageObj = $element[0].querySelector('.gbip-image-viewer__image');
      _self.containerWidth = _self.imageObj.getBoundingClientRect().width;
      _self.updateStrokeColor(_self.colorList[0]);

      if (_self.containerWidth > 0) {
        var annotationData = QAService.get(_self.currentJob.id).cropTypes[
          _self.selectedCropType
        ].annotation;

        if (annotationData) {
          if (Array.isArray(annotationData)) {
            annotationData = annotationData[0];
          }
        }

        CanvasService.init(_self.imageObj, annotationData, _self.imageObj.getAttribute('http-src'));
      }

      clearInterval(_self.intvBgImage);
      _self.working = false;
    };

    _self.updateStrokeColor = function(color) {
      $timeout(function() {
        _self.strokeColor = color;
        CanvasService.setStrokeColor(_self.strokeColor.hex);
      }, 0);
    };

    _self.setCurrentJob = function() {
      _self.currentJob = _self.assetJobs[_self.currentIndex];
      _self.url = JobService.getImageByType(
        _self.currentJob.cropTypes[_self.selectedCropType] ||
          _self.assetJob.relatedAssets.Deliverable,
        'large'
      );
      var assetJob = QAService.get(_self.currentJob.id);
      if (assetJob) {
        var selectedAsset = assetJob.cropTypes[_self.selectedCropType];
        selectedAsset.assetJobTaskId = assetJob.job.tasks[0].id;
        _self.loadRejectedReasonsForAsset(selectedAsset);
        _self.pastAnnotations = selectedAsset.pastAnnotations;
        var annotationData = selectedAsset.annotation;
        if (annotationData) {
          CanvasService.loadJSON(annotationData);
        }
        _self.updateView();
        _self.showEditControls = true;
      }
    };

    _self.updateView = function() {
      CanvasService.setNotesMode(_self.model['notes']);
      CanvasService.setDrawingMode(_self.model['drawing']);
    };

    /** Canvas Controls ********************************************/
    _self.toggleControl = function(view) {
      _self.cursorStyle = '';
      for (var i in _self.model) {
        if (i !== view) {
          _self.model[i] = false;
        } else {
          _self.model[view] = !_self.model[view];
          if (_self.model[i] === true) {
            _self.cursorStyle = i;
          }
        }
      }
      _self.updateView();
    };

    _self.setControls = function(isVisible) {
      if (isVisible && !_self.readonly) {
        _self.showEditControls = true;
        _self.model['zoom'] = false;
        _self.model['drawing'] = true;
        _self.cursorStyle = 'drawing';
        _self.model['note'] = false;
        _self.canvasService.reset();
        _self.activePastAnnotation = null;
        _self.updateView();
      } else {
        _self.cursorStyle = 'drawing';
        _self.model['zoom'] = false;
        _self.model['drawing'] = true;
        _self.model['note'] = false;
        _self.showEditControls = false;
        _self.updateView();
      }
    };

    /** Note Changes  **********************************************/
    _self.onNoteTextChange = function($event, note) {
      note.text = $event.text;
      _self.validateNoteList();
    };

    _self.validateNoteList = function() {
      _self.validFlag = _self.canvasService.data.notes.length > 0;
      var notesText = _self.canvasService.data.notes;
      for (var i = 0; i < notesText.length; i++) {
        _self.validFlag = true;
        if (notesText[i].text.length === 0) {
          _self.validFlag = false;
          break;
        }
      }
    };

    _self.removeNotes = function(index) {
      _self.canvasService.removeNotes(index);
      _self.validateNoteList();
    };

    /** Navigational Controls **************************************/
    _self.showPreviousAsset = function() {
      var selectedCropTypeIndex = _self.job.expectedCropTypes.indexOf(
        _self.selectedCropType
      );

      _self.clickedAnnotation = null;
      _self.setCurrentJobAnnotations();
      _self.resetView();

      if (!_self.displayCropTypes) {
        if (_self.currentIndex === 0) {
          _self.currentIndex = _self.assetJobs.length - 1;
        } else {
          _self.currentIndex -= 1;
        }
      } else if (selectedCropTypeIndex == 0) {
        if (_self.currentIndex === 0) {
          _self.currentIndex = _self.assetJobs.length - 1;
        } else {
          _self.currentIndex -= 1;
        }
        _self.selectedCropType =
          _self.job.expectedCropTypes[_self.job.expectedCropTypes.length - 1];
      } else {
        _self.selectedCropType =
          _self.job.expectedCropTypes[selectedCropTypeIndex - 1];
      }

      _self.setControls(true);
      _self.setCurrentJob();
      if(_self.assetRejectedReasons.length === 0) {
        // if we got a cropType that wasn't actually rejected, skip over it
        _self.showPreviousAsset();
      }
    };

    _self.showNextAsset = function() {
      var selectedCropTypeIndex = _self.job.expectedCropTypes.indexOf(
        _self.selectedCropType
      );

      _self.clickedAnnotation = null;
      _self.setCurrentJobAnnotations();
      _self.resetView();

      if (!_self.displayCropTypes) {
        if (_self.currentIndex === _self.assetJobs.length - 1) {
          _self.currentIndex = 0;
        } else {
          _self.currentIndex += 1;
        }
      } else if (
        selectedCropTypeIndex ==
        _self.job.expectedCropTypes.length - 1
      ) {
        if (_self.currentIndex === _self.assetJobs.length - 1) {
          _self.currentIndex = 0;
        } else {
          _self.currentIndex += 1;
        }
        _self.selectedCropType = _self.job.expectedCropTypes[0];
      } else {
        _self.selectedCropType =
          _self.job.expectedCropTypes[selectedCropTypeIndex + 1];
      }

      _self.setControls(true);
      _self.setCurrentJob();
      if(_self.assetRejectedReasons.length === 0) {
        // if we got a cropType that wasn't actually rejected, skip over it
        _self.showNextAsset();
      }
    };

    _self.setCurrentJobAnnotations = function() {
      var annotationData = CanvasService.getAnnotationData();
      QAService.setAnnotation(
        _self.currentJob.id,
        _self.selectedCropType,
        annotationData
      );
    };

    /** Annotation View Controls ***********************************/
    _self.cancel = function() {
      QAService.set(_self.currentJob.id, undefined);
      _self.close();
    };

    _self.close = function() {
      _self.updateParentView({ flag: false });
      CanvasService.reset();
    };

    _self.submit = function() {
      _self.setCurrentJobAnnotations();
      CanvasService.reset();
      _self.close();
    };

    this.$onDestroy = function() {
      CanvasService.destroy();
    };
  },
  templateUrl: 'templates/partials/qa/annotation.tpl.html'
};

module.exports = annotationComponent;
