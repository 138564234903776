'use strict';

var angular = require('angular');

var textareaComponent = {
  bindings: {
    model: '=',
    disabled: '=',
    placeholderText: '<',
    type: '@',
    onChange: '&',
    required: '<',
    openOnMouseover: '<',
    onFocus: '&',
    onBlur: '&'
  },
  controller: /*@ngInject*/ function($interpolate, CHARACTER_LIMITS) {
    var _self = this;

    _self.maxNumberOfLines = 5;

    _self.$onChanges = function(changes) {
      if (_self.placeholderText) {
        _self.placeholder = _self.placeholderText;
      }

      if (changes.model) {
        _self.model = angular.copy(_self.model);
      }

      if (changes.required) {
        _self.required = angular.copy(_self.required);
      }

      if (changes.type) {
        if (!_self.type) {
          return;
        }
        _self.charLimit = CHARACTER_LIMITS[_self.type];
      }
      // _self.check();
    };

    _self.check = function() {
      _self.showMessage = false;
      _self.invalid = _self.required
        ? _self.model === undefined || _self.model.length === 0
        : false;

      if (_self.model !== undefined && _self.type) {
        if (_self.model.length > _self.charLimit) {
          _self.placeholder = $interpolate('{{charLimit}} Character Limit')(
            _self
          );
          _self.message = $interpolate('{{placeholder}} Exceeded')(_self);
          _self.invalid = true;
          _self.showMessage = true;
        }
        if (_self.type === 'slate') {
          if (_self.model.split(/\r\n|\r|\n/).length > _self.maxNumberOfLines) {
            _self.placeholder = $interpolate(
              'Maximum number of lines ({{maxNumberOfLines}})'
            )(_self);
            _self.message = $interpolate('{{placeholder}} Exceeded')(_self);
            _self.invalid = true;
            _self.showMessage = true;
          }
        }
      }

      _self.onChange({
        $event: { text: _self.model, type: _self.type, valid: !_self.invalid }
      });
    };
  },
  templateUrl: 'templates/partials/ui/textarea.tpl.html'
};

module.exports = textareaComponent;
