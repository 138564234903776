'use strict';

var angular = require('angular');

var shootDetails = {
  bindings: {
    base: '<',
    model: '<',
    onChange: '&',
    onEdit: '&',
    delayUpdate: '<',
    disabled: '<'
  },
  controller: /*@ngInject*/ function(
    $timeout,
    $q,
    JobService,
    SEARCH_CONSTANTS,
    AuthService,
    USER_ROLES,
    META_STATUS,
    APP_DATA,
    $scope
  ) {
    var _self = this,
      productIdentifier,
      businessPartner;
    _self.error = {};

    _self.formData = {
      shootType: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['shootType.code'].name,
        required: true
      },
      expectedViewCodes: {
        label: SEARCH_CONSTANTS.jobDetailHeadersHash['expectedViewCodes'].name,
        required: true
      }
    };

    _self.viewCodes = { available: [], defaults: [] };

    // Return an array of selected view codes
    _self.getExpectedViews = function() {
      var expectedViews = [];

      _self.formData.expectedViewCodes.options.forEach(function(viewCode) {
        if (viewCode.selected) {
          expectedViews.push(viewCode.label);
        }
      });

      return expectedViews;
    };

    _self.updateModel = function() {
      _self.model.shootType = _self.formData.shootType.value
        ? _self.formData.shootType.value.data.shootType
        : undefined;
      _self.model.expectedViews = _self.getExpectedViews();
      _self.onChange({ $event: { job: _self.model } });
    };

    _self.resetError = function() {
      _self.error = {};
    };

    _self.resetFormData = function() {
      var useBase = !!_self.base && _self.model.createdFrom !== _self.base.id,
        referenceJob = useBase ? _self.base : _self.model;

      if (!_self.formData.shootType.options) {
        return;
      }
      _self.filterShootTypesByJobType(_self.model.jobType);

      delete _self.formData.shootType.value;

      if (_self.formData.expectedViewCodes.options) {
        _self.formData.expectedViewCodes.options.forEach(function(view) {
          view.selected = false;
        });
      }

      if (referenceJob.shootType) {
        _self.formData.shootType.options.some(function(option) {
          if (referenceJob.shootType.code === option.data.code) {
            _self.formData.shootType.value = angular.copy(option);
            return true;
          }
        });
      }

      _self.disableViewCodes();

      if (referenceJob.shootType === undefined) {
        var defaultShootType = _self.formData.shootType.options.filter(function(
          shootType
        ) {
          return shootType.data.shootTypeDefault;
        });

        if (defaultShootType.length) {
          _self.setShootType(defaultShootType[0]);
        }
      } else {
        var defaultExpectedViews =
          _self.model.jobType === 'External Delivery'
            ? ['A']
            : referenceJob.expectedViews;
        _self.setSelectedViewCodes({
          data: { defaultExpectedViews: defaultExpectedViews }
        });
      }

      if (_self.delayUpdate) {
        _self.updateModel();
      }
      _self.validate(true);
    };

    // Get allowed shoot type options based on the products style-color
    _self.fetchShootTypeData = function(job) {
      // Only fetch new data if StyleColor has changed
      if (
        productIdentifier === JobService.getProductIdentifier(job) &&
        businessPartner === job.businessPartner
      ) {
        _self.resetFormData();
        return;
      }

      _self.working = true;
      _self.toggleEditMode(false);

      productIdentifier = JobService.getProductIdentifier(job);
      businessPartner = job.businessPartner;

      JobService.getAvailableShootTypes()
        .then(function(response) {
          _self.formData.shootType.options = response.data.map(function(data) {
            return {
              label: `${data.code} ${data.name}`,
              data: data
            };
          });
        })
        .finally(function() {
          _self.resetFormData();

          if (_self.formData.expectedViewCodes.options) {
            _self.working = false;
          }
          for (var i = 0; i < _self.formData.shootType.options.length; i++) {
            var current = _self.formData.shootType.options[i];
            if (current.data.code === _self.model.shootType.code) {
              _self.formData.shootType.value = current;
              break;
            }
          }
        });
    };

    _self.filterShootTypesByJobType = function(jobType) {
      if (jobType !== 'Duplicate') {
        _self.formData.shootType.options = _self.formData.shootType.options.filter(
          function(shootType) {
            return shootType.label === 'Duplicate Duplicate' ? false : true;
          }
        );
      }
    };

    // Shoot type can only be edited on new jobs or existing job that has no assets yet.
    // Expected views can only be edited until all the expected views are approved (thus it leaves In Photography)
    _self.setPermissions = function() {
      _self.canEditShootType =
        !_self.base &&
        (!_self.model.assetJobs || _self.model.assetJobs.length === 0);
      _self.canEditViews =
        _self.model.status != META_STATUS.qa &&
        (!!_self.base ||
          (_self.model.active &&
            !_self.model.expectedViewsApproved &&
            AuthService.isAuthorized([
              USER_ROLES.merchOps,
              USER_ROLES.studioStylist,
              USER_ROLES.studioOps,
              USER_ROLES.superUser
            ])));
    };

    _self.validate = function(isLoading) {
      _self.resetError();
      _self.isFormValid = true;

      $timeout(function() {
        const shootType = _self.formData.shootType.value.data.code;
        if(!JobService.validateMerchTypeShootTypeCombination(_self.model.merchType, shootType, (response) => { _self.error.title = response.data.error; })) {
          return;
        }

        if (
          _self.formData.expectedViewCodes &&
          _self.formData.expectedViewCodes.options
        ) {
          for (
            var i = 0;
            i < _self.formData.expectedViewCodes.options.length;
            i++
          ) {
            if (_self.formData.expectedViewCodes.options[i].selected) {
              return;
            }
          }
        }

        if(!isLoading) {
          _self.error.title = 'At least one view code must be selected.';
        }
      }, 0);
    };

    _self.toggleEditMode = function(boolean) {
      if (boolean !== undefined && _self.editMode === boolean) {
        return;
      }

      _self.editMode = boolean !== undefined ? boolean : !_self.editMode;

      if (_self.editMode) {
        _self.resetFormData();
      }

      _self.onEdit({ $event: { boolean: _self.editMode } });
    };

    _self.updateJob = function() {
      var apiData = angular.copy(_self.model);

      apiData.shootType = _self.formData.shootType.value.data.code;
      apiData.expectedViews = _self.getExpectedViews();

      if(!JobService.validateMerchTypeShootTypeCombination(apiData.merchType, apiData.shootType, (response) => { _self.error.title = response.data.error; })) {
        return;
      }

      if (_self.delayUpdate) {
        _self.updateModel();
        _self.toggleEditMode(false);
        return;
      }

      // prep data for PUTing
      apiData.shootTypeCode = apiData.shootType;
      delete apiData.tasks;
      delete apiData.product;
      delete apiData.merchJob;
      delete apiData.assetJobs;
      delete apiData.shootType;

      _self.working = true;

      apiData.studioName = apiData.studio.studioName;

      JobService.update(apiData)
        .then(function(response) {
          _self.toggleEditMode(false);
          _self.onChange({ $event: { job: response.data } });
        })
        .catch(function(response) {
          _self.error.title = response.data.error;
          _self.error.data = response.data.data;
        })
        .finally(function() {
          _self.working = false;
        });
    };

    _self.setShootType = function(option) {
      _self.formData.shootType.value = angular.copy(option);

      if (_self.formData.shootType.value) {
        if (_self.model.jobType === 'External Delivery') {
          _self.formData.shootType.value.data.defaultExpectedViews = ['A'];
        }
        _self.setSelectedViewCodes(_self.formData.shootType.value);
      }
    };

    _self.onSetShootType = function(shootType) {
      if(!!_self.model.merchType && !JobService.validateMerchTypeShootTypeCombination(_self.model.merchType, shootType.data.code, (response) => { _self.error = { title: response.data.error }; })) {
        _self.formData.expectedViewCodes.options = [];
        return;
      }
      // grab shoot stype stuff!
      _self.setShootType(shootType);
      _self.formData.expectedViewCodes.options = [];
      _self.fetchViewCodesFromShootType(shootType.data.code).then(function (data) {
        // now set the defaults?
        _self.formData.expectedViewCodes.options.forEach((view) => {
          view.selected = _self.viewCodes.defaults.includes(view.label);
        });

        _self.validate();
      });
    }

    // Select view codes based on selected Shoot Type's default expected views.
    _self.setSelectedViewCodes = function(option) {
      if (
        _self.formData.expectedViewCodes &&
        _self.formData.expectedViewCodes.options
      ) {
        _self.formData.expectedViewCodes.options.forEach(function(view) {
          view.selected = option.data.defaultExpectedViews
            ? option.data.defaultExpectedViews.indexOf(view.label) >= 0
            : false;
        });
      }
    };

    // Disable view codes that aren't present in the base job.
    _self.disableViewCodes = function() {
      if (
        _self.formData.expectedViewCodes &&
        _self.formData.expectedViewCodes.options
      ) {
        _self.formData.expectedViewCodes.options.forEach(function(view) {
          if (_self.model.jobType === 'External Delivery') {
            view.disabled = true;
          } else {
            view.disabled = !_self.base
              ? false
              : _self.base.expectedViews.indexOf(view.label) === -1;
          }
        });
      }
    };

    _self.updateViewCodesFromFetch = function(res) {
      _self.viewCodes = res;

      _self.formData.expectedViewCodes.options = res.available.map(label => ({
          label,
          selected: !!_self.model.expectedViews && _self.model.expectedViews.length > 0 && _self.model.expectedViews.includes(label),
          disabled: false
        }));
      _self.validate();
    };

    _self.fetchViewCodesFromShootType = function (shootType) {
      var deferred = $q.defer();
      JobService.getViewCodesFromShootTypeMerchType(shootType, _self.model.merchType).then(function (res) {
        _self.updateViewCodesFromFetch(res);
        deferred.resolve({ data: res });
      }, function (err) {
        _self.error = { title: err.title, data: err.data };
        _self.updateViewCodesFromFetch({
          available: [],
          defaults: []
        });
        deferred.reject();
      });
      return deferred.promise;
    }

    $scope.$watch('$ctrl.model.merchType', function() {
      _self.disabled = !_self.model.merchType;
    });

    _self.$onInit = function() {
      _self.working = true;

      // This should be loaded ahead of any saves for validations
      JobService.loadMerchTypeShootTypeMap().then(function() {
        if(!JobService.validateMerchTypeShootTypeCombination(_self.model.merchType, _self.model.shootType.code, (response) => { _self.error.title = response.data.error; })) {
          _self.working = false;
          return;
        }
        // Get a list of all possible view codes
        JobService.getViewCodesFromShootTypeMerchType(_self.model.shootType.code, _self.model.merchType).then(function (res) {
          // set available codes
          _self.updateViewCodesFromFetch(res);
          _self.working = false;
        }, function (error) {
          _self.updateViewCodesFromFetch({
            available: [],
            defaults: []
          });
          _self.working = false;
        });
      });

    };

    _self.$onChanges = function(changes) {
      if (changes.disabled) {
        if (_self.disabled) {
          _self.toggleEditMode(false);
        }
      }

      if (changes.base) {
        _self.base = angular.copy(_self.base);

        if (_self.model) {
          _self.setPermissions();
          _self.resetFormData();
        }
      }

      if (changes.model) {
        if (_self.model === undefined || _self.model.product === undefined) {
          return;
        }

        _self.model = angular.copy(_self.model);
        _self.setPermissions();
        _self.fetchShootTypeData(_self.model);
        
      }
    };

  },
  templateUrl: 'templates/partials/detail/shootDetails.tpl.html'
};

module.exports = shootDetails;
